import { useContext } from "react";
import { AuthContext } from "@sage/state";
import { DealRoomProjectElementType } from "../Types/DealRoom";
import { BasePrompt, CitationInstructions, UserPrompt } from "./Base";
import { Bullets } from "./Bullets";
import { ChartInstructions } from "./Charts";
import { ConversationalChat } from "./Chat";
import { GenerateSlideTemplate } from "./GenerateSlideTemplate";
import { InfoRequests } from "./InfoRequests";
import { ParagraphInstructions } from "./Paragraph";
import { BulletsInstructions } from "./ProjectBullets";
import { Reports } from "./Reports";
import { SmartArt } from "./SmartArt";
import { TableInstructions } from "./Table";
import { Teaser } from "./Teaser";

export function usePrompts() {
	const authState = useContext(AuthContext);

	function buildPrompt(prompt: string): string {
		const basePrompt = BasePrompt.replace("{{teamName}}", authState.team.teamName);
		const userPrompt = UserPrompt.replace("{{firstName}}", authState.user.firstName).replace("{{lastName}}", authState.user.lastName);

		return `${basePrompt}${userPrompt}${prompt}${CitationInstructions}`;
	}

	function conversationalChat(): string {
		return `
            ${BasePrompt}

            # For Charts:
            ${ChartInstructions}

            # For Tables:
            ${TableInstructions}
        `;
	}

	function bulletPoints(): string {
		return buildPrompt(Bullets);
	}

	function reportWriting(): string {
		return buildPrompt(Reports);
	}

	function teaser(): string {
		return buildPrompt(Teaser);
	}

	function userDefault(): string {
		const userPreference = localStorage.getItem("AthenaResponseStyle") || "Chat";

		switch (userPreference) {
			case "Chat":
				return conversationalChat();
			case "Bullet Points":
				return bulletPoints();
			case "Report Writing":
				return reportWriting();
			case "No Preprompt":
				return "";
		}
	}

	function dealRoom(element_type: DealRoomProjectElementType): string {
		if (element_type === DealRoomProjectElementType.Chart) {
			return ChartInstructions;
		} else if (element_type === DealRoomProjectElementType.Table) {
			return TableInstructions;
		} else if (element_type === DealRoomProjectElementType.Paragraph) {
			return ParagraphInstructions;
		} else if (element_type === DealRoomProjectElementType.Bullets) {
			return BulletsInstructions;
		} else {
			return "";
		}
	}

	function infoRequest(): string {
		return InfoRequests;
	}

	function screenshot(): string {
		return GenerateSlideTemplate;
	}

	return {
		conversationalChat,
		bulletPoints,
		reportWriting,
		userDefault,
		teaser,
		dealRoom,
		infoRequest,
		screenshot
	};
}
