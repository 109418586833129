import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { UserIcon } from "@sage/shared/other";
import { FloatingPane } from "../FloatingPane/FloatingPane";
import "./Share.scss";

export function Share() {
	const authState = useContext(AuthContext);
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();
	const [loading, setLoading] = useState<boolean>(false);

	const { control, setValue } = useForm();
	const formValue = useWatch({ control });

	function closePane() {
		const tools = workspaceState.openTools.filter((name) => name !== "SHARE");

		workspaceDispatch({
			type: WorkspaceAction.SetOpenTools,
			payload: tools
		});
	}

	function toggleUser(user_id: string) {
		setValue(user_id, !formValue[user_id]);
	}

	function updateShareSettings() {
		workspaceState.project.sharedWith?.forEach((user_id) => setValue(user_id, true));
	}

	async function saveShareSettings() {
		setLoading(true);
		const newSharedWith = Object.keys(formValue).filter((key) => formValue[key]);

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: {
				...workspaceState.project,
				sharedWith: newSharedWith
			}
		});

		await ProjectService.UpdateProjectSharedWith({
			project_id: workspaceState.project.project_id,
			sharedWith: newSharedWith
		});
		setLoading(false);
		closePane();
	}

	useEffect(() => {
		if (workspaceState.project) updateShareSettings();
	}, [workspaceState.project]);

	if (workspaceState.openTools.includes("SHARE")) {
		return (
			<div className={"__share-pane-container"}>
				<FloatingPane
					title={"Share with your team"}
					actions={[
						{
							children: "Close",
							variant: ButtonVariant.Secondary,
							borderShape: ButtonBorderShape.Round,
							action: closePane
						},
						{
							children: "Save",
							borderShape: ButtonBorderShape.Round,
							action: saveShareSettings,
							loading
						}
					]}
				>
					<div className={"__project-files-container"}>
						{authState.team.members?.length > 0
							? authState.team.members
									.filter((user) => user.user_id !== authState.user.user_id)
									.map((user) => (
										<div
											className={"file"}
											key={user.user_id}
											onClick={() => toggleUser(user.user_id)}
										>
											<Checkbox
												control={control}
												name={user.user_id}
												defaultValue={false}
											/>
											<UserIcon email={user.email} />
											<div className={"file-name"}>
												{user.firstName} {user.lastName}
											</div>
										</div>
									))
							: null}
					</div>
				</FloatingPane>
			</div>
		);
	} else {
		return null;
	}
}
