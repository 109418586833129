import { Controller } from "react-hook-form";
import "./Checkbox.scss";

export function Checkbox({
	control,
	name,
	defaultValue,
	refocusRef,
	testid,
	onChange
}: {
	control: any;
	name: string;
	defaultValue?: boolean;
	refocusRef?: any;
	testid?: string;
	onChange?: (e: boolean) => void;
}) {
	function handleChange(e, field) {
		e.stopPropagation();
		if (onChange) {
			onChange(!field.value);
		}
		field.onChange(!field.value);
		refocusRef?.current?.focus();
	}

	return (
		<Controller
			defaultValue={defaultValue}
			render={({ field }) => (
				<div
					className={"__checkbox-container"}
					onClick={(e) => handleChange(e, field)}
					data-testid={testid || `${name}-form-control`}
				>
					<div className={"checkbox " + (field.value ? "filled" : "")}>
						<img src={"https://cdn.ccm.vc/sage/icons/check_white_thick.svg"} />
					</div>
				</div>
			)}
			name={name}
			control={control}
		/>
	);
}
