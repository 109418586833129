import { Route, Routes } from "react-router-dom";
import { DealRoomHome } from "../DealRoomHome";
import { InformationRequest } from "../InformationRequest";
import { Meetings } from "../Meetings/Meetings";
import { ProjectEditor } from "../ProjectEditor";
import { SearchHome } from "../SearchHome";
import { SearchResults } from "../SearchResults";

export function DealRoomRouter() {
	return (
		<Routes>
			<Route path=":deal_id">
				<Route
					path=""
					element={<DealRoomHome />}
				/>
				<Route path="search">
					<Route
						path=""
						element={<SearchHome />}
					/>
					<Route
						path=":query"
						element={<SearchResults />}
					/>
				</Route>
				<Route path="phase/:phase_id">
					<Route
						path=""
						element={<DealRoomHome />}
					/>
					<Route path="project">
						<Route
							path=":project_id"
							element={<ProjectEditor />}
						/>
					</Route>
					<Route path="info">
						<Route
							path=":info_request_id"
							element={<InformationRequest />}
						/>
					</Route>
					<Route
						path="meetings"
						element={<Meetings />}
					/>
				</Route>
			</Route>
		</Routes>
	);
}
