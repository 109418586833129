import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	DealRoomProjectElementType,
	IDealRoom,
	IDealRoomCrawl,
	IDealRoomElementTemplate,
	IDealRoomPhase,
	IDealRoomProject,
	IDealRoomSource,
	IDealRoomThread,
	IInfoRequest,
	PhaseStatus
} from "@sage/types";
import { DealRoomService } from "@sage/services";
import { isNullOrUndefined, useDebounce, usePageMeta } from "@sage/utils";

export interface IDealRoomState extends Partial<IDealRoom> {
	phases: IDealRoomPhase[];
	phase_id: string;
	projects: IDealRoomProject[];
	phaseThreads: IDealRoomThread[];
	dealThreads: IDealRoomThread[];
	uploadModalVisible: boolean;
	setUploadModalVisible: Dispatch<SetStateAction<boolean>>;
	addWebDataModalVisible: boolean;
	setAddWebDataModalVisible: Dispatch<SetStateAction<boolean>>;
	createElement: DealRoomProjectElementType;
	setCreateElement: Dispatch<SetStateAction<DealRoomProjectElementType>>;
	browseTemplateModalVisible: boolean;
	setBrowseTemplateModalVisible: Dispatch<SetStateAction<boolean>>;
	updateElement: IDealRoomElementTemplate;
	setUpdateElement: Dispatch<SetStateAction<IDealRoomElementTemplate>>;
	phase: IDealRoomPhase;
	deleteProject: IDealRoomProject;
	setDeleteProject: Dispatch<SetStateAction<IDealRoomProject>>;
	deleteThread: Dispatch<SetStateAction<IDealRoomThread>>;
	setDeleteThread: IDealRoomThread;
	activeThread: IDealRoomThread;
	setActiveThread: Dispatch<SetStateAction<IDealRoomThread>>;
	viewDealSources: boolean;
	setViewDealSources: Dispatch<SetStateAction<boolean>>;
	viewDealWebData: boolean;
	setViewDealWebData: Dispatch<SetStateAction<boolean>>;
	deleteSource: IDealRoomSource;
	setDeleteSource: Dispatch<SetStateAction<IDealRoomSource>>;
	deleteInfoRequest: IInfoRequest;
	setDeleteInfoRequest: Dispatch<SetStateAction<IInfoRequest>>;
	crawls: IDealRoomCrawl[];
	confirmDeleteProject: () => void;
	confirmDeleteInfoRequest: () => void;
	updateDealName: (name: string) => void;
	loadProjects: () => void;
	loadDeal: () => void;
	loadPhaseThreads: () => void;
	loadDealThreads: () => void;
	loadPhases: () => void;
	infoRequests: IInfoRequest[];
}

const initialDealRoomState: IDealRoomState = {
	deal_id: null,
	phases: [],
	phase_id: null,
	projects: [],
	loadProjects: null,
	loadDeal: null,
	phaseThreads: [],
	dealThreads: [],
	loadPhaseThreads: null,
	loadDealThreads: null,
	loadPhases: null,
	phase: null,
	deleteProject: null,
	setDeleteProject: null,
	confirmDeleteProject: null,
	uploadModalVisible: false,
	setUploadModalVisible: null,
	createElement: null,
	setCreateElement: null,
	viewDealSources: null,
	setViewDealSources: null,
	browseTemplateModalVisible: null,
	setBrowseTemplateModalVisible: null,
	updateElement: null,
	setUpdateElement: null,
	updateDealName: null,
	deleteThread: null,
	setDeleteThread: null,
	activeThread: null,
	setActiveThread: null,
	deleteSource: null,
	setDeleteSource: null,
	addWebDataModalVisible: null,
	setAddWebDataModalVisible: null,
	viewDealWebData: null,
	confirmDeleteInfoRequest: null,
	setViewDealWebData: null,
	crawls: null,
	infoRequests: null,
	deleteInfoRequest: null,
	setDeleteInfoRequest: null
};

const DealRoomContext = createContext<IDealRoomState>(initialDealRoomState);

function createDealRoomState(): IDealRoomState {
	const { deal_id, phase_id } = useParams<{ deal_id: string; phase_id: string }>();
	const navigate = useNavigate();
	const [deal, setDeal] = useState(null);
	const [phases, setPhases] = useState([]);
	const [projects, setProjects] = useState([]);
	const [phaseThreads, setPhaseThreads] = useState([]);
	const [dealThreads, setDealThreads] = useState([]);
	const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
	const [createElement, setCreateElement] = useState<boolean>(null);
	const [browseTemplateModalVisible, setBrowseTemplateModalVisible] = useState<boolean>(false);
	const [updateElement, setUpdateElement] = useState<IDealRoomElementTemplate>(null);
	const [deleteProject, setDeleteProject] = useState<IDealRoomProject>(null);
	const [deleteThread, setDeleteThread] = useState<IDealRoomThread>(null);
	const [deleteSource, setDeleteSource] = useState<IDealRoomSource>(null);
	const [activeThread, setActiveThread] = useState<IDealRoomThread>(null);
	const [viewDealSources, setViewDealSources] = useState<boolean>(false);
	const [dealName, setDealName] = useState<string>(null);
	const [addWebDataModalVisible, setAddWebDataModalVisible] = useState<boolean>(false);
	const [viewDealWebData, setViewDealWebData] = useState<boolean>(false);
	const [crawls, setCrawls] = useState<IDealRoomCrawl[]>([]);
	const [infoRequests, setInfoRequests] = useState<IInfoRequest[]>([]);
	const [deleteInfoRequest, setDeleteInfoRequest] = useState<IInfoRequest>(null);

	usePageMeta({
		title: dealName,
		defaultTitle: "Sage AI Dealroom"
	});

	const phase = useMemo(() => phases?.find((p) => p.phase_id === phase_id), [phase_id, phases]);

	async function loadDeal() {
		const _deal = await DealRoomService.GetDealRoom(deal_id);
		setDeal(_deal);
		setDealName(_deal.deal_name);
	}

	async function loadCrawls() {
		setCrawls(await DealRoomService.GetCrawls(deal_id));
	}

	const saveNewDealName = useDebounce(async (name: string) => {
		setDealName(name);
		await DealRoomService.UpdateDealRoomName(deal_id, name);
	});

	async function updateDealName(name: string) {
		setDeal({ ...deal, deal_name: name });
		saveNewDealName(name);
	}

	async function loadPhases() {
		let _phases = await DealRoomService.GetPhases(deal_id);
		_phases = _phases.sort((a, b) => a.phase_index - b.phase_index);
		setPhases(_phases);

		if (isNullOrUndefined(phase_id)) {
			const inProgress = _phases.filter((phase) => phase.phase_status === PhaseStatus.InProgress);
			if (inProgress.length) {
				navigate(`./phase/${inProgress[0].phase_id}`, { replace: true });
			} else {
				const unFinished = _phases.filter((phase) => phase.phase_status !== PhaseStatus.Finished);

				if (unFinished.length) {
					navigate(`./phase/${unFinished[0].phase_id}`, { replace: true });
				} else {
					navigate(`./phase/${_phases[0].phase_id}`, { replace: true });
				}
			}
		}
	}

	async function loadInfoRequests() {
		setInfoRequests(await DealRoomService.GetInfoRequestsByPhase(phase_id));
	}

	async function loadProjects() {
		if (phase_id) {
			const _projects = await DealRoomService.GetProjects(phase_id);
			setProjects(_projects.sort((a, b) => a.lastModifiedTimestamp - b.lastModifiedTimestamp));
		}
	}

	async function loadDealThreads() {
		const _threads = await DealRoomService.GetThreadsByDeal(deal_id);
		setDealThreads(_threads.sort((a, b) => a.lastModifiedTimestamp - b.lastModifiedTimestamp));
	}

	async function loadPhaseThreads() {
		if (phase_id) {
			const _threads = await DealRoomService.GetThreadsByPhase(phase_id);
			setPhaseThreads(_threads.sort((a, b) => a.lastModifiedTimestamp - b.lastModifiedTimestamp));
		}
	}

	async function confirmDeleteProject() {
		if (deleteProject) {
			await DealRoomService.DeleteProject(deleteProject.project_id);
			setDeleteProject(null);
			loadProjects();
		}
	}

	async function confirmDeleteThread() {
		if (deleteThread) {
			await DealRoomService.DeleteDealThread(deleteThread.thread_id);
			setDeleteThread(null);
			loadDealThreads();
		}
	}

	async function confirmDeleteInfoRequest() {
		if (deleteInfoRequest) {
			await DealRoomService.DeleteInfoRequest(deleteInfoRequest.request_id);
			setDeleteInfoRequest(null);
			loadInfoRequests();
		}
	}

	useEffect(() => {
		loadDeal();
		loadDealThreads();
		loadPhases();
		loadCrawls();
	}, [deal_id]);

	useEffect(() => {
		loadProjects();
		loadPhaseThreads();
		loadInfoRequests();
	}, [phase_id]);

	return {
		...deal,
		phases,
		phase,
		phase_id,
		projects,
		loadProjects,
		loadDeal,
		phaseThreads,
		dealThreads,
		loadDealThreads,
		loadPhaseThreads,
		loadPhases,
		uploadModalVisible,
		setUploadModalVisible,
		createElement,
		setCreateElement,
		browseTemplateModalVisible,
		setBrowseTemplateModalVisible,
		updateElement,
		setUpdateElement,
		updateDealName,
		deleteProject,
		setDeleteProject,
		confirmDeleteProject,
		confirmDeleteThread,
		setDeleteThread,
		deleteThread,
		activeThread,
		setActiveThread,
		deleteSource,
		setDeleteSource,
		viewDealSources,
		setViewDealSources,
		addWebDataModalVisible,
		setAddWebDataModalVisible,
		viewDealWebData,
		setViewDealWebData,
		crawls,
		infoRequests,
		deleteInfoRequest,
		setDeleteInfoRequest,
		confirmDeleteInfoRequest
	};
}

interface IExtendedDealRoomState extends IDealRoomState {}

export function useDealRoom(): IExtendedDealRoomState {
	const dealRoomState = useContext(DealRoomContext);

	return dealRoomState;
}

export function DealRoomProvider({ children }: { children: ReactNode }) {
	const dealRoomState = createDealRoomState();

	return <DealRoomContext.Provider value={dealRoomState}>{dealRoomState.phases?.length > 0 && children}</DealRoomContext.Provider>;
}
