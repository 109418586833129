import React from "react";
import "./InlineLoading.scss";

export function InlineLoading({ invert, dark }: { invert?: boolean; dark?: boolean }) {
	return (
		<div
			className={"__loading-container"}
			data-testid="inline-loading"
		>
			<div className={"loading-inner"}>
				<div className={`bounce1 ${invert ? "invert" : ""} ${dark ? "dark" : ""}`}></div>
				<div className={`bounce2 ${invert ? "invert" : ""} ${dark ? "dark" : ""}`}></div>
				<div className={`bounce3 ${invert ? "invert" : ""} ${dark ? "dark" : ""}`}></div>
			</div>
		</div>
	);
}
