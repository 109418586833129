import { IAnnouncement } from "@sage/types";
import { HttpService } from "../HttpService";

export function GetAnnouncements(): Promise<IAnnouncement[]> {
	return HttpService.Get<IAnnouncement[]>({ path: "/announcements" }).then((res) => res.payload);
}

export function SaveAnnouncementReciept(announcement_id: string) {
	return HttpService.Post({ path: "/announcements/reciepts", body: { announcement_id } });
}
