import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IVaultFile } from "@sage/types";
import { useWorkflowState } from "@sage/state";
import { CompanyAutosuggest } from "@sage/shared/company";
import { Button, ButtonBorderShape, ButtonVariant, Row, Table } from "@sage/shared/core";
import { Checkbox, Form, FormFieldVariant, Tiles } from "@sage/shared/forms";
import { TemplateAutosuggest, VaultFile, VaultFileVariant } from "@sage/shared/other";
import { useVaultHandler } from "@sage/utils";
import "./Review.scss";

export function Review() {
	const { form, updatePageStatus, setActiveFiles } = useWorkflowState();
	const deleteFilesForm = useForm();
	const navigate = useNavigate();
	const activeFiles = useWatch({ control: form.control, name: "activeFiles" });
	const filesToDelete = useWatch({ control: deleteFilesForm.control });
	const vaultHandler = useVaultHandler();

	function removeSelectedFiles() {
		const filesFormValue = deleteFilesForm.getValues();

		for (let file_id of Object.keys(filesFormValue)) {
			filesFormValue[file_id] = !filesFormValue[file_id];
		}

		setActiveFiles(filesFormValue);
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Review");
		return () => {
			updatePageStatus("complete", "Review");
		};
	}, []);

	return (
		<section className="__sage-review-container">
			<h2>Review</h2>
			<p>Before completing this workflow, please review your settings, you can always go back and make changes if you need to.</p>
			<h3>Basic Details</h3>
			<Form
				fields={[
					{
						name: "name",
						label: "Name",
						placeholder: "Project Transport CIM...",
						control: form.control,
						variant: FormFieldVariant.Vertical
					},
					{
						name: "description",
						label: "Description",
						control: form.control,
						type: "textarea",
						variant: FormFieldVariant.Vertical
					}
				]}
			/>
			<Button
				variant={ButtonVariant.Tertiary}
				action={() => navigate("../basic-details")}
			>
				Edit
			</Button>
			<h3>Company</h3>
			<Form
				fields={[
					<CompanyAutosuggest
						control={form.control}
						setValue={form.setValue}
						name={"company"}
						label={"Search for your company"}
					/>
				]}
			/>
			<Button
				variant={ButtonVariant.Tertiary}
				action={() => navigate("../company")}
			>
				Edit
			</Button>
			<h3>Deep Crawl</h3>
			<Tiles
				control={form.control}
				name="use_deepcrawl"
				setValue={form.setValue}
				tiles={[
					{
						id: true,
						label: "Yes",
						description: "Deep Crawl your company"
					},
					{
						id: false,
						label: "No",
						description: "Skip Deep crawl"
					}
				]}
			/>
			<Button
				variant={ButtonVariant.Tertiary}
				action={() => navigate("../deep-crawl")}
			>
				Edit
			</Button>
			<h3>Template</h3>
			<TemplateAutosuggest
				control={form.control}
				setValue={form.setValue}
				description={" "}
			/>
			<Button
				variant={ButtonVariant.Tertiary}
				action={() => navigate("../template")}
			>
				Edit
			</Button>
			<h3>Data Sources</h3>
			<Table
				items={activeFiles || []}
				emptyText={"No active files selected"}
				row={(file: IVaultFile) => (
					<VaultFile
						selection={
							<Checkbox
								control={deleteFilesForm.control}
								name={file.file_id}
							/>
						}
						onClick={() => vaultHandler.open(file)}
						key={file.file_id}
						file={file}
						variant={VaultFileVariant.Abrev}
					/>
				)}
			/>
			<Row>
				<Button
					variant={ButtonVariant.Tertiary}
					action={() => navigate("../data-sources")}
				>
					Edit
				</Button>
				{Object.values(filesToDelete).filter((v) => v).length > 0 && (
					<Button
						variant={ButtonVariant.Tertiary}
						action={removeSelectedFiles}
					>
						Remove Selected
					</Button>
				)}
			</Row>
			<h3>Ready?</h3>
			<Row>
				<Button
					borderShape={ButtonBorderShape.Round}
					variant={ButtonVariant.Secondary}
				>
					Save For Later
				</Button>
				<Button
					borderShape={ButtonBorderShape.Round}
					action={() => navigate("../results")}
				>
					Next
				</Button>
			</Row>
		</section>
	);
}
