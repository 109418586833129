import React from "react";
import { Controller } from "react-hook-form";
import "./Radio.scss";

export interface IRadioProps {
	control: any;
	name: string;
	id: string;
	testid?: string;
}

export function Radio({ control, name, id, testid }: IRadioProps) {
	function handleChange(e, field) {
		e.stopPropagation();
		field.onChange(field.value === id ? null : id);
	}

	return (
		<Controller
			render={({ field }) => (
				<div
					className={"__radio-container"}
					onClick={(e) => handleChange(e, field)}
					data-testid={testid}
				>
					<div className={"radio " + (field.value === id ? "filled" : "")}>
						<div className={"filler"} />
					</div>
				</div>
			)}
			name={name}
			control={control}
		/>
	);
}
