import { IProject } from "@sage/types";
import { HttpService } from "../HttpService/HttpService";

export class FsService {
	static GetRecentProjects() {
		return HttpService.Get<IProject[]>({
			uri: `/workspace-api-v2/projects/user`
		}).then((response) => response.payload);
	}

	static GetSharedProjects() {
		return HttpService.Get<IProject[]>({
			uri: `/workspace-api-v2/projects/team`
		}).then((response) => response.payload);
	}

	static GetFavoritedProjects() {
		return HttpService.Get<IProject[]>({
			uri: `/workspace-api-v2/projects/favorites`
		}).then((response) => response.payload);
	}

	static CreateNewProject(body: { user_id: string; teamCode: string }): Promise<IProject> {
		return HttpService.Post<IProject>({
			uri: `/workspace-api-v2/projects`,
			body
		}).then((response) => response.payload);
	}

	static DeleteProjects(body: { project_ids: string[] }) {
		return Promise.all(
			body.project_ids.map((project_id) =>
				HttpService.Delete({
					uri: `/workspace-api-v2/projects/${project_id}`
				}).then((response) => response.payload)
			)
		);
	}
}
