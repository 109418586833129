import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IArticle } from "@sage/types";
import { NewsService } from "@sage/services";
import { Row } from "@sage/shared/core";
import { Article } from "@sage/shared/other";

export function CompanyNews() {
	const [news, setNews] = useState<IArticle[]>(null);

	const params = useParams();

	async function loadNews() {
		setNews(await NewsService.GetCompany(params.uid));
	}

	useEffect(() => {
		if (params.uid) loadNews();
	}, [params.uid]);

	return news?.length ? (
		<Row>
			{news.map((article) => (
				<Article
					key={article.uid}
					article={article}
				/>
			))}
		</Row>
	) : null;
}
