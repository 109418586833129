import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { ITemplate } from "@sage/types";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService } from "@sage/services";
import {
	ButtonBorderShape,
	ButtonVariant,
	InlineLoading,
	Modal,
	Table,
	TableColumn,
	TableColumnSpacing,
	TableRow,
	TableVariant
} from "@sage/shared/core";
import { Radio } from "@sage/shared/forms";
import { UserIcon, UserTag } from "@sage/shared/other";
import { FloatingPane } from "../FloatingPane/FloatingPane";
import "./Templates.scss";

export function Templates({ onDismiss }) {
	const authState = useContext(AuthContext);
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { control, setValue, reset } = useForm();
	const formValue = useWatch({ control });

	async function generateTemplate() {
		const template_ = workspaceState.templates.find((t) => t.template_id === formValue["selectedTemplate"]);

		workspaceDispatch({
			type: WorkspaceAction.SetGenerateTemplate,
			payload: template_
		});
	}

	function toggleTemplate(template_id: string) {
		if (formValue["selectedTemplate"] === template_id) {
			setValue("selectedTemplate", null);
		} else {
			setValue("selectedTemplate", template_id);
		}
	}

	function setEditTemplate() {
		workspaceDispatch({
			type: WorkspaceAction.SetEditTemplate,
			payload: formValue["selectedTemplate"]
		});
	}

	async function createNewTemplate() {
		await ProjectService.CreateTemplate({
			user_id: authState.user.user_id,
			teamCode: authState.user.teamCode
		});

		workspaceDispatch({
			type: WorkspaceAction.LoadTemplates,
			dispatch: workspaceDispatch
		});
	}

	async function deleteTemplate() {
		setDeleteLoading(true);
		const templateToDelete = workspaceState.templates.find((template) => template.template_id === formValue["selectedTemplate"]);
		await ProjectService.DeleteTemplate(templateToDelete.template_id);
		workspaceDispatch({
			type: WorkspaceAction.LoadTemplates,
			dispatch: workspaceDispatch
		});
		setDeleteLoading(false);
		setShowDeleteModal(false);
		reset({});
	}

	useEffect(() => {
		setValue("selectedTemplate", workspaceState.editTemplate);
	}, [workspaceState.editTemplate]);

	return (
		<FloatingPane
			title={"Templates"}
			actions={[
				{
					children: "Close",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Secondary,
					action: onDismiss,
					testid: "templates-pane-close"
				},
				{
					children: "Delete",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Error,
					action: () => setShowDeleteModal(true),
					disabled: !formValue["selectedTemplate"],
					testid: "templates-pane-delete"
				},
				{
					children: "Edit",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Secondary,
					action: setEditTemplate,
					disabled: !formValue["selectedTemplate"],
					testid: "templates-pane-edit"
				},
				{
					children: "New",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Secondary,
					action: createNewTemplate,
					testid: "templates-pane-new"
				},
				{
					children: "Generate",
					borderShape: ButtonBorderShape.Round,
					disabled: !formValue["selectedTemplate"],
					action: generateTemplate,
					testid: "templates-pane-generate"
				}
			]}
			resizable
		>
			<div className={"__templates-container"}>
				<Table
					items={workspaceState.templates}
					variant={TableVariant.Inline}
					emptyText={"Looks like you don't have any templates yet, create one to get started!"}
					row={(template: ITemplate) => (
						<TableRow key={template.template_id}>
							<TableColumn width={"0"}>
								<Radio
									control={control}
									name={"selectedTemplate"}
									id={template.template_id}
									testid="select-template"
								/>
							</TableColumn>
							<TableColumn
								icon={
									<img
										alt={"template"}
										src={`https://cdn.ccm.vc/sage/icons/new-template.svg`}
										width={`18px`}
									/>
								}
							>
								{template?.templateName}
							</TableColumn>
							<TableColumn
								width={"10rem"}
								spacing={TableColumnSpacing.Fixed}
							>
								<UserIcon
									user_id={template.user_id}
									size={"sm"}
								/>
								<UserTag
									user_id={template?.user_id}
									transparent
								/>
							</TableColumn>
						</TableRow>
					)}
				/>
				{workspaceState.templatesLoading ? (
					<div className={"loading-wrapper"}>
						<InlineLoading invert={true} />
					</div>
				) : null}
				{formValue["selectedTemplate"] && (
					<DeleteTemplateModal
						template={workspaceState.templates.find((template) => template.template_id === formValue["selectedTemplate"])}
						visible={showDeleteModal}
						onDismiss={() => setShowDeleteModal(false)}
						confirm={deleteTemplate}
						loading={deleteLoading}
					/>
				)}
			</div>
		</FloatingPane>
	);
}

interface IDeleteTemplateModalProps {
	template: ITemplate;
	visible: boolean;
	onDismiss: () => void;
	confirm: () => void;
	loading: boolean;
}

function DeleteTemplateModal({ template, visible, onDismiss, confirm, loading }: IDeleteTemplateModalProps) {
	if (template) {
		return (
			<Modal
				visible={visible}
				onDismiss={onDismiss}
				title={"Delete Template"}
				subtitle={"Are you sure you want to delete this template? This action cannot be undone."}
				actions={[
					{
						children: "Cancel",
						variant: ButtonVariant.Secondary,
						borderShape: ButtonBorderShape.Round,
						action: onDismiss,
						testid: "templates-pane-cancel-delete"
					},
					{
						children: "Delete",
						variant: ButtonVariant.Error,
						borderShape: ButtonBorderShape.Round,
						action: confirm,
						loading,
						testid: "templates-pane-confirm-delete"
					}
				]}
			>
				<div className="__delete-template-modal-container">
					<TableRow>
						<TableColumn
							icon={
								<img
									alt={"template"}
									src={`https://cdn.ccm.vc/sage/icons/new-template.svg`}
									width={`20px`}
								/>
							}
						>
							{template.templateName}
						</TableColumn>
						<TableColumn
							width={"10rem"}
							spacing={TableColumnSpacing.Fixed}
						>
							<UserIcon
								user_id={template.user_id}
								size={"sm"}
							/>
							<UserTag
								user_id={template.user_id}
								transparent
							/>
						</TableColumn>
					</TableRow>
				</div>
			</Modal>
		);
	} else return null;
}
