import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ICompanyProduct } from "@sage/types";
import { DealstreamService } from "@sage/services";
import {
	Button,
	ButtonBorderShape,
	ButtonIcon,
	ButtonVariant,
	Card,
	CardList,
	CardListItem,
	CardListItemText,
	CardListItemTitle,
	CardTitle
} from "@sage/shared/core";

export function Products({ products }: { products: ICompanyProduct[] }) {
	return products.length ? (
		<Card>
			<CardTitle>Products</CardTitle>
			<CardList>
				{products.map((product) => (
					<Product
						key={product.name}
						product={product}
					/>
				))}
			</CardList>
		</Card>
	) : null;
}

function Product({ product }: { product: ICompanyProduct }) {
	let [expansion, setExpansion] = useState<string>(null);
	let [expansionLoading, setExpansionLoading] = useState<boolean>(false);

	const params = useParams();

	async function expand() {
		if (params.uid) {
			setExpansionLoading(true);
			const expansionPrompt = `Product Details:\nName: ${product.name}\nDescription: ${product.description}`;

			const response = await DealstreamService.Expand(params.uid, expansionPrompt, setExpansion);
			setExpansionLoading(false);
			console.log(response.sources);
		}
	}

	return (
		<CardListItem>
			<CardListItemTitle>{product.name}</CardListItemTitle>
			<CardListItemText>{product.description}</CardListItemText>
			{expansion ? (
				<CardListItemText data-testid="company-products-expansion">{expansion}</CardListItemText>
			) : (
				<Button
					variant={ButtonVariant.Secondary}
					icon={ButtonIcon.SageLogo}
					borderShape={ButtonBorderShape.Round}
					action={expand}
					loading={expansionLoading}
					disabled={expansionLoading}
				>
					Expand
				</Button>
			)}
		</CardListItem>
	);
}
