export function isNullOrUndefined(input: any) {
	return input === null || input === undefined;
}

export function notNullOrUndefined(input: any) {
	return !isNullOrUndefined(input);
}

export function sanitizeFormInput(input: any) {
	if (isNullOrUndefined(input)) {
		return "";
	} else {
		return input;
	}
}
