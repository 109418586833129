import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function FinancialStatementSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "KFPDBBSGWPBBSQLD"
									})
								);
							}}
						>
							Monthly Income Statement Comparison
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CRSJPMBLLBABZWAB"
									})
								);
							}}
						>
							Quarterly Income Statement Trends
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "DYVRXZUCGPMBPAXM"
									})
								);
							}}
						>
							Annual Income Statement Summary
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "EWHSRECMMQLJKCZY"
									})
								);
							}}
						>
							Income Statement Common Size Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "QMYYJLEBGGBXWXRC"
									})
								);
							}}
						>
							Income Statement Growth Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "BFPHMYNLGQVZQJZU"
									})
								);
							}}
						>
							Income Statement Segment Breakdown
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "PTKQBGJQFBCHUPWZ"
									})
								);
							}}
						>
							Income Statement by Product Line
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "JSLHAFWNHPUWNPHW"
									})
								);
							}}
						>
							EBITDA Reconciliation Table
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "HQLXKZYJQKHCMGZK"
									})
								);
							}}
						>
							Revenue Bridge Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "FZKZWJPDERUPKNYS"
									})
								);
							}}
						>
							Cost of Goods Sold Breakdown
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "JEDNRGEJAMWAJWRQ"
									})
								);
							}}
						>
							Operating Expense Detail
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WHYETPYAVMERHFVJ"
									})
								);
							}}
						>
							Management Adjusted Income Statement
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"file-spreadsheet"}
					size={"2x"}
					color={"#0051a8"}
				>
					Income Statement
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "BNBYUKKKAAPSXZKQ"
									})
								);
							}}
						>
							Quarterly Balance Sheet Comparison
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "GPVLQTPJNVLFAAQW"
									})
								);
							}}
						>
							Annual Balance Sheet Summary
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "KSMWAXDEDKMUQQTA"
									})
								);
							}}
						>
							Working Capital Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WQURFEFHDDQPAGYC"
									})
								);
							}}
						>
							Asset Breakdown Table
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RZFTMTSTKVPYVCXW"
									})
								);
							}}
						>
							Liability Structure Table
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "BLHZCFAFGYMKTTVU"
									})
								);
							}}
						>
							Fixed Asset Schedule
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "ZMYGHHXXBSVRUEUL"
									})
								);
							}}
						>
							Debt Schedule
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "ZXCCGJSPMDPFVUJU"
									})
								);
							}}
						>
							Inventory Composition
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "HNRMKDWXLYQPUKXP"
									})
								);
							}}
						>
							Accounts Receivable Aging
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "APKYJKSQDDZKFSZF"
									})
								);
							}}
						>
							Accounts Payable Aging
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "JXUSYDYNMRFJBDDK"
									})
								);
							}}
						>
							Capital Structure Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "XZMBWYCSDAKECAHU"
									})
								);
							}}
						>
							Balance Sheet Ratios
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"money-bill-trend-up"}
					size={"2x"}
					color={"#0051a8"}
				>
					Balance Sheet
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RKLYYAMVHFQKUHMD"
									})
								);
							}}
						>
							Monthly P&L Summary
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "NXYRNYCPMWGNVCQL"
									})
								);
							}}
						>
							Quarterly P&L Trends
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "QHGCJUUFJGACWQDF"
									})
								);
							}}
						>
							Annual P&L Comparison
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "EPYWXXLDSUPKUCYN"
									})
								);
							}}
						>
							P&L by Business Unit
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "LEVYDUCMJJVNFXEP"
									})
								);
							}}
						>
							P&L by Product Category
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "FUKVFPYGKVZAZZRR"
									})
								);
							}}
						>
							P&L Margin Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "AAUGFWQAAEDAHEPB"
									})
								);
							}}
						>
							Fixed vs Variable Cost Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "MFJXSWNYAXWRCEHP"
									})
								);
							}}
						>
							Direct Cost Attribution
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "DMWDZDHELEQHPALM"
									})
								);
							}}
						>
							Overhead Allocation
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "LXGTEQKHDXGJPYLZ"
									})
								);
							}}
						>
							Profitability Metrics Table
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "JKLZFSEXTGQXMYXQ"
									})
								);
							}}
						>
							Revenue Stream Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "HGCZLASPCFDRTPQH"
									})
								);
							}}
						>
							Break-even Analysis
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"chart-line-up-down"}
					size={"2x"}
					color={"#0051a8"}
				>
					P&L
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
