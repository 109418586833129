import React, { useEffect, useState } from "react";
import { useDecksState } from "../../DecksState";

export function SpanGroup({ group }: { group: string[] }) {
	const { activeSlide, scale, breakSpanGroup, activeSpanGroup, rewrittenSections, editorSettings } = useDecksState();

	const [bbox, setBBox] = useState({});
	const [fontSize, setFontSize] = useState<number>(12);

	function createBBox() {
		const resolvedSpans = [];

		for (let block of activeSlide.blocks) {
			for (let line of block.lines) {
				for (let span of line.spans) {
					if (group.includes(span.span_id)) {
						resolvedSpans.push(span);
					}
				}
			}
		}

		const ys = [];
		const xs = [];
		const font_size = [];

		for (let span of resolvedSpans) {
			ys.push(span.bbox.y1);
			ys.push(span.bbox.y2);
			xs.push(span.bbox.x1);
			xs.push(span.bbox.x2);
			font_size.push(span.font_size);
		}

		let _bbox = { x1: Math.min(...xs) - 2, x2: Math.max(...xs) + 2, y1: Math.min(...ys) - 2, y2: Math.max(...ys) + 2 };

		_bbox.height = _bbox.y2 - _bbox.y1;
		_bbox.width = _bbox.x2 - _bbox.x1;

		setBBox(_bbox);
		setFontSize(font_size.reduce((a, b) => a + b, 0) / font_size.length);
	}

	function breakGroup() {
		breakSpanGroup(group.join(","));
	}

	useEffect(() => {
		createBBox();
	}, []);

	return (
		<div
			className={`span-group ${activeSpanGroup === group.join(",") ? "active" : ""} ${rewrittenSections[group.join(",")] ? "rewritten" : ""}`}
			style={{
				top: `${bbox.y1 * scale}pt`,
				left: `${bbox.x1 * scale}pt`,
				height: `${bbox.height * scale}pt`,
				width: `${bbox.width * scale}pt`
			}}
		>
			<div className="span-group-title">Section</div>
			<button
				className="delete-group"
				onClick={breakGroup}
			>
				<img
					alt="img-icon"
					src={`https://cdn.ccm.vc/sage/icons/material-close.svg`}
				/>
			</button>
			{rewrittenSections[group.join(",")] && (
				<div
					className="rewritten-section"
					style={{ fontSize: `${fontSize * scale}pt` }}
					contentEditable={editorSettings.canEdit}
				>
					{rewrittenSections[group.join(",")].replace("\n\n", "\n")}
				</div>
			)}
		</div>
	);
}
