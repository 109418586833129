import { useEffect, useRef } from "react";

export interface IPageMetaProps {
	title?: string;
	revertTitle?: boolean;
	defaultTitle?: string;
}

export function usePageMeta({ title, defaultTitle, revertTitle }: IPageMetaProps) {
	const initialTitleRef = useRef(null);

	useEffect(() => {
		if (!initialTitleRef.current) {
			initialTitleRef.current = document.title;
		}

		document.title = title || defaultTitle;

		if (revertTitle) {
			return () => {
				document.title = initialTitleRef.current;
			};
		}
	}, [title]);
}
