import React, { useEffect } from "react";
import { NewsService } from "@sage/services";
import { PageHeader } from "@sage/shared/core";
import { Article } from "@sage/shared/other";
import "./News.scss";

export function TrendingNewsPage() {
	const [articles, setArticles] = React.useState([]);

	function sortArticles(a, b) {
		const aTime = new Date(a.enriched_at).getTime();
		const bTime = new Date(b.enriched_at).getTime();
		return bTime - aTime;
	}

	async function loadArticles() {
		const articles = await NewsService.GetNews();

		setArticles(articles.sort(sortArticles).filter((article) => article.description !== null));
	}

	useEffect(() => {
		loadArticles();
	}, []);

	return (
		<div className={"__trending-news-page-container"}>
			<PageHeader title={"Sage News"} />
			<div className={"articles-wrapper"}>
				{articles.map((article) => (
					<Article
						key={article.uid}
						article={article}
					/>
				))}
			</div>
		</div>
	);
}
