import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DecksService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Radio } from "@sage/shared/forms";
import { VaultFSIcon, VaultIconName } from "@sage/shared/other";
import { useDecksState } from "../../../DecksState";
import { ActiveTab, useToolsPaneState } from "../../ToolsPaneState";
import "./ReferenceSlides.scss";

export function ReferenceSlides() {
	const { activeTab } = useToolsPaneState();

	function calculatePosition() {
		switch (activeTab) {
			case ActiveTab.Template:
				return "300%";
			case ActiveTab.ProjectFiles:
				return "200%";
			case ActiveTab.Vault:
				return "100%";
			case ActiveTab.ReferenceSlides:
				return "0%";
			case ActiveTab.Athena:
				return "-100%";
		}
	}

	return (
		<div
			className={`__decks-tools-pane-reference-decks-container tab`}
			style={{ left: calculatePosition() }}
		>
			<ReferenceDecksList />
			<DetailReference />
		</div>
	);
}

function DetailReference() {
	const { activeReferenceDeck, setActiveReferenceDeck } = useDecksState();

	const [slides, setSlides] = useState<{ deck_id: string; slide_id: string; idx: number }[]>(null);

	function calculateTop() {
		if (activeReferenceDeck) {
			return "0";
		} else {
			return "-100%";
		}
	}

	async function loadDeck() {
		if (activeReferenceDeck) setSlides(await DecksService.getSlidesShort(activeReferenceDeck?.deck_id));
	}

	useEffect(() => {
		loadDeck();
	}, [activeReferenceDeck]);

	return (
		<div
			className="subsection"
			style={{ top: calculateTop() }}
		>
			<div>
				<p>{activeReferenceDeck?.deck_name}</p>
				<div className="reference-slides">
					{slides?.map((slide) => (
						<ReferenceSlide
							key={slide.slide_id}
							slide={slide}
						/>
					))}
				</div>
			</div>
			<Button
				action={() => setActiveReferenceDeck(null)}
				children={"Close"}
				variant={ButtonVariant.Secondary}
				borderShape={ButtonBorderShape.Round}
			/>
		</div>
	);
}

function ReferenceSlide({ slide }) {
	const { getSlideImage, setDraggingSlide } = useDecksState();
	const [imageUrl, setImageUrl] = useState<string>(null);

	async function getImageUrl() {
		if (slide?.slide_id) {
			setImageUrl(await getSlideImage(slide.deck_id, slide.slide_id, "lq"));
		}
	}

	async function handleDragStart() {
		setDraggingSlide(await DecksService.getSlideById(slide.slide_id));
	}

	useEffect(() => {
		getImageUrl();
	}, [slide?.slide_id]);

	return (
		<div
			className={"slide-container"}
			draggable
			onDragStart={handleDragStart}
		>
			<div className="slide-number">{slide.idx + 1}</div>
			{imageUrl && (
				<img
					src={imageUrl}
					onError={(e) => (e.target.src = "https://cdn.ccm.vc/sage/icons/material-edit-document.svg")}
				/>
			)}
		</div>
	);
}

function ReferenceDecksList() {
	const { referenceDecks, removeReferenceDeck, activeReferenceDeck, setActiveReferenceDeck } = useDecksState();
	const selectedDeckForm = useForm();

	function calculateTop() {
		if (activeReferenceDeck) {
			return "100%";
		} else {
			return "0%";
		}
	}

	function removeDeck() {
		const selectedDeck = selectedDeckForm.getValues();
		removeReferenceDeck(referenceDecks.find((deck) => deck.deck_id === selectedDeck.selectedDeck));
	}

	useEffect(() => {
		console.log(activeReferenceDeck);
	}, [activeReferenceDeck]);

	return (
		<div
			className="subsection"
			style={{ top: calculateTop() }}
		>
			<div className="header">Reference Decks</div>
			<div className="decks">
				{referenceDecks.map((deck) => (
					<div
						className={"deck"}
						key={deck.deck_id}
						onClick={() => setActiveReferenceDeck(deck)}
					>
						<Radio
							id={deck.deck_id}
							control={selectedDeckForm.control}
							name={"selectedDeck"}
						/>
						<VaultFSIcon iconName={VaultIconName.Decks} />
						<div className={"deck-name"}>{deck.deck_name}</div>
					</div>
				))}
			</div>
			<div className="vault-actions">
				<Button
					action={removeDeck}
					borderShape={ButtonBorderShape.Round}
					children="Remove"
				/>
			</div>
		</div>
	);
}
