import { IBaseProps } from "./Base";

export interface IDealRoomProjectElement extends IBaseProps {
	element_id: string;
	page_id: string;
	element_template_id: string;
	element_name: string;
	element_type: DealRoomProjectElementType;
	element_text?: string;
	element_idx?: number;
}

export enum DealRoomProjectElementType {
	Chart = "chart",
	Table = "table",
	Bullets = "bullets",
	Paragraph = "paragraph",
	Custom = "Custom Element"
}
