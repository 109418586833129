import { IMeeting } from "@sage/types";
import { HttpService } from "../../HttpService";

export function SaveMeeting(body: Partial<IMeeting>): Promise<IMeeting> {
	return HttpService.Post<IMeeting>({ path: "/dealrooms/meetings", body }).then((res) => res.payload);
}

export function GetMeetings(deal_id: string): Promise<IMeeting[]> {
	return HttpService.Get<IMeeting[]>({ path: `/dealrooms/meetings/deal/${deal_id}` }).then((res) => res.payload);
}

export function DeleteMeeting(meeting_id: string) {
	return HttpService.Delete({ path: `/dealrooms/meetings/${meeting_id}` });
}

export function ExtractMeetingDetails(meeting_id: string): Promise<IMeeting> {
	return HttpService.Get<IMeeting>({ path: `/dealrooms/meetings/${meeting_id}/extract` }).then((res) => res.payload);
}

export function CheckMeetingStatus(meeting_id: string) {
	return HttpService.Get({ path: `/dealrooms/meetings/${meeting_id}/transcribe-status` });
}

export function StartMeetingTranscribe(meeting_id: string) {
	return HttpService.Get({ path: `/dealrooms/meetings/${meeting_id}/transcribe` }).then((res) => res.payload);
}

export async function UploadMeeting({ meeting_id, file, onProgress }) {
	const presignedPost = await HttpService.Get({ path: `/dealrooms/meetings/${meeting_id}/upload` }).then((res) => res.payload);

	const form = new FormData();

	Object.keys(presignedPost.fields).forEach((key) => {
		form.append(key, presignedPost.fields[key]);
	});

	form.append("file", file);

	await UploadFile(presignedPost.url, form, onProgress);
}

function UploadFile(url: string, request, onProgress?: (progress: number) => void): Promise<void> {
	return new Promise((resolve) => {
		const xhr = new XMLHttpRequest();

		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentComplete = (event.loaded / event.total) * 100;
				onProgress(percentComplete);
			}
		};

		xhr.onload = () => {
			resolve();
		};

		xhr.open("POST", url, true);

		xhr.send(request);
	});
}
