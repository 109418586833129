import React, { useEffect, useRef, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import { DealstreamService } from "@sage/services";
import { Autosuggest } from "@sage/shared/forms";

export interface ICompanyAutosuggestProps {
	control: Control;
	name: string;
	label?: string;
	description?: string;
	setValue: (name: string, value: any) => any | void;
	onChange?: (company) => void;
	company_field_name?: string;
}

export function CompanyAutosuggest({
	control,
	name,
	label,
	description,
	setValue,
	onChange,
	company_field_name
}: ICompanyAutosuggestProps) {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [companies, setCompanies] = useState([]);
	const fieldRef = useRef(null);

	const companyName = useWatch({ control, name });

	async function handleFieldChange(e) {
		fieldRef.current = e;
		setLoading(true);
		setOptions([]);
		setTimeout(async () => {
			if (fieldRef.current == e) {
				const companies_ = await DealstreamService.SearchCompany(e);
				setCompanies(companies_);
				setOptions(
					(
						await Promise.all(
							companies_.map(async (company) => {
								const image_url = await DealstreamService.GetCompanyImage(company.image_loc);
								return {
									value: company["legal_name"],
									label: company["legal_name"],
									description: company["description"],
									image: image_url.url,
									score: company["_score"],
									url: `/app/fs/private-company/${company["uid"]}`,
									uid: company["uid"],
									_id: company["uid"],
									_source: company
								};
							})
						)
					).sort((a, b) => b.score - a.score)
				);
				setLoading(false);
			}
		}, 1500);
	}

	function handleClick(option) {
		setValue("company_id", option.uid);

		if (company_field_name) {
			const company = companies.find((comp) => comp.uid === option.uid);
			setValue(company_field_name, company);
		}

		if (onChange) onChange(option);
	}

	function handleClear() {
		setValue("company_id", null);
		setValue(name, null);
		if (company_field_name) {
			setValue(company_field_name, null);
		}
		if (onChange) onChange(null);
	}

	useEffect(() => {
		if (options.length === 0 && companyName?.length) {
			handleFieldChange(companyName);
		}
	}, [companyName]);

	return (
		<Autosuggest
			control={control}
			name={name}
			options={options}
			setValue={setValue}
			onChange={handleFieldChange}
			label={label}
			description={description}
			onClick={handleClick}
			onClear={handleClear}
			loading={loading}
		/>
	);
}
