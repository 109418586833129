import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IDealRoomSource } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Col, DeleteConfirmation, Modal, P, Row, SectionTitle } from "@sage/shared/core";
import { SourceFile } from "@sage/shared/dealrooms";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { isNullOrUndefined, useDebounce } from "@sage/utils";
import "./ViewDealSources.scss";

export function ViewDealSourcesModal() {
	const { viewDealSources, setViewDealSources, deal_id } = useDealRoom();
	const [sources, setSources] = useState<IDealRoomSource[]>([]);
	const [allSources, setAllSources] = useState<IDealRoomSource[]>([]);
	const [sourceToDelete, setSourceToDelete] = useState<IDealRoomSource>();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const form = useForm();

	async function loadSources() {
		const _sources = await DealRoomService.GetSourcesByDeal(deal_id);
		setAllSources(_sources);
		setSources(_sources);
	}

	function dismiss() {
		setViewDealSources(false);
		setSources([]);
		setAllSources([]);
	}

	const updateSources = useDebounce(setSources, 100);

	function search(query) {
		setSearchTerm(query);
		if (query.length) {
			updateSources(allSources.filter((source) => source.file_name.toLowerCase().includes(query.toLowerCase())));
		} else {
			updateSources(allSources);
		}
	}

	async function deleteSource() {
		setSources((s) => s.filter((source) => source.source_id !== sourceToDelete.source_id));
		setAllSources((s) => s.filter((source) => source.source_id !== sourceToDelete.source_id));

		await DealRoomService.DeleteSource(deal_id, sourceToDelete.source_id);
		setSourceToDelete(null);
	}

	function updateSourceToDelete(source_id: string) {
		setSourceToDelete(allSources.find((s) => s.source_id === source_id));
	}

	useEffect(() => {
		if (viewDealSources) {
			loadSources();
		}
	}, [viewDealSources]);

	return (
		<Modal
			visible={viewDealSources}
			onDismiss={dismiss}
		>
			<div className="__view-deal-source-modal">
				<SectionTitle>Sources</SectionTitle>
				<Col>
					<FormField
						control={form.control}
						name="search"
						variant={FormFieldVariant.Vertical}
						placeholder="Search..."
						onChange={(e) => search(e)}
					/>
					{sources.map((source) => (
						<SourceFile
							key={source.source_id}
							source={source}
							deleteSource={updateSourceToDelete}
							searchTerm={searchTerm}
						/>
					))}
					{sources.length === 0 && (
						<Row horizontalAlign="center">
							<P>No Sources</P>
						</Row>
					)}
				</Col>
			</div>
			<DeleteConfirmation
				visible={!isNullOrUndefined(sourceToDelete)}
				onDismiss={() => setSourceToDelete(null)}
				onConfirm={deleteSource}
			>
				<SourceFile source={sourceToDelete} />
			</DeleteConfirmation>
		</Modal>
	);
}
