import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { DealFileType, DealStatus, IDeal } from "@sage/types";
import { AuthContext, DealsAction } from "@sage/state";
import { DealsService, DealstreamService, VaultService } from "@sage/services";
import { CompanyAutosuggest } from "@sage/shared/company";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, InlineLoading, Link, Modal, PageHeader, Row } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import { UserAutosuggest } from "../UserAutosuggest/UserAutosuggest";
import "./CreateDealModal.scss";

dayjs.extend(utc);

const tagColors = {
	purple: "green",
	green: "blue",
	blue: "red",
	red: "purple"
};

export function CreateDealModal({
	visible,
	onDismiss,
	activeDeal,
	refresh
}: {
	visible: boolean;
	onDismiss: () => void;
	activeDeal?: IDeal;
	refresh?: () => void;
}) {
	const authState = useContext(AuthContext);
	const { dealsDispatch } = useOutletContext<{ dealsDispatch }>();

	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [company, setCompany] = useState(null);

	const { control, reset, handleSubmit, setValue } = useForm();
	const formValue = useWatch({ control });
	const newTagForm = useForm();
	const fieldArray = useFieldArray({ control, name: "tags" });

	async function createDeal(formValue) {
		setLoading(true);
		if (activeDeal) {
			const owner = authState.team.members.find((user) => `${user.firstName} ${user.lastName}` === formValue.owner).user_id;
			console.log(formValue);
			await DealsService.UpdateDeal({
				...formValue,
				owner,
				endTimestamp: new Date(formValue.endDate).getTime(),
				status: formValue.status.toUpperCase(),
				status_teamCode: `${formValue.teamCode}_${formValue.status.toUpperCase()}`
			});
		} else {
			let owner = undefined;
			if (formValue.owner) {
				owner = authState.team.members.find((user) => `${user.firstName} ${user.lastName}` === formValue.owner).user_id;
			}

			let prefix = `/${authState.user.teamCode}/${authState.user.teamCode}_deals`;

			let deal_folder = await VaultService.CreateFolder({
				teamCode: authState.user.teamCode,
				parent_folder_id: `${authState.user.teamCode}_deals`,
				created_by: authState.user.user_id,
				filename: formValue.name,
				prefix
			});

			await DealsService.CreateDeal({
				...formValue,
				owner,
				created_by: authState.user.user_id,
				teamCode: authState.user.teamCode,
				status: DealStatus.ACTIVE,
				tags: formValue.tags || [],
				endTimestamp: new Date(formValue.endDate).getTime(),
				column: formValue.column || "bake off",
				deal_files: [
					{
						file_id: deal_folder.file_id,
						file_type: DealFileType.FOLDER
					}
				]
			});
		}
		if (refresh) {
			await refresh();
		} else {
			dealsDispatch({
				type: DealsAction.GetDealsByStatus,
				payload: {
					teamCode: authState.user.teamCode,
					status: DealStatus.ACTIVE
				},
				dispatch: dealsDispatch
			});
		}
		setLoading(false);
		onDismiss();
	}

	function addTag(formValue) {
		fieldArray.append({
			name: formValue.tagName,
			color: "purple"
		});
		newTagForm.setValue("tagName", "");
	}

	function toggleTagColor(field) {
		console.log("happened");
		field.onChange(tagColors[field.value]);
	}

	function closeForm() {
		reset();
		onDismiss();
	}

	function handleRemoveTag(e, idx) {
		e.stopPropagation();
		fieldArray.remove(idx);
	}

	function deleteDeal() {
		setDeleteModalVisible(true);
	}

	async function confirmDelete() {
		setDeleteModalVisible(false);
		setDeleteLoading(true);
		await DealsService.DeleteDeal(activeDeal.deal_id);

		dealsDispatch({
			type: DealsAction.GetDealsByStatus,
			payload: {
				teamCode: authState.user.teamCode,
				status: DealStatus.ACTIVE
			},
			dispatch: dealsDispatch
		});

		setDeleteLoading(false);
		onDismiss();
	}

	async function updateCompany(e) {
		await loadCompany({ ...formValue, company_id: e.uid });
	}

	async function loadCompany(deal) {
		if (deal.company_id) {
			const company_ = await DealstreamService.GetCompany(deal.company_id);
			const image_url = await DealstreamService.GetCompanyImage(company_.image_loc);

			setCompany({
				...company_,
				image: image_url.url
			});
		}
	}

	function prefillForm() {
		if (activeDeal) {
			loadCompany(activeDeal);
			const owner = authState.team.members.find((user) => user.user_id === activeDeal.owner);

			const ownerName = `${owner.firstName} ${owner.lastName}`;

			reset({
				...activeDeal,
				owner: ownerName,
				endDate: dayjs.utc(activeDeal?.endTimestamp).format("YYYY-MM-DD")
			});
		} else {
			reset({
				owner: `${authState.user.firstName} ${authState.user.lastName}`,
				teamCode: authState.user.teamCode
			});
		}
	}

	useEffect(() => {
		prefillForm();
	}, [activeDeal]);

	return (
		<Modal
			title={activeDeal ? "Edit Deal" : "Create Deal"}
			visible={visible}
			onDismiss={onDismiss}
			actions={[
				{
					children: "Cancel",
					variant: ButtonVariant.Secondary,
					action: closeForm,
					borderShape: ButtonBorderShape.Round,
					testid: "cancel"
				},
				...(activeDeal
					? [
							{
								children: "Delete",
								variant: ButtonVariant.Error,
								action: deleteDeal,
								loading: deleteLoading,
								borderShape: ButtonBorderShape.Round,
								testid: "delete"
							}
						]
					: []),
				{
					children: "Save",
					action: handleSubmit(createDeal),
					loading,
					borderShape: ButtonBorderShape.Round,
					testid: "save-form"
				}
			]}
		>
			<Modal
				visible={deleteModalVisible}
				onDismiss={() => setDeleteModalVisible(false)}
				title={"Delete Deal"}
				actions={[
					{
						children: "Cancel",
						variant: ButtonVariant.Secondary,
						action: () => setDeleteModalVisible(false),
						borderShape: ButtonBorderShape.Round,
						testid: "cancel-delete"
					},
					{
						children: "Delete",
						variant: ButtonVariant.Error,
						action: () => confirmDelete(),
						borderShape: ButtonBorderShape.Round,
						testid: "confirm-delete"
					}
				]}
			>
				<div>Are you sure you want to delete this deal?</div>
			</Modal>
			<div className={"__create-deal-modal-container"}>
				<div className={"form-col"}>
					<PageHeader
						title={"Details"}
						size={"sm"}
					/>
					{activeDeal && (
						<Row horizontalAlign="start">
							<Button
								variant={ButtonVariant.Tertiary}
								to={`/app/dealroom/${activeDeal?.deal_id}`}
							>
								Go to DealRoom
							</Button>
						</Row>
					)}
					<Form
						fields={[
							{
								name: "name",
								control,
								label: "Deal Name",
								variant: FormFieldVariant.Vertical,
								required: true
							},
							<CompanyAutosuggest
								name={"company_name"}
								control={control}
								label={"Company"}
								setValue={setValue}
								onChange={updateCompany}
							/>,
							formValue.company_id ? (
								<div
									key={formValue.company_id}
									className={"company-card"}
								>
									{company ? (
										<>
											{company.image ? (
												<div className={"image"}>
													<img src={company.image} />
												</div>
											) : null}
											<div className={"col"}>
												<div className={"label"}>
													<a
														className="link"
														href={`/app/fs/private-company/${company.uid}`}
														target={"_blank"}
														data-testid={"deal-company-legal-name"}
													>
														{company.legal_name}
													</a>
												</div>
												{company.description ? <div className={"description"}>{company.description}</div> : null}
											</div>
										</>
									) : (
										<InlineLoading invert />
									)}
								</div>
							) : (
								<span></span>
							),
							{
								name: "description",
								control,
								label: "Description",
								variant: FormFieldVariant.Vertical,
								required: false,
								type: "textarea"
							},
							{
								name: "endDate",
								control,
								label: "Estimated Close Date",
								variant: FormFieldVariant.Vertical,
								required: false,
								type: "date"
							},
							{
								name: "status",
								control,
								label: "Deal Status",
								variant: FormFieldVariant.Vertical,
								required: false,
								type: "select",
								options: ["ACTIVE", "UPCOMING", "FINISHED"]
							},
							{
								name: "column",
								control,
								label: "Deal Workflow",
								variant: FormFieldVariant.Vertical,
								required: false,
								type: "select" as "select",
								options: [
									"uncategorized",
									...(authState.team.settings?.columns || [
										"bake off",
										"pre marketing",
										"marketing",
										"diligence",
										"documentation",
										"closed"
									])
								]
							},
							{
								name: "teamCode",
								control,
								label: "Team",
								variant: FormFieldVariant.Vertical,
								disabled: true,
								type: "text"
							},
							<UserAutosuggest
								name="owner"
								control={control}
								label="Owner"
								setValue={setValue}
							/>
						]}
					/>
					<Form
						handleSubmit={newTagForm.handleSubmit(addTag)}
						fields={[
							{
								control: newTagForm.control,
								name: "tagName",
								variant: FormFieldVariant.Vertical,
								label: "Tags",
								actions: [
									{
										variant: ButtonVariant.IconSecondarySM,
										icon: ButtonIcon.MaterialAdd,
										action: newTagForm.handleSubmit(addTag)
									}
								]
							}
						]}
					/>
					<div className={"tags"}>
						{fieldArray.fields.map((fieldItem, idx) => (
							<Controller
								key={fieldItem.id}
								render={({ field }) => (
									<div
										className={`tag ${field.value}`}
										onClick={() => toggleTagColor(field)}
									>
										<div>{(fieldItem as any).name}</div>
										<div
											className={"remove"}
											onClick={(e) => handleRemoveTag(e, idx)}
										>
											<img
												alt="img-icon"
												src={`https://cdn.ccm.vc/sage/icons/material-close.svg`}
												width={`16px`}
											/>
										</div>
									</div>
								)}
								name={`tags.${idx}.color`}
								control={control}
							/>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
}
