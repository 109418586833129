import { Dispatch } from "react";
import { IProject, ITemplate, IVaultFile } from "@sage/types";

export enum WorkspaceAction {
	LoadProject = "LoadProject",
	SetProject = "SetProject",
	SetActiveDocument = "SetActiveDocument",
	SetActiveOperations = "SetActiveOperations",
	SetActiveCompanies = "SetActiveCompanies",
	SetOpenTools = "SetOpenTools",
	SetToolsPaneOpen = "SetToolsPaneOpen",
	SetActiveSources = "SetActiveSources",
	InsertIntoDocument = "InsertIntoDocument",
	SetEditTemplate = "SetEditTemplate",
	SetGenerateTemplate = "SetGenerateTemplate",
	LoadTemplates = "LoadTemplates",
	SetTemplates = "SetTemplates",
	TogglePromptModal = "TogglePromptModal",
	SetCurrentEditor = "SetCurrentEditor",
	RefreshProject = "RefreshProject",
	SetRefreshProject = "SetRefreshProject"
}

export interface ISetCurrentEditorAction {
	type: WorkspaceAction.SetCurrentEditor;
	payload?: string;
}

export interface ITogglePromptModalAction {
	type: WorkspaceAction.TogglePromptModal;
	payload: string;
}

export interface IInsertIntoDocumentAction {
	type: WorkspaceAction.InsertIntoDocument;
	payload: {
		text: string;
		actor: "USER" | "ASSISTANT";
	};
}

export interface ISetActiveSourcesAction {
	type: WorkspaceAction.SetActiveSources;
	payload: IVaultFile[];
}

export interface ISetActiveCompaniesAction {
	type: WorkspaceAction.SetActiveCompanies;
	payload: string[];
}

export interface ISetOpenToolsAction {
	type: WorkspaceAction.SetOpenTools;
	payload: string[];
}

export interface ISetToolsPaneOpenAction {
	type: WorkspaceAction.SetToolsPaneOpen;
	payload: boolean;
}

export interface ILoadWorkspace {
	type: WorkspaceAction.LoadProject;
	dispatch: Dispatch<ISetProject>;
	payload: {
		project_id: string;
	};
}

export interface ISetProject {
	type: WorkspaceAction.SetProject;
	payload: IProject;
}

export interface IRefreshProject {
	type: WorkspaceAction.RefreshProject;
	payload: {
		project_id: string;
	};
	dispatch: Dispatch<ISetRefreshProject>;
}

export interface ISetRefreshProject {
	type: WorkspaceAction.SetRefreshProject;
	payload: IProject;
}

export interface ISetActiveDocumentAction {
	type: WorkspaceAction.SetActiveDocument;
	payload: string;
}

export interface ISetActiveOperationsAction {
	type: WorkspaceAction.SetActiveOperations;
	payload: string[];
}

export interface ISetEditTemplateAction {
	type: WorkspaceAction.SetEditTemplate;
	payload: string;
}

export interface ISetGenerateTemplateAction {
	type: WorkspaceAction.SetGenerateTemplate;
	payload: ITemplate;
}

export interface ILoadTemplatesAction {
	type: WorkspaceAction.LoadTemplates;
	dispatch: Dispatch<ISetTemplatesAction>;
}

export interface ISetTemplatesAction {
	type: WorkspaceAction.SetTemplates;
	payload: ITemplate[];
}

export type WorkspaceActions =
	| ILoadWorkspace
	| ISetProject
	| ISetActiveDocumentAction
	| ISetActiveOperationsAction
	| ISetOpenToolsAction
	| ISetToolsPaneOpenAction
	| ISetActiveSourcesAction
	| IInsertIntoDocumentAction
	| ISetEditTemplateAction
	| ISetTemplatesAction
	| ILoadTemplatesAction
	| ITogglePromptModalAction
	| ISetCurrentEditorAction
	| IRefreshProject
	| ISetRefreshProject
	| ISetActiveCompaniesAction
	| ISetGenerateTemplateAction;
