import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IDeal } from "@sage/types";
import { DealTags, UserIcon } from "@sage/shared/other";
import "./Card.scss";

dayjs.extend(utc);

export function Card({ deal, editDeal }: { deal: IDeal; editDeal: (deal: IDeal) => void }) {
	return (
		<div
			className="__deals-active-deals-board-card-container"
			onClick={() => editDeal(deal)}
		>
			<div className={"title"}>{deal.name}</div>
			<DealTags tags={deal.tags} />
			<div className={"description"}>{deal.description}</div>
			<div className={"status"}>
				<div className={"col-start"}>
					<UserIcon
						size={"sm"}
						user_id={deal.owner}
					/>
				</div>
				<div className={"col-end"}>
					{deal.endTimestamp ? <div className={"dt"}>{dayjs.utc(deal.endTimestamp).format("MMM D, YYYY")}</div> : null}
				</div>
			</div>
		</div>
	);
}
