import React, { useEffect } from "react";
import { useWorkflowState } from "@sage/state";
import "./GenerateTemplate.scss";

export function GenerateTemplate() {
	const { updatePageStatus } = useWorkflowState();

	useEffect(() => {
		updatePageStatus("in-progress", "Templates", "Generate a Template");
	}, []);

	return (
		<section>
			<h2>Generate a Template</h2>
		</section>
	);
}
