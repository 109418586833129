import { IDealRoomThread } from "@sage/types";
import { Bullet, Col, DateHeader, FaIcon, Link, P, Row, SectionTitle, TimeHeader } from "@sage/shared/core";
import { UserIcon, UserTag } from "@sage/shared/other";
import { isNullOrUndefined } from "@sage/utils";
import "./Thread.scss";

export interface IThreadProps {
	thread: IDealRoomThread;
	deleteThread?: (thread_id: string) => void;
	addThread?: (thread_id: string) => void;
	select?: () => void;
	variant?: ThreadVariant;
}

export enum ThreadVariant {
	DealRoomThread,
	PhaseThread
}

export function Thread({ thread, deleteThread, addThread, variant, select }: IThreadProps) {
	function handleClick(fn) {
		function inner(e, ...args) {
			e.preventDefault();
			e.stopPropagation();
			fn(...args);
		}
		return inner;
	}

	return (
		<div
			className="__sage-deal-room-thread"
			onClick={select}
		>
			<Col>
				<Row
					gap="1rem"
					wrap={false}
					verticalAlign="center"
				>
					<FaIcon
						color={"#d97757"}
						icon={"messages"}
						width="fit-content"
						size={"2x"}
						borderRadius={"0.5rem"}
						paddingInline="0.75rem"
						padding="0.5rem"
						border
						hideBg
						direction="row"
					/>

					<Col gap={"0.25rem"}>
						<SectionTitle inline>{thread.thread_name}</SectionTitle>
						<Row verticalAlign="center">
							<DateHeader
								size="sm"
								thin
							>
								{thread.lastModifiedTimestamp}
							</DateHeader>
							<Bullet size="sm" />
							<TimeHeader size="sm">{thread.lastModifiedTimestamp}</TimeHeader>
						</Row>
					</Col>
				</Row>
				<Row verticalAlign="center">
					<UserIcon
						size="md"
						user_id={thread.user_id}
					/>
					<UserTag user_id={thread.user_id} />
				</Row>
			</Col>
			<P>{thread.thread_description}</P>
			<Row horizontalAlign="right">
				{variant !== ThreadVariant.PhaseThread && !isNullOrUndefined(addThread) && (
					<FaIcon
						icon="rectangle-history-circle-plus"
						color="#0051a8"
						size="lg"
						onClick={(e) => handleClick(addThread)(e, thread.thread_id)}
					/>
				)}
				{!isNullOrUndefined(deleteThread) && (
					<FaIcon
						icon="trash-can"
						color="#a80300"
						size="lg"
						onClick={(e) => handleClick(deleteThread)(e, thread.thread_id)}
					/>
				)}
				{variant !== ThreadVariant.PhaseThread && false && (
					<FaIcon
						icon="thumbtack"
						color="#00a857"
						size="lg"
					/>
				)}
			</Row>
		</div>
	);
}
