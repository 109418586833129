import React from "react";
import { Header, Navigation } from "@sage/shared/other";
import "./AppLayout.scss";

export function AppLayout({ children }: { children: any }) {
	return (
		<div className={"__app-layout-container"}>
			<Header />
			<div className={"layout"}>
				<div className={"child-container"}>{children}</div>
			</div>
		</div>
	);
}
