import React from "react";
import { useFieldArray } from "react-hook-form";
import { Button, ButtonIcon, ButtonVariant } from "@sage/shared/core";
import { FormField, FormFieldVariant } from "../FormField/FormField";
import "./FormList.scss";

export function FormList({ control, name, placeholder }: { control: any; name: string; placeholder?: string }) {
	const fieldArray = useFieldArray({ control, name });

	return (
		<div className={"__form-list-container"}>
			{fieldArray.fields.map((field, idx) => (
				<div
					className={"form-list-item"}
					key={field.id}
				>
					<FormField
						control={control}
						name={`${name}.${idx}.value`}
						variant={FormFieldVariant.Vertical}
						placeholder={placeholder}
					/>
					<Button
						variant={ButtonVariant.IconSecondarySM}
						icon={ButtonIcon.MaterialClose}
						action={() => fieldArray.remove(idx)}
						testid={`${name}.${idx}-form-list-remove-item`}
					/>
				</div>
			))}
			<Button
				action={() => fieldArray.append({ value: "" })}
				variant={ButtonVariant.Icon}
				icon={ButtonIcon.MaterialAddWhite}
				testid={`${name}-form-list-add-item`}
			/>
		</div>
	);
}
