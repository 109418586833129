import React from "react";
import { TeamDetails } from "@sage/shared/core";
import { VaultFS } from "../FS";
import "./VaultHome.scss";

export function VaultHomePage() {
	return (
		<div className={"__vault-home-page-container"}>
			<TeamDetails />
			<VaultFS />
		</div>
	);
}
