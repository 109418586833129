import React, { useContext, useEffect, useState } from "react";
import { DndContext, MouseSensor, TouchSensor, rectIntersection, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, Modal } from "@sage/shared/core";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import "./EditTemplate.scss";
import { TemplateDroppable } from "./TemplateDroppable/TemplateDroppable";
import { TemplateItemHeading1 } from "./TemplateItems/Heading1/Heading1";
import { TemplateItemHeading2 } from "./TemplateItems/Heading2/Heading2";
import { TemplateItemHeading3 } from "./TemplateItems/Heading3/Heading3";
import { TemplateItemParagraph } from "./TemplateItems/Paragraph/Paragraph";
import { TemplateItemPrompt } from "./TemplateItems/Prompt/Prompt";
import { TemplateOptionHeading1 } from "./TemplateOptions/Heading1/Heading1";
import { TemplateOptionHeading2 } from "./TemplateOptions/Heading2/Heading2";
import { TemplateOptionHeading3 } from "./TemplateOptions/Heading3/Heading3";
import { TemplateOptionParagraph } from "./TemplateOptions/Paragraph/Paragraph";
import { TemplateOptionPrompt } from "./TemplateOptions/Prompt/Prompt";

const defaultTextMap = {
	h1: "Heading 1",
	h2: "Heading 2",
	h3: "Heading 3",
	p: "Paragraph",
	prompt: "Prompt"
};

export function EditTemplate() {
	const authState = useContext(AuthContext);
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	const [activeId, setActiveId] = useState<string>(null);
	const [title, setTitle] = useState<string>("Untitled Template");

	const { control, reset } = useForm<{
		items: { id: string; item_type: string; text: string }[];
		templateName: string;
		template_id: string;
		params: { name: string; param_type: string }[];
	}>({
		defaultValues: {
			items: [],
			templateName: "Untitled Template",
			template_id: null,
			params: []
		}
	});
	const fieldArray = useFieldArray({ control, name: "items" });
	const paramsFieldArray = useFieldArray({ control, name: "params" });
	const formValue = useWatch({ control });

	const getIndex = (id) => fieldArray.fields.findIndex((obj) => obj.id === id);

	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	function onDismiss() {
		workspaceDispatch({
			type: WorkspaceAction.SetEditTemplate,
			payload: null
		});
	}

	async function save() {
		const template = {
			...formValue,
			items: formValue.items.map((i) => ({
				id: i.id,
				text: i.text,
				item_type: i.item_type
			}))
		};

		await ProjectService.SaveTemplate(template);

		workspaceDispatch({
			type: WorkspaceAction.LoadTemplates,
			payload: {
				user_id: authState.user.user_id,
				teamCode: authState.user.teamCode
			},
			dispatch: workspaceDispatch
		});

		onDismiss();
	}

	function loadTemplate() {
		const template = workspaceState.templates.find((t) => t.template_id === workspaceState.editTemplate);
		reset(template);
	}

	useEffect(() => {
		loadTemplate();
	}, [workspaceState.editTemplate]);

	return (
		<Modal
			onDismiss={onDismiss}
			visible={!!workspaceState.editTemplate}
			title={
				<Controller
					name={"templateName"}
					control={control}
					render={({ field }) => (
						<div
							className={"__editable_template_title"}
							contentEditable
							onInput={(e) => setTitle(e.currentTarget.textContent)}
							onBlur={(e) => {
								if (title.length === 0) {
									field.onChange("Untitled Template");
									e.currentTarget.textContent = "Untitled Template";
								} else {
									field.onChange(title);
								}
							}}
							suppressContentEditableWarning
						>
							{field.value}
						</div>
					)}
				/>
			}
			actions={[
				{
					variant: ButtonVariant.Secondary,
					children: "Close",
					action: onDismiss,
					borderShape: ButtonBorderShape.Round,
					testid: "close-edit-template"
				},
				{
					variant: ButtonVariant.Primary,
					children: "Save",
					action: save,
					borderShape: ButtonBorderShape.Round,
					testid: "save-edit-template"
				}
			]}
		>
			<div className={"__edit-template-container"}>
				<div className={"parameters"}>
					<div className={"parameters-title"}>Template Parameters</div>
					<div>
						{paramsFieldArray.fields.map((field, idx) => (
							<div
								className={"template-param"}
								key={field.id}
							>
								<FormField
									control={control as any}
									name={`params.${idx}.name`}
									variant={FormFieldVariant.Vertical}
								/>
								<FormField
									control={control as any}
									name={`params.${idx}.param_type`}
									variant={FormFieldVariant.Vertical}
									type={"select"}
									options={["text", "company"]}
								/>
								<Button
									variant={ButtonVariant.IconSecondarySM}
									icon={ButtonIcon.MaterialClose}
									action={() => paramsFieldArray.remove(idx)}
								/>
							</div>
						))}
						<Button
							variant={ButtonVariant.IconSecondarySM}
							icon={ButtonIcon.MaterialAdd}
							action={() =>
								paramsFieldArray.append({
									name: "New Field",
									param_type: "text"
								})
							}
						/>
					</div>
				</div>
				<div className={"t-editor"}>
					<DndContext
						sensors={sensors}
						collisionDetection={rectIntersection}
						onDragStart={({ active }) => {
							if (!active) {
								return;
							}

							setActiveId(active.id);
						}}
						onDragEnd={({ over, active }) => {
							setActiveId(null);

							if (active && over) {
								const overIndex = getIndex(over.id);
								const activeIndex = getIndex(activeId);

								if (!fieldArray.fields.map((obj) => obj.id).includes(activeId)) {
									if (overIndex === -1) {
										fieldArray.append({
											...active,
											id: uuidv4(),
											item_type: active.id as string,
											text: defaultTextMap[active.id]
										});
									} else {
										fieldArray.insert(overIndex, {
											...active,
											id: uuidv4(),
											item_type: active.id as string,
											text: defaultTextMap[active.id]
										});
									}
								} else if (activeIndex !== overIndex) {
									fieldArray.move(activeIndex, overIndex);
								}
							}
						}}
						onDragCancel={() => setActiveId(null)}
					>
						<div className={"library-section"}>
							<TemplateOptionHeading1 />
							<TemplateOptionHeading2 />
							<TemplateOptionHeading3 />
							<TemplateOptionParagraph />
							<TemplateOptionPrompt />
						</div>
						<div className={"template-section"}>
							<TemplateDroppable>
								<SortableContext
									items={fieldArray.fields}
									strategy={rectSortingStrategy}
								>
									{fieldArray.fields.map((item, idx) => {
										switch (item.item_type) {
											case "h1":
												return (
													<TemplateItemHeading1
														key={item.id}
														control={control}
														idx={idx}
														item={item}
														remove={() => fieldArray.remove(idx)}
													/>
												);
											case "h2":
												return (
													<TemplateItemHeading2
														key={item.id}
														control={control}
														idx={idx}
														item={item}
														remove={() => fieldArray.remove(idx)}
													/>
												);
											case "h3":
												return (
													<TemplateItemHeading3
														key={item.id}
														control={control}
														idx={idx}
														item={item}
														remove={() => fieldArray.remove(idx)}
													/>
												);
											case "p":
												return (
													<TemplateItemParagraph
														key={item.id}
														control={control}
														idx={idx}
														item={item}
														remove={() => fieldArray.remove(idx)}
													/>
												);
											case "prompt":
												return (
													<TemplateItemPrompt
														key={item.id}
														control={control}
														idx={idx}
														item={item}
														remove={() => fieldArray.remove(idx)}
													/>
												);
										}
									})}
								</SortableContext>
							</TemplateDroppable>
						</div>
					</DndContext>
				</div>
			</div>
		</Modal>
	);
}
