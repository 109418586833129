import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IVaultFile, VaultFileType } from "@sage/types";
import { AuthContext, useAthenaState } from "@sage/state";
import { VaultService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, PageHeader, Table, TableVariant } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { VaultFile, VaultFileVariant } from "@sage/shared/other";
import { useVaultHandler } from "@sage/utils";
import "./Vault.scss";

export function Vault() {
	const authState = useContext(AuthContext);
	const { parentFile, setParentFile, vaultFiles, setVaultFiles, addSourceFiles } = useAthenaState();
	const [filesLoading, setFilesLoading] = useState(false);
	const { control, reset } = useForm();
	const formValue = useWatch({ control });

	const vaultHandler = useVaultHandler({ navigate });

	async function add() {
		const files_to_add = Object.keys(formValue)
			.filter((k) => formValue[k])
			.map((file_id) => vaultFiles.find((f) => f.file_id == file_id))
			.filter((file) => !!file);

		addSourceFiles(files_to_add);

		reset({});
	}

	async function navigate(parent?: string) {
		setFilesLoading(true);

		if (parent && parent !== authState.user.teamCode) {
			setVaultFiles((await VaultService.LoadDirectory(parent)).filter((file) => file.file_type !== VaultFileType.DOC));
			setParentFile(await VaultService.LoadFile(parent));
		} else {
			setVaultFiles(
				(await VaultService.LoadDirectory(authState.user.teamCode)).filter((file) => file.file_type !== VaultFileType.DOC)
			);
			setParentFile(null);
		}

		setFilesLoading(false);
	}

	function navBack() {
		navigate(parentFile.parent_folder_id);
	}

	useEffect(() => {
		if (!parentFile && vaultFiles.length === 0) {
			navigate();
		}
	}, []);

	return (
		<div className={"__athena-vault-panel"}>
			<div className={"header"}>
				{parentFile ? (
					<Button
						icon={ButtonIcon.MaterialArrowLeft}
						variant={ButtonVariant.IconSecondarySM}
						action={navBack}
						testid="vault-files-pane-nav-back"
					/>
				) : null}
				<div>
					<PageHeader
						title={parentFile?.file_name || "Home"}
						size={"md"}
					/>
				</div>
			</div>
			<Table
				items={vaultFiles}
				variant={TableVariant.Inline}
				emptyText={"This folder is empty"}
				loading={filesLoading}
				row={(file: IVaultFile) => (
					<VaultFile
						selection={
							<Checkbox
								control={control}
								name={file.file_id}
								defaultValue={false}
								testid="select-vault-file"
							/>
						}
						onClick={() => vaultHandler.open(file)}
						key={file.file_id}
						file={file}
						variant={VaultFileVariant.Abrev}
					/>
				)}
				actions={[
					{
						children: "Add",
						action: add,
						borderShape: ButtonBorderShape.Round
					}
				]}
			/>
		</div>
	);
}
