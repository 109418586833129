import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DealRoomService, LlmService } from "@sage/services";
import { Chat } from "@sage/shared/chat";
import { Bullet, Bytes, Col, DateHeader, Link, P, Pad, Row, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import { SourceFile } from "@sage/shared/dealrooms";
import { VaultFSIcon } from "@sage/shared/other";
import { usePrompts } from "@sage/prompts";
import "./SearchResults.scss";

export function SearchResults() {
	const { query, deal_id } = useParams();
	const [results, setResults] = useState([]);
	const [llmResponse, setLlmResponse] = useState("");
	const prompts = usePrompts();

	async function search() {
		const prompt = decodeURIComponent(query);
		const searchResults = await DealRoomService.SearchSources(deal_id, prompt);
		setResults(searchResults);
		await LlmService.Stream(
			{ prompt, context: JSON.stringify(searchResults), preprompt: prompts.conversationalChat() },
			setLlmResponse
		);
	}

	useEffect(() => {
		if (query) {
			search();
		}
	}, [query]);

	return (
		<div className="__sage-search-results">
			<Pad>
				<Col gap="2rem">
					<Chat
						inProgressMessage={llmResponse}
						hideChat
						hideStyle
					/>
					{results.map((result) => (
						<div key={result.source_id}>
							<SourceFile source={result} />
						</div>
					))}
				</Col>
			</Pad>
		</div>
	);
}
