import { ReactNode, useState } from "react";
import { Collapsible } from "../Collapsible";
import "./Dropdown.scss";

export interface IDropdown {
	title: string;
	size?: string;
	position?: string;
	children: ReactNode;
	direction?: string;
}

export function Dropdown({ title, children, size, position, direction }: IDropdown) {
	const [active, setActive] = useState<boolean>(false);

	return (
		<div
			className={`__sage-dropdown-container ${size} ${position}`}
			onClick={() => setActive((e) => !e)}
			onMouseLeave={() => setActive(false)}
			onMouseOver={() => setActive(true)}
		>
			<div className="dropdown-content">
				{title}
				<img
					alt="img-icon"
					src={`https://cdn.ccm.vc/sage/icons/material-arrow-down.svg`}
					width={12}
				/>
			</div>
			<div className={`dropdown-inner ${active ? "open" : "closed"} ${direction || "right"}`}>
				<Collapsible visible={active}>{children}</Collapsible>
			</div>
		</div>
	);
}

export function DropdownOption({ children }: { children: ReactNode }) {
	return <div className="__sage-dropdown-option">{children}</div>;
}
