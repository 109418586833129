import React, { ReactNode, useMemo } from "react";
import prettyBytes from "pretty-bytes";
import { isNullOrUndefined } from "@sage/utils";
import "./Text.scss";

export function H1({ children, xl }: { children: ReactNode; xl?: boolean }) {
	return <h1 className={`__sage-text-h1 ${xl && "xl"}`}>{children}</h1>;
}

export function H2({ children }: { children: ReactNode }) {
	return <h2 className="__sage-text-h2">{children}</h2>;
}

export function H3({ children }: { children: ReactNode }) {
	return <h3 className="__sage-text-h3">{children}</h3>;
}

export function P({ children, maxWidth }: { children: ReactNode; maxWidth?: string }) {
	return (
		<p
			className="__sage-text-p"
			style={{ maxWidth }}
		>
			{children}
		</p>
	);
}

export function Tag({ children, size, caps, color }: { children: ReactNode; size?: string; caps?: boolean; color?: string }) {
	return (
		<div
			className={`__sage-text-tag ${size} ${caps ? "caps" : ""}`}
			style={{ color }}
		>
			{children}
		</div>
	);
}

export function CopySection({ children }: { children: ReactNode }) {
	return <div className="__sage-text-copy-section">{children}</div>;
}

export function SectionTitle({
	children,
	strong,
	inline,
	caps,
	size
}: {
	children: ReactNode;
	strong?: boolean;
	inline?: boolean;
	caps?: boolean;
	size?: string;
}) {
	return (
		<div className={`__sage-text-section-title ${strong ? "strong" : ""} ${inline ? "inline" : ""} ${caps ? "caps" : ""} ${size}`}>
			{children}
		</div>
	);
}

export function DateHeader({ children, size, thin }: { children: number | Date | string; size?: string; thin?: boolean }) {
	return (
		<div className={`__sage-text-date-header ${size} ${thin ? "thin" : ""}`}>
			{new Date(children).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}
		</div>
	);
}

export function DowHeader({
	children,
	size,
	thin,
	light,
	color
}: {
	children: number | Date | string;
	size?: string;
	thin?: boolean;
	light?: boolean;
	color?: string;
}) {
	return (
		<div
			className={`__sage-text-date-header ${size} ${thin ? "thin" : ""} ${light ? "light" : ""}`}
			style={{ color }}
		>
			{new Date(children).toLocaleDateString("en-US", { weekday: "short" })}
		</div>
	);
}

export function DayHeader({
	children,
	size,
	thin,
	light,
	color
}: {
	children: number | Date | string;
	size?: string;
	thin?: boolean;
	light?: boolean;
	color?: string;
}) {
	return (
		<div
			className={`__sage-text-date-header ${size} ${thin ? "thin" : ""} ${light ? "light" : ""}`}
			style={{ color }}
		>
			{new Date(children).toLocaleDateString("en-US", { day: "2-digit" })}
		</div>
	);
}

export function MonthHeader({
	children,
	size,
	thin,
	light,
	color
}: {
	children: number | Date | string;
	size?: string;
	thin?: boolean;
	light?: boolean;
	color?: string;
}) {
	return (
		<div
			className={`__sage-text-date-header ${size} ${thin ? "thin" : ""} ${light ? "light" : ""}`}
			style={{ color }}
		>
			{new Date(children).toLocaleDateString("en-US", { month: "long" })}
		</div>
	);
}

export function TimeHeader({ children, thin, size }: { children: number | Date | string; thin?: boolean; size?: string }) {
	return (
		<div className={`__sage-text-time-header ${size} ${thin ? "thin" : ""}`}>
			{new Date(children).toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true })}
		</div>
	);
}

export function Bullet({ size }: { thin?: boolean; size?: string }) {
	return <div className={`__sage-bullet ${size}`}>•</div>;
}

export function Bytes({ children, thin, size }: { children: number; thin?: boolean; size?: string }) {
	return <div className={`__sage-bytes ${size}`}>{prettyBytes(children)}</div>;
}

export function SearchResult({ children, searchTerm }: { children: string; searchTerm?: string }) {
	const chunks = useMemo(() => {
		if (!isNullOrUndefined(searchTerm) && !isNullOrUndefined(children)) {
			const regex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
			const chunks = children.split(regex);

			if (chunks.length > 1) {
				return chunks.map((ch, idx) => (
					<span key={idx}>
						{ch}
						{idx < chunks.length - 1 && <strong className="__sage-search-result-match-text">{searchTerm}</strong>}
					</span>
				));
			}
		}
		return [children];
	}, [children, searchTerm]);

	return <>{chunks}</>;
}
