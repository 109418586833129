import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { IVaultFile } from "@sage/types";
import { useAthenaState } from "@sage/state";
import { CompanyCard } from "@sage/shared/company";
import { ButtonBorderShape, ButtonVariant, Table, TableColumn, TableRow, TableVariant } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { VaultFile, VaultFileVariant } from "@sage/shared/other";
import { useVaultHandler } from "@sage/utils";
import "./Sources.scss";

export function Sources() {
	const { sourceFiles, sourceCompanies, removeSourceCompanies, removeSourceFiles, toggleActiveSource, toggleActiveCompanies } =
		useAthenaState();
	const { control, reset } = useForm();
	const formValue = useWatch({ control });

	const vaultHandler = useVaultHandler();

	function handleRemove() {
		removeSourceFiles(sourceFiles.map((file) => file.file_id).filter((file_id) => !!formValue[file_id]));
		removeSourceCompanies(sourceCompanies.filter((company_id) => !!formValue[company_id]));
		reset({});
	}

	return (
		<div className="__athena-sources-panel">
			<Table
				items={sourceFiles || []}
				variant={TableVariant.Inline}
				emptyText={!sourceCompanies?.length && "You don't have any active sources"}
				row={(file: IVaultFile) => (
					<VaultFile
						selection={
							<Checkbox
								control={control}
								name={file.file_id}
								testid="select-vault-file"
								onChange={(b: boolean) => toggleActiveSource(file.file_id, b)}
							/>
						}
						onClick={() => vaultHandler.open(file)}
						key={file.file_id}
						file={file}
						variant={VaultFileVariant.Abrev}
					/>
				)}
			/>
			<Table
				items={sourceCompanies || []}
				variant={TableVariant.Inline}
				row={(company_id: string) => (
					<TableRow key={company_id}>
						<TableColumn width={"0"}>
							<Checkbox
								control={control}
								name={company_id}
								testid="select-company_id"
								onChange={(b: boolean) => toggleActiveCompanies(company_id, b)}
							/>
						</TableColumn>
						<TableColumn>
							<CompanyCard company_id={company_id} />
						</TableColumn>
					</TableRow>
				)}
				actions={[
					{
						children: "Remove",
						variant: ButtonVariant.Error,
						borderShape: ButtonBorderShape.Round,
						action: handleRemove
					}
				]}
			/>
		</div>
	);
}
