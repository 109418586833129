import { DealRoomProjectPageType } from "@sage/types";
import { useDealRoomProject } from "@sage/state";
import { RibonMenu, RibonSection } from "@sage/shared/core";
import { PageIcon } from "../../Shared";

export function PagesSection({ title, id }: { title: string; id: string }) {
	const { addPage } = useDealRoomProject();
	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.BusinessOverview}
					onClick={() => addPage(DealRoomProjectPageType.BusinessOverview)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.CompanyHistory}
					onClick={() => addPage(DealRoomProjectPageType.CompanyHistory)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.FinancialAnalysis}
					onClick={() => addPage(DealRoomProjectPageType.FinancialAnalysis)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.IndustryAnalysis}
					onClick={() => addPage(DealRoomProjectPageType.IndustryAnalysis)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.SalesAndMarketing}
					onClick={() => addPage(DealRoomProjectPageType.SalesAndMarketing)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.ManagementTeam}
					onClick={() => addPage(DealRoomProjectPageType.ManagementTeam)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.CustomPage}
					onClick={() => addPage(DealRoomProjectPageType.CustomPage)}
				/>
			</RibonMenu>
			<RibonMenu>
				<PageIcon
					pageType={DealRoomProjectPageType.CoverPage}
					onClick={() => addPage(DealRoomProjectPageType.CoverPage, 0)}
				/>
			</RibonMenu>
		</RibonSection>
	);
}
