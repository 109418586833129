import { IDealRoomCrawl, IDealRoomCrawlSearchResult, IDealRoomCrawlSource } from "@sage/types";
import { HttpService } from "../../HttpService";

export function StartCrawl(base_url: string, crawl_id: string) {
	return HttpService.Post({ path: "/dealrooms/crawls/start", body: { base_url, company_id: crawl_id } });
}

export function SaveCrawl(body: Partial<IDealRoomCrawl>): Promise<IDealRoomCrawl> {
	return HttpService.Post<IDealRoomCrawl>({ path: "/dealrooms/crawls", body }).then((res) => res.payload);
}

export function CreateCrawlSource(body: Partial<IDealRoomCrawlSource>): Promise<IDealRoomCrawlSource> {
	return HttpService.Post<IDealRoomCrawlSource>({ path: "/dealrooms/crawl-sources", body }).then((res) => res.payload);
}

export function GetCrawlSources(element_id: string): Promise<IDealRoomCrawlSource[]> {
	return HttpService.Get<IDealRoomCrawlSource[]>({ path: `/dealrooms/crawl-sources/${element_id}` }).then((res) => res.payload);
}

export function DeleteCrawl(crawl_id: string) {
	return HttpService.Delete({ path: `/dealrooms/crawls/${crawl_id}` });
}

export function GetCrawls(deal_id: string): Promise<IDealRoomCrawl[]> {
	return HttpService.Get<IDealRoomCrawl[]>({ path: `/dealrooms/crawls/deal/${deal_id}` }).then((res) => res.payload);
}

export function CountCrawledPages(host: string): Promise<{ count: number }> {
	return HttpService.Get<{ count: number }>({ path: `/dealrooms/crawls/count/${host}` }).then((res) => res.payload);
}

export function SearchWebData(host: string, query: string): Promise<IDealRoomCrawlSearchResult[]> {
	return HttpService.Post<IDealRoomCrawlSearchResult[]>({ path: `/dealrooms/crawls/search/${host}`, body: { query } }).then((res) =>
		res.statusCode === 200 ? res.payload : []
	);
}
