import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AdminService } from "@sage/services";
import { Button, ButtonBorderShape, Col, PageHeader } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import "./CreateAccount.scss";

export function CreateAccount() {
	const form = useForm();
	const formValue = useWatch({ control: form.control });
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);

	async function createAccount() {
		setLoading(true);
		if (await AdminService.BootstrapTeam(formValue)) {
			navigate("../");
		}
		setLoading(false);
	}

	function updateSupportAccountEmail() {
		const supportAccountEmail = `sage-support-${formValue.teamName?.toLowerCase()?.replace(" ", "-")}@sageai.dev`;
		form.setValue("supportEmail", supportAccountEmail);
	}

	useEffect(() => {
		updateSupportAccountEmail();
	}, [formValue]);

	return (
		<Col>
			<PageHeader title={"Create Account"} />
			<Form
				fields={[
					{
						control: form.control,
						name: "teamName",
						label: "Team Name",
						variant: FormFieldVariant.Vertical
					},
					{
						control: form.control,
						name: "teamCode",
						label: "Team Code",
						variant: FormFieldVariant.Vertical
					},
					{
						control: form.control,
						name: "supportEmail",
						label: "Support Account Email",
						variant: FormFieldVariant.Vertical
					},
					{
						control: form.control,
						name: "supportPassword",
						label: "Support Account Password",
						variant: FormFieldVariant.Vertical
					}
				]}
			/>
			<Button
				borderShape={ButtonBorderShape.Round}
				action={createAccount}
				loading={loading}
			>
				Create
			</Button>
		</Col>
	);
}
