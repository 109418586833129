import React, { useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { AuthAction } from "@sage/state";

export function VaultGuard() {
	const { authDispatch } = useOutletContext<{ authDispatch }>();

	useEffect(() => {
		if (authDispatch)
			authDispatch({
				type: AuthAction.UpdateSearchSettings,
				payload: {
					settings: {
						use_deals: false,
						use_llm: true,
						use_news: false,
						use_sage_news: false,
						use_data: true,
						use_files: true
					}
				}
			});
	}, []);

	return <Outlet context={{ authDispatch }} />;
}
