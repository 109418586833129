import { useNavigate } from "react-router-dom";
import { WorkflowService } from "@sage/services";
import { LinkCard, PageHeader } from "@sage/shared/core";
import "./PickWorkflow.scss";

export function PickWorkflow() {
	const navigate = useNavigate();

	async function createWorkflow(workflow_type: string) {
		const workflow = await WorkflowService.CreateWorkflow(workflow_type);
		navigate(`/app/fs/workflows/run/${workflow.workflow_id}/${workflow_type}`);
	}

	return (
		<div className="__sage-run-workflow-container">
			<PageHeader title="Run Workflow" />
			<div className="cards">
				<LinkCard
					title="CIM"
					content="Create a CIM section with your data room."
					image="https://cdn.sageai.dev/website/assets/templates-closeup-1.png"
					onClick={() => createWorkflow("cim")}
					cta="Create CIM"
				/>
				<LinkCard
					title="Teaser"
					content="Create a teaser with Sage Deep Crawl data or your data room."
					image="https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.png"
					onClick={() => createWorkflow("teaser")}
					cta="Create Teaser"
				/>
				<LinkCard
					title="Answer Buyer Questions"
					subtitle="Coming Soon!"
					content="Answer buyer questions with your data room, or Sage Deep Crawl data."
					image="https://cdn.sageai.dev/website/assets/athena-close-up-1.png"
					href="."
					cta="Coming Soon!"
				/>
				<LinkCard
					title="Research Prospect"
					subtitle="Coming Soon!"
					content="Research a prospect you are pitching using Sage Deep Crawl data."
					image="https://cdn.sageai.dev/website/assets/dealstream-close-up-1.png"
					href="."
					cta="Coming Soon!"
				/>
			</div>
		</div>
	);
}
