import React from "react";
import "./Watermark.scss";

export function Watermark() {
	return (
		<img
			className={"__watermark-img"}
			src={"https://cdn.ccm.vc/sage/logo-lg.svg"}
		/>
	);
}
