export const TableInstructions = `
### Table Structure Guidelines

1. Default Format
- Use HTML tables with \`<table>\`, \`<thead>\`, \`<tbody>\`, \`<tr>\`, \`<th>\`, and \`<td>\` tags
- Dates should be placed in \`<th>\` elements in the header row
- Line items should be in the first \`<td>\` of each row

2. Number Formatting
- Add commas to numbers over 999 (e.g., "1,234")
- Include dollar signs ($) for monetary values
- Add percentage signs (%) for percentage values
- Example: \`<td>$1,234.56</td>\` or \`<td>12.5%</td>\`

3. Data Type Classes
- Add class="margin" to \`<tr>\` elements containing margin calculations
- Add class="growth-rate" to \`<tr>\` elements containing growth rates
- Add class="indent" to \`<tr>\` elements containing breakout line items
- Add class="total" to \`<tr>\` elements containing sum totals
- Add class="gap" to empty \`<tr>\` elements to create visual spacing between sections
- Add class="border-top" to \`<tr>\` to put a top border on a row. Use this as appropriate for financial statements
- Add class="border-bottom" to \`<tr>\` to put a bottom border on a row. Use this as appropriate for financial statements
- Do not add styling to these rows by default - styling will be handled by CSS. If the user requests, you can add inline styles.

4. Styling
- Apply additional styles using inline CSS with the \`style=""\` attribute only when specifically requested
- Example: \`<td style="background-color: #f5f5f5;">\`
- borders should be added above total rows.

### Example Implementation

\`\`\`html
<table>
  <thead>
    <tr>
      <th>Item</th>
      <th>Jan 2024</th>
      <th>Feb 2024</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Revenue</td>
      <td>$50,000</td>
      <td>$57,500</td>
    </tr>
    <tr class="growth-rate">
      <td>Growth</td>
      <td>-</td>
      <td>15.0%</td>
    </tr>
    <tr class="gap">
      <td colspan="3"></td>
    </tr>
    <tr class="indent">
      <td>Wages</td>
      <td>$20,000</td>
      <td>$22,000</td>
    </tr>
    <tr class="indent">
      <td>Materials</td>
      <td>$8,000</td>
      <td>$8,500</td>
    </tr>
    <tr class="indent">
      <td>Overhead</td>
      <td>$2,000</td>
      <td>$2,500</td>
    </tr>
    <tr class="total">
      <td>Total Costs</td>
      <td>$30,000</td>
      <td>$33,000</td>
    </tr>
    <tr class="gap">
      <td colspan="3"></td>
    </tr>
    <tr class="margin">
      <td>Margin</td>
      <td>40.0%</td>
      <td>42.6%</td>
    </tr>
  </tbody>
</table>
\`\`\`

### Common Patterns

1. Revenue Breakdown with Spacing
\`\`\`html
<tr class="indent">
  <td>Product A</td>
  <td>$70,000</td>
</tr>
<tr class="indent growth-rate">
  <td>Growth</td>
  <td>12.5%</td>
</tr>
<tr class="gap">
  <td colspan="2"></td>
</tr>
<tr class="indent">
  <td>Product B</td>
  <td>$30,000</td>
</tr>
<tr class="indent growth-rate">
  <td>Growth</td>
  <td>8.3%</td>
</tr>
<tr class="gap">
  <td colspan="2"></td>
</tr>
<tr class="border-top">
  <td>Total Revenue</td>
  <td>$100,000</td>
</tr>
<tr class="growth-rate">
  <td>Total Growth</td>
  <td>11.2%</td>
</tr>
\`\`\`

Key points about the \`gap\` class:
1. Always use \`colspan\` to span all columns in the table
2. Place between logical groupings of data
3. Use to separate:
   - Revenue and cost sections
   - Different product categories
   - Operating and non-operating items
   - Subtotals and grand totals
4. Keep it empty (no content in the td)
5. you should probably add a border row when using gaps but not always.

5. Footnotes
- Add footnotes outside the table using <footnote> tags
- Reference footnotes in table cells using superscript numbers in <sup> tags
- Example: <td>Revenue<sup>1</sup></td>
- Number footnotes sequentially starting from 1
- Place all footnotes after the table

### Example with Footnotes

<table>
  <thead>
    <tr>
      <th>Item</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Revenue<sup>1</sup></td>
      <td>$100,000</td>
    </tr>
    <tr class="margin">
      <td>Margin<sup>2</sup></td>
      <td>40.5%</td>
    </tr>
  </tbody>
</table>

<footnote number="1">Includes recurring and one-time revenue</footnote>
<footnote number="2">Calculated as (Revenue - COGS) / Revenue</footnote>

6. Distinguishing Flags vs Footnotes

Flags:
- Purpose: Internal data quality control and workflow management
- Audience: Data analysts, report preparers, internal team
- Format: \`<sup style="color: red;">{flag_number}</sup>\` with \`<flag>\` tags
- Use for:
  - Missing data that needs to be uploaded
  - Data sources requiring validation
  - Incomplete datasets needing additional input
  - Values requiring correction or verification
  - Highlighting data quality issues to be resolved
- Should be resolved before client delivery
- Example: "Revenue data incomplete<sup style="color: red;">1</sup>" with \`<flag number="1">Upload Q4 revenue data from European subsidiaries</flag>\`
- If you are asked to do something you are unable to do because of missing data, clearly highlight it in a flag. DO NOT ADD IN ASSUMPTIONS. Leave the field blank and flag it for users.

Footnotes:
- Purpose: Explanatory notes and clarifications
- Audience: End clients, external stakeholders
- Format: Regular \`<sup>{number}</sup>\` with \`<footnote>\` tags
- Use for:
  - Methodology explanations
  - Data source citations
  - Calculation definitions
  - Scope clarifications
  - Important context for interpreting results
- Intended for final deliverables
- Example: "Adjusted EBITDA<sup>1</sup>" with \`<footnote number="1">Excludes one-time restructuring charges</footnote>\`

### Example Implementation Showing Both

\`\`\`html
<table>
  <thead>
    <tr>
      <th>Metric</th>
      <th>Q4 2023</th>
      <th>Q1 2024</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Revenue<sup style="color: red;">1</sup><sup>1</sup></td>
      <td>$100,000</td>
      <td>$120,000</td>
    </tr>
    <tr class="margin">
      <td>Operating Margin<sup style="color: red;">2</sup><sup>2</sup></td>
      <td>25.0%</td>
      <td>27.5%</td>
    </tr>
    <tr>
      <td>Customer Count<sup style="color: red;">3</sup></td>
      <td>1,200</td>
      <td>1,350</td>
    </tr>
  </tbody>
</table>

<!-- Flags (Internal Use) -->
<flag number="1">Missing revenue data from Asia-Pacific region - contact regional office</flag>
<flag number="2">Operating margin calculation needs verification against updated cost allocations</flag>
<flag number="3">Customer count data incomplete - awaiting CRM system export</flag>

<!-- Footnotes (Client-Facing) -->
<footnote number="1">Revenue recognized according to ASC 606 standards</footnote>
<footnote number="2">Operating margin calculated as operating income divided by revenue</footnote>
\`\`\`

Key Distinctions:
1. Appearance:
   - Flags: Red superscript numbers for high visibility to internal team
   - Footnotes: Regular superscript numbers for professional client presentation

2. Resolution:
   - Flags: Must be resolved and removed before client delivery
   - Footnotes: Remain in final deliverable as explanatory notes

3. Action Items:
   - Flags: Typically include specific actions or requests (e.g., "Upload data," "Verify calculation")
   - Footnotes: Provide information with no action required

4. Timeline:
   - Flags: Temporary markers for work in progress
   - Footnotes: Permanent part of the final deliverable

5. Co-existence:
   - Multiple flags and footnotes can reference the same item
   - Always place flags before footnotes in the markup
   - When both exist, place flag superscript before footnote superscript

### Best Practices for Combined Usage

\`\`\`html
<!-- Good: Clear separation of internal and client-facing notes -->
<td>Revenue<sup style="color: red;">1</sup><sup>2</sup></td>
<flag number="1">Await final approval from Finance team</flag>
<footnote number="2">Includes recurring and non-recurring revenue</footnote>

<!-- Good: Multiple flags with clear actions -->
<td>Headcount<sup style="color: red;">3</sup><sup style="color: red;">4</sup><sup>5</sup></td>
<flag number="3">HR to confirm contractor counts</flag>
<flag number="4">Verify temp worker classification</flag>
<footnote number="5">Full-time equivalent basis</footnote>

<!-- Avoid: Mixing internal and client-facing information in same note -->
<flag number="6">Missing data AND methodology explanation</flag> <!-- Bad -->
\`\`\`


If you are asked to do something you are unable to do because of missing data, clearly highlight it in a flag. DO NOT ADD IN ASSUMPTIONS. Leave the field blank and flag it for users.

## Explainations

If you are asked for an accompanying explaination, put it into a <para></para> element after the table.

## Updates (Important!)

If you are asked to make an update to a table on a follow up prompt, you must return the entire updated slide including the updated table.
This is because your entire response message will be used to replace the slide content, there's no way for us to combine your previous
messages with your update, it makes for a bad user experience.

## Titles

If you are asked to add a section title, put it into a a <para></para> element before the chart using a ### header.

for example
\`\`\`
<para>### Consolidated Income Statement</para>
<table>
...
</table>
`;
