import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";
import { validatePassword } from "@sage/utils";

export function CollectEmail() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();
	const [loading, setLoading] = React.useState(false);
	const { control, handleSubmit, reset, setError } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		if (validatePassword(e.password)) {
			setLoading(true);
			if (e.password !== e.confirmPassword) {
				setError("confirmPassword", {
					type: "custom",
					message: "Passwords do not match"
				});
				return;
			}
			let user_id = welcomeState.welcomeForm.user_id;

			if (!user_id) {
				try {
					const createUserResponse = await UserService.CreateUser(e);
					user_id = createUserResponse.user_id;
				} catch (err) {
					setError("email", {
						type: "custom",
						message: "This email is already in use."
					});
					return;
				}
			}

			welcomeDispatch({
				type: WelcomeAction.UpdateWelcomeForm,
				payload: {
					...e,
					user_id
				}
			});

			setLoading(false);

			navigate("/app/welcome/create-account/verify-email");
		} else {
			setError("password", {
				type: "custom",
				message: `Passwords must:\n- Contain at least one lower case letter\n- Contain at least one upper case letter\n- Contain at least one special character\n- Contain at least one number\n- Be at least 8 characters long`
			});
		}
	}

	useEffect(() => {
		if (welcomeState.welcomeForm) {
			reset(welcomeState.welcomeForm);
		}
	}, [welcomeState.welcomeForm]);

	return (
		<Form
			title={"Create an Account"}
			headerAction={{
				variant: ButtonVariant.Tertiary,
				children: "Log in instead",
				to: "/app/welcome/login",
				testid: "navigate-to-login"
			}}
			fields={[
				{
					label: "First Name",
					placeholder: "e.g. Matt",
					control,
					name: "firstName",
					required: true
				},
				{
					label: "Last Name",
					placeholder: "e.g. Carlson",
					control,
					name: "lastName",
					required: true
				},
				{
					label: "Email",
					placeholder: "e.g. matt@sageai.dev",
					control,
					name: "email",
					description:
						"Please enter your email address. If you are signing up to an enterprise account, please use your company email.",
					required: true
				},
				{
					label: "Password",
					placeholder: "* * * * * * * *",
					control,
					name: "password",
					description: "Please enter a password.",
					required: true,
					type: "password"
				},
				{
					label: "Confirm Password",
					placeholder: "* * * * * * * *",
					control,
					name: "confirmPassword",
					description: "Please confirm your password.",
					required: true,
					type: "password"
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Primary,
					children: "Next",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-to-next",
					loading
				}
			]}
		/>
	);
}
