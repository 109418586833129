import React, { useContext, useEffect, useReducer, useRef } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AuthContext, InitialWorkspaceState, WorkspaceAction, WorkspaceContext, WorkspaceReducer } from "@sage/state";
import { LoadingSplash } from "@sage/shared/core";

export function WorkspaceGuard() {
	const authState = useContext(AuthContext);
	const [workspaceState, workspaceDispatch] = useReducer(WorkspaceReducer, InitialWorkspaceState);
	const refreshProjectRef = useRef(null);

	const params = useParams();

	function loadProject() {
		if (params.project_id)
			workspaceDispatch({
				type: WorkspaceAction.LoadProject,
				payload: {
					project_id: params.project_id
				},
				dispatch: workspaceDispatch
			});

		if (authState.user)
			workspaceDispatch({
				type: WorkspaceAction.LoadTemplates,
				dispatch: workspaceDispatch
			});
	}

	function refreshProject() {
		workspaceDispatch({
			type: WorkspaceAction.RefreshProject,
			payload: {
				project_id: params.project_id
			},
			dispatch: workspaceDispatch
		});
	}

	useEffect(() => {
		loadProject();
		refreshProjectRef.current = setInterval(refreshProject, 3000);
		return () => clearInterval(refreshProjectRef.current);
	}, [params.project_id]);

	if (workspaceState.loading) {
		return <LoadingSplash invert />;
	}

	return (
		<WorkspaceContext.Provider value={workspaceState}>
			<Outlet context={{ workspaceDispatch }} />
		</WorkspaceContext.Provider>
	);
}
