import React, { useContext } from "react";
import { FsContext } from "@sage/state";
import { TeamDetails } from "@sage/shared/core";
import { Projects } from "@sage/shared/other";
import "./Favorites.scss";

export function DocsFavorites() {
	const fsState = useContext(FsContext);

	return (
		<div className={"__favorites-page-container"}>
			<TeamDetails />
			<Projects
				title={"Favorite Docs"}
				projects={fsState.favoritedProjects.projects}
				loading={fsState.favoritedProjects.loading}
				emptyText={"It looks like you don't have any favorited docs yet."}
			/>
		</div>
	);
}
