import { IBaseProps } from "./Base";
import { IDealRoomProjectElement } from "./Element";

export interface IDealRoomProjectPage extends IBaseProps {
	page_id: string;
	project_id: string;
	page_index: number;
	page_type: DealRoomProjectPageType;
	page_layout: string;
	page_theme?: string;
	page_name: string;
	elements: IDealRoomProjectElement[];
}

export enum DealRoomProjectPageType {
	BusinessOverview = "Business Overview",
	CompanyHistory = "Company History",
	FinancialAnalysis = "Financials",
	IndustryAnalysis = "Industry",
	SalesAndMarketing = "Sales & Marketing",
	ManagementTeam = "Management",
	CustomPage = "Custom Page",
	CoverPage = "Cover Page"
}

export interface IDealRoomProjectPageTemplate extends IBaseProps {
	page_id: string;
	page_name: string;
	page_title: string;
	page_description: string;
	elements: string[];
	page_layout: string;
}
