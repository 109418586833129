import React, { useState } from "react";
import { IDeal } from "@sage/types";
import { ButtonBorderShape, ButtonIcon, PageHeader, TeamDetails } from "@sage/shared/core";
import { CreateDealModal } from "@sage/shared/other";
import "./ActiveDeals.scss";
import { Board } from "./Kanban/Board/Board";

export function ActiveDeals() {
	const [createDealModalVisible, setCreateDealModalVisible] = useState<boolean>(false);
	const [activeDeal, setActiveDeal] = useState<IDeal>(null);

	function dismissModal() {
		setCreateDealModalVisible(false);
		setActiveDeal(null);
	}

	function editDeal(deal: IDeal) {
		setCreateDealModalVisible(true);
		setActiveDeal(deal);
	}

	return (
		<div className={"__deals-active-deals-container"}>
			{createDealModalVisible ? (
				<CreateDealModal
					visible={createDealModalVisible}
					onDismiss={dismissModal}
					activeDeal={activeDeal}
				/>
			) : null}
			<TeamDetails />
			<PageHeader
				title={"Active Deals"}
				size={"md"}
				actions={[
					{
						children: "New Deal",
						icon: ButtonIcon.MaterialAddWhite,
						action: () => setCreateDealModalVisible(true),
						borderShape: ButtonBorderShape.Round,
						testid: "create-deal"
					}
				]}
			/>
			<div className={"board-container"}>
				<Board editDeal={editDeal} />
			</div>
		</div>
	);
}
