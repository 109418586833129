import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { Button, ButtonBorderShape } from "@sage/shared/core";
import { Tiles } from "@sage/shared/forms";
import "./DataSources.scss";

export function DataSources() {
	const { updatePageStatus, form } = useWorkflowState();
	const source = useWatch({ control: form.control, name: "source_method" });

	const navigate = useNavigate();

	function useExistingSources() {
		updatePageStatus("skip", "Data Sources", "Upload Files");
		navigate("../select-data-sources");
	}

	function uploadFiles() {
		updatePageStatus("skip", "Data Sources", "Select Sources");
		navigate("../upload-data-sources");
	}

	function handleNav() {
		if (source === "existing-sources") {
			useExistingSources();
		} else if (source === "upload-files") {
			uploadFiles();
		}
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Data Sources");

		return () => {
			updatePageStatus("complete", "Data Sources");
		};
	}, []);

	return (
		<section>
			<h2>Data Sources</h2>
			<p>Time to connect your data. You have two options:</p>
			<p>
				<strong>Use Existing Data Sources:</strong> Use data that is already uploaded to vault.
			</p>
			<p>
				<strong>Upload Files:</strong> Upload new data here.
			</p>
			<Tiles
				control={form.control}
				setValue={form.setValue}
				name={"source_method"}
				tiles={[
					{
						id: "existing-sources",
						label: "Existing Sources",
						description: "Connect your existing data sources"
					},
					{
						id: "upload-files",
						label: "Upload Files",
						description: "Upload more files"
					}
				]}
			/>
			<Button
				borderShape={ButtonBorderShape.Round}
				disabled={source === undefined}
				action={handleNav}
			>
				Next
			</Button>
		</section>
	);
}
