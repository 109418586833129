import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function TablesSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CVNXQMKHMLKBGVPX"
									})
								);
							}}
						>
							Historical Income Statement (Monthly)
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RZNAALHKTYYBWTFL"
									})
								);
							}}
						>
							Historical Income Statement (Quarterly)
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "EQLLBTLBMBUWSLVT"
									})
								);
							}}
						>
							Historical Income Statement (Annual)
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "BMLPFAWMDFKCNZHD"
									})
								);
							}}
						>
							Operating Expense Breakdown
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "EGKTHDXNCSADYXLX"
									})
								);
							}}
						>
							Historical EBITDA Performance
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "BAZZUSDHMHGQLRXD"
									})
								);
							}}
						>
							Financial Projections
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "MCLEKJZWDRRQRAHG"
									})
								);
							}}
						>
							Operating Expense Forecast
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "SBGSFMEARXDUWGEP"
									})
								);
							}}
						>
							Management Adjustments
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "TLAMZFGPSRVBYRWX"
									})
								);
							}}
						>
							Cost Structure Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "DULYXYVENUKUFZNV"
									})
								);
							}}
						>
							Employee Cost Structure Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "FTFWPSQRZJEMEBXY"
									})
								);
							}}
						>
							Operating Expense Margin Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CWQRYFEETUERHHCR"
									})
								);
							}}
						>
							Financial Performance Metrics
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"envelope-open-dollar"}
					size={"2x"}
					color={"#0051a8"}
				>
					Financials
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "PYNGCAJZZUELTXCH"
									})
								);
							}}
						>
							Product Portfolio
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "XBNDWHXHZFDHBZYD"
									})
								);
							}}
						>
							Product Category Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "EZFNJDXYSKYHTKCF"
									})
								);
							}}
						>
							Product Sales Volume Mix
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "6JLZKNH9C43S"
									})
								);
							}}
						>
							Components & Accessories List
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"boxes-stacked"}
					size={"2x"}
					color={"#0051a8"}
				>
					Products & Services
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CWGNUVLNNCPRXGVG"
									})
								);
							}}
						>
							Top Supplier Matrix
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "UPGDLLQDDBDADKUQ"
									})
								);
							}}
						>
							Supplier Concentration Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "ZVUZRVNWDTFXMXVP"
									})
								);
							}}
						>
							Supplier Payment Terms
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "LRKBWSXLALASKVPL"
									})
								);
							}}
						>
							Supply Chain Risk Assessment
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "QPPKYRRWFLUEGZVQ"
									})
								);
							}}
						>
							Supplier Cost Comparison
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RQXWJLBNPLHGHPDM"
									})
								);
							}}
						>
							Supplier Geographic Distribution
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"truck-field"}
					size={"2x"}
					color={"#0051a8"}
				>
					Suppliers
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "YMGYUMWLEHBDSBVN"
									})
								);
							}}
						>
							Customer Segment Profiles
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "YKTERHMSDDSDMAUJ"
									})
								);
							}}
						>
							Key Customer Matrix
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "MAJECUFENTFVHEZY"
									})
								);
							}}
						>
							Customer Type Distribution
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WUUANPCVYTFXCBNK"
									})
								);
							}}
						>
							Sales Channel Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "YGLCZHMHWCLNZDZB"
									})
								);
							}}
						>
							Sales Team Structure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RPXWPXYDDTRBZTCH"
									})
								);
							}}
						>
							Sales Cycle Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RJNVLNDLZLRCSKFK"
									})
								);
							}}
						>
							Customer Relationship Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WTNEHTVNPUVNAANR"
									})
								);
							}}
						>
							Customer Retention Metrics
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "PNNVBFGCGPGUWZJG"
									})
								);
							}}
						>
							Customer Lifetime Value Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WQXFMGWQKVMLYGGS"
									})
								);
							}}
						>
							Customer Geographic Distribution
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "ENFUDZFUVZCNGYLB"
									})
								);
							}}
						>
							Customer Support Metrics
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CNKMUEYMQKCZMPKB"
									})
								);
							}}
						>
							Sales Pipeline by Customer
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "VPXDMCHWKLWPCKSZ"
									})
								);
							}}
						>
							Customer Profitability Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "NVYMWZNRRGBVGKQX"
									})
								);
							}}
						>
							Customer Growth Potential
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"cart-shopping"}
					size={"2x"}
					color={"#0051a8"}
				>
					Customers & Sales
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "YTKCJYAAJVMGSRBG"
									})
								);
							}}
						>
							Operational KPIs
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "HLUZNQETVBMDDGAX"
									})
								);
							}}
						>
							Top Supplier Analysis Table
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "XNTGFPWMWVKSUSZF"
									})
								);
							}}
						>
							Facility Lease Summary Table
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WHQFLDKHDRTVQPVT"
									})
								);
							}}
						>
							Production Process Efficiency
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WYNETDERUJVUEALB"
									})
								);
							}}
						>
							Supplier Concentration
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "HSMEXARTFGQHALLP"
									})
								);
							}}
						>
							Engineering Capabilities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "FJJNKEVLAKEJNKKC"
									})
								);
							}}
						>
							Resource Allocation
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"network-wired"}
					size={"2x"}
					color={"#0051a8"}
				>
					Operations
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "LSBRWFYPHTAEATCF"
									})
								);
							}}
						>
							Management Team Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "FCHMTJCLLSXUFYHR"
									})
								);
							}}
						>
							Management Team Responsibilities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CRTFXHAXVAHUXVDD"
									})
								);
							}}
						>
							Management Compensation
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "DYLMNUGHEDNDWQZG"
									})
								);
							}}
						>
							Ownership Structure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "PUCKCVMBFRFDQHAR"
									})
								);
							}}
						>
							Management Reporting Structure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "YNRXAHYFJPRNSAFR"
									})
								);
							}}
						>
							Functional Department Coverage
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CVJXLHUUZWFFEJMS"
									})
								);
							}}
						>
							Management Goals & Objectives
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "KAAPVNYJYPYPAWFU"
									})
								);
							}}
						>
							Management KPIs
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "MFEAKYFRKNMDACAZ"
									})
								);
							}}
						>
							Succession Planning
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"user-tie"}
					size={"2x"}
					color={"#0051a8"}
				>
					Management
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "XJYFMXLPRVDNXUSA"
									})
								);
							}}
						>
							Facility Location Summary
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "XUNLHBKCSSBANKCZ"
									})
								);
							}}
						>
							Facility Lease Terms
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "TFXLDDRJKGUKCDYB"
									})
								);
							}}
						>
							Facility Capacity
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "QWULUNHCPUMXANQD"
									})
								);
							}}
						>
							Equipment & Inventory by Location
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "CNVSHNLPCHWKKQHD"
									})
								);
							}}
						>
							Facility Operating Costs
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "GHAAJZZEKQELKFBA"
									})
								);
							}}
						>
							Facility Staff Allocation
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "JXJKDTKFZUPKYZSQ"
									})
								);
							}}
						>
							Facility Expansion Plans
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "TVEMFRYDZQZTLEWL"
									})
								);
							}}
						>
							Capital Expenditure by Facility
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "WEGWVBUSUDNFQLSE"
									})
								);
							}}
						>
							Facility Performance Metrics
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"industry-windows"}
					size={"2x"}
					color={"#0051a8"}
				>
					Facilities
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
