import { ReactNode } from "react";
import { Badge, Row } from "@sage/shared/core";
import "./Flag.scss";

export function Flag({ children, number }: { children: ReactNode; number: number }) {
	return (
		<div className="__sage-chat-flag-container">
			<Row
				verticalAlign="center"
				gap={"1rem"}
				wrap={false}
			>
				<Badge>{number}</Badge>
				<i>{children}</i>
			</Row>
		</div>
	);
}
