import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IVaultFile, VaultFileType } from "@sage/types";
import { AuthContext } from "@sage/state";
import { DecksService, VaultService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, IDropdownOptionProps, Modal, PageHeader } from "@sage/shared/core";
import { DragUpload, Form, FormFieldVariant } from "@sage/shared/forms";
import { VaultFile } from "../DataItems";
import { GenerateTemplate } from "../GenerateTemplate/GenerateTemplate";

export interface IVaultActionsProps {
	title: string;
	files: IVaultFile[];
	loadFiles: () => void;
	parentFolder: IVaultFile;
	form: any;
	size?: "sm" | "lg";
	editFolderName?: boolean;
}

export function VaultActions({ title, files, loadFiles, parentFolder, form, size }: IVaultActionsProps) {
	const authState = useContext(AuthContext);
	const [showGenerateTemplate, setShowGenerateTemplate] = useState(false);
	const [createFileModalOpen, setCreateFileModalOpen] = useState(false);
	const [uploadFileLoading, setUploadFileLoading] = useState(false);
	const [deleteFileModalOpen, setDeleteFileModalOpen] = useState(false);
	const [deleteFileLoading, setDeleteFileLoading] = useState(false);
	const [editFileModalOpen, setEditFileModalOpen] = useState(false);
	const [editFileLoading, setEditFileLoading] = useState(false);
	const [moving, setMoving] = useState(null);
	const [moveLoading, setMoveLoading] = useState(false);
	const [prefilledFilename, setPrefilledFilename] = useState(null);
	const selectedFile = useWatch({
		control: form.control,
		name: "SelectedFile"
	});
	const editFolderForm = useForm();
	const editFileForm = useForm();

	const fileToUpload = useWatch({ control: form.control, name: "file" });

	function getPrefix() {
		if (parentFolder) {
			return parentFolder.prefix;
		} else {
			return `/${authState.user.teamCode}`;
		}
	}

	async function saveEditFolderName(e) {
		if (e.title?.length > 0) {
			await VaultService.UpdateSource({
				...parentFolder,
				file_name: e.title
			});
		}
	}

	async function createDoc() {
		await VaultService.CreateDoc({
			created_by: authState.user.user_id,
			teamCode: authState.user.teamCode,
			prefix: getPrefix(),
			parent_folder_id: parentFolder?.file_id || authState.user.teamCode
		});
		await loadFiles();
	}

	async function createDeck() {
		await VaultService.CreateDeck({
			created_by: authState.user.user_id,
			teamCode: authState.user.teamCode,
			prefix: getPrefix(),
			parent_folder_id: parentFolder?.file_id || authState.user.teamCode,
			file_name: "Untitled Deck"
		});
		await loadFiles();
	}

	async function createDeckFromFile() {
		const activeFile = files.find((file) => file.file_id === selectedFile);

		await DecksService.createDeckFromFile({
			created_by: authState.user.user_id,
			teamCode: authState.user.teamCode,
			prefix: getPrefix(),
			parent_folder_id: parentFolder?.file_id || authState.user.teamCode,
			reference_file: activeFile
		});
		await loadFiles();
	}

	async function createFolder() {
		await VaultService.CreateFolder({
			teamCode: authState.user.teamCode,
			created_by: authState.user.user_id,
			prefix: getPrefix(),
			parent_folder_id: parentFolder?.file_id || authState.user.teamCode
		});
		await loadFiles();
	}

	async function uploadFile(formValue) {
		setUploadFileLoading(true);
		await VaultService.CreateSource({
			teamCode: authState.user.teamCode,
			created_by: authState.user.user_id,
			prefix: getPrefix(),
			parent_folder_id: parentFolder?.file_id || authState.user.teamCode,
			file_name: formValue.file_name,
			description: formValue.description,
			mime_type: formValue.file.file.type,
			size: formValue.file.file.size,
			file: formValue.file.file,
			original_filename: formValue.file.file.name
		});
		await loadFiles();
		form.reset({});
		setUploadFileLoading(false);
		setCreateFileModalOpen(false);
	}

	async function editFile(formValue) {
		setEditFileLoading(true);
		await VaultService.UpdateSource(formValue);
		await loadFiles();
		setEditFileLoading(false);
		setEditFileModalOpen(false);
	}

	async function confirmDelete() {
		setDeleteFileLoading(true);
		const activeFile = files.find((file) => file.file_id === selectedFile);
		await VaultService.DeleteFile(activeFile);
		await loadFiles();
		form.reset();
		setDeleteFileLoading(false);
		setDeleteFileModalOpen(false);
	}

	function handleOpenEditModal() {
		const activeFile = files.find((file) => file.file_id === selectedFile);
		editFileForm.reset(activeFile);
		setEditFileModalOpen(true);
	}

	function startMove() {
		const activeFile = files.find((file) => file.file_id === selectedFile);
		setMoving(activeFile);
	}

	async function moveHere() {
		setMoveLoading(true);
		await VaultService.MoveFile(moving.prefix, parentFolder ? parentFolder.prefix : `/${authState.user.teamCode}`);
		await loadFiles();
		form.reset();
		setMoveLoading(false);
		setMoving(null);
	}

	async function move() {
		if (!moving) {
			startMove();
		} else {
			await moveHere();
		}
	}

	function prefillFilename() {
		if (fileToUpload) {
			const uploadFormValue = form.getValues();

			if (typeof uploadFormValue.file_name === "undefined" || uploadFormValue.file_name === prefilledFilename) {
				const new_file_name = fileToUpload.file.name.split(".").slice(0, -1).join(".");
				if (uploadFormValue.file_name !== new_file_name) {
					form.setValue("file_name", new_file_name);
					setPrefilledFilename(new_file_name);
				}
			}
		}
	}

	function prefillFolderName() {
		editFolderForm.setValue("title", title);
	}

	useEffect(() => {
		prefillFilename();
	}, [fileToUpload]);

	useEffect(() => {
		prefillFolderName();
	}, [title]);

	return (
		<>
			<PageHeader
				editTitle={true}
				form={editFolderForm}
				save={saveEditFolderName}
				size={size}
				title={title}
				subtitle={moving ? `Moving: ${moving.file_name}` : null}
				actions={[
					{
						variant: ButtonVariant.Secondary,
						borderShape: ButtonBorderShape.Round,
						children: "Actions",
						dropdownPosition: "left",
						testid: "vault-actions",
						options: [
							{
								text: "Edit",
								type: "option",
								action: handleOpenEditModal,
								icon: ButtonIcon.MaterialEditDocument,
								disabled: selectedFile == null,
								testid: "vault-edit-file"
							},
							{
								text: "Delete",
								type: "option",
								action: () => setDeleteFileModalOpen(true),
								icon: ButtonIcon.MaterialRemove,
								disabled: selectedFile == null,
								testid: "vault-delete-file"
							},
							{
								text: moving ? "Move here" : "Move",
								type: "option",
								icon: ButtonIcon.MaterialExport,
								disabled: selectedFile == null,
								action: move,
								loading: moveLoading,
								testid: "vault-move-file"
							},
							...((window.useDecks
								? [
										{
											text: "Create Deck from File",
											type: "option",
											disabled: files.find((file) => file.file_id === selectedFile)?.file_type !== VaultFileType.PDF,
											icon: ButtonIcon.Deck,
											action: createDeckFromFile,
											testid: "vault-create-deck-from-file"
										}
									]
								: []) as IDropdownOptionProps[]),
							{
								text: "Generate Template",
								type: "option",
								disabled: [VaultFileType.DECK, VaultFileType.DOC].includes(
									files.find((file) => file.file_id === selectedFile)?.file_type
								),
								icon: ButtonIcon.Template,
								action: () => setShowGenerateTemplate(true),
								testid: "vault-generate-template"
							}
						]
					},
					{
						variant: ButtonVariant.Icon,
						icon: ButtonIcon.MaterialAddWhite,
						dropdownPosition: "left",
						testid: "vault-new-file",
						options: [
							{
								text: "New Doc",
								type: "option",
								icon: ButtonIcon.Document,
								action: createDoc,
								testid: "vault-create-doc"
							},
							...((window.useDecks
								? [
										{
											text: "New Deck",
											type: "option",
											icon: ButtonIcon.Deck,
											action: createDeck,
											testid: "vault-create-deck"
										}
									]
								: []) as IDropdownOptionProps[]),
							{
								text: "New Folder",
								type: "option",
								icon: ButtonIcon.Folder,
								action: createFolder,
								testid: "vault-create-folder"
							},
							{
								type: "divider"
							},
							{
								text: "Upload a File",
								type: "option",
								icon: ButtonIcon.Source,
								action: () => setCreateFileModalOpen(true),
								testid: "vault-upload-file"
							}
						]
					}
				]}
			/>
			<GenerateTemplate
				visible={showGenerateTemplate}
				onDismiss={() => setShowGenerateTemplate(false)}
				selectedFile={selectedFile}
				files={files}
			/>
			<Modal
				visible={deleteFileModalOpen}
				onDismiss={() => setDeleteFileModalOpen(false)}
				title={"Confirm Delete"}
				subtitle={"Are you sure you want to delete this files? This action can not be undone."}
				actions={[
					{
						children: "Cancel",
						variant: ButtonVariant.Secondary,
						action: () => setDeleteFileModalOpen(false),
						borderShape: ButtonBorderShape.Round,
						testid: "vault-cancel-delete-file"
					},
					{
						children: "Delete",
						variant: ButtonVariant.Error,
						action: confirmDelete,
						loading: deleteFileLoading,
						borderShape: ButtonBorderShape.Round,
						testid: "vault-confirm-delete-file"
					}
				]}
			>
				<VaultFile file={files?.find((file) => file.file_id === selectedFile)} />
			</Modal>
			<Modal
				visible={createFileModalOpen}
				onDismiss={() => setCreateFileModalOpen(false)}
				title={"Upload File"}
				actions={[
					{
						children: "Close",
						borderShape: ButtonBorderShape.Round,
						variant: ButtonVariant.Secondary,
						action: () => {
							setCreateFileModalOpen(false);
							loadFiles();
						},
						testid: "vault-confirm-upload-file"
					}
				]}
			>
				<DragUpload
					destination={parentFolder?.prefix}
					parent_folder_id={parentFolder?.file_id}
				/>
			</Modal>
			<Modal
				visible={editFileModalOpen}
				onDismiss={() => setEditFileLoading(false)}
				title={"Edit File"}
			>
				<Form
					handleSubmit={editFileForm.handleSubmit(editFile)}
					fields={[
						{
							control: editFileForm.control,
							name: "file_name",
							label: "File Name",
							variant: FormFieldVariant.Vertical,
							required: true
						},
						{
							control: editFileForm.control,
							name: "description",
							label: "Description",
							description:
								"A detailed description will improve search results as your vault grows. You will develop a very powerful asset if you attach detailed descriptions to each file.",
							variant: FormFieldVariant.Vertical,
							type: "textarea"
						}
					]}
					actions={[
						{
							children: "Close",
							variant: ButtonVariant.Secondary,
							borderShape: ButtonBorderShape.Round,
							action: () => setEditFileModalOpen(false),
							testid: "vault-cancel-edit-file"
						},
						{
							children: "Save",
							variant: ButtonVariant.Primary,
							borderShape: ButtonBorderShape.Round,
							action: editFileForm.handleSubmit(editFile),
							loading: editFileLoading,
							testid: "vault-confirm-edit-file"
						}
					]}
				/>
			</Modal>
		</>
	);
}
