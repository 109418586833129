import React from "react";
import { IVaultFile } from "@sage/types";
import { Table, TableColumn, TableColumnSpacing } from "@sage/shared/core";
import { VaultFile } from "../DataItems/VaultFile";
import "./FSTable.scss";

export interface IFSTableProps {
	files: IVaultFile[];
	handleClick: (file: IVaultFile) => void;
	control?: any;
	navBack?: () => void;
	showPrevious?: boolean;
	variant?: "compact" | "full";
	loading?: boolean;
}

export function FSTable({ files, handleClick, control, navBack, showPrevious, variant, loading }: IFSTableProps) {
	return (
		<Table
			items={files}
			emptyText={"..."}
			headers={
				<>
					<TableColumn
						width={"28px"}
						spacing={TableColumnSpacing.Fixed}
					>
						{showPrevious && (
							<button
								className={"__vault-table-navigate-back"}
								onMouseDown={navBack}
							>
								<img
									alt="img-icon"
									src={`https://cdn.ccm.vc/sage/icons/material-arrow-left.svg`}
								/>
							</button>
						)}
					</TableColumn>
					<TableColumn header>File Name</TableColumn>
					<TableColumn
						header
						spacing={TableColumnSpacing.Fixed}
						width={"10rem"}
					>
						User
					</TableColumn>
					<TableColumn
						header
						spacing={TableColumnSpacing.Fixed}
						width={"10rem"}
					>
						Last Modified
					</TableColumn>
					<TableColumn
						header
						spacing={TableColumnSpacing.Fixed}
						width={"4rem"}
					>
						Size
					</TableColumn>
				</>
			}
			row={(file: IVaultFile) => (
				<VaultFile
					key={file.file_id}
					file={file}
					control={control}
					onClick={handleClick}
				/>
			)}
		/>
	);
}
