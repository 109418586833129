import React, { ReactNode, useState } from "react";
import { Collapsible } from "../Collapsible";
import "./Select.scss";

export interface ISelectProps {
	value: string;
	onChange: (e: string) => void;
	option: (e: string) => ReactNode;
	options: string[];
}

export function Select({ value, onChange, option, options }: ISelectProps) {
	const [open, setOpen] = useState<boolean>(false);

	function handleChange(e: string) {
		onChange(e);
		setOpen(false);
	}

	return (
		<div className="__sage-select-container">
			<div
				className={`active-option ${open ? "open" : "closed"}`}
				onClick={() => setOpen((e) => !e)}
			>
				{option(value)}
			</div>
			<div className={`options ${open ? "open" : "closed"}`}>
				<Collapsible visible={open}>
					{options
						.filter((e) => e !== value)
						.map((e) => (
							<div
								key={e}
								className="option"
								onClick={() => handleChange(e)}
							>
								{option(e)}
							</div>
						))}
				</Collapsible>
			</div>
		</div>
	);
}
