import React from "react";
import { CompanyCard } from "@sage/shared/company";
import { Card, CardTitle, Row } from "@sage/shared/core";

export function MAndAActivity({ portcos, owner }) {
	return (
		<>
			{portcos ? (
				<Card>
					<CardTitle>Acquisitions</CardTitle>
					<Row>
						{portcos.map((portco) => (
							<CompanyCard
								key={portco}
								company_id={portco}
							/>
						))}
					</Row>
				</Card>
			) : null}
			{owner ? (
				<Card>
					<CardTitle>Parent Company</CardTitle>
					<CompanyCard
						key={owner}
						company_id={owner}
					/>
				</Card>
			) : null}
		</>
	);
}
