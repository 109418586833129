import React from "react";
import "./Splash.scss";

export function Splash() {
	return (
		<>
			<div className={"__splash-bottom-card-color"}></div>
			<div className={"__splash-bottom-card-filler"}></div>
		</>
	);
}
