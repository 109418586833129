import React from "react";
import { Link } from "react-router-dom";
import { SearchBar } from "../../../ContextAwareLLM/SearchBar/SearchBar";
import { UserIcon } from "../UserIcon/UserIcon";
import "./Header.scss";

export function Header() {
	return (
		<div className={"__header-container"}>
			<div className={"left"}>
				<Link to={"/app/"}>
					<img
						className={"img"}
						src="https://cdn.ccm.vc/sage/logo-white.svg"
						height="26px"
					/>
				</Link>
				<div className={"title"}>Sage AI</div>
			</div>
			<div className={"right"}>
				<UserIcon />
			</div>
		</div>
	);
}
