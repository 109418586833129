import { UserService } from "../../Services/UserService/UserService";

export const LookupUser = async (user_id: string) => {
	let user_name = localStorage.getItem(user_id);

	if (!user_name) {
		const user_obj = await UserService.GetUserById(user_id);
		user_name = `${user_obj.firstName} ${user_obj.lastName}`;
		localStorage.setItem(user_id, user_name);
	}

	return user_name;
};
