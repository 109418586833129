import React from "react";
import { Link as RouterLink } from "react-router-dom";
import "./Link.scss";

export function Link(props) {
	return (
		<div className={`__sage-link ${props.inline ? "inline" : null} ${props.accent ? "accent" : ""}`}>
			<RouterLink {...props} />
		</div>
	);
}
