import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function ListsSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "GZWUCXASSLLWQVST"
									})
								);
							}}
						>
							Company Overview Points
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EGFMXLAEDUQGACFH"
									})
								);
							}}
						>
							Key Business Highlights
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "UHYRZWSRMAWMPBQC"
									})
								);
							}}
						>
							Core Business Activities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "QSAYFAUEVYFNJPCV"
									})
								);
							}}
						>
							Revenue Model
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZSMQWGEELEJACVWH"
									})
								);
							}}
						>
							Value Proposition
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "TBXXQRJCJWHKWZTX"
									})
								);
							}}
						>
							Key Business Drivers
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "JWWTJUTAKSZTKBXS"
									})
								);
							}}
						>
							Competitive Advantages
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BXNRSMJCBFCVGMPQ"
									})
								);
							}}
						>
							Market Strengths
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "CZNNQTCXFRNRWAVG"
									})
								);
							}}
						>
							Position Highlights
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BWSYYRLVYMPDLPDV"
									})
								);
							}}
						>
							Strategic Partnerships
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "MULQBHMDPMGGXHSY"
									})
								);
							}}
						>
							Key Growth Factors
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HKTVXWPNCGENVQTP"
									})
								);
							}}
						>
							Market Opportunities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HHKZWLEGEJKYMBDZ"
									})
								);
							}}
						>
							Growth Initiatives
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"chess-knight"}
					size={"2x"}
					color={"#0051a8"}
				>
					Strategic & Overview
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "UHUKVDVHQURVMJJR"
									})
								);
							}}
						>
							Current Initiatives
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "SJJSJBAJVFACMTNQ"
									})
								);
							}}
						>
							Growth Targets
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ENMSRECZNNYAYSZB"
									})
								);
							}}
						>
							Geographic Expansion
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BXZVBETKYGVTEQGA"
									})
								);
							}}
						>
							Market Opportunities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZBKXAJAAUVWXGYPU"
									})
								);
							}}
						>
							Product Expansions
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "WLLTSCLJQYWDFMML"
									})
								);
							}}
						>
							Strategic Partnerships
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "SSEDPFXMBWXAHCZF"
									})
								);
							}}
						>
							Technology Innovation
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "QULJXYUCHUWWECJM"
									})
								);
							}}
						>
							Customer Acquisition
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "XMJVPRZNKLUBPZKB"
									})
								);
							}}
						>
							Operational Scalability
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EJWRZYYGYDMAHSTR"
									})
								);
							}}
						>
							YOY Growth Rates
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "TJQJDSNGRYUTUYVR"
									})
								);
							}}
						>
							Customer Base Expansion
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "DNVZLDTYPGFDSAZT"
									})
								);
							}}
						>
							Segment Revenue Growth
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EFLDTCHFEGMYFUKU"
									})
								);
							}}
						>
							New Product Contribution
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"arrow-up-right-dots"}
					size={"2x"}
					color={"#0051a8"}
				>
					Growth
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "SDMKAPNYAZYHGQEY"
									})
								);
							}}
						>
							Core Product Lines
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EXYTECLANQSWEXHL"
									})
								);
							}}
						>
							Key Features
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YHNENPJNQTQBBRCN"
									})
								);
							}}
						>
							Target Markets
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZAFNVRGWBQDAXHWK"
									})
								);
							}}
						>
							Quality Metrics
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "SEUZKNMQGFYQWEKN"
									})
								);
							}}
						>
							Development Stages
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YXCUVQVUVYSSWQZP"
									})
								);
							}}
						>
							Timeline Milestones
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "CTTWVWDSRNZMTCND"
									})
								);
							}}
						>
							Feature Roadmap
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EXCXQXZCZFSKPRYD"
									})
								);
							}}
						>
							Product Positioning
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZGVTZNXBWBUXXSQV"
									})
								);
							}}
						>
							R&D Initiatives
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "SSNPVXLTPGFELDVC"
									})
								);
							}}
						>
							Sales by Product
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZPJZNNEPKLKRNBPM"
									})
								);
							}}
						>
							Product Profitability
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"boxes-stacked"}
					size={"2x"}
					color={"#0051a8"}
				>
					Products & Services
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "LZGJCRPHHJGFJVAL"
									})
								);
							}}
						>
							Executive Background
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "RZTQGQBTPBGYXABJ"
									})
								);
							}}
						>
							Key Roles
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "KQCQPLKNGZMPZJGA"
									})
								);
							}}
						>
							Responsibilities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZDZJPSDLNTWJZQEF"
									})
								);
							}}
						>
							Reporting Relationships
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZZTRBLFFVJBRAYYG"
									})
								);
							}}
						>
							Growth Initiatives
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HHQWGQLAUCPBFDAG"
									})
								);
							}}
						>
							Performance Metrics
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "GAZPRZTKNGTCQVQX"
									})
								);
							}}
						>
							Department Listings
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "CMCMDJCLPQCMRZCU"
									})
								);
							}}
						>
							Functional Areas
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "FHZCACVAJNZRKJEV"
									})
								);
							}}
						>
							Management Capabilities
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"user-tie"}
					size={"2x"}
					color={"#0051a8"}
				>
					Management
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YVMEEFGWGQJGNATJ"
									})
								);
							}}
						>
							Departments
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "GWJANZYZPTAPJKKD"
									})
								);
							}}
						>
							Reporting Lines
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "TZMQXEJGXRWGHDYA"
									})
								);
							}}
						>
							Geographic Presence
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "JJGRHHCPUTZCNYXH"
									})
								);
							}}
						>
							Operational KPIs
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "LDHAKKYNUBLMLGMY"
									})
								);
							}}
						>
							Operational Requirements
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "CZHAJLGEGFSFCVLJ"
									})
								);
							}}
						>
							Infrastructure Components
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"network-wired"}
					size={"2x"}
					color={"#0051a8"}
				>
					Operations
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "NQRNVHLCYYWVMHVF"
									})
								);
							}}
						>
							Current Trends
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "MJGDULXZCMFVABTJ"
									})
								);
							}}
						>
							Emerging Patterns
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "CSBSAXQRGFSWHEUZ"
									})
								);
							}}
						>
							Market Shifts
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "FPFZNPBAJYTNBPYK"
									})
								);
							}}
						>
							Industry Drivers
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "RLLMABFSULRXSDYK"
									})
								);
							}}
						>
							Market Challenges
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BNDVQQSQPQNCQPFX"
									})
								);
							}}
						>
							Industry Trends
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "DLGBJCZGSXLXWTGC"
									})
								);
							}}
						>
							Leader Characteristics
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HYKALMWENBWZXWMA"
									})
								);
							}}
						>
							Market Dominance Factors
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HWGBKLKTZDGMTEMW"
									})
								);
							}}
						>
							Total Addressable Market
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZPWLBALRNGGCGLYV"
									})
								);
							}}
						>
							Key Segments
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "PEBCGKKMEEVATRNH"
									})
								);
							}}
						>
							Growth Rates
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "NUVQXBGJWBSSHVWL"
									})
								);
							}}
						>
							Industry Regulations
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "VDBLPBFZVQQSCQJX"
									})
								);
							}}
						>
							Market Consolidation
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "KHBMPQNDAJKXYSJH"
									})
								);
							}}
						>
							Technology Shifts
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BBZFPZKFNXEEPCVV"
									})
								);
							}}
						>
							Regulatory Changes
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"industry"}
					size={"2x"}
					color={"#0051a8"}
				>
					Industry
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
