import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { useChartColors } from "@sage/state";
import { Chart, format_tick, format_value, useDims } from "../ChartProvider";

export function ScatterPlot({
	title,
	data,
	colors,
	updateColor
}: {
	title?: string;
	data: { category: string; value: number; date: Date }[];
	colors?: { [key: string]: string };
	updateColor?: (category: string, color: string) => void;
}) {
	const { chart_colors } = useChartColors();
	const c = useMemo(
		() =>
			d3
				.scaleOrdinal()
				.domain([
					...Object.keys(colors || {}),
					...data.map((d) => d.category).filter((c) => !Object.keys(colors || {}).includes(c))
				])
				.range([
					...Object.keys(colors || {}).map((c) => colors[c]),
					...(chart_colors?.length > 0
						? chart_colors
						: ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056", "#8dddd0"]),
					...d3.schemeTableau10
				]),
		[data]
	);

	const legend = useMemo(
		() => [...new Set(data.map((d) => d.category))].sort().map((category) => ({ category, color: c(category) })),
		[data, c]
	);

	return (
		<Chart
			legend={legend}
			title={title}
			updateColor={updateColor ? updateColor : undefined}
			data={data}
		>
			<Series
				data={data}
				c={c}
			/>
		</Chart>
	);
}

function Series({ data, c }) {
	const dims = useDims();
	const containerRef = useRef(null);
	const seriesRef = useRef(null);
	const labelsRef = useRef(null);
	const xAxisRef = useRef(null);
	const xGridRef = useRef(null);
	const yAxisRef = useRef(null);
	const yGridRef = useRef(null);

	const x = useMemo(() => {
		const minDate = d3.min(data, (d) => new Date(d.date).getTime());
		const maxDate = d3.max(data, (d) => new Date(d.date).getTime());

		const duration = maxDate - minDate;

		const pad = duration * 0.05;

		return d3
			.scaleUtc()
			.domain([minDate - pad, maxDate + pad])
			.range([dims.marginLeft, dims.width - dims.marginRight]);
	}, [data, dims]);

	const y = useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([0, d3.max(data, (d) => d.value) * 1.05])
				.range([dims.height - dims.marginBottom, dims.marginTop]),
		[data, dims]
	);

	useEffect(() => {
		if ((seriesRef.current, containerRef.current && x && y && c && data)) {
			d3.select(seriesRef.current)
				.selectAll("circle")
				.data(data)
				.join(
					(enter) =>
						enter
							.append("circle")
							.attr("fill", (d) => c(d.category))
							.attr("r", 5)
							.attr("cx", (d) => x(d.date))
							.attr("cy", (d) => y(d.value)),
					(update) =>
						update
							.transition()
							.attr("fill", (d) => c(d.category))
							.attr("r", 5)
							.attr("cx", (d) => x(d.date))
							.attr("cy", (d) => y(d.value)),
					(exit) => exit.remove()
				);

			d3.select(labelsRef.current)
				.selectAll("text")
				.data(data)
				.join("text")
				.attr("text-anchor", "middle")
				.attr("font-size", "0.95em")
				.attr("x", (d) => {
					const xPos = x(d.date);
					return xPos;
				})
				.attr("y", (d) => {
					const yPos = y(d.value) + 22;
					if (yPos >= dims.height - dims.marginTop - 20) {
						return yPos - 32;
					}
					return yPos;
				})

				.attr("fill", "black")
				.text((d) => format_value(d.value));

			d3.select(xAxisRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3
						.axisBottom(x)
						.ticks(7)
						.tickFormat((d) => {
							try {
								const dateString = new Date(d).toLocaleDateString("en-US", {
									month: "long",
									day: "numeric",
									year: "numeric",
									timeZone: "UTC"
								});
								if (dateString.includes("January 1") || dateString.includes("December 31")) {
									return new Date(d).toLocaleDateString("en-US", { year: "numeric", timeZone: "UTC" });
								}
								return dateString;
							} catch (e) {
								return d.toLocaleString("en-US", { timeZone: "UTC" });
							}
						})
				);

			d3.select(yAxisRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(d3.axisLeft(y).ticks(6).tickFormat(format_tick));

			d3.select(yGridRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(
					d3
						.axisLeft(y)
						.tickSize(-(dims.width - dims.marginRight - dims.marginLeft))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);

			d3.select(xGridRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3
						.axisBottom(x)
						.tickSize(-(dims.height - dims.marginTop - dims.marginBottom))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);
		}
	}, [seriesRef.current, containerRef.current, x, y, c, dims, data]);

	return (
		<svg
			ref={containerRef}
			width={"100%"}
			height={dims.height}
		>
			<g ref={xAxisRef} />
			<g ref={xGridRef} />
			<g ref={yAxisRef} />
			<g ref={yGridRef} />
			<g ref={seriesRef} />
			<g ref={labelsRef} />
		</svg>
	);
}
