import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row } from "@sage/shared/core";
import "./SearchHome.scss";

export function SearchHome() {
	const [search, setSearch] = useState("");
	const navigate = useNavigate();

	function submit() {
		navigate(`./${encodeURIComponent(search)}`);
	}

	return (
		<div className="__sage-search-home">
			<Row verticalAlign="center">
				<input
					className="search-input"
					value={search}
					placeholder="Search..."
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Button action={submit}>Search</Button>
			</Row>
		</div>
	);
}
