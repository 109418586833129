import { useEffect, useState } from "react";
import { AreaChart, BarChart, BubblePlot, LineChart, PieChart, ScatterPlot, StackedBarChart, WaterfallChart } from "@sage/shared/charts";
import { LoadingBubble } from "@sage/shared/core";
import "./Chart.scss";

export function Chart({ data, done, updateChart }: { data: string; done?: boolean; updateChart?: (prev: string, cur: string) => void }) {
	const [parsedData, setParsedData] = useState(null);

	useEffect(() => {
		if (done !== false) {
			try {
				setParsedData(JSON.parse(new DOMParser().parseFromString(`<!doctype html><body>${data}`, "text/html").body.textContent));
			} catch (e) {
				console.log(e);
			}
		}
	}, [data, done]);

	function updateColor(category: string, color: string) {
		setParsedData((p) => {
			const newParsedData = { ...p, colors: { ...p.colors, [category]: color } };
			updateChart(data, newParsedData);
			return newParsedData;
		});
	}

	if (done !== false && parsedData && parsedData.data) {
		switch (parsedData.type) {
			case "BarChart":
				return (
					<BarChart
						title={parsedData.title}
						data={parsedData.data.map((d) => ({
							...d
							//date: new Date(d.date).toLocaleDateString("en-US", { timeZone: "UTC" })
						}))}
						trendline={parsedData.trendline}
						colors={parsedData.colors}
						updateColor={updateChart ? updateColor : undefined}
					/>
				);
			case "StackedBarChart":
				return (
					<StackedBarChart
						title={parsedData.title}
						data={parsedData.data.map((d) => ({
							...d
							//date: new Date(d.date).toLocaleDateString("en-US", { timeZone: "UTC" })
						}))}
						colors={parsedData.colors}
						updateColor={updateChart ? updateColor : undefined}
					/>
				);
			case "WaterfallChart":
				return (
					<WaterfallChart
						title={parsedData.title}
						data={parsedData.data}
						colors={parsedData.colors}
					/>
				);

			case "LineChart":
				return (
					<LineChart
						title={parsedData.title}
						data={parsedData.data}
						colors={parsedData.colors}
						updateColor={updateChart ? updateColor : undefined}
					/>
				);
			case "ScatterPlot":
				return (
					<ScatterPlot
						title={parsedData.title}
						data={parsedData.data.map((d) => ({ ...d, date: new Date(d.date) }))}
						colors={parsedData.colors}
						updateColor={updateChart ? updateColor : undefined}
					/>
				);
			case "BubblePlot":
				return (
					<BubblePlot
						title={parsedData.title}
						data={parsedData.data.map((d) => ({ ...d, date: new Date(d.date) }))}
						colors={parsedData.colors}
					/>
				);

			case "AreaChart":
				return (
					<AreaChart
						title={parsedData.title}
						data={parsedData.data.map((d) => ({ ...d, date: new Date(d.date) }))}
						colors={parsedData.colors}
						updateColor={updateChart ? updateColor : undefined}
					/>
				);
			case "PieChart":
				return (
					<PieChart
						title={parsedData.title}
						data={parsedData.data}
						colors={parsedData.colors}
						updateColor={updateChart ? updateColor : undefined}
					/>
				);
			default:
				return null;
		}
	} else {
		return (
			<div className="__sage-chart-loading">
				<div className="title">Creating Your Chart...</div>
				<LoadingBubble />
			</div>
		);
	}
}
