import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AuthContext, DealsContext } from "@sage/state";
import { UserIcon } from "@sage/shared/other";
import { Col, ICol } from "../Col/Col";
import "./Board.scss";

export function Board({ editDeal }) {
	const dealsState = useContext(DealsContext);
	const authState = useContext(AuthContext);

	const [activeOwners, setActiveOwners] = useState([]);
	const [owners, setOwners] = useState([]);

	const { control, reset } = useForm<{ columns: ICol[] }>({
		defaultValues: {
			columns: []
		}
	});
	const columns = useFieldArray({ name: "columns", control });

	function setDeals(newOwners) {
		setOwners([...new Set(dealsState.deals.map((deal) => deal.owner))]);

		let cols: any = { uncategorized: [] };

		if (authState.team.settings?.columns?.length) {
			authState.team.settings.columns.forEach((col) => {
				cols[col.toLowerCase()] = [];
			});
		} else {
			cols = {
				"uncategorized": [],
				"bake off": [],
				"pre marketing": [],
				"marketing": [],
				"diligence": [],
				"documentation": []
			};
		}

		dealsState.deals.forEach((deal) => {
			let column = deal.column.toLowerCase();
			if (newOwners.length) {
				if (newOwners.includes(deal.owner)) {
					if (Object.keys(cols).includes(column)) cols[column].push(deal);
					else cols["uncategorized"].push(deal);
				}
			} else {
				if (Object.keys(cols).includes(column)) cols[column].push(deal);
				else cols["uncategorized"].push(deal);
			}
		});

		reset({
			columns: Object.keys(cols).map((col, idx) => ({
				title: col,
				id: `${idx}`,
				items: cols[col]
			}))
		});
	}

	function updateActiveOwners(owner) {
		setActiveOwners((o) => {
			let newOwners;
			if (o.includes(owner)) {
				newOwners = o.filter((owner_) => owner_ !== owner);
			} else {
				newOwners = [...o, owner];
			}

			setDeals(newOwners);
			return newOwners;
		});
	}

	useEffect(() => {
		setDeals([]);
	}, [dealsState.deals]);

	return (
		<div className={"__deals-board-container"}>
			<div className={"filters"}>
				{owners.map((owner) => (
					<UserIcon
						user_id={owner}
						key={owner}
						active={activeOwners.includes(owner)}
						action={() => updateActiveOwners(owner)}
					/>
				))}
			</div>
			<div className={"columns"}>
				{columns.fields
					.filter((col) => (col.title === "uncategorized" && col.items.length) || col.title !== "uncategorized")
					.map((col) => (
						<Col
							key={col.id}
							{...col}
							editDeal={editDeal}
						/>
					))}
			</div>
		</div>
	);
}
