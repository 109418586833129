import { ReactNode } from "react";
import { FaIcon } from "../FaIcon";
import "./Badge.scss";

export function Badge({ children, color }: { children: ReactNode; color?: string }) {
	return (
		<div
			className="__sage-badge-container"
			style={{ backgroundColor: color || "#cc3433" }}
		>
			<div className="inner">{children}</div>
		</div>
	);
}
