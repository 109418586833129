import React from "react";
import { VaultFileType } from "@sage/types";
import "./VaultIcon.scss";

export enum VaultIconName {
	Decks = "https://cdn.ccm.vc/sage/icons/decks-new.svg",
	Docs = "https://cdn.ccm.vc/sage/icons/document-new.svg",
	Word = "https://cdn.ccm.vc/sage/icons/docx.svg",
	Powerpoint = "https://cdn.ccm.vc/sage/icons/pptx.svg",
	Excel = "https://cdn.ccm.vc/sage/icons/xlsx.svg",
	Txt = "https://cdn.ccm.vc/sage/icons/plain-document-new.svg",
	Html = "https://cdn.ccm.vc/sage/icons/html.svg",
	Folder = "https://cdn.ccm.vc/sage/icons/folder_color.svg",
	Source = "https://cdn.ccm.vc/sage/icons/new-source.svg",
	Pdf = "https://cdn.ccm.vc/sage/icons/pdf.svg"
}

const VaultFileTypeToIconName = {
	DECKS: VaultIconName.Decks,
	DOCS: VaultIconName.Docs,
	WORD: VaultIconName.Word,
	POWERPOINT: VaultIconName.Powerpoint,
	EXCEL: VaultIconName.Excel,
	TXT: VaultIconName.Txt,
	PLAINTEXT: VaultIconName.Pdf,
	HTML: VaultIconName.Pdf,
	FOLDER: VaultIconName.Folder,
	SOURCE: VaultIconName.Source,
	PDF: VaultIconName.Pdf,
	OTHER: VaultIconName.Pdf
};

export function VaultFSIcon({
	iconName,
	fileType,
	size,
	bg
}: {
	size?: number;
	iconName?: VaultIconName;
	fileType?: VaultFileType;
	bg?: boolean;
}) {
	function getIcon() {
		if (iconName) {
			return iconName;
		} else if (fileType) {
			return VaultFileTypeToIconName[fileType];
		}
	}

	return (
		<div className={`__vault-fs-icon-img ${bg ? "bg" : ""}`}>
			<img
				src={getIcon()}
				height={size || 16}
				width={size || 16}
			/>
		</div>
	);
}
