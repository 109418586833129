import React, { ReactElement, ReactNode } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import prettyBytes from "pretty-bytes";
import { Button, IButtonProps } from "../Button";
import "./Table.scss";

dayjs.extend(relativeTime);

export interface ITableProps {
	row: (e: any) => ReactElement;
	headers?: ReactElement;
	items: any[];
	emptyText?: ReactElement | string;
	variant?: TableVariant;
	loading?: boolean;
	actions?: IButtonProps[];
}

export enum TableVariant {
	Default = "default",
	Inline = "inline"
}

export function Table({ row, items, headers, emptyText, variant, loading, actions }: ITableProps) {
	return (
		<div className={`__sage-table-container ${variant || TableVariant.Default}`}>
			{headers && <TableHeaders>{headers}</TableHeaders>}
			<TableBody>{items.map((item) => row(item))}</TableBody>
			{items.length === 0 && emptyText && !loading && <TableEmptyText>{emptyText}</TableEmptyText>}
			{actions && <TableActions actions={actions} />}
		</div>
	);
}

interface ITableEmptyTextProps {
	children: ReactNode | string;
}

function TableEmptyText({ children }: ITableEmptyTextProps) {
	return <div className="sage-table-empty-text">{children}</div>;
}

export interface ITableRowProps {
	children: ReactNode;
}

export function TableRow({ children }: ITableRowProps) {
	return <div className="__sage-table-row">{children}</div>;
}

interface ITableBodyProps {
	children: ReactNode;
}

function TableBody({ children }: ITableBodyProps) {
	return <div className="sage-table-body">{children}</div>;
}

interface ITableHeadersProps {
	children: ReactNode;
}

function TableHeaders({ children }: ITableHeadersProps) {
	return <div className="sage-table-headers">{children}</div>;
}

export interface ITableColumnProps {
	children?: ReactNode | string | number;
	header?: boolean;
	relativeDate?: boolean;
	absoluteDate?: boolean;
	bytes?: boolean;
	spacing?: TableColumnSpacing;
	width?: string | number;
	icon?: ReactNode;
	onClick?: () => void;
}

export enum TableColumnSpacing {
	Flex = "flex",
	Fixed = "fixed"
}

export function TableColumn({ children, spacing, width, header, relativeDate, absoluteDate, icon, bytes, onClick }: ITableColumnProps) {
	function handleClick() {
		if (onClick) onClick();
	}

	return (
		<div
			className={`__sage-table-column ${header && "header"} ${relativeDate || absoluteDate || bytes ? "date" : ""}`}
			style={{
				width: spacing === TableColumnSpacing.Fixed ? width : null,
				flex: spacing !== TableColumnSpacing.Fixed ? width || "1" : null
			}}
			onClick={handleClick}
		>
			{icon}
			{absoluteDate
				? new Date(children as unknown as number).toLocaleString()
				: relativeDate
					? dayjs(children as unknown as number).fromNow()
					: bytes && children
						? prettyBytes(children as number)
						: children}
		</div>
	);
}

export interface ITableActionsProps {
	actions: IButtonProps[];
}

function TableActions({ actions }: ITableActionsProps) {
	return (
		<div className="__sage-table-actions">
			{actions.map((action, idx) => (
				<Button
					key={idx}
					{...action}
				/>
			))}
		</div>
	);
}
