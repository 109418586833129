import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { ButtonVariant, Col, Modal, P, Pad } from "@sage/shared/core";
import { TemplateListItem } from "@sage/shared/dealrooms";
import { Form, FormFieldVariant } from "@sage/shared/forms";

export function SavePageModal() {
	const { pages, savePageModalVisible, setSavePageModalVisible, savePageId, setSavePageId, elements } = useDealRoomProject();
	const form = useForm();

	async function save(e) {
		await DealRoomService.SavePageTemplate({
			...e,
			elements: pageElements.map((el) => el.element_template_id)
		});
		form.reset({});
		setSavePageModalVisible(false);
		setSavePageId(null);
	}

	function dismiss() {
		form.reset({});
		setSavePageModalVisible(false);
		setSavePageId(null);
	}

	const [page, pageElements] = useMemo(() => {
		if (savePageId && savePageId in elements) {
			const _page = pages.find((p) => p.page_id === savePageId);
			form.setValue("page_title", _page.page_name);
			return [_page, elements[savePageId]];
		}
		return [null, []];
	}, [savePageId, pages, elements]);

	return (
		<Modal
			title={"Save Page as Template"}
			visible={savePageModalVisible}
			onDismiss={dismiss}
			minWidth={"40rem"}
			actions={[
				{ children: "Cancel", variant: ButtonVariant.Secondary, action: dismiss },
				{ children: "Save", variant: ButtonVariant.Primary, action: form.handleSubmit(save) }
			]}
		>
			<Pad>
				<Form
					fields={[
						{
							control: form.control,
							label: "Page Name",
							variant: FormFieldVariant.Vertical,
							name: "page_name",
							description: "A descriptive name that you can use to find this in your list of page templates",
							required: true
						},
						{
							control: form.control,
							label: "Page Title",
							variant: FormFieldVariant.Vertical,
							name: "page_title",
							description: "The title of the slide that this will create"
						},
						{
							control: form.control,
							label: "Page Description",
							variant: FormFieldVariant.Vertical,
							name: "page_description",
							description: "So you can find this in your list of page templates & so it will be easier to search",
							type: "textarea"
						}
					]}
				/>
				<P>Elements:</P>
				<Col gap={"1rem"}>
					{pageElements.map((element) => (
						<TemplateListItem
							key={element.element_template_id}
							template={element}
							select={() => {}}
							searchTerm={null}
						/>
					))}
				</Col>
			</Pad>
		</Modal>
	);
}
