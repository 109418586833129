import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { MimeToFileType } from "@sage/types";
import { DealRoomService } from "@sage/services";
import { Bullet, Bytes, Col, DateHeader, FaIcon, Link, LoadingBar, Modal, P, Row, SectionTitle, TimeHeader } from "@sage/shared/core";
import { VaultFSIcon } from "@sage/shared/other";
import "./UploadMeetingModal.scss";

export function UploadMeetingModal({ uploadModalVisible, setUploadModalVisible, loadMeetings }) {
	const [isFileOver, setIsFileOver] = useState<boolean>(false);
	const [files, setFiles] = useState<File[]>([]);

	function dropHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		setIsFileOver(false);

		const _files = [];

		for (let item of e.dataTransfer.items) {
			if (item.kind === "file") {
				const file = item.getAsFile();
				_files.push(file);
			}
		}

		setFiles((f) => [...f, ..._files]);
	}

	function manualUpload() {
		const input = document.createElement("input");
		input.type = "file";
		input.style.display = "none";
		input.multiple = true;

		input.addEventListener("change", (e) => {
			setFiles((f) => [...f, ...e.target.files]);
			document.body.removeChild(input);
		});

		document.body.appendChild(input);
		input.click();
	}

	function dragOverHandler(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	function dragEnterHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		for (let item of e.dataTransfer.items) {
			if (item.kind === "file") {
				setIsFileOver(true);
			}
		}
	}

	function dragLeaveHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		setIsFileOver(false);
	}

	function dismissModal() {
		setFiles([]);
		setUploadModalVisible(false);
	}

	return (
		<Modal
			visible={uploadModalVisible}
			onDismiss={dismissModal}
		>
			<div
				className="__sage-deal-room-upload-meeting-modal-container"
				onDrop={dropHandler}
				onDragLeave={dragLeaveHandler}
				onDragEnter={dragEnterHandler}
				onDragOver={dragOverHandler}
			>
				<SectionTitle>Upload Meeting Video File</SectionTitle>
				<div className={`drop-zone ${isFileOver ? "active" : ""}`}>
					{files.length === 0 && (
						<div
							className="drop-zone-text"
							onClick={manualUpload}
						>
							<FaIcon
								icon="cloud-arrow-up"
								size="10x"
								color="#0094f3"
								hideBg
							/>
							<P>Drag and drop your file in.</P>
						</div>
					)}
					{files.map((f, idx) => (
						<UploadingFile
							key={`${idx}-${f.name}`}
							file={f}
							dismissModal={dismissModal}
							loadMeetings={loadMeetings}
						/>
					))}
				</div>
			</div>
		</Modal>
	);
}

function UploadingFile({ file, dismissModal, loadMeetings }: { file: File; dismissModal: () => void; loadMeetings: () => void }) {
	const params = useParams();
	const [progress, setProgress] = useState<number>(0);
	const uploadInProgressRef = useRef(false);

	async function uploadFile() {
		if (!uploadInProgressRef.current) {
			uploadInProgressRef.current = true;
			const meeting = await DealRoomService.SaveMeeting({
				deal_id: params.deal_id,
				phase_id: params.phase_id,
				format: file.type.split("/")[1]
			});
			await DealRoomService.UploadMeeting({ meeting_id: meeting.meeting_id, file, onProgress: setProgress });
			await DealRoomService.StartMeetingTranscribe(`${meeting.meeting_id}.${meeting.format}`);
			await loadMeetings();
			dismissModal();
		}
	}

	useEffect(() => {
		uploadFile();
	}, [file]);

	return (
		<div className="file">
			<Col gap={"0.5rem"}>
				<Row horizontalAlign="between">
					<Row verticalAlign={"center"}>
						<VaultFSIcon
							fileType={MimeToFileType(file.type)}
							size={30}
							bg
						/>
						<Col gap={"0.125rem"}>
							<div className="file-name">
								<Link>{file.name}</Link>
							</div>
							<Row verticalAlign="center">
								<DateHeader size="sm">{file.lastModified}</DateHeader>
								<Bullet size="sm" />
								<TimeHeader size="sm">{file.lastModified}</TimeHeader>
								<Bullet size="sm" />
								<Bytes size="sm">{file.size}</Bytes>
							</Row>
						</Col>
					</Row>
				</Row>
				<LoadingBar progress={Math.round(progress)} />
			</Col>
		</div>
	);
}
