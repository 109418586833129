import React, { useEffect, useState } from "react";
import { IVaultFile } from "@sage/types";
import { VaultService } from "@sage/services";

export function VaultImagePreview({ file }: { file: IVaultFile }) {
	const [imagesrc, setImagesrc] = useState<string>(null);

	async function loadImage() {
		setImagesrc(await VaultService.GetFileUrl(file));
	}

	useEffect(() => {
		loadImage();
	}, [file]);

	if (imagesrc) {
		return (
			<img
				className={"vault-fs-icon-img"}
				src={imagesrc}
				height={16}
				width={16}
				onError={(e) => {
					(e.target as any).src = "https://cdn.ccm.vc/sage/icons/photo.svg";
				}}
			/>
		);
	} else {
		return (
			<img
				className={"vault-fs-icon-img"}
				src={"https://cdn.ccm.vc/sage/icons/photo.svg"}
				width={16}
			/>
		);
	}
}
