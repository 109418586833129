import React from "react";
import { Control, useWatch } from "react-hook-form";
import { Row } from "@sage/shared/core";
import { Radio } from "../Radio";
import "./Tiles.scss";

export interface ITilesProps {
	control: Control;
	setValue: any;
	name: string;
	tiles: {
		id: string;
		label: string;
		description: string;
	}[];
}

export function Tiles({ tiles, name, control, setValue }: ITilesProps) {
	const active = useWatch({ control, name });

	return (
		<div className="__sage-tiles-container">
			<Row>
				{tiles.map((tile) => (
					<Tile
						key={tile.id}
						id={tile.id}
						name={name}
						label={tile.label}
						description={tile.description}
						control={control}
						active={active === tile.id}
						setValue={setValue}
					/>
				))}
			</Row>
		</div>
	);
}

function Tile({ id, name, label, description, active, control, setValue }) {
	return (
		<div
			className={`tile ${active ? "active" : ""}`}
			onClick={() => setValue(name, id)}
		>
			<div className="radio">
				<Radio
					name={name}
					control={control}
					id={id}
				/>
			</div>
			<div className="details">
				<div className="label">{label}</div>
				<div className="description">{description}</div>
			</div>
		</div>
	);
}
