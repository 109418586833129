import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ICompanyCBD } from "@sage/types";
import { useWorkflowState } from "@sage/state";
import { DealstreamService } from "@sage/services";
import { CompanyOverview, Crawl } from "@sage/shared/company";
import { Button, ButtonBorderShape, ButtonVariant, Col } from "@sage/shared/core";
import "./ReviewCrawlResult.scss";

export function ReviewCrawlResult() {
	const { form, updatePageStatus } = useWorkflowState();
	const navigate = useNavigate();
	const company_id = useWatch({ control: form.control, name: "company_id" });
	const [company, setCompany] = useState<ICompanyCBD>(null);

	async function loadCompany() {
		if (company_id) {
			setCompany(await DealstreamService.GetCompanyCBD(company_id));
		}
	}

	function handleNav() {
		navigate("../template");
	}

	useEffect(() => {
		loadCompany();
	}, [company_id]);

	useEffect(() => {
		updatePageStatus("in-progress", "Deal Details", "Review Crawl Result");
		return () => {
			updatePageStatus("complete", "Deal Details", "Review Crawl Result");
		};
	}, []);

	return (
		<section className="__sage-review-crawl-result-container">
			<Col>
				{company && (
					<Crawl
						company={company}
						refresh={loadCompany}
					/>
				)}
				{company && <CompanyOverview company={company} />}
				<Button
					action={handleNav}
					borderShape={ButtonBorderShape.Round}
					variant={ButtonVariant.Primary}
				>
					Next
				</Button>
			</Col>
		</section>
	);
}
