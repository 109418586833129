import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { WorkflowService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonVariant, Row } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";

export function BasicDetails() {
	const { workflow_id } = useWorkflowState();
	const { getPageStatus, updatePageStatus, form } = useWorkflowState();
	const navigate = useNavigate();
	const name = useWatch({ control: form.control, name: "name" });

	function handleSkip() {
		updatePageStatus("skip", "Deal Details", "Basic Details");
		handleNav();
	}

	async function handleNav() {
		navigate("../company");
		await saveName();
	}

	async function handleNavigateAway() {
		if (getPageStatus("Deal Details", "Basic Details") === "skip") {
		} else if (Object.keys(form.formState.errors).length) {
			updatePageStatus("errors", "Deal Details", "Basic Details");
		} else {
			updatePageStatus("complete", "Deal Details", "Basic Details");
		}
		await saveName();
	}

	async function saveName() {
		await WorkflowService.SaveName(workflow_id, name);
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Deal Details", "Basic Details");

		return () => {
			handleNavigateAway();
		};
	}, []);

	useEffect(() => {
		saveName();
	}, [name]);

	return (
		<section>
			<h2>Basic Details</h2>
			<p>First lets collect some basic details about the project you're working on.</p>
			<Form
				fields={[
					{
						name: "name",
						label: "Name",
						placeholder: "Project Transport CIM...",
						control: form.control,
						variant: FormFieldVariant.Vertical
					},
					{
						name: "description",
						label: "Description",
						placeholder: "Heritage Transport was founded in 2003, they operate in 4 states...",
						control: form.control,
						type: "textarea",
						variant: FormFieldVariant.Vertical
					}
				]}
			/>
			<Row>
				<Button
					borderShape={ButtonBorderShape.Round}
					action={handleSkip}
					variant={ButtonVariant.Secondary}
				>
					Skip
				</Button>
				<Button
					borderShape={ButtonBorderShape.Round}
					action={handleNav}
				>
					Next
				</Button>
			</Row>
		</section>
	);
}
