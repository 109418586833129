import { IDealRoomProjectElement } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetElementsByProject(project_id: string): Promise<IDealRoomProjectElement[]> {
	return HttpService.Get<IDealRoomProjectElement[]>({ path: `/dealrooms/elements/project/${project_id}` }).then((res) => res.payload);
}

export function GetElementsByPage(page_id: string): Promise<IDealRoomProjectElement[]> {
	return HttpService.Get<IDealRoomProjectElement[]>({ path: `/dealrooms/elements/page/${page_id}` }).then((res) => res.payload);
}

export function SaveElement(element: Partial<IDealRoomProjectElement>): Promise<IDealRoomProjectElement> {
	return HttpService.Post<IDealRoomProjectElement>({ path: "/dealrooms/elements", body: element }).then((res) => res.payload);
}

export function DeleteElement(element_id: string) {
	return HttpService.Delete({ path: `/dealrooms/elements/${element_id}` }).then((res) => res.payload);
}

export function UpdateElementText(element_id: string, element_text: string) {
	return HttpService.Post({ path: `/dealrooms/elements/${element_id}/text`, body: { element_text } });
}

export function MoveElement(element_id: string, page_id: string) {
	return HttpService.Post({ path: `/dealrooms/elements/${element_id}/${page_id}` }).then((res) => res.payload);
}

export function UpdateElementIdx(element_id: string, element_idx: number) {
	return HttpService.Post({ path: `/dealrooms/elements/${element_id}/idx`, body: { element_idx } }).then((res) => res.payload);
}
