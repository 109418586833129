import { customAlphabet } from "nanoid";
import { v4 as uuidv4 } from "uuid";

export class Uuid {
	static V4() {
		return uuidv4();
	}

	static Nano(n = 8) {
		const nano = customAlphabet("ABCDEFGHJKMNPQRSTUVWXYZ23456789-", n);
		return nano();
	}
}
