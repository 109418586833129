import { useMemo, useState } from "react";
import { IDealRoomProject, ProjectStatus, ProjectType } from "@sage/types";
import { Bullet, Col, Collapsible, DateHeader, FaIcon, Link, Row, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import { UserIcon, UserTag } from "@sage/shared/other";
import { isNullOrUndefined } from "@sage/utils";
import "./Project.scss";

export interface IProjectProps {
	project: IDealRoomProject;
	deleteProject?: (project_id: string) => void;
}

export function Project({ project, deleteProject }: IProjectProps) {
	const [showDrafts, setShowDrafts] = useState(false);

	const [statusIcon, statusIconColor, statusText] = useMemo(() => {
		if (project.project_status === ProjectStatus.Finished) {
			return ["circle-check", "#5da035", "Finished"];
		} else if (project.project_status === ProjectStatus.InProgress) {
			return ["circle-half-stroke", "#0094f3", "In Progress"];
		} else {
			return [null, null, null];
		}
	}, [project?.project_status]);

	const [projectTypeIcon, projectTypeColor] = useMemo(() => {
		if (project.project_type === ProjectType.CIM) {
			return ["file-chart-column", "#a80300"];
		} else if (project.project_type === ProjectType.Memo) {
			return ["file-spreadsheet", "#00a857"];
		} else if (project.project_type === ProjectType.MP) {
			return ["screen-users", "#f7a501"];
		} else if (project.project_type === ProjectType.Teaser) {
			return ["memo", "#0051a8"];
		} else if (project.project_type === ProjectType.BakeOff) {
			return ["screen-users", "#f7a501"];
		} else if (project.project_type === ProjectType.Other) {
			return ["file-chart-pie", "#5700a8"];
		} else {
			return [null, null, null];
		}
	}, [project?.project_type]);

	function handleDeleteProject(e) {
		e.preventDefault();
		e.stopPropagation();
		deleteProject(project.project_id);
	}

	return (
		<Link to={`./project/${project.project_id}`}>
			<div className="__sage-deal-room-phase-project">
				<Col>
					<Row
						gap="1rem"
						wrap={false}
					>
						<FaIcon
							color={projectTypeColor}
							icon={projectTypeIcon}
							width="fit-content"
							size={"2x"}
							borderRadius={"0.5rem"}
							paddingInline="0.75rem"
							padding="0.5rem"
							border
							hideBg
							direction="row"
						/>
						<Col gap={"0.25rem"}>
							<SectionTitle inline>{project.project_name}</SectionTitle>
							<Row
								verticalAlign="center"
								wrap
							>
								<DateHeader
									size="sm"
									thin
								>
									{project.lastModifiedTimestamp}
								</DateHeader>
								<Bullet size="sm" />
								<TimeHeader size="sm">{project.lastModifiedTimestamp}</TimeHeader>
							</Row>
						</Col>
					</Row>
					<Row verticalAlign="center">
						{statusIcon && statusIconColor && statusText && (
							<FaIcon
								color={statusIconColor}
								icon={statusIcon}
								direction={"row"}
								width="fit-content"
								active
								borderRadius={"0.25rem"}
							>
								{statusText}
							</FaIcon>
						)}
						<UserIcon
							size="md"
							user_id={project.user_id}
						/>
						<UserTag user_id={project.user_id} />
					</Row>
				</Col>
				<Collapsible visible={showDrafts}>
					<div className="revisions">
						<Col>
							{[].map((d) => (
								<ProjectVersion
									key={d.draft_id}
									version={d.version}
									date={d.date}
								/>
							))}
						</Col>
					</div>
				</Collapsible>
				<Row horizontalAlign="right">
					{
						//         <FaIcon
						// 	icon={showDrafts ? "square-caret-up" : "list-dropdown"}
						// 	color="#0051a8"
						// 	size="lg"
						// 	onClick={() => setShowDrafts((v) => !v)}
						// />
					}
					{!isNullOrUndefined(deleteProject) && (
						<FaIcon
							icon="trash-can"
							color="#a80300"
							size="lg"
							onClick={handleDeleteProject}
						/>
					)}
				</Row>
			</div>
		</Link>
	);
}

function ProjectVersion({ version, date }: { version: number; date: number }) {
	return (
		<div className="__sage-deal-room-phase-project-version">
			<Row verticalAlign="center">
				<DateHeader size={"md"}>{date}</DateHeader>
				<TimeHeader>{date}</TimeHeader>
				<UserIcon size="sm" />
				<UserTag />
				<div>v{version}</div>
			</Row>
		</div>
	);
}
