import React, { useEffect, useState } from "react";
import { DecksSelectMode, useDecksState } from "../DecksState";
import "./ActiveSlide.scss";
import { EditorTools } from "./EditorTools/EditorTools";
import { Header } from "./Header";
import { Span } from "./Span";
import { SpanGroup } from "./SpanGroup";

export function ActiveSlide() {
	const {
		xShift,
		yShift,
		scale,
		panCanvasHandler,
		selectBoxHandler,
		selection,
		isSelecting,
		activeSlide,
		spanGroups,
		headers,
		getSlideImage,
		editorSettings,
		setEditorSettings
	} = useDecksState();

	const [imageUrl, setImageUrl] = useState<string>(null);

	function handleKeyDown(e) {
		if (e.ctrlKey) {
			switch (e.key) {
				case "1":
				case "Escape":
					setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Default }));
					break;
				case "2":
				case "o":
					setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.View }));
					break;
				case "3":
				case "e":
					setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Write }));
					break;
				case "4":
				case "h":
					setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Header }));
					break;
				case "5":
				case "g":
					setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Section }));
					break;
				case "6":
				case "d":
					setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Delete }));
					break;
			}
		} else if (e.key === "Escape") {
			setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Default }));
		}
	}

	async function getImageUrl() {
		if (activeSlide?.slide_id) {
			setImageUrl(await getSlideImage(activeSlide.deck_id, activeSlide.slide_id, "hq"));
		}
	}

	useEffect(() => {
		getImageUrl();
	}, [activeSlide]);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, []);

	return (
		<div className={`__preview-slide-container ${editorSettings.colorful ? "colorful" : ""}`}>
			<div
				className="slide-preview-content"
				onMouseDown={panCanvasHandler}
			>
				<EditorTools />
				{isSelecting && (
					<div
						className="select-box"
						style={{ top: selection?.y1, left: selection?.x1, width: selection?.w, height: selection?.h }}
					/>
				)}
				<div
					className="slide-canvas"
					onMouseDown={selectBoxHandler}
					style={{
						top: yShift,
						left: xShift,
						width: `${activeSlide?.bbox.w * scale}pt`,
						height: `${activeSlide?.bbox.h * scale}pt`,
						backgroundImage: `url('${imageUrl}')`
					}}
				>
					{activeSlide?.blocks.map((block) =>
						block.lines.map((line) =>
							line.spans.map((span) => (
								<Span
									key={span.span_id}
									span={span}
								/>
							))
						)
					)}
					{spanGroups.map((group) => (
						<SpanGroup
							key={group.join(",")}
							group={group}
						/>
					))}

					{headers.map((group) => (
						<Header
							key={group.join(",")}
							group={group}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
