import { IDealRoomElementTemplate } from "@sage/types";
import { useStages } from "@sage/utils";
import { HttpService } from "../../HttpService";

export function SaveTemplate(template: Partial<IDealRoomElementTemplate>): Promise<IDealRoomElementTemplate> {
	return HttpService.Post<IDealRoomElementTemplate>({ path: "/dealrooms/element-templates", body: template }).then((res) => res.payload);
}

export function GetTemplate(template_id: string): Promise<IDealRoomElementTemplate> {
	return HttpService.Get<IDealRoomElementTemplate>({ path: `/dealrooms/element-templates/${template_id}` }).then((res) => res.payload);
}

export function GetTemplateByTeam(): Promise<IDealRoomElementTemplate[]> {
	return HttpService.Get<IDealRoomElementTemplate[]>({ path: "/dealrooms/element-templates/team" }).then((res) => res.payload);
}

export function GetSageTemplates(): Promise<IDealRoomElementTemplate[]> {
	const stage = useStages({
		local: "SAGE-AI-BETA",
		beta: "SAGE-AI-BETA",
		gamma: "SAGE-AI-GAMMA",
		prod: "SAGE-AI-PROD"
	});
	return HttpService.Get<IDealRoomElementTemplate[]>({ path: `/dealrooms/element-templates/sage/${stage}` }).then((res) => res.payload);
}

export function SearchSageTemplates(query: string): Promise<IDealRoomElementTemplate[]> {
	return HttpService.Post<IDealRoomElementTemplate[]>({ path: `/dealrooms/element-templates/search-sage`, body: { query } }).then(
		(res) => res.payload
	);
}

export function SearchTeamTemplates(query: string): Promise<IDealRoomElementTemplate[]> {
	return HttpService.Post<IDealRoomElementTemplate[]>({ path: `/dealrooms/element-templates/search`, body: { query } }).then(
		(res) => res.payload
	);
}

export function EmitTeamTemplateUse(element_id: string) {
	return HttpService.Post({ path: "dealrooms/element-templates/events/team", body: { element_id } });
}

export function EmitSageTemplateUse(element_id: string) {
	return HttpService.Post({ path: "dealrooms/element-templates/events/sage", body: { element_id } });
}

export function GetRecentSageElements(): Promise<IDealRoomElementTemplate[]> {
	return HttpService.Get<IDealRoomElementTemplate[]>({ path: "dealrooms/element-templates/events/sage" }).then((res) => res.payload);
}

export function GetRecentTeamElements(): Promise<IDealRoomElementTemplate[]> {
	return HttpService.Get<IDealRoomElementTemplate[]>({ path: "dealrooms/element-templates/events/team" }).then((res) => res.payload);
}
