import { IAcquisition, ICompany, ICompanyCBD } from "@sage/types";
import { HttpService } from "../HttpService/HttpService";
import { LlmService } from "../LlmService/LlmService";

export class DealstreamService {
	static SearchCompany(keywords: string) {
		return HttpService.Get<any[]>({
			path: `/dealstream/company/search?q=${keywords}`
		}).then((response) => response.payload);
	}

	static CreateCompany(company: { name: string; legal_name?: string; canonical_url: string }) {
		return HttpService.Post<ICompany>({ path: "/dealstream/company", body: company });
	}

	static GetBuyers(company_id: string, k?: number) {
		return HttpService.Get<any[]>({
			path: `/dealstream/buyers/${company_id}?k=${k || 12}`
		}).then((response) => response.payload);
	}

	static GetCompany(company_id: string) {
		return HttpService.Get<any>({
			path: `/dealstream/company/${company_id}`
		}).then((response) => response.payload);
	}

	static GetCompanyCBD(company_id: string) {
		return HttpService.Get<ICompanyCBD>({
			path: `/dealstream/company/cbd/${company_id}`
		}).then((response) => response.payload);
	}

	static GetCompanyImage(image_loc: string) {
		return HttpService.Get<{ url: string }>({
			path: `/dealstream/company/image?l=${image_loc}`
		}).then((response) => response.payload);
	}

	static GetAcquisition(acquisition_id: string) {
		return HttpService.Get<IAcquisition>({
			path: `/dealstream/acquisition/${acquisition_id}`
		}).then((response) => response.payload);
	}

	static GetCrawlStatus(company_id: string) {
		return HttpService.Get<{ uid: string; last_crawled?: string; is_crawling?: boolean }>({
			path: `/dealstream/company/crawl-status?uid=${company_id}`
		}).then((response) => response.payload);
	}

	static RequestDeepCrawl(company_id: string, base_url: string) {
		return HttpService.Post({
			path: `/dealstream/company/crawl`,
			body: { company_id, base_url }
		});
	}

	static Expand(company_id: string, prompt: string, cb: (e: string) => void) {
		return LlmService.Stream(
			{
				prompt: prompt,
				preprompt:
					"You are a Mergers & Acquisitions Target Analyst. Your job is to read web pages and write a detailed analysis & explaination of the requested topic. Requests will come as an instance of an employee, a product, a service, or an event. Read the web pages passed in as context and provide your analysis directly.",
				sources: [
					{
						knn: true,
						index: "company_pages",
						company_id,
						target_fields: ["title", "l1_content"],
						size: 8,
						k: 8,
						label: "Web Pages:",
						tag: "web_page"
					}
				]
			},
			cb
		);
	}
}
