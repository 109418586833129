import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { IVaultFile, VaultFileType } from "@sage/types";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService, VaultService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, Modal, PageHeader, Table, TableVariant } from "@sage/shared/core";
import { Checkbox, Form, FormFieldVariant } from "@sage/shared/forms";
import { VaultFile, VaultFileVariant } from "@sage/shared/other";
import { FloatingPane } from "../FloatingPane/FloatingPane";
import "./VaultFiles.scss";

export function VaultFiles({ onDismiss }) {
	const authState = useContext(AuthContext);
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{
		workspaceDispatch;
	}>();
	const [addLoading, setAddLoading] = useState<boolean>(false);
	const [parentFile, setParentFile] = useState(null);
	const [filesLoading, setFilesLoading] = useState(null);
	const [files, setFiles] = useState<IVaultFile[]>([]);
	const [createFileModalOpen, setCreateFileModalOpen] = useState<boolean>(false);
	const [uploadFileLoading, setUploadFileLoading] = useState<boolean>(false);

	const { control, setValue, reset } = useForm();
	const formValue = useWatch({ control });

	const editFolderNameForm = useForm();
	const uploadFileForm = useForm();

	async function add() {
		setAddLoading(true);
		const files_to_add = Object.keys(formValue)
			.filter((k) => formValue[k] && workspaceState.project.sources.map((source) => source.file_id))
			.map((file_id) => files.find((f) => f.file_id == file_id));

		const newProject = {
			...workspaceState.project,
			sources: [...workspaceState.project.sources, ...files_to_add]
		};

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: newProject
		});

		await ProjectService.UpdateProjectSources({
			project_id: newProject.project_id,
			sources: newProject.sources.map(({ file_id }) => file_id)
		});
		reset({});
		setAddLoading(false);
	}

	function navBack() {
		navigate(parentFile.parent_folder_id);
	}

	async function handleClick(file) {
		if (file.file_type === VaultFileType.FOLDER) {
			navigate(file.file_id);
		} else {
			const url = await VaultService.GetFileUrl(file);
			window.open(url, "_blank");
		}
	}

	async function navigate(parent?: string) {
		setFiles([]);
		setFilesLoading(true);

		if (parent && parent !== authState.user.teamCode) {
			setFiles((await VaultService.LoadDirectory(parent)).filter((file) => file.file_type !== VaultFileType.DOC));
			setParentFile(await VaultService.LoadFile(parent));
		} else {
			setFiles((await VaultService.LoadDirectory(authState.user.teamCode)).filter((file) => file.file_type !== VaultFileType.DOC));
			setParentFile(null);
		}

		setFilesLoading(false);
	}

	function getPrefix() {
		if (parentFile) {
			return `${parentFile.prefix}/${parentFile.file_id}`;
		} else {
			return `/${authState.user.teamCode}`;
		}
	}

	async function newFolder() {
		await VaultService.CreateFolder({
			teamCode: authState.user.teamCode,
			created_by: authState.user.user_id,
			prefix: getPrefix(),
			parent_folder_id: parentFile?.file_id || authState.user.teamCode
		});

		await navigate(parentFile?.file_id || authState.user.teamCode);
	}

	async function uploadFile(formValue) {
		setUploadFileLoading(true);
		await VaultService.CreateSource({
			teamCode: authState.user.teamCode,
			created_by: authState.user.user_id,
			prefix: getPrefix(),
			parent_folder_id: parentFile?.file_id || authState.user.teamCode,
			file_name: formValue.file_name,
			description: formValue.description,
			mime_type: formValue.file.file.type,
			size: formValue.file.file.size,
			file: formValue.file.file,
			original_filename: formValue.file.file
		});
		await navigate(parentFile?.file_id || authState.user.teamCode);
		setUploadFileLoading(false);
		setCreateFileModalOpen(false);
		uploadFileForm.reset();
	}

	async function editFolderName(e) {
		if (e.title?.length > 0) {
			await VaultService.UpdateSource({
				...parentFile,
				file_name: e.title
			});
		}
	}

	useEffect(() => {
		navigate();
	}, []);

	return (
		<>
			<Modal
				visible={createFileModalOpen}
				onDismiss={() => setCreateFileModalOpen(false)}
				title={"Upload File"}
			>
				<Form
					handleSubmit={uploadFileForm.handleSubmit(uploadFile)}
					fields={[
						{
							control: uploadFileForm.control,
							name: "file_name",
							label: "File Name",
							variant: FormFieldVariant.Vertical,
							required: true
						},
						{
							control: uploadFileForm.control,
							name: "file",
							label: "File",
							variant: FormFieldVariant.Vertical,
							type: "file",
							required: true
						},
						{
							control: uploadFileForm.control,
							name: "description",
							label: "Description",
							description:
								"A detailed description will improve search results as your vault grows. You will develop a very powerful asset if you attach detailed descriptions to each file.",
							variant: FormFieldVariant.Vertical,
							type: "textarea"
						}
					]}
					actions={[
						{
							children: "Close",
							variant: ButtonVariant.Secondary,
							borderShape: ButtonBorderShape.Round,
							action: () => setCreateFileModalOpen(false),
							testid: "close-vault-upload-modal"
						},
						{
							children: "Upload",
							variant: ButtonVariant.Primary,
							borderShape: ButtonBorderShape.Round,
							action: uploadFileForm.handleSubmit(uploadFile),
							loading: uploadFileLoading,
							testid: "confirm-vault-file-upload"
						}
					]}
				/>
			</Modal>
			<FloatingPane
				title={"Vault Files"}
				actions={[
					{
						children: "Close",
						borderShape: ButtonBorderShape.Round,
						variant: ButtonVariant.Secondary,
						action: onDismiss,
						testid: "vault-files-pane-close-pane"
					},
					{
						children: "Add",
						borderShape: ButtonBorderShape.Round,
						variant: ButtonVariant.Primary,
						action: add,
						loading: addLoading,
						testid: "vault-files-pane-add-vault-files"
					}
				]}
				resizable
			>
				<div className={"__vault-files-container"}>
					<div className={"header"}>
						{parentFile ? (
							<Button
								icon={ButtonIcon.MaterialArrowLeft}
								variant={ButtonVariant.IconSecondarySM}
								action={navBack}
								testid="vault-files-pane-nav-back"
							/>
						) : null}
						<div>
							{parentFile ? (
								<PageHeader
									title={parentFile.file_name}
									save={editFolderName}
									size={"md"}
									editTitle={true}
									form={editFolderNameForm}
								/>
							) : (
								<PageHeader
									title={"Home"}
									size={"md"}
								/>
							)}
						</div>
						<div className={"header-right"}>
							<Button
								dropdownPosition={"left"}
								borderShape={ButtonBorderShape.Round}
								variant={ButtonVariant.Icon}
								icon={ButtonIcon.MaterialAddWhite}
								options={[
									{
										text: "New Folder",
										type: "option",
										action: newFolder,
										icon: ButtonIcon.Folder,
										testid: "vault-files-pane-create-folder"
									},
									{
										text: "Upload File",
										type: "option",
										action: () => setCreateFileModalOpen(true),
										icon: ButtonIcon.Source,
										testid: "vault-files-pane-upload-file"
									}
								]}
							/>
						</div>
					</div>
					<Table
						items={files}
						variant={TableVariant.Inline}
						emptyText={"This folder is empty"}
						loading={filesLoading}
						row={(file: IVaultFile) => (
							<VaultFile
								selection={
									<Checkbox
										control={control}
										name={file.file_id}
										defaultValue={false}
										testid="select-vault-file"
									/>
								}
								onClick={() => handleClick(file)}
								key={file.file_id}
								file={file}
								variant={VaultFileVariant.Abrev}
							/>
						)}
					/>
				</div>
			</FloatingPane>
		</>
	);
}
