import React from "react";
import { Control } from "react-hook-form";
import { IProject } from "@sage/types";
import { Link, TableColumn, TableColumnSpacing, TableRow } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { UserIcon } from "../../UserIcon";
import { UserTag } from "../../UserTag";
import { VaultFSIcon, VaultIconName } from "../VaultIcon";

export interface IDocsProjectProps {
	doc: IProject;
	control?: Control;
	showMenu?: boolean;
}

export function DocsProject({ doc, control, showMenu }: IDocsProjectProps) {
	return (
		<TableRow>
			{control && showMenu !== false && (
				<TableColumn width={"0"}>
					<Checkbox
						name={doc.project_id}
						control={control}
						testid="select-docs-project"
					/>
				</TableColumn>
			)}
			<TableColumn icon={<VaultFSIcon iconName={VaultIconName.Docs} />}>
				<Link to={`/app/workspace/${doc.project_id}`}>{doc.projectName}</Link>
			</TableColumn>
			<TableColumn
				spacing={TableColumnSpacing.Fixed}
				width={"10rem"}
			>
				<UserIcon
					user_id={doc.user_id}
					size={"sm"}
				/>
				<UserTag
					user_id={doc.user_id}
					transparent
				/>
			</TableColumn>
			<TableColumn
				relativeDate
				spacing={TableColumnSpacing.Fixed}
				width={"10rem"}
			>
				{doc.lastModifiedTimestamp}
			</TableColumn>
		</TableRow>
	);
}
