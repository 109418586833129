import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "@sage/state";
import { InlineLoading } from "@sage/shared/core";
import "./UserTag.scss";

export interface IUserTagProps {
	user_id?: string;
	size?: string;
	transparent?: boolean;
}

export function UserTag({ user_id, size, transparent }: IUserTagProps) {
	const authState = useContext(AuthContext);
	const [user, setUser] = useState<string>(null);

	function lookupUser() {
		const user = authState.team?.members?.find((user) => user.user_id === (user_id || authState.user.user_id));

		if (user) setUser(`${user.firstName} ${user.lastName}`);
	}

	useEffect(() => {
		lookupUser();
	}, [user_id, authState.team?.members]);

	return (
		<div className={"__user-tag"}>
			<div className={`user-text ${size} ${transparent === false ? "" : "transparent"}`}>{user || <InlineLoading />}</div>
		</div>
	);
}
