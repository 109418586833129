import React, { useContext, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService } from "@sage/services";
import { CompanyAutosuggest } from "@sage/shared/company";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { FloatingPane } from "../FloatingPane/FloatingPane";

export function Companies({ onDismiss }) {
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();
	const [addLoading, setAddLoading] = useState<boolean>(false);

	const { control, setValue, reset } = useForm();
	const formValue = useWatch({ control });

	async function add() {
		setAddLoading(true);
		const company_id = formValue["company_id"];

		if (workspaceState.project.companies?.includes(company_id)) {
			reset({});
			setAddLoading(false);
		} else {
			const newProject = {
				...workspaceState.project,
				companies: [...(workspaceState.project.companies || []), company_id]
			};

			workspaceDispatch({
				type: WorkspaceAction.SetProject,
				payload: newProject
			});

			await ProjectService.UpdateProjectCompanies({
				project_id: newProject.project_id,
				companies: newProject.companies
			});
			reset({});
			setAddLoading(false);
		}
	}

	return (
		<FloatingPane
			title={"Companies"}
			actions={[
				{
					children: "Close",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Secondary,
					action: onDismiss,
					testid: "companies-pane-close"
				},
				{
					children: "Add",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Primary,
					action: add,
					loading: addLoading,
					testid: "companies-pane-add"
				}
			]}
			resizable
		>
			<CompanyAutosuggest
				control={control}
				name={"company_name"}
				setValue={setValue}
				description={"Add a Company to this doc"}
			/>
		</FloatingPane>
	);
}
