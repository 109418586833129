import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useAthenaState } from "@sage/state";
import { CompanyAutosuggest, CompanyCard } from "@sage/shared/company";
import { Button, ButtonBorderShape } from "@sage/shared/core";
import "./Companies.scss";

export function Companies() {
	const { addSourceCompany } = useAthenaState();
	const { control, reset, setValue } = useForm();
	const formValue = useWatch({ control });

	function handleAddCompany() {
		addSourceCompany(formValue["company_id"]);
		reset({});
	}

	return (
		<div>
			<CompanyAutosuggest
				control={control}
				name={"company_name"}
				setValue={setValue}
				description={"Add a Company as a source"}
			/>
			{formValue["company_id"] && <CompanyCard company_id={formValue["company_id"]} />}
			<div className="__companies-pane-actions">
				<Button
					children={"Add"}
					action={handleAddCompany}
					disabled={!formValue["company_id"]}
					borderShape={ButtonBorderShape.Round}
				/>
			</div>
		</div>
	);
}
