import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@sage/state";
import "./UserIcon.scss";

const colors = {
	"A": "rgba(223, 127, 100, 255)",
	"B": "rgba(137, 204, 202, 255)",
	"C": "rgba(230, 169, 128, 255)",
	"D": "rgba(193, 142, 157, 255)",
	"E": "rgba(75, 178, 163, 255)",
	"F": "rgba(35, 37, 127, 255)",
	"G": "rgba(241, 82, 114, 255)",
	"H": "rgba(104, 157, 186, 255)",
	"I": "rgba(10, 56, 105, 255)",
	"J": "rgba(64, 148, 132, 255)",
	"K": "rgba(247, 75, 74, 255)",
	"L": "rgba(93, 188, 200, 255)",
	"M": "rgba(195, 77, 62, 255)",
	"N": "rgba(106, 150, 119, 255)",
	"O": "rgba(87, 122, 148, 255)",
	"P": "rgba(78, 29, 30, 255)",
	"Q": "rgba(50, 5, 206, 255)",
	"R": "rgba(227, 116, 126, 255)",
	"S": "rgba(125, 105, 91, 255)",
	"T": "rgba(77, 161, 158, 255)",
	"U": "rgba(237, 68, 44, 255)",
	"V": "rgba(21, 60, 104, 255)",
	"W": "rgba(168, 63, 97, 255)",
	"X": "rgba(27, 99, 101, 255)",
	"Y": "rgba(250, 106, 59, 255)",
	"Z": "rgba(45, 21, 27, 255)",
	"": ""
};

export function UserIcon({
	useSage,
	email,
	size,
	action,
	user_id,
	active
}: {
	useSage?: boolean;
	email?: string;
	size?: string;
	action?: () => void;
	user_id?: string;
	active?: boolean;
}) {
	const authState = useContext(AuthContext);
	const [_email, setEmail] = useState(email);
	const [letter, setLetter] = useState("");

	function lookupUser() {
		const user = authState.team?.members?.find((user) => user.user_id === user_id);
		if (user) setEmail(user.email);
	}

	useEffect(() => {
		if (user_id) lookupUser();
	}, [user_id, authState.team?.members]);

	useEffect(() => {
		if (_email || authState.user) {
			setLetter((_email || authState.user?.email).substring(0, 1).toUpperCase());
		}
	}, [authState.user, _email]);

	if (useSage) {
		return (
			<div
				className={`__user-icon-container use-sage ${size}`}
				onClick={action}
			>
				<img src={"https://cdn.ccm.vc/sage/app-icon-circle.png"} />
			</div>
		);
	} else {
		return (
			<div
				className={`__user-icon-container ${size} ${active ? "active" : ""}`}
				style={{ backgroundColor: colors[letter] }}
				onClick={action}
			>
				<div className={"user-letter"}>{letter}</div>
			</div>
		);
	}
}
