import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function JoinTeam() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();

	const { control, handleSubmit, reset, setError } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		const res = await UserService.JoinTeam({
			teamCode: e.teamCode,
			user_id: welcomeState.welcomeForm.user_id
		});

		if (res === false) {
			setError("teamCode", {
				type: "custom",
				message: "This team code is invalid. Either this team does not exist, or you don't have access to join."
			});
		} else {
			welcomeDispatch({
				type: WelcomeAction.UpdateWelcomeForm,
				payload: {
					...e,
					teamCode: e.teamCode
				}
			});

			navigate("/app/fs");
		}
	}

	React.useEffect(() => {
		if (welcomeState.welcomeForm) {
			reset(welcomeState.welcomeForm);
		}
	}, [welcomeState.welcomeForm]);

	return (
		<Form
			title={"Join Team"}
			headerAction={{
				variant: ButtonVariant.Tertiary,
				children: "Create a new team instead",
				to: "/app/welcome/create-account/create-team",
				testid: "navigate-to-create-team"
			}}
			fields={[
				{
					label: "Team Code",
					placeholder: "e.g. SAGE-AI",
					control,
					name: "teamCode",
					description: "Enter your unique team code to join an existing team.",
					required: true
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Primary,
					children: "Get Started",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-to-next"
				}
			]}
		/>
	);
}
