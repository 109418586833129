import { createContext, useContext } from "react";
import { ITeam, IUser } from "@sage/types";
import { AuthAction, AuthActions } from "./AuthActions";
import { AuthEffects } from "./AuthEffects";

export interface IAuthState {
	user: IUser;
	team: ITeam;
	loading: boolean;
	loggedInTimestamp: number;
	failed: boolean;
	searchSettings: { [key: string]: boolean };
	searchFolderScope: string;
	searchFileScope: string;
	searchCompanyId: string;
}

export const InitialAuthState: IAuthState = {
	user: null,
	team: null,
	loading: true,
	loggedInTimestamp: null,
	failed: false,
	searchSettings: null,
	searchFolderScope: null,
	searchFileScope: null,
	searchCompanyId: null
};

export const AuthReducer = (state: IAuthState = InitialAuthState, action: AuthActions): IAuthState => {
	switch (action.type) {
		case AuthAction.Authenticate:
			AuthEffects.Authenticate().then((res) =>
				action.dispatch({
					type: AuthAction.AuthenticationComplete,
					payload: res
				})
			);
			return {
				...state,
				loading: true
			};
		case AuthAction.AuthenticationComplete:
			return {
				...state,
				user: action.payload?.user,
				team: action.payload?.team,
				loading: false,
				loggedInTimestamp: new Date().getTime(),
				failed: !!action.payload
			};
		case AuthAction.UpdateSearchSettings:
			return {
				...state,
				searchSettings: action.payload.settings || state.searchSettings,
				searchFolderScope: action.payload.searchFolderScope,
				searchFileScope: action.payload.searchFileScope,
				searchCompanyId: action.payload.searchCompanyId
			};
		case AuthAction.UpdateTeamSettings:
			return {
				...state,
				team: {
					...state.team,
					settings: action.payload
				}
			};
		case AuthAction.UpdateUserSettings:
			return {
				...state,
				user: {
					...state.user,
					...action.payload
				}
			};
		default:
			return state;
	}
};

export const AuthContext = createContext(InitialAuthState);

export function useAuthState() {
	const authState = useContext(AuthContext);
	return authState;
}
