import React, { useContext, useEffect, useReducer } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { DealStatus } from "@sage/types";
import { AuthAction, AuthContext, DealsAction, DealsContext, DealsReducer, InitialDealsState } from "@sage/state";
import { LoadingSplash } from "@sage/shared/core";

export function DealsGuard() {
	const { authDispatch } = useOutletContext<{ authDispatch }>();
	const authState = useContext(AuthContext);
	const [dealsState, dealsDispatch] = useReducer(DealsReducer, InitialDealsState);

	function loadActiveDeals() {
		dealsDispatch({
			type: DealsAction.GetDealsByStatus,
			payload: {
				status: DealStatus.ACTIVE
			},
			dispatch: dealsDispatch
		});
	}

	useEffect(() => {
		if (authState.team.teamCode) loadActiveDeals();
	}, [authState.team.teamCode]);

	useEffect(() => {
		if (authDispatch)
			authDispatch({
				type: AuthAction.UpdateSearchSettings,
				payload: {
					settings: {
						use_deals: true,
						use_llm: true,
						use_news: false,
						use_sage_news: false,
						use_data: false,
						use_files: false
					}
				}
			});
	}, []);

	if (dealsState.deals) {
		return (
			<DealsContext.Provider value={dealsState}>
				<Outlet context={{ dealsDispatch }} />
			</DealsContext.Provider>
		);
	} else {
		return <LoadingSplash invert />;
	}
}
