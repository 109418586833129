import React, { useEffect, useState } from "react";
import { Control, SetFieldValue } from "react-hook-form";
import { ITemplate } from "@sage/types";
import { ProjectService } from "@sage/services";
import { Button, ButtonVariant } from "@sage/shared/core";
import { Autosuggest } from "@sage/shared/forms";

export interface ITemplateAutosuggestProps {
	control: Control;
	setValue: SetFieldValue<any>;
	description?: string;
	generateTemplatesLink?: string;
}

export function TemplateAutosuggest({ control, setValue, description, generateTemplatesLink }: ITemplateAutosuggestProps) {
	const [options, setOptions] = useState([]);
	const [templates, setTemplates] = useState<ITemplate[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	async function loadTemplates() {
		setLoading(true);
		const _templates = await ProjectService.GetTemplates();
		setTemplates(_templates);
		setOptions(
			_templates.map((t) => ({
				_id: t.template_id,
				value: t.templateName,
				label: t.templateName,
				icon: (
					<img
						alt={"template"}
						src={`https://cdn.ccm.vc/sage/icons/new-template.svg`}
						width={`18px`}
					/>
				)
			}))
		);
		setLoading(false);
	}

	function handleClick(option) {
		setValue("template_id", option._id);
	}

	function handleChange(e) {
		setOptions(
			templates
				.filter((t) => (e.length === 0 ? true : t.templateName.toLowerCase().includes(e.toLowerCase())))
				.map((t) => ({
					_id: t.template_id,
					value: t.templateName,
					label: t.templateName,
					icon: (
						<img
							alt={"template"}
							src={`https://cdn.ccm.vc/sage/icons/new-template.svg`}
							width={`18px`}
						/>
					)
				}))
		);
		console.log(e);
	}

	useEffect(() => {
		loadTemplates();
	}, []);

	return (
		<Autosuggest
			label={"Template"}
			name="templateName"
			description={description || "Choose a template from the dropdown below. You can type to search."}
			control={control}
			setValue={setValue}
			onChange={handleChange}
			onClick={handleClick}
			onRefresh={loadTemplates}
			options={options}
			loading={loading}
			actions={
				generateTemplatesLink && (
					<Button
						variant={ButtonVariant.Tertiary}
						testid="generate-template-autosuggest"
						to={generateTemplatesLink}
					>
						Generate Template
					</Button>
				)
			}
		/>
	);
}
