import React, { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { IVaultFile, VaultFileType } from "@sage/types";
import { AuthAction, AuthContext } from "@sage/state";
import { VaultService } from "@sage/services";
import { FSTable, VaultActions } from "@sage/shared/other";
import "./FS.scss";

dayjs.extend(relativeTime);

export function VaultFS({ defaultParent }: { defaultParent?: IVaultFile }) {
	const authState = useContext(AuthContext);
	const { authDispatch } = useOutletContext<{ authDispatch }>();

	const params = useParams();
	const navigate = useNavigate();
	const selectedFileForm = useForm();
	const selectedFile = useWatch({
		control: selectedFileForm.control,
		name: "SelectedFile"
	});

	const [files, setFiles] = useState<IVaultFile[]>([]);
	const [parentFolder, setParent] = useState<IVaultFile>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const loadingRef = useRef(null);

	async function handleClick(file: IVaultFile) {
		if (file.file_type === VaultFileType.FOLDER) {
			navigate(`/app/fs/vault/files/${file.file_id}`);
		} else if (file.file_type === VaultFileType.DOC) {
			window.open(`/app/workspace/${file.sage_filetype_id}`, "_blank");
		} else if (file.file_type === VaultFileType.DECK) {
			window.open(`/app/decks/${file.sage_filetype_id}`, "_blank");
		} else {
			const url = await VaultService.GetFileUrl(file);
			window.open(url, "_blank");
		}
	}

	function updateSearchScope() {
		if (parentFolder) {
			authDispatch({
				type: AuthAction.UpdateSearchSettings,
				payload: {
					searchFolderScope: parentFolder.prefix,
					searchFileScope: null,
					settings: {
						...authState.searchSettings,
						use_scope: true,
						use_file_scope: false,
						use_companies: false
					}
				}
			});
		}
	}

	function updateSelectedFileSearchScope() {
		if (selectedFile) {
			const activeFile = files.find((f) => f.file_id === selectedFile);
			authDispatch({
				type: AuthAction.UpdateSearchSettings,
				payload: {
					searchFileScope: activeFile.prefix,
					searchFolderScope: authState.searchFolderScope,
					settings: {
						...authState.searchSettings,
						use_file_scope: true,
						use_scope: false
					}
				}
			});
		}
	}

	async function loadFiles() {
		loadingRef.current = true;
		setTimeout(() => {
			if (loadingRef.current === true) {
				setLoading(true);
			}
		}, 100);
		if (params.parent_folder_id) {
			setFiles(await VaultService.LoadDirectory(params.parent_folder_id));

			if (params.parent_folder_id !== authState.user.teamCode) {
				if (params.parent_folder_id !== authState.user.user_id) {
					setParent(await VaultService.LoadFile(params.parent_folder_id));
				} else {
					setParent({
						file_id: authState.user.user_id,
						prefix: `/${authState.user.teamCode}`,
						file_name: `Your Files`
					} as IVaultFile);
				}
			} else {
				setParent(null);
			}
		} else if (defaultParent) {
			setFiles(await VaultService.LoadDirectory(defaultParent.file_id));
			setParent(defaultParent);
		}

		setLoading(false);
		loadingRef.current = false;
	}

	function navBack() {
		navigate(`/app/fs/vault/files/${parentFolder?.parent_folder_id || authState.user.teamCode}`);
	}

	useEffect(() => {
		loadFiles();
	}, [params.parent_folder_id]);

	useEffect(() => {
		updateSearchScope();
	}, [parentFolder]);

	useEffect(() => {
		updateSelectedFileSearchScope();
	}, [selectedFile]);

	return (
		<div className={"__vault-fs-container"}>
			<VaultActions
				title={loading ? "" : parentFolder?.file_name || `${authState.team.teamName} Files`}
				form={selectedFileForm}
				parentFolder={parentFolder}
				files={files}
				loadFiles={loadFiles}
			/>
			<FSTable
				files={files}
				navBack={navBack}
				handleClick={handleClick}
				control={selectedFileForm.control}
				showPrevious={params.parent_folder_id !== authState.user.teamCode}
				loading={loading}
			/>
		</div>
	);
}
