import React from "react";
import { Link } from "react-router-dom";
import { SidePanelView, useAuthState, useDealRoom, useDealRoomProject } from "@sage/state";
import { Col, Collapsible, FaIcon, Ribon, RibonMenu, Row, SageLogo, StaticRibonSection } from "@sage/shared/core";
import { UploadFilesModal } from "../../DealRoomHome/UploadFilesModal";
import "./Header.scss";
import {
	ChartsAndGraphsSection,
	FinancialStatementSection,
	ListsSection,
	NarrativeSection,
	PagesSection,
	SectionsSection,
	StatusSection,
	TablesSection
} from "./Sections";

export function Header({ showHelp }: { showHelp?: () => void }) {
	const { user } = useAuthState();
	const { setUploadModalVisible } = useDealRoom();
	const {
		project_name,
		updateProjectName,
		setSidePanelView,
		setUpdateSplit,
		setShowStyleEditor,
		setBrowseElements,
		setShowNewElementModal,
		showRibbon,
		setShowRibbon,
		setShowScreenshotModal
	} = useDealRoomProject();

	return (
		<>
			<div className={`__sage-deal-room-project-header ${showRibbon ? "" : "collapsed"}`}>
				<Collapsible
					visible={showRibbon}
					allowOverflow
				>
					<Col
						height="100%"
						gap="0"
						verticalAlign="bottom"
						flex={"1"}
					>
						<Row
							horizontalAlign="between"
							verticalAlign="center"
						>
							<Row verticalAlign="center">
								<Link to={user.is_lead_magnet ? "." : "../../"}>
									<SageLogo color="blue" />
								</Link>
								<input
									className="project-name"
									value={project_name}
									onChange={(e) => updateProjectName(e.target.value)}
									style={{ width: `${Math.max(project_name?.length * 1.3, 12)}ch` }}
								/>
							</Row>
							<Row
								horizontalAlign="right"
								verticalAlign="center"
							>
								{user.is_lead_magnet && (
									<FaIcon
										icon={"circle-question"}
										color={"#00435c"}
										size={"lg"}
										onClick={showHelp}
									>
										Show Help Message
									</FaIcon>
								)}
								<FaIcon
									icon={"eye-slash"}
									color={"#00435c"}
									size={"lg"}
									onClick={() => setShowRibbon(false)}
								>
									Hide Header
								</FaIcon>
								<FaIcon
									icon={"cloud-arrow-up"}
									color={"#00435c"}
									size={"lg"}
									onClick={() => setUploadModalVisible(true)}
								>
									Upload Data
								</FaIcon>
								<FaIcon
									icon={"print"}
									color={"#00435c"}
									size={"lg"}
									onClick={() => window.print()}
								>
									Export to PDF
								</FaIcon>
								<FaIcon
									icon={"comments"}
									color={"#00435c"}
									size={"lg"}
									onClick={() => {
										setSidePanelView(SidePanelView.ProjectComments);
										setUpdateSplit(Math.random() / 10000 + 0.3);
									}}
								>
									Comments
								</FaIcon>
								<FaIcon
									icon={"palette"}
									color={"#00435c"}
									size={"lg"}
									onClick={() => setShowStyleEditor(true)}
								>
									Style
								</FaIcon>
							</Row>
						</Row>
						<Row verticalAlign="bottom">
							<Ribon>
								<SectionsSection
									title={"Sections"}
									id={"Sections"}
								/>
								<ChartsAndGraphsSection
									title={"Charts"}
									id={"charts-graphs"}
								/>
								<FinancialStatementSection
									title={"Financial Statements"}
									id={"financial-statements"}
								/>
								<TablesSection
									title={"Tables"}
									id={"tables"}
								/>
								<ListsSection
									title={"Lists"}
									id={"lists"}
								/>
								<NarrativeSection
									title={"Narratives"}
									id={"narratives"}
								/>
								<StatusSection
									title={"Status"}
									id={"status"}
								/>
								<PagesSection
									title={"Add Page"}
									id={"add-page"}
								/>
							</Ribon>
							<StaticRibonSection>
								<RibonMenu>
									<FaIcon
										icon={"box-taped"}
										size={"2x"}
										color={"#00435c"}
										onClick={() => {
											setShowNewElementModal(true);
										}}
									>
										New Element
									</FaIcon>
								</RibonMenu>
								<RibonMenu>
									<FaIcon
										icon={"image"}
										size={"2x"}
										color={"#00435c"}
										onClick={() => {
											setShowScreenshotModal(true);
										}}
									>
										Screenshot
									</FaIcon>
								</RibonMenu>
								<RibonMenu>
									<FaIcon
										icon={"layer-group"}
										size={"2x"}
										color={"#00435c"}
										onClick={() => {
											setBrowseElements(true);
											setSidePanelView(SidePanelView.PageTemplates);
											setUpdateSplit(Math.random() / 10000 + 0.3);
										}}
									>
										Page Templates
									</FaIcon>
								</RibonMenu>
								<RibonMenu>
									<FaIcon
										icon={"books"}
										size={"2x"}
										color={"#00435c"}
										onClick={() => {
											setBrowseElements(true);
											setSidePanelView(SidePanelView.BrowseElements);
											setUpdateSplit(Math.random() / 10000 + 0.3);
										}}
									>
										Team Elements
									</FaIcon>
								</RibonMenu>
								<RibonMenu>
									<FaIcon
										icon={"books"}
										size={"2x"}
										color={"#00435c"}
										onClick={() => {
											setBrowseElements(true);
											setSidePanelView(SidePanelView.BrowseSageElements);
											setUpdateSplit(Math.random() / 10000 + 0.3);
										}}
									>
										Sage Elements
									</FaIcon>
								</RibonMenu>
							</StaticRibonSection>
						</Row>
					</Col>
				</Collapsible>
				<UploadFilesModal />
			</div>
			{showRibbon === false && (
				<div
					className="__sage-project-editor-show-header"
					onClick={() => setShowRibbon(true)}
				>
					Show header
				</div>
			)}
		</>
	);
}
