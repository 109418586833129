import React from "react";
import { useForm } from "react-hook-form";
import { VaultFileType } from "@sage/types";
import { Checkbox } from "@sage/shared/forms";
import { VaultFSIcon, VaultIconName, VaultImagePreview } from "@sage/shared/other";
import { useDecksState } from "../../../DecksState";
import { ActiveTab, useToolsPaneState } from "../../ToolsPaneState";
import "./ProjectFiles.scss";

export function ProjectFiles() {
	const { sourceFiles } = useDecksState();
	const { activeTab } = useToolsPaneState();
	const selectedFileForm = useForm();

	function calculatePosition() {
		switch (activeTab) {
			case ActiveTab.Template:
				return "100%";
			case ActiveTab.ProjectFiles:
				return "0%";
			case ActiveTab.Vault:
				return "-100%";
			case ActiveTab.ReferenceSlides:
				return "-200%";
			case ActiveTab.Athena:
				return "-300%";
		}
	}

	return (
		<div
			className={`__decks-tools-pane-project-files-container tab`}
			style={{ left: calculatePosition() }}
		>
			<div className="header">Project Source Files</div>
			<div className="files">
				{sourceFiles.map((file) => (
					<div
						className={"file"}
						key={file.file_id}
					>
						<Checkbox
							control={selectedFileForm.control}
							name={file.file_id}
							defaultValue={false}
						/>
						{file.file_type === VaultFileType.IMAGE ? (
							<VaultImagePreview file={file} />
						) : (
							<VaultFSIcon
								iconName={
									file.file_type === VaultFileType.FOLDER
										? VaultIconName.Folder
										: file.file_type === VaultFileType.DOC
											? VaultIconName.Docs
											: file.file_type === VaultFileType.HTML
												? VaultIconName.Html
												: VaultIconName.Source
								}
							/>
						)}
						<div className={"file-name"}>{file.file_name}</div>
					</div>
				))}
			</div>
		</div>
	);
}
