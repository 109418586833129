import { ReactNode, useEffect, useRef, useState } from "react";
import { isNullOrUndefined, useMouseHandler } from "@sage/utils";
import { FaIcon } from "../FaIcon";
import "./SplitPane.scss";

export enum PaneDirection {
	Horizontal = "horizontal",
	Vertical = "vertical"
}

export function SplitPane({
	children,
	direction,
	defaultSplit,
	updateSplit
}: {
	children: ReactNode;
	direction?: PaneDirection;
	defaultSplit?: number;
	updateSplit?: number;
}) {
	if (direction === PaneDirection.Vertical) {
		return (
			<VerticalSplitPane
				defaultSplit={defaultSplit}
				updateSplit={updateSplit}
			>
				{children}
			</VerticalSplitPane>
		);
	}

	return (
		<HorizontalSplitPane
			defaultSplit={defaultSplit}
			updateSplit={updateSplit}
		>
			{children}
		</HorizontalSplitPane>
	);
}

function HorizontalSplitPane({
	children,
	defaultSplit,
	updateSplit
}: {
	children: ReactNode;
	defaultSplit?: number;
	updateSplit?: number;
}) {
	const [split, setSplit] = useState(isNullOrUndefined(defaultSplit) ? 0.25 : defaultSplit);
	const [active, setActive] = useState(false);
	const splitPaneRef = useRef(null);
	const handleRef = useRef(null);

	useEffect(() => {
		if (!isNullOrUndefined(updateSplit) && updateSplit > split) {
			setSplit(updateSplit);
		}
	}, [updateSplit]);

	function onMouseMove(e) {
		setSplit((prevSplit) => {
			const newSplit = prevSplit - e.movementX / splitPaneRef.current.clientWidth;
			const handleWidth = handleRef.current.clientWidth / splitPaneRef.current.clientWidth;
			const max = 1 - handleWidth;
			const maxThreshold = max - 0.01;
			const min = 0;
			const minThreashold = min + 0.01;

			if (newSplit < minThreashold) {
				return min;
			}
			if (newSplit > maxThreshold) {
				return max;
			}
			return newSplit;
		});
	}

	const onMouseDown = useMouseHandler({
		onMouseMove,
		onMouseUp() {
			setActive(false);
		},
		onMouseDown() {
			setActive(true);
		}
	});

	return (
		<div
			className="__sage-split-pane-container"
			ref={splitPaneRef}
		>
			<div className="split-pane-row">
				<div className="full-pane-row">{children[0]}</div>
				<div
					className={`pane-handle-row ${active ? "active" : ""}`}
					onMouseDown={onMouseDown}
					ref={handleRef}
				>
					<FaIcon
						icon={split === 0 ? "chevron-left" : "chevron-right"}
						color="#0C2741"
						size="xl"
						onClick={() => setSplit((s) => (s === 0 ? defaultSplit || 0.25 : 0))}
						hideBg
					/>
					<FaIcon
						icon={"grip-lines-vertical"}
						color="#0C2741"
						size="xl"
						hideBg
					/>
					<FaIcon
						icon={split === 0 ? "chevron-left" : "chevron-right"}
						color="#0C2741"
						size="xl"
						onClick={() => setSplit((s) => (s === 0 ? defaultSplit || 0.25 : 0))}
						hideBg
					/>
				</div>
				<div
					className="rest-pane-row"
					style={{ width: `${split * 100}%` }}
				>
					{children[1]}
				</div>
			</div>
		</div>
	);
}

function VerticalSplitPane({ children, defaultSplit, updateSplit }: { children: ReactNode; defaultSplit?: number; updateSplit?: number }) {
	const [split, setSplit] = useState(isNullOrUndefined(defaultSplit) ? 0.25 : defaultSplit);
	const [active, setActive] = useState(false);
	const splitPaneRef = useRef(null);
	const handleRef = useRef(null);

	useEffect(() => {
		if (!isNullOrUndefined(updateSplit) && updateSplit > split) {
			setSplit(updateSplit);
		}
	}, [updateSplit]);

	function onMouseMove(e) {
		setSplit((prevSplit) => {
			const newSplit = prevSplit - e.movementY / splitPaneRef.current.clientHeight;
			const handleWidth = handleRef.current.clientHeight / splitPaneRef.current.clientHeight;
			const max = 1 - handleWidth;
			const maxThreshold = max - 0.01;
			const min = 0;
			const minThreashold = min + 0.01;

			if (newSplit < minThreashold) {
				return min;
			}
			if (newSplit > maxThreshold) {
				return max;
			}
			return newSplit;
		});
	}

	const onMouseDown = useMouseHandler({
		onMouseMove,
		onMouseUp() {
			setActive(false);
		},
		onMouseDown() {
			setActive(true);
		}
	});

	return (
		<div
			className="__sage-split-pane-container"
			ref={splitPaneRef}
		>
			<div className="split-pane-col">
				<div className="full-pane-col">{children[0]}</div>
				<div
					className={`pane-handle-col ${active ? "active" : ""}`}
					onMouseDown={onMouseDown}
					ref={handleRef}
				>
					<FaIcon
						icon={split === 0 ? "chevron-up" : "chevron-down"}
						color="#0C2741"
						size="xl"
						onClick={() => setSplit((s) => (s === 0 ? defaultSplit || 0.25 : 0))}
						hideBg
					/>
					<FaIcon
						icon={"grip-lines"}
						color="#0C2741"
						size="xl"
						hideBg
					/>
					<FaIcon
						icon={split === 0 ? "chevron-up" : "chevron-down"}
						color="#0C2741"
						size="xl"
						onClick={() => setSplit((s) => (s === 0 ? defaultSplit || 0.25 : 0))}
						hideBg
					/>
				</div>
				<div
					className="rest-pane-col"
					style={{ height: `${split * 100}%` }}
				>
					{children[1]}
				</div>
			</div>
		</div>
	);
}
