import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction } from "@sage/state";
import { AuthService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function Login() {
	const { control, handleSubmit, setError } = useForm();
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();
	const [loading, setLoading] = useState<boolean>(false);

	const navigate = useNavigate();

	async function formSubmit(e) {
		setLoading(true);

		try {
			const res = await AuthService.Login(e);
			console.log("<Login/>", { res });
			if (res.is_verified && res.has_team) {
				setLoading(false);
				navigate("/app/");
			} else if (!res.is_verified) {
				setLoading(false);
				welcomeDispatch({
					type: WelcomeAction.UpdateWelcomeForm,
					payload: { ...e, user_id: res.user_id }
				});
				navigate("/app/welcome/create-account/verify-email");
			} else if (!res.has_team) {
				setLoading(false);
				welcomeDispatch({
					type: WelcomeAction.UpdateWelcomeForm,
					payload: { ...e, user_id: res.user_id }
				});
				navigate("/app/welcome/create-account/create-team");
			}
		} catch (e) {
			setLoading(false);
			setError("email", { type: "custom" });
			setError("password", {
				type: "custom",
				message: "The email or password you entered was incorrect"
			});
		}
	}

	return (
		<Form
			title={"Sign in"}
			headerAction={{
				variant: ButtonVariant.Tertiary,
				children: "Sign up instead",
				to: "/app/welcome/create-account",
				testid: "navigate-to-sign-up"
			}}
			fields={[
				{
					label: "Email",
					placeholder: "e.g. support@sageai.dev",
					control,
					name: "email",
					type: "email",
					required: true
				},
				{
					label: "Password",
					placeholder: "* * * * * * * *",
					control,
					name: "password",
					type: "password",
					required: true
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Tertiary,
					children: "Forgot password?",
					borderShape: ButtonBorderShape.Round,
					to: "/app/welcome/forgot-password",
					testid: "reset-password"
				},
				{
					variant: ButtonVariant.Primary,
					children: "Continue",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "submit-login",
					loading
				}
			]}
		/>
	);
}
