import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
	DealRoomProjectElementType,
	DealRoomProjectPageType,
	IDealRoomCrawl,
	IDealRoomProject,
	IDealRoomProjectElement,
	IDealRoomProjectPage,
	IDealRoomProjectPageTemplate,
	ProjectType
} from "@sage/types";
import { DealRoomService } from "@sage/services";
import { LoadingSplash } from "@sage/shared/core";
import { isNullOrUndefined, useDebounce } from "@sage/utils";
import { usePageMeta } from "@sage/utils";
import { useDealRoom } from "../DealRoomState";

export interface IDealRoomProjectState extends Partial<IDealRoomProject> {
	project_id: string;
	activePage: string;
	setActivePage: Dispatch<SetStateAction<string>>;
	activeElement: string;
	setActiveElement: Dispatch<SetStateAction<string>>;
	pages: IDealRoomProjectPage[];
	elements: { [key: string]: IDealRoomProjectElement[] };
	primary_color: string;
	secondary_color: string;
	font_color: string;
	ready: boolean;
	updateSplit: number;
	setUpdateSplit: Dispatch<SetStateAction<number>>;
	addPage: (pageType: DealRoomProjectPageType, insertIdx?: number) => void;
	updatePage: (page: IDealRoomProjectPage) => void;
	removePage: (page_id?: string) => void;
	addElement: (elementType: DealRoomProjectElementType, element_id?: string) => void;
	removeElement: (element_id: string) => void;
	moveElementUp: (element_id: string, page_id: string) => void;
	moveElementDown: (element_id: string, page_id: string) => void;
	moveElementUpWithinPage: (element_id: string, page_id: string) => void;
	moveElementDownWithinPage: (element_id: string, page_id: string) => void;
	toMoveUp: { page_id: string; distance: number };
	toMoveDown: { page_id: string; distance: number };
	swap: (from: string, to: string) => void;
	pageHeights: Record<string, number>;
	reportPageHeight: (e: { page_id: string; height: number }) => void;
	updateProjectName: (name: string) => void;
	loadElements: () => void;
	loadProject: () => void;
	deletePage: IDealRoomProjectPage;
	setDeletePage: Dispatch<SetStateAction<IDealRoomProjectPage>>;
	confirmDeletePage: () => void;
	deleteElement: IDealRoomProjectElement;
	setDeleteElement: Dispatch<SetStateAction<IDealRoomProjectElement>>;
	confirmDeleteElement: () => void;
	setBrowseElements: Dispatch<SetStateAction<boolean>>;
	sidePanelView: SidePanelView;
	setSidePanelView: Dispatch<SetStateAction<SidePanelView>>;
	showStyleEditor: boolean;
	setShowStyleEditor: Dispatch<SetStateAction<boolean>>;
	refreshElement: number;
	setRefreshElement: Dispatch<SetStateAction<number>>;
	showNewElementModal: boolean;
	setShowNewElementModal: Dispatch<SetStateAction<boolean>>;
	savePageModalVisible: boolean;
	setSavePageModalVisible: Dispatch<SetStateAction<boolean>>;
	savePageId: string;
	setSavePageId: Dispatch<SetStateAction<string>>;
	createPageFromTemplate: (pageTemplate: IDealRoomProjectPageTemplate) => void;
	crawls: IDealRoomCrawl[];
	showRibbon: boolean;
	setShowRibbon: Dispatch<SetStateAction<boolean>>;
	showScreenshotModal: boolean;
	setShowScreenshotModal: Dispatch<SetStateAction<boolean>>;
}

export enum SidePanelView {
	Element = "element",
	BrowseElements = "browse-elements",
	BrowseSageElements = "browse-sage-elements",
	ElementComments = "element-comments",
	PageComments = "page-comments",
	ProjectComments = "project-comments",
	PageTemplates = "page-templates"
}

const initialState: IDealRoomProjectState = {
	deletePage: null,
	setDeletePage: null,
	confirmDeletePage: null,
	deleteElement: null,
	setDeleteElement: null,
	confirmDeleteElement: null,
	project_id: null,
	activePage: "1",
	primary_color: null,
	secondary_color: null,
	font_color: null,
	setActivePage: null,
	activeElement: null,
	setActiveElement: null,
	pages: [],
	elements: {},
	updateSplit: 0,
	setUpdateSplit: null,
	ready: null,
	addPage: null,
	updatePage: null,
	removePage: null,
	addElement: null,
	removeElement: null,
	toMoveUp: null,
	toMoveDown: null,
	swap: null,
	pageHeights: null,
	reportPageHeight: null,
	updateProjectName: null,
	loadElements: null,
	loadProject: null,
	setBrowseElements: null,
	sidePanelView: null,
	setSidePanelView: null,
	showStyleEditor: null,
	setShowStyleEditor: null,
	refreshElement: null,
	setRefreshElement: null,
	showNewElementModal: null,
	setShowNewElementModal: null,
	moveElementUp: null,
	moveElementDown: null,
	moveElementUpWithinPage: null,
	moveElementDownWithinPage: null,
	savePageModalVisible: null,
	setSavePageModalVisible: null,
	setSavePageId: null,
	savePageId: null,
	createPageFromTemplate: null,
	crawls: null,
	showRibbon: null,
	setShowRibbon: null,
	showScreenshotModal: null,
	setShowScreenshotModal: null
};

const DealRoomProjectContext = createContext<IDealRoomProjectState>(initialState);

export function DealRoomProjectProvider({ children }: { children: ReactNode }) {
	const state = createDealRoomProjectState();

	return (
		<DealRoomProjectContext.Provider value={state}>{state.ready ? children : <LoadingSplash invert />}</DealRoomProjectContext.Provider>
	);
}

function createDealRoomProjectState(): IDealRoomProjectState {
	const { deal_name } = useDealRoom();
	const { project_id, deal_id } = useParams();
	const [project, setProject] = useState<IDealRoomProject>(null);
	const [activePage, setActivePage] = useState<string>(null);
	const [activeElement, setActiveElement] = useState<string>(null);
	const [ready, setReady] = useState<boolean>(false);
	const [pages, setPages] = useState<IDealRoomProjectPage[]>([]);
	const [elements, setElements] = useState<{ [key: string]: IDealRoomProjectElement[] }>({});
	const [toMoveUp, setToMoveUp] = useState<{ page_id: string; distance: number }>(null);
	const [toMoveDown, setToMoveDown] = useState<{ page_id: string; distance: number }>(null);
	const [pageHeights, setPageHeights] = useState<Record<string, number>>({});
	const [updateSplit, setUpdateSplit] = useState<number>(0);
	const [deletePage, setDeletePage] = useState<IDealRoomProjectPage>(null);
	const [deleteElement, setDeleteElement] = useState<IDealRoomProjectElement>(null);
	const [sidePanelView, setSidePanelView] = useState<SidePanelView>(SidePanelView.Element);
	const [showStyleEditor, setShowStyleEditor] = useState<boolean>(false);
	const [refreshElement, setRefreshElement] = useState<number>(null);
	const [projectName, setProjectName] = useState<string>(null);
	const [showNewElementModal, setShowNewElementModal] = useState<boolean>(false);
	const [savePageModalVisible, setSavePageModalVisible] = useState<boolean>(false);
	const [savePageId, setSavePageId] = useState<string>(null);
	const [crawls, setCrawls] = useState<IDealRoomCrawl[]>([]);
	const [showRibbon, setShowRibbon] = useState<boolean>(true);
	const [showScreenshotModal, setShowScreenshotModal] = useState<boolean>(false);

	usePageMeta({
		title: projectName,
		defaultTitle: "Sage AI Project Editor"
	});

	const activePageContent = useMemo(() => pages.find((p) => p.page_id === activePage) || pages[0], [pages, activePage]);

	const saveProjectName = useDebounce(async (name: string) => {
		setProjectName(name);
		await DealRoomService.UpdateProjectName(project_id, name);
	});

	async function updateProjectName(name: string) {
		setProject({ ...project, project_name: name });
		saveProjectName(name);
	}

	async function loadCrawls() {
		setCrawls(await DealRoomService.GetCrawls(deal_id));
	}

	async function loadProject() {
		const _project = await DealRoomService.GetProject(project_id);
		setProject(_project);
		setProjectName(_project.project_name);
		setReady(true);
	}

	async function loadPages() {
		const _pages = await DealRoomService.GetPages(project_id);
		if (_pages?.length === 0) {
			const page = await createPage(DealRoomProjectPageType.CustomPage, 0);
			setActivePage(page.page_id);
			setPages([page]);
		} else {
			let pages_sorted = _pages.sort((a, b) => a.page_index - b.page_index);
			setActivePage(pages_sorted[0].page_id);
			setPages(pages_sorted);
		}
	}

	async function loadElements() {
		const _elements = await DealRoomService.GetElementsByProject(project_id);
		let _element_map = {};

		for (let el of _elements) {
			if (el.page_id in _element_map) {
				_element_map[el.page_id].push(el);
			} else {
				_element_map[el.page_id] = [el];
			}
		}

		setElements(_element_map);

		for (let _page_id of Object.keys(_element_map)) {
			const _els = _element_map[_page_id];
			if (_els.some((el) => isNullOrUndefined(el.element_idx))) {
				await Promise.all(
					_els
						.sort((a, b) => a.lastModifiedTimestamp - b.lastModifiedTimestamp)
						.map((el, idx) => DealRoomService.UpdateElementIdx(el.element_id, idx))
				);
				await loadElements();
			}
		}
	}

	useEffect(() => {
		if (project_id) {
			loadProject();
			loadPages();
			loadElements();
			loadCrawls();
		}
	}, [project_id]);

	function createPage(page_type: DealRoomProjectPageType, page_index: number): Promise<IDealRoomProjectPage> {
		return DealRoomService.SavePage({
			page_type,
			page_index,
			deal_id,
			project_id,
			page_name: page_type !== DealRoomProjectPageType.CoverPage ? page_type.toString() : deal_name
		});
	}

	async function createElement(
		element_type: DealRoomProjectElementType,
		element_template_id?: string,
		page?: string
	): Promise<IDealRoomProjectElement> {
		const template = await DealRoomService.GetTemplate(element_template_id);

		let page_id = "";

		if (!isNullOrUndefined(page)) {
			page_id = page;
		} else if (!isNullOrUndefined(activePage)) {
			page_id = activePage;
		} else {
			page_id = pages[0].page_id;
		}
		return await DealRoomService.SaveElement({
			element_type: template.element_type,
			page_id,
			deal_id,
			project_id,
			element_template_id,
			element_name: template.element_name
		});
	}

	async function addPage(page_type: DealRoomProjectPageType, insertIdx?: number) {
		let idx = insertIdx;

		if (isNullOrUndefined(idx)) {
			if (activePageContent) {
				idx = activePageContent.page_index + 1;
			} else {
				idx = pages.length;
			}
		}

		const newPage = await createPage(page_type, idx);

		const newPages = [...pages.slice(0, idx), newPage, ...pages.slice(idx)].map((p, idx) => ({ ...p, page_index: idx }));

		setPages(newPages);

		setElements((currentElements) => {
			return {
				...currentElements,
				[newPage.page_id]: []
			};
		});

		await Promise.all(newPages.map((page) => DealRoomService.UpdatePageIndex(page.page_id, page.page_index)));
		return newPage;
	}

	function updatePage(page: IDealRoomProjectPage) {
		setPages((currentPages) => {
			let cur = [...currentPages];
			cur[page.page_index] = page;
			return cur;
		});
	}

	async function removePage(page_id?: string) {
		await DealRoomService.DeletePage(page_id);

		const newPages = pages
			.filter((p) => p.page_id !== (page_id || activePageContent.page_id))
			.map((p, idx) => ({ ...p, page_index: idx }));

		setPages(newPages);

		await Promise.all(newPages.map((page) => DealRoomService.UpdatePageIndex(page.page_id, page.page_index)));
	}

	async function addElement(element_type: DealRoomProjectElementType, element_template_id?: string, to_page_id?: string) {
		if (to_page_id) {
			const newElement = await createElement(element_type, element_template_id, to_page_id);

			setElements((currentElements) => {
				let currentPageElements = [];
				let active_page_id = to_page_id;
				if (Object.keys(currentElements).includes(active_page_id)) {
					currentPageElements = currentElements[active_page_id];
				}
				return {
					...currentElements,
					[active_page_id]: [...currentPageElements, newElement]
				};
			});
		} else if (pages.length === 0) {
			const page = await addPage(DealRoomProjectPageType.CustomPage);
			const newElement = await createElement(element_type, element_template_id, page.page_id);

			setElements((currentElements) => {
				let currentPageElements = [];
				let active_page_id = page.page_id;
				if (Object.keys(currentElements).includes(active_page_id)) {
					currentPageElements = currentElements[active_page_id];
				}
				return {
					...currentElements,
					[active_page_id]: [...currentPageElements, newElement]
				};
			});
		} else {
			const newElement = await createElement(element_type, element_template_id);
			setElements((currentElements) => {
				let currentPageElements = [];
				let active_page_id = pages[0].page_id;
				if (!isNullOrUndefined(activePage)) {
					active_page_id = activePage;
				}
				if (Object.keys(currentElements).includes(active_page_id)) {
					currentPageElements = currentElements[active_page_id];
				}
				return {
					...currentElements,
					[active_page_id]: [...currentPageElements, newElement]
				};
			});
		}
	}

	async function removeElement(element_id: string) {
		let currentElements = { ...elements };
		let updates = [];
		for (let page_id of Object.keys(currentElements)) {
			if (currentElements[page_id].find((el) => el.element_id === element_id)) {
				const updatedElements = currentElements[page_id]
					.filter((el) => el.element_id !== element_id)
					.sort((a, b) => a.element_idx - b.element_idx)
					.map((el, idx) => ({ ...el, element_idx: idx }));
				currentElements[page_id] = updatedElements;
				updates = [...updates, updatedElements];
			}
		}
		setElements(currentElements);

		await DealRoomService.DeleteElement(element_id);

		if (element_id === activeElement) {
			setActiveElement(null);
		}

		await Promise.all(updates.map((el) => DealRoomService.UpdateElementIdx(el.element_id, el.element_idx)));
	}

	async function swap(from: string, to: string) {
		setToMoveDown({ page_id: from, distance: pageHeights[to] });
		setToMoveUp({ page_id: to, distance: pageHeights[from] });

		const cur = [...pages];
		const fromPage = cur.find((p) => p.page_id === from);
		const toPage = cur.find((p) => p.page_id === to);
		let tmpfrom = fromPage.page_index;
		fromPage.page_index = toPage.page_index;
		toPage.page_index = tmpfrom;
		const newPages = cur.sort((a, b) => a.page_index - b.page_index);

		await Promise.all(newPages.map((page) => DealRoomService.UpdatePageIndex(page.page_id, page.page_index)));

		setTimeout(() => {
			setToMoveUp(null);
			setToMoveDown(null);
			setPages(newPages);
		}, 300);
	}

	async function createPageFromTemplate(pageTemplate: IDealRoomProjectPageTemplate) {
		const newPage = await addPage(DealRoomProjectPageType.CustomPage);

		await DealRoomService.UpdatePageName(newPage.page_id, pageTemplate.page_title);
		updatePage({ ...newPage, page_name: pageTemplate.page_title });

		for (let element of pageTemplate.elements) {
			const elTemplate = await DealRoomService.GetTemplate(element);
			await addElement(elTemplate.element_type, elTemplate.element_id, newPage.page_id);
		}
	}

	function reportPageHeight({ page_id, height }: { page_id: string; height: number }) {
		setPageHeights((heights) => ({ ...heights, [page_id]: height }));
	}

	function confirmDeletePage() {
		removePage(deletePage.page_id);
		setDeletePage(null);
	}

	function confirmDeleteElement() {
		removeElement(deleteElement?.element_id);
		setDeleteElement(null);
	}

	function setBrowseElements() {
		setSidePanelView(SidePanelView.BrowseElements);
	}

	async function moveElementUp(element_id: string, page_id: string) {
		const pageNumber = pages.find((p) => p.page_id === page_id).page_index;
		const newPageId = pages.find((p) => p.page_index === pageNumber - 1).page_id;
		if (newPageId) {
			const currentNeighbors = elements[page_id];
			await Promise.all([
				...currentNeighbors
					.filter((el) => el.element_id !== element_id)
					.map((el, idx) => DealRoomService.UpdateElementIdx(el.element_id, idx)),
				DealRoomService.MoveElement(element_id, newPageId)
			]);
			await loadElements();
		}
	}

	async function moveElementDown(element_id: string, page_id: string) {
		const pageNumber = pages.find((p) => p.page_id === page_id).page_index;
		const newPageId = pages.find((p) => p.page_index === pageNumber + 1).page_id;

		if (newPageId) {
			const currentNeighbors = elements[page_id];
			await Promise.all([
				...currentNeighbors
					.filter((el) => el.element_id !== element_id)
					.map((el, idx) => DealRoomService.UpdateElementIdx(el.element_id, idx)),
				DealRoomService.MoveElement(element_id, newPageId)
			]);
			await loadElements();
		}
	}

	async function moveElementUpWithinPage(element_id: string, page_id: string) {
		const pageElements = elements[page_id].sort((a, b) => a.element_idx - b.element_idx);
		const curIdx = pageElements.find((el) => el.element_id === element_id).element_idx;

		if (curIdx > 0) {
			await Promise.all([
				DealRoomService.UpdateElementIdx(element_id, curIdx - 1),
				DealRoomService.UpdateElementIdx(pageElements[curIdx - 1].element_id, curIdx)
			]);
			await loadElements();
		}
	}

	async function moveElementDownWithinPage(element_id: string, page_id: string) {
		const pageElements = elements[page_id].sort((a, b) => a.element_idx - b.element_idx);
		const curIdx = pageElements.find((el) => el.element_id === element_id).element_idx;

		if (curIdx < pageElements.length - 1) {
			await Promise.all([
				DealRoomService.UpdateElementIdx(element_id, curIdx + 1),
				DealRoomService.UpdateElementIdx(pageElements[curIdx + 1].element_id, curIdx)
			]);
			await loadElements();
		}
	}

	return {
		...project,
		activePage,
		activeElement,
		setActiveElement,
		addElement,
		addPage,
		pages,
		elements,
		project_id,
		updateSplit,
		setUpdateSplit,
		ready,
		removeElement,
		removePage,
		setActivePage,
		swap,
		moveElementUp,
		moveElementDown,
		moveElementUpWithinPage,
		moveElementDownWithinPage,
		toMoveDown,
		toMoveUp,
		updatePage,
		pageHeights,
		reportPageHeight,
		updateProjectName,
		loadElements,
		loadProject,
		deleteElement,
		setDeleteElement,
		deletePage,
		setDeletePage,
		confirmDeletePage,
		confirmDeleteElement,
		setBrowseElements,
		sidePanelView,
		setSidePanelView,
		showStyleEditor,
		setShowStyleEditor,
		refreshElement,
		setRefreshElement,
		showNewElementModal,
		setShowNewElementModal,
		savePageModalVisible,
		setSavePageModalVisible,
		savePageId,
		setSavePageId,
		createPageFromTemplate,
		crawls,
		showRibbon,
		setShowRibbon,
		showScreenshotModal,
		setShowScreenshotModal
	};
}

export function useDealRoomProject(): IDealRoomProjectState {
	return useContext(DealRoomProjectContext);
}

export interface IDealRoomProjectPageState extends IDealRoomProjectPage {
	active: boolean;
	project_type: ProjectType;
	moveDown: () => void;
	moveUp: () => void;
	remove: () => void;
	setPageActive: () => void;
	shouldMoveDown: number;
	shouldMoveUp: number;
	updatePageElements: (elements: IDealRoomProjectElement[]) => void;
	updatePageName: (pageName: string) => void;
	reportHeight: (height: number) => void;
}

export function useDealRoomProjectPage(page_id: string): IDealRoomProjectPageState {
	const {
		pages,
		elements,
		updatePage,
		activePage,
		setActivePage,
		setDeletePage,
		swap,
		toMoveUp,
		toMoveDown,
		reportPageHeight,
		project_type
	} = useDealRoomProject();

	const page = useMemo(() => pages.find((p) => p.page_id === page_id), [pages]);

	const pageElements = useMemo(() => {
		if (page_id in elements) {
			return elements[page_id];
		} else {
			return [];
		}
	}, [elements]);

	function remove() {
		setDeletePage(page);
	}

	function setPageActive() {
		setActivePage(page_id);
	}

	async function updatePageName(page_name: string) {
		updatePage({
			...page,
			page_name
		});
		await DealRoomService.UpdatePageName(page_id, page_name);
	}

	function updatePageElements(elements: IDealRoomProjectElement[]) {
		updateElements(elements);
	}

	function moveUp() {
		if (page.page_index - 1 >= 0) {
			swap(pages[page.page_index - 1].page_id, page.page_id);
		}
	}

	function moveDown() {
		if (pages.length > page.page_index + 1) {
			swap(page.page_id, pages[page.page_index + 1].page_id);
		}
	}

	function reportHeight(height: number) {
		reportPageHeight({ page_id: page.page_id, height });
	}

	return {
		...page,
		project_type,
		elements: pageElements,
		active: activePage === page.page_id,
		moveDown,
		moveUp,
		remove,
		setPageActive,
		shouldMoveDown: toMoveDown?.page_id === page.page_id ? toMoveDown?.distance : null,
		shouldMoveUp: toMoveUp?.page_id === page.page_id ? toMoveUp?.distance : null,
		updatePageElements,
		updatePageName,
		reportHeight
	};
}

export interface IDealRoomProjectElementState extends IDealRoomProjectElement {
	remove: () => void;
	active: boolean;
	setActive: () => void;
}

export function useDealRoomProjectElement({ element_id, page_id }: { element_id: string; page_id: string }): IDealRoomProjectElementState {
	const { activeElement, setActiveElement, setDeleteElement, setUpdateSplit, setSidePanelView } = useDealRoomProject();
	const { elements } = useDealRoomProjectPage(page_id);
	const element = useMemo(() => elements.find((el) => el.element_id === element_id), [elements]);

	async function remove() {
		setDeleteElement(element);
	}

	function setActive() {
		setActiveElement(element_id);
		setUpdateSplit(0.3 + Math.random() / 10000);
		setSidePanelView(SidePanelView.Element);
	}

	return {
		...element,
		remove,
		active: activeElement === element_id,
		setActive
	};
}

export interface IDealRoomProjectActiveElementState {
	element: IDealRoomProjectElement;
	page: IDealRoomProjectPage;
	updateElement: (e: Partial<IDealRoomProjectElement>) => void;
}

export function useDealRoomProjectActiveElement(): IDealRoomProjectActiveElementState {
	const { activeElement, pages, elements } = useDealRoomProject();
	const { page, element } = useMemo(() => {
		for (let page_id of Object.keys(elements)) {
			const found = elements[page_id].find((el) => el.element_id === activeElement);
			if (found) {
				return { page: pages.find((p) => p.page_id), element: found };
			}
		}
		return { page: null, element: null };
	}, [activeElement]);

	function updateElement(el) {
		console.log(el);
	}

	return {
		page,
		element,
		updateElement
	};
}

export function useProjectSidePanel() {
	const { sidePanelView, setSidePanelView } = useDealRoomProject();

	return {
		sidePanelView,
		setSidePanelView
	};
}

const pageTypeColor = {
	[DealRoomProjectPageType.BusinessOverview]: "#0051a8",
	[DealRoomProjectPageType.CompanyHistory]: "#30abc6",
	[DealRoomProjectPageType.CustomPage]: "#b62ad9",
	[DealRoomProjectPageType.FinancialAnalysis]: "#00a857",
	[DealRoomProjectPageType.IndustryAnalysis]: "#a80300",
	[DealRoomProjectPageType.ManagementTeam]: "#5700a8",
	[DealRoomProjectPageType.SalesAndMarketing]: "#f7a501",
	[DealRoomProjectPageType.CoverPage]: "#0051a8"
};

const pageTypeIcon = {
	[DealRoomProjectPageType.BusinessOverview]: "building-memo",
	[DealRoomProjectPageType.CompanyHistory]: "timeline-arrow",
	[DealRoomProjectPageType.CustomPage]: "file-pen",
	[DealRoomProjectPageType.FinancialAnalysis]: "file-spreadsheet",
	[DealRoomProjectPageType.IndustryAnalysis]: "industry-windows",
	[DealRoomProjectPageType.ManagementTeam]: "people-group",
	[DealRoomProjectPageType.SalesAndMarketing]: "display-chart-up-circle-dollar",
	[DealRoomProjectPageType.CoverPage]: "notebook"
};

export function dealRoomProjectPageIcon(page_type: DealRoomProjectPageType): { icon: string; color: string } {
	return {
		icon: pageTypeIcon[page_type],
		color: pageTypeColor[page_type]
	};
}

const elementTypeColor = {
	"bullets": "#30abc6",
	"chart": "#0051a8",
	"Custom Element": "#5700a8",
	"Element Library": "#f7a501",
	"paragraph": "#a80300",
	"table": "#00a857"
};

const elementTypeIcon = {
	"bullets": "list-ul",
	"chart": "chart-pie-simple-circle-dollar",
	"Custom Element": "message-bot",
	"Element Library": "books",
	"paragraph": "align-left",
	"table": "file-spreadsheet"
};

export function dealRoomProjectElementIcon(elementType: DealRoomProjectElementType): { icon: string; color: string } {
	return {
		icon: elementTypeIcon[elementType],
		color: elementTypeColor[elementType]
	};
}

const projectTypeNames = {
	[ProjectType.CIM]: "CONFIDENTIAL INFORMATION MEMORANDUM",
	[ProjectType.MP]: "MANAGEMENT PRESENTATION",
	[ProjectType.Memo]: "CONFIDENTIAL MEMO",
	[ProjectType.Teaser]: "ANONYMOUS TEASER",
	[ProjectType.BakeOff]: "BAKE OFF PRESENTATION",
	[ProjectType.Other]: ""
};

export function useProjectTypeName(project_type: ProjectType): string {
	return projectTypeNames[project_type];
}
