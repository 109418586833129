import React from "react";
import { Card, CardList, CardListItem, CardListItemText, CardListItemTitle, CardTitle } from "@sage/shared/core";

export function Faqs({ faqs }) {
	return faqs.length ? (
		<Card>
			<CardTitle>Frequently Asked Questions</CardTitle>
			<CardList>
				{faqs.map((faq) => (
					<CardListItem key={faq.answer}>
						<CardListItemTitle data-testid="company-faqs-question">{faq.question}</CardListItemTitle>
						<CardListItemText data-testid="company-faqs-answer">{faq.answer}</CardListItemText>
					</CardListItem>
				))}
			</CardList>
		</Card>
	) : null;
}
