import { Dispatch } from "react";
import { IProject } from "@sage/types";

export enum FsAction {
	LoadRecentProjects = "LoadRecentProjects",
	SetRecentProjects = "SetRecentProjects",
	LoadSharedProjects = "LoadSharedProjects",
	SetSharedProjects = "SetSharedProjects",
	LoadFavoritedProjects = "LoadFavoritedProjects",
	SetFavoritedProjects = "SetFavoritedProjects"
}

export interface ILoadRecentProjectsAction {
	type: FsAction.LoadRecentProjects;
	dispatch: Dispatch<ISetRecentProjectsAction>;
}

export interface ISetRecentProjectsAction {
	type: FsAction.SetRecentProjects;
	payload: IProject[];
}

export interface ILoadSharedProjectsAction {
	type: FsAction.LoadSharedProjects;
	dispatch: Dispatch<ISetSharedProjectsAction>;
}

export interface ISetSharedProjectsAction {
	type: FsAction.SetSharedProjects;
	payload: IProject[];
}

export interface ILoadFavoritedProjectsAction {
	type: FsAction.LoadFavoritedProjects;
	dispatch: Dispatch<ISetFavoritedProjectsAction>;
}

export interface ISetFavoritedProjectsAction {
	type: FsAction.SetFavoritedProjects;
	payload: IProject[];
}

export type FsActions =
	| ILoadRecentProjectsAction
	| ISetRecentProjectsAction
	| ILoadSharedProjectsAction
	| ISetSharedProjectsAction
	| ILoadFavoritedProjectsAction
	| ISetFavoritedProjectsAction;
