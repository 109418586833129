import React from "react";
import { useDroppable } from "@dnd-kit/core";

export function TemplateDroppable({ children }) {
	const { isOver, setNodeRef } = useDroppable({
		id: "template-droppable"
	});

	return (
		<div
			ref={setNodeRef}
			className={`template-droppable ${isOver ? "is-over" : ""}`}
			aria-label="Droppable region"
		>
			{children}
		</div>
	);
}
