import React, { useState } from "react";
import { ChartColorsProvider, DealRoomProjectProvider, DealRoomProvider, useAuthState, useDealRoom, useDealRoomProject } from "@sage/state";
import { Col, Link, Modal, P, SplitPane } from "@sage/shared/core";
import { DeleteModals } from "../DeleteModals";
import { ElementDetails } from "../ElementDetails";
import { Header } from "../Header";
import { NewElementModal } from "../NewElementModal";
import { Page } from "../Page";
import { SavePageModal } from "../SavePageModal";
import { ScreenshotModal } from "../ScreenshotModal";
import { StyleEditorModal } from "../StyleEditorModal";
import "./ProjectEditor.scss";

export function ProjectEditor() {
	return (
		<DealRoomProvider>
			<DealRoomProjectProvider>
				<ProjectEditorPage />
				<DeleteModals />
				<StyleEditorModal />
				<NewElementModal />
				<SavePageModal />
				<ScreenshotModal />
			</DealRoomProjectProvider>
		</DealRoomProvider>
	);
}

export function ProjectEditorPage() {
	const { user } = useAuthState();
	const { setUploadModalVisible } = useDealRoom();
	const { pages, updateSplit, showRibbon, chart_colors } = useDealRoomProject();
	const [showLeadMagnetPage, setShowLeadMagnetPage] = useState(true);

	return (
		<ChartColorsProvider chart_colors={chart_colors}>
			<div className={`__sage-project-editor-container`}>
				<SplitPane
					defaultSplit={0}
					updateSplit={updateSplit}
				>
					<Col gap="0">
						<Header showHelp={() => setShowLeadMagnetPage(true)} />
						<div className={`page-editor ${showRibbon ? "" : "collapsed"}`}>
							<Col
								horizontalAlign="center"
								gap={"0"}
							>
								{pages.map((page) => (
									<Page
										key={page.page_id}
										page_id={page.page_id}
									/>
								))}
							</Col>
						</div>
					</Col>
					<ElementDetails />
				</SplitPane>
				{user.is_lead_magnet ? (
					<Modal
						title={"Welcome!"}
						visible={showLeadMagnetPage}
						onDismiss={() => {
							setShowLeadMagnetPage(false);
							setUploadModalVisible(true);
						}}
						actions={[
							{
								children: "Continue",
								action: () => {
									setShowLeadMagnetPage(false);
									setUploadModalVisible(true);
								}
							}
						]}
					>
						<Col
							width={"50ch"}
							padding={"0.5rem"}
						>
							<P>Hi, thank's for checking out Sage AI.</P>
							<P>This is a trial project that you can use to create some CIM slides</P>
							<P>
								There is no time limit, but since you didn't have to create an account, this project is temporary and you
								may loose it if you clear your cookies.
							</P>
							<P>If you decide that you want to create an account, we can get this linked to your actual account later.</P>
							<P>
								<strong>To get started, upload some data</strong>
							</P>
							<P>
								Then once you drag and drop your files in, you can click on the elements in the toolbar to start creating
								CIM elements.
							</P>
							<P>
								To see some examples, check out our{" "}
								<Link
									to="https://sageai.dev/examples"
									target="_blank"
									accent
									inline
								>
									examples page on our website
								</Link>
								.
							</P>
							<P>
								If you like what you see and want to get started,{" "}
								<Link
									to="https://sageai.dev/#get-started"
									target="_blank"
									accent
									inline
								>
									schedule a 15 minute call with the team
								</Link>
								.
							</P>
							<P>
								If you have any questions, please email{" "}
								<Link
									to="mailto:brendan.oreilly@sageai.dev"
									target="_blank"
									accent
									inline
								>
									brendan.oreilly@sageai.dev
								</Link>
								.
							</P>
						</Col>
					</Modal>
				) : null}
			</div>
		</ChartColorsProvider>
	);
}
