import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { WorkflowLayout } from "@sage/shared/layouts";
import { WorkflowStatus } from "@sage/shared/other";
import {
	BasicDetails,
	Company,
	DataSources,
	DealDetails,
	DeepCrawl,
	GenerateTemplate,
	Results,
	Review,
	ReviewCrawlResult,
	SageTemplate,
	SelectDataSources,
	SelectTemplate,
	Templates,
	UploadDataSources
} from "../Pages";

export function CreateCIMWorkflowRouter() {
	const { outline } = useWorkflowState();
	return (
		<Routes>
			<Route
				element={
					<WorkflowLayout
						status={
							<WorkflowStatus
								title={"Create CIM"}
								sections={outline}
							/>
						}
					>
						<Outlet />
					</WorkflowLayout>
				}
			>
				<Route
					path={"details"}
					element={<DealDetails />}
				/>
				<Route
					path={"basic-details"}
					element={<BasicDetails />}
				/>
				<Route
					path={"company"}
					element={<Company />}
				/>
				<Route
					path={"crawl"}
					element={<DeepCrawl />}
				/>
				<Route
					path={"crawl-result"}
					element={<ReviewCrawlResult />}
				/>
				<Route
					path={"template"}
					element={<Templates />}
				/>
				<Route
					path={"sage-template"}
					element={<SageTemplate />}
				/>
				<Route
					path={"generate-template"}
					element={<GenerateTemplate />}
				/>
				<Route
					path={"select-template"}
					element={<SelectTemplate />}
				/>
				<Route
					path={"data-sources"}
					element={<DataSources />}
				/>
				<Route
					path={"upload-data-sources"}
					element={<UploadDataSources />}
				/>
				<Route
					path={"select-data-sources"}
					element={<SelectDataSources />}
				/>
				<Route
					path={"review"}
					element={<Review />}
				/>
				<Route
					path={"results"}
					element={<Results />}
				/>
				<Route
					path={""}
					element={
						<Navigate
							to={"details"}
							replace
							relative="path"
						/>
					}
				/>
			</Route>
		</Routes>
	);
}
