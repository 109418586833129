import { ReactNode, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PhaseStatus } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, Row } from "@sage/shared/core";
import "./DealTimeline.scss";

export function DealTimeline() {
	const { deal_id, phases, phase_id, loadPhases } = useDealRoom();

	async function updateStatus(_phase_id: string, phaseStatus: PhaseStatus) {
		await DealRoomService.UpdatePhaseStatus(_phase_id, phaseStatus);
		await loadPhases();
	}

	async function deletePhase(_phase_id: string) {
		if (phases.length > 1) {
			await DealRoomService.DeletePhase(_phase_id);
			const phase_index = phases.find((phase) => phase.phase_id === _phase_id).phase_index;
			const phasesToUpdate = phases
				.filter((phase) => phase.phase_index > phase_index)
				.map((phase) => ({ ...phase, phase_index: phase.phase_index - 1 }));
			await Promise.all(phasesToUpdate.map((phase) => DealRoomService.UpdatePhaseIndex(phase.phase_id, phase.phase_index)));
			await loadPhases();
		}
	}

	async function addPhase(phase_index: number) {
		await DealRoomService.SavePhase({
			deal_id,
			phase_name: "New Phase",
			phase_color: "#5700a8",
			phase_icon: "building-user",
			phase_status: PhaseStatus.None,
			phase_index
		});
		const phasesToUpdate = phases
			.filter((phase) => phase.phase_index >= phase_index)
			.map((phase) => ({ ...phase, phase_index: phase.phase_index + 1 }));
		await Promise.all(phasesToUpdate.map((phase) => DealRoomService.UpdatePhaseIndex(phase.phase_id, phase.phase_index)));
		await loadPhases();
	}

	return (
		<div className="__sage-deal-timeline-container">
			{phases.map((phase, idx) => (
				<Row
					key={phase.phase_id}
					gap="0.5rem"
					verticalAlign="center"
					wrap={false}
				>
					<Link to={`../phase/${phase.phase_id}`}>
						<Phase
							active={phase.phase_id === phase_id}
							status={phase.phase_status}
							updateStatus={(phaseStatus: PhaseStatus) => updateStatus(phase.phase_id, phaseStatus)}
							deletePhase={() => deletePhase(phase.phase_id)}
							addPhase={() => addPhase(phase.phase_index + 1)}
						>
							<FaIcon
								icon={phase.phase_icon}
								size="2x"
								color={phase.phase_color}
								direction="row"
								hideBg
							>
								{phase.phase_name}
							</FaIcon>
						</Phase>
					</Link>
					{idx < phases.length - 1 && <PhaseConnection />}
				</Row>
			))}
		</div>
	);
}

function Phase({
	children,
	status,
	active,
	updateStatus,
	deletePhase,
	addPhase
}: {
	children: ReactNode;
	status?: string;
	active: boolean;
	updateStatus: (phaseStatus: PhaseStatus) => void;
	deletePhase: () => void;
	addPhase: () => void;
}) {
	const [editStatusOpen, setEditStatusOpen] = useState<boolean>(false);
	const [hover, setHover] = useState<boolean>(false);
	const [hideIcon, setHideIcon] = useState<boolean>(false);
	const mouseInRef = useRef(false);
	const mouseOutRef = useRef(null);

	const { statusIcon, statusColor } = useMemo(() => {
		if (status === "finished") {
			return { statusIcon: "circle-check", statusColor: "#5da035" };
		} else if (status === "in-progress") {
			return { statusIcon: "circle-ellipsis", statusColor: "#0051a8" };
		} else {
			return { statusIcon: null, statusColor: null };
		}
	}, [status]);

	function handleDeletePhase(e) {
		e.preventDefault();
		e.stopPropagation();
		setHover(false);
		deletePhase();
	}

	function handleAdd(e) {
		e.preventDefault();
		e.stopPropagation();
		setHover(false);
		addPhase();
	}

	function handleUpdateStatus(e, newStatus: PhaseStatus) {
		e.preventDefault();
		e.stopPropagation();
		setEditStatusOpen(false);
		setHover(false);
		setHideIcon(true);
		setTimeout(() => {
			updateStatus(newStatus);
			setHideIcon(false);
		}, 100);
	}

	function toggleEditStatus(e) {
		e.preventDefault();
		e.stopPropagation();
		setEditStatusOpen(true);
	}

	function handleMouseOut() {
		mouseInRef.current = false;
		const ts = new Date().getTime();
		mouseOutRef.current = ts;

		setTimeout(() => {
			if (!mouseInRef.current && mouseOutRef.current === ts) {
				setEditStatusOpen(false);
				setHover(false);
			}
		}, 1000);
	}

	function handleMouseIn() {
		mouseInRef.current = true;
		setHover(true);
	}

	return (
		<div
			className={`__deal-phase-container ${hover ? "hover" : ""} ${active ? "active" : ""}`}
			onMouseOut={handleMouseOut}
			onMouseOver={handleMouseIn}
		>
			{children}
			<div className={`status-indicator ${statusIcon && statusColor && !hideIcon ? "visible" : "hidden"}`}>
				<FaIcon
					icon={statusIcon}
					size="2x"
					color={statusColor}
					hideBg
					onClick={toggleEditStatus}
					padding={"0"}
				/>
			</div>
			<div className={`status-indicator edit ${hover ? "visible" : "hidden"}`}>
				<FaIcon
					icon={"circle-dot"}
					size="2x"
					color={"#1e3050"}
					hideBg
					onClick={toggleEditStatus}
					padding={"0"}
				/>
				<div className={`edit-status-indicator ${editStatusOpen ? "open" : "closed"}`}>
					<Row
						wrap={false}
						gap={"0.25rem"}
					>
						<FaIcon
							icon={"circle-check"}
							size="2x"
							color={"#5da035"}
							hideBg
							padding={"0"}
							onClick={(e) => handleUpdateStatus(e, PhaseStatus.Finished)}
						/>
						<FaIcon
							icon={"circle-ellipsis"}
							size="2x"
							color={"#0051a8"}
							hideBg
							padding={"0"}
							onClick={(e) => handleUpdateStatus(e, PhaseStatus.InProgress)}
						/>
						<FaIcon
							icon={"circle-x"}
							size="2x"
							color={"#1e3050"}
							hideBg
							padding={"0"}
							onClick={(e) => handleUpdateStatus(e, PhaseStatus.None)}
						/>
					</Row>
				</div>
			</div>
			<div className={`status-indicator delete ${hover ? "visible" : "hidden"}`}>
				<FaIcon
					icon={"circle-trash"}
					size="2x"
					color={"#a80300"}
					hideBg
					onClick={handleDeletePhase}
					padding={"0"}
				/>
			</div>
			<div className={`status-indicator add ${hover ? "visible" : "hidden"}`}>
				<FaIcon
					icon={"circle-plus"}
					size="2x"
					color={"#5700a8"}
					hideBg
					onClick={handleAdd}
					padding={"0"}
				/>
			</div>
		</div>
	);
}

function PhaseConnection() {
	return <div className="__deal-phase-connection"></div>;
}
