import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ILlmSource, ProjectService } from "@sage/services";
import { CompanyAutosuggest } from "@sage/shared/company";
import { ButtonBorderShape, ButtonVariant, Modal } from "@sage/shared/core";
import { Form, FormField, FormFieldVariant } from "@sage/shared/forms";
import "./GenerateTemplate.scss";
import { TemplateSection } from "./TemplateSection";

const DefaultRequest = {
	preprompt:
		"You are an advanced Mergers & Acquisitions Associate. An analyst is going to give you a writing prompt for a section of a document. Tt is your job to write a highly professional paragraph for their report in response. The context might include past deals the firm has worked on, files in the firm's filesystem, or trending news stories. Sort through the massive context and extract only the most relevant information to assist the analyst. If you are going to respond with a fact, please use information directly from a source, whether it is from a news story or from a file in the database."
};

const RootNode = ({ children }) => ({
	root: {
		children,
		direction: "ltr",
		format: "",
		indent: 0,
		type: "root",
		version: 0
	}
});

export function GenerateTemplate() {
	const workspaceState = useContext(WorkspaceContext);
	const authState = useContext(AuthContext);
	const [generating, setGenerating] = useState<boolean>(false);
	const [sections, setSections] = useState([]);
	const [finished, setFinished] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [sectionsGenerated, setSectionsGenerated] = useState({});
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();
	const templateParamsForm = useForm();

	function closeModal() {
		setGenerating(false);
		setFinished(false);
		setSaving(false);
		setSections([]);
		setSectionsGenerated({});
		workspaceDispatch({
			type: WorkspaceAction.SetGenerateTemplate,
			payload: null
		});
	}

	function getSources() {
		const activeCompanies = workspaceState.activeCompanies;
		const companies = workspaceState.project.companies;
		const activeSources = workspaceState.activeSources.filter((e) => !!e).map((file) => file.prefix);
		const sources = workspaceState.project.sources.filter((e) => !!e).map((file) => file.prefix);

		let sourcesToUse = [];
		let companiesToUse = [];

		if (activeCompanies.length) {
			companiesToUse = activeCompanies;
		} else if (activeSources.length === 0) {
			companiesToUse = companies;
		}

		if (activeSources.length) {
			sourcesToUse = activeSources;
		} else if (activeCompanies.length === 0) {
			sourcesToUse = sources;
		}

		return { sourceFiles: sourcesToUse, companies: companiesToUse };
	}

	async function save() {
		setSaving(true);
		let _template = [];

		Object.keys(sectionsGenerated)
			.sort((a, b) => parseInt(a) - parseInt(b))
			.forEach((k) => (_template = [..._template, ...sectionsGenerated[k]]));

		const editorState = RootNode({ children: _template });

		const newDocument = await ProjectService.CreateDocument({
			user_id: authState.user.user_id,
			project_id: workspaceState.project.project_id,
			teamCode: authState.user.teamCode,
			documentName: workspaceState.generateTemplate.templateName
		});

		await ProjectService.SaveEditorState(newDocument.document_id, JSON.stringify(editorState));

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: {
				...workspaceState.project,
				documents: [...workspaceState.project.documents, newDocument]
			}
		});

		workspaceDispatch({
			type: WorkspaceAction.SetActiveDocument,
			payload: newDocument.document_id
		});
		setSaving(false);
		templateParamsForm.reset({});
		closeModal();
	}

	function finish(node) {
		setSectionsGenerated((p) => {
			const newState = { ...p, ...node };

			if (Object.keys(newState).length === sections.length) setFinished(true);

			return newState;
		});
	}

	function generateTemplate(e) {
		const thread_id = uuidv4();

		const _template = { ...workspaceState.generateTemplate };

		const params = {};

		_template.params?.forEach((p) => {
			params[p.name] = e[p.name];
		});

		const sources: ILlmSource[] = [];

		const { companies, sourceFiles } = getSources();

		if (sourceFiles.length) {
			sources.push({
				knn: true,
				index: "vault_data_v2",
				pretty: true,
				teamCode: authState.user.teamCode,
				label: "Reference Data:",
				target_fields: ["file_name", "page_content"],
				prefix: sourceFiles
			});
		}

		if (companies.length) {
			companies.forEach((company_id) => {
				sources.push({
					knn: true,
					index: "company_pages",
					company_id,
					label: "Web Pages:",
					target_fields: ["l1_content"],
					k: sources.length ? 5 : 10,
					size: sources.length ? 5 : 10
				});
			});

			sources.push({
				knn: false,
				index: "companies",
				pretty: true,
				label: "Company Data:",
				target_fields: ["deep_crawl_result"],
				tag: "company",
				uids: companies
			});
		}

		_template.params?.forEach((param) => {
			if (param.param_type === "company") {
				sources.push({
					knn: false,
					index: "companies",
					pretty: true,
					label: params[param.name].name,
					target_fields: ["deep_crawl_result"],
					tag: param.name,
					uids: [params[param.name].uid]
				});
				sources.push({
					knn: true,
					index: "company_pages",
					company_id: params[param.name].uid,
					label: "Web Pages:",
					target_fields: ["l1_content"],
					k: 10,
					size: 10
				});
			}
		});

		const templateItems = _template.items.map((item) => {
			if (item.item_type == "prompt") {
				let prompt = item.text;

				Object.keys(params).forEach((p) => {
					prompt = prompt.replaceAll(`{{${p}}}`, params[p].name);
				});

				return {
					templateSection: item,
					request: {
						...DefaultRequest,
						sources,
						prompt,
						thread: thread_id
					}
				};
			}
			return { templateSection: item };
		});

		setSections(templateItems);
		setGenerating(true);
	}

	useEffect(() => {
		if (workspaceState.generateTemplate && !workspaceState.generateTemplate.params?.length) {
			generateTemplate({});
		}
	}, [workspaceState.generateTemplate]);

	if (generating) {
		return (
			<Modal
				visible={generating}
				onDismiss={() => {}}
				actions={[
					{
						children: "Cancel",
						variant: ButtonVariant.Secondary,
						action: closeModal,
						borderShape: ButtonBorderShape.Round,
						testid: "generate-template-cancel"
					},
					{
						children: "Save",
						variant: ButtonVariant.Primary,
						action: save,
						borderShape: ButtonBorderShape.Round,
						disabled: !finished,
						loading: saving,
						testid: "generate-template-save"
					}
				]}
			>
				<div className="template-params-modal">
					{sections.slice(0, Object.keys(sectionsGenerated).length + 1).map((section, idx) => (
						<TemplateSection
							key={section.templateSection.id}
							{...section}
							finish={finish}
							idx={idx}
						/>
					))}
				</div>
			</Modal>
		);
	}
	return (
		<Modal
			title={"Template Parameters"}
			subtitle={"This template requires you to pass in parameters, please complete this form before generating."}
			visible={!!workspaceState.generateTemplate}
			onDismiss={closeModal}
			actions={[
				{
					children: "Cancel",
					variant: ButtonVariant.Secondary,
					action: closeModal,
					borderShape: ButtonBorderShape.Round
				},
				{
					children: "Generate",
					variant: ButtonVariant.Primary,
					action: templateParamsForm.handleSubmit(generateTemplate),
					borderShape: ButtonBorderShape.Round
				}
			]}
		>
			<div className={"template-params-modal"}>
				{workspaceState.generateTemplate?.params && (
					<Form
						fields={workspaceState.generateTemplate?.params?.map((param) =>
							param.param_type === "text" ? (
								<FormField
									control={templateParamsForm.control}
									name={param.name}
									label={param.name}
									variant={FormFieldVariant.Vertical}
								/>
							) : (
								<CompanyAutosuggest
									control={templateParamsForm.control}
									name={`${param.name}__name`}
									company_field_name={param.name}
									setValue={templateParamsForm.setValue}
									label={param.name}
								/>
							)
						)}
					/>
				)}
			</div>
		</Modal>
	);
}
