import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function CreateTeam() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();

	const { control, handleSubmit, setError } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		const res = await UserService.CreateTeam(e);
		if (res === false) {
			setError("teamCode", {
				type: "custom",
				message: "This team code is already being used."
			});
		} else {
			await UserService.JoinTeam({
				teamCode: e.teamCode,
				user_id: welcomeState.welcomeForm.user_id
			});

			welcomeDispatch({
				type: WelcomeAction.UpdateWelcomeForm,
				payload: e
			});

			navigate("/app/fs");
		}
	}

	return (
		<Form
			title={"Create a Team"}
			headerAction={{
				variant: ButtonVariant.Tertiary,
				children: "Join an existing team instead",
				to: "/app/welcome/create-account/join-team",
				testid: "navigate-to-join-team"
			}}
			fields={[
				{
					label: "Team Name",
					placeholder: "e.g. Sage AI",
					control,
					name: "teamName",
					description:
						"Create a name for your team. This could be a company name or if you are signing up for your department or division, that works too.",
					required: true
				},
				{
					label: "Team Code",
					placeholder: "e.g. SAGE-AI",
					control,
					name: "teamCode",
					description: "Enter your unique team code so other's can join your team.",
					required: true
				}
				// {
				// 	label: "Email Domain",
				// 	placeholder: "e.g. sageai.dev",
				// 	control,
				// 	name: "emailDomain",
				// 	description: "(optional) Restrict sign ups to users who register with this email domain."
				// }
			]}
			actions={[
				{
					variant: ButtonVariant.Primary,
					children: "Next",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-to-next"
				}
			]}
		/>
	);
}
