import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, useWorkflowState } from "@sage/state";
import { Button, ButtonBorderShape, Col } from "@sage/shared/core";
import { DragUpload } from "@sage/shared/forms";
import "./UploadDataSources.scss";

export function UploadDataSources() {
	const authState = useContext(AuthContext);
	const { workflow_id } = useWorkflowState();
	const { updatePageStatus, addActiveFile } = useWorkflowState();
	const navigate = useNavigate();

	function handleNav() {
		navigate("../review");
	}

	async function handleUpload(file_id: string) {
		await addActiveFile(file_id);
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Data Sources", "Upload Files");
		return () => {
			updatePageStatus("complete", "Data Sources", "Upload Files");
		};
	}, []);

	return (
		<section className="__sage-upload-data-sources-container">
			<h2>Upload Files</h2>
			<Col>
				<DragUpload
					destination={`/${authState.user.teamCode}/${authState.user.teamCode}_workflows/${workflow_id}`}
					parent_folder_id={workflow_id}
					onUpload={handleUpload}
				/>
				<Button
					borderShape={ButtonBorderShape.Round}
					action={handleNav}
				>
					Next
				</Button>
			</Col>
		</section>
	);
}
