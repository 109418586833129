export interface IInfoRequest {
	request_id: string;
	deal_id: string;
	phase_id: string;
	teamCode: string;
	request_name: string;
	lastModifiedTimestamp: number;
	createdTimestamp: number;
}

export interface IInfoRequestQuestion {
	question_id: string;
	request_id: string;
	teamCode: string;
	question: string;
	answer?: string;
	answer_source?: string;
	status: QuestionStatus;
	question_index: number;
	lastModifiedTimestamp: number;
	createdTimestamp: number;
}

export enum QuestionStatus {
	Answered = "answered",
	NotAnswered = "not-answered",
	UnableToAnswer = "unable-to-answer"
}
