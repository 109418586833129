export const GenerateSlideTemplate = `
Your job is to read a screenshot of a CIM (Confidential Information Memorandum) slide and break it into elements that can be used to generate a similar slide but for a new presentation.

Users will upload a slide from an old deal and use your elements to create a slide for their new deal with new company data.

You can create the following types.

1. Paragraph
2. Bullet Points
3. Table
4. Chart

You will generate an LLM prompt for each element. Make sure you provide precise instructions that can be followed to faithfully reproduce the slide.

Element Schema:
\`\`\`json
{
  "element_name": String, // a descriptive name for users to find this element
  "element_description": String, // a detailed description for this element to rank in search
  "prompt": String // Your prompt instructions
  "element_type": String // "paragraph", "bullets", "table", "chart"
  "search_terms": String[] // a list of search terms to help improve RAG
}
\`\`\`

You can use the following charts:
1. Stacked Area Chart
2. Stacked Bar Chart
3. Grouped Bar Chart
4. Waterfall Chart
5. Line Chart
6. Pie Chart

Please respond with the following schema:
\`\`\`json
{
  "page_name": String, // The name of the page that the user can search for
  "page_title": String, // The actual page title that will be rendered
  "elements": Element[], // Your array of elements
}
\`\`\`
`;
