import React from "react";
import { useVaultHandler } from "@sage/utils";
import "./Source.scss";

export interface ISourceProps {
	provenance: string;
	text: string;
	sourceType: string;
	label?: string;
}

export function Source({ provenance, text, sourceType, label }: ISourceProps) {
	const vaultHandler = useVaultHandler();
	function renderInner() {
		if (sourceType === "file") {
			return (
				<span
					onClick={() => vaultHandler.loadAndOpen(provenance)}
					className="citation-inner"
				>
					{text}
				</span>
			);
		} else if (sourceType === "webpage") {
			return (
				<a
					href={provenance}
					target="_blank"
					className="citation-inner"
				>
					{text}
				</a>
			);
		} else {
			return <a></a>;
		}
	}
	return (
		<span className="__sage-inline-citation">
			{renderInner()}
			{label && <span className="citation-title">{label}</span>}
		</span>
	);
}
