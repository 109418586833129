import React from "react";
import "./SageLogo.scss";

export interface ISageLogoProps {
	onClick?: () => void;
	color?: "white" | "blue" | "full";
}

export function SageLogo({ onClick, color }: ISageLogoProps) {
	return (
		<div
			className="__sage-sage-logo-container"
			onClick={onClick}
		>
			<img
				alt={"logo"}
				className={`${onClick ? "clickable" : ""}`}
				src={
					color === "white"
						? "https://cdn.ccm.vc/sage/logo-white.svg"
						: color === "full"
							? "https://cdn.ccm.vc/sage/logo-lg.svg"
							: "https://cdn.ccm.vc/sage/logo-cropped.svg"
				}
			/>
		</div>
	);
}
