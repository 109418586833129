import { createContext } from "react";
import { IDeal } from "@sage/types";
import { DealsAction, DealsActions } from "./DealsActions";
import { GetDealsByStatus } from "./DealsEffects";

export interface IDealsState {
	deals: IDeal[];
}

export const InitialDealsState: IDealsState = {
	deals: []
};

export const DealsReducer = (state: IDealsState = InitialDealsState, action: DealsActions): IDealsState => {
	switch (action.type) {
		case DealsAction.GetDealsByStatus:
			GetDealsByStatus(action.payload).then((deals) =>
				action.dispatch({
					type: DealsAction.SetDeals,
					payload: deals
				})
			);
			return state;
		case DealsAction.SetDeals:
			return {
				...state,
				deals: action.payload
			};
	}
};

export const DealsContext = createContext(InitialDealsState);
