import { ITeam, IUser } from "@sage/types";
import { AuthService, UserService } from "@sage/services";

export class AuthEffects {
	static async Authenticate(): Promise<{ user: IUser; team: ITeam }> {
		const res = await AuthService.Authenticate();
		if (res) {
			try {
				return await UserService.GetUserById();
			} catch (_) {
				return null;
			}
		}

		return null;
	}
}
