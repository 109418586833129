import { useContext, useEffect } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { usePostHog } from "posthog-js/react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "@sage/state";
import { AnnouncementLayout } from "@sage/shared/layouts";

export function AnalyticsGuard() {
	const fp = useVisitorData({ timeout: 300000, extendedResult: true });
	const posthog = usePostHog();

	const authState = useContext(AuthContext);

	useEffect(() => {
		if (!fp.isLoading && fp.data) {
			posthog.identify(fp.data.visitorId, { fp: fp.data });
		}
	}, [fp.data, fp.isLoading, fp.error, authState?.user?.email]);

	useEffect(() => {
		if (authState?.user) {
			posthog.capture("capture_user", { $set: { user: authState.user, team: authState.team, email: authState.user?.email } });
		}
	}, [authState?.user]);

	return (
		<AnnouncementLayout>
			<Outlet />
		</AnnouncementLayout>
	);
}
