import React, { useContext, useEffect, useRef, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { AuthContext, useWorkflowState } from "@sage/state";
import { LlmService, ProjectService } from "@sage/services";
import { Message } from "@sage/shared/chat";
import { Button, ButtonBorderShape, ButtonVariant, Col, H1, H2, H3, P, Row } from "@sage/shared/core";
import { StatusIcon } from "@sage/shared/other";
import { usePrompts } from "@sage/prompts";
import "./Results.scss";

export function Results() {
	const authState = useContext(AuthContext);
	const { form, updatePageStatus } = useWorkflowState();
	const formValue = useWatch({ control: form.control });
	const [inProgressMessage, setInProgressMessage] = useState(null);
	const inProgressRef = useRef(null);
	const sections = useFieldArray({ control: form.control, name: "sections" });
	const preprompt = usePrompts();

	function clear() {
		sections.remove();
	}

	function getSources() {
		const fileSources = formValue.activeFiles.map((file) => file.prefix);
		const sources = [];

		if (formValue.activeFiles?.length) {
			sources.push({
				knn: true,
				index: "vault_data_v2",
				teamCode: authState.user.teamCode,
				prefix: fileSources,
				label: "Documents: ",
				target_fields: ["file_name", "file_id", "page_content"],
				k: 10,
				size: 10
			});
		}

		if (formValue.company_id) {
			sources.push({
				knn: true,
				index: "company_pages",
				company_id: formValue.company_id,
				label: "Web Pages:",
				target_fields: ["l1_content", "url"],
				k: 10,
				size: 10
			});
			sources.push({
				knn: false,
				index: "companies",
				pretty: true,
				teamCode: authState.user.teamCode,
				uids: [formValue.company_id],
				label: "Company Data:",
				target_fields: ["name", "deep_crawl_result"]
			});
		}

		return sources;
	}

	async function startTemplate() {
		if ((formValue.company_id || formValue.activeFiles?.length) && formValue.template_id) {
			if (!inProgressRef.current) {
				inProgressRef.current = true;

				const template = await ProjectService.GetTemplate(formValue.template_id);

				for (const item of template.items) {
					if (!sections.fields.find((section) => section.section_id === item.id)) {
						if (item.item_type !== "prompt") {
							sections.append({ ...item, section_id: item.id });
						} else if (item.item_type === "prompt") {
							const sources = getSources();
							const request = {
								prompt: item.text,
								preprompt: preprompt.userDefault(),
								sources
							};
							const res = await LlmService.Stream(request, setInProgressMessage);
							setInProgressMessage(null);
							sections.append({ ...item, section_id: item.id, text: res.generated_text });
						}
					}
				}
				inProgressRef.current = false;
			}
		}
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Results");
	}, []);

	useEffect(() => {
		if (sections.fields?.length) {
			updatePageStatus("complete", "Results");
		}
	}, [sections.fields?.length]);

	return (
		<section className="__sage-results-container">
			<h2>Template Results</h2>
			<Col>
				<Row verticalAlign="center">
					<P>Company: </P>
					<StatusIcon status={formValue.company_id ? "complete" : "errors"} />
					<P>Template: </P>
					<StatusIcon status={formValue.template_id ? "complete" : "errors"} />
					<P>Data Sources: </P>
					<StatusIcon status={formValue.activeFiles?.length ? "complete" : "errors"} />
				</Row>
				<Row>
					<Button
						borderShape={ButtonBorderShape.Round}
						variant={ButtonVariant.Error}
						action={clear}
					>
						Clear Output
					</Button>
					<Button
						borderShape={ButtonBorderShape.Round}
						action={startTemplate}
						disabled={!(formValue.company_id || formValue.activeFiles?.length) || !formValue.template_id}
					>
						Run Template
					</Button>
				</Row>
				{sections.fields.map((section) => (
					<TemplateSection
						key={section.id}
						item_type={section.item_type}
					>
						{section.text}
					</TemplateSection>
				))}
				{inProgressMessage && (
					<Message
						message={{
							idx: -1,
							timestamp: new Date().getTime(),
							message_id: "current",
							text: inProgressMessage,
							sources: []
						}}
					/>
				)}
			</Col>
		</section>
	);
}

function TemplateSection({ children, item_type }) {
	switch (item_type) {
		case "h1":
			return <H1>{children}</H1>;
		case "h2":
			return <H2>{children}</H2>;
		case "h3":
			return <H2>{children}</H2>;
		case "p":
			return <P>{children}</P>;
		case "prompt":
			return (
				<Message
					message={{
						idx: -1,
						timestamp: new Date().getTime(),
						message_id: "current",
						text: children,
						sources: []
					}}
				/>
			);
	}
}
