import React, { ReactElement, ReactNode } from "react";
import { Button, IButtonProps } from "../Button";
import "./Modal.scss";

interface IModalProps {
	title?: string | ReactNode;
	subtitle?: string | ReactNode;
	visible: boolean;
	onDismiss: () => void;
	children?: any;
	actions?: (IButtonProps | ReactElement)[];
	full?: boolean;
	minWidth?: string;
}

export function Modal({ visible, onDismiss, children, title, subtitle, actions, full, minWidth }: IModalProps) {
	if (visible) {
		return (
			<div className={`__modal-container ${full ? "full" : ""}`}>
				<div
					className={"modal-body"}
					style={{ minWidth }}
				>
					{title ? <div className={"modal-title"}>{title}</div> : null}
					{subtitle ? <div className={"modal-subtitle"}>{subtitle}</div> : null}
					<div className={"modal-content"}>{children}</div>
					{actions ? (
						<div className={"modal-actions"}>
							{actions.map((action, idx) => {
								if (React.isValidElement(action)) {
									return action;
								} else {
									return (
										<Button
											key={idx}
											{...action}
										/>
									);
								}
							})}
						</div>
					) : null}
				</div>
				<div
					className={"modal-bg"}
					onClick={onDismiss}
				></div>
			</div>
		);
	} else {
		return null;
	}
}
