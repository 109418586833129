import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { AuthAction, AuthContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant, Modal, TeamDetails } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import "./Settings.scss";

export function SettingsPage() {
	const authState = useContext(AuthContext);
	const { authDispatch } = useOutletContext<{ authDispatch }>();
	const [loading, setLoading] = useState<boolean>(false);
	const [logOutModalVisible, setLogOutModalVisible] = useState<boolean>(false);

	const { control, handleSubmit, reset } = useForm();

	function logout() {
		localStorage.clear();
		window.location.replace(`/app`);
	}

	async function saveSettings(e) {
		setLoading(true);
		await UserService.UpdateUser(e);
		authDispatch({
			type: AuthAction.UpdateUserSettings,
			payload: e
		});
		setLoading(false);
	}

	useEffect(() => {
		reset({ ...authState.team, ...authState.user });
	}, [authState.user]);

	return (
		<div className={"__settings-page-container"}>
			<TeamDetails />
			<div className={"form-section"}>
				<Form
					title={"Account Information"}
					fields={[
						{
							variant: FormFieldVariant.Vertical,
							label: "First Name",
							placeholder: "e.g. Matt",
							control,
							name: "firstName",
							required: true
						},
						{
							variant: FormFieldVariant.Vertical,
							label: "Last Name",
							placeholder: "e.g. Carlson",
							control,
							name: "lastName",
							required: true
						},
						{
							variant: FormFieldVariant.Vertical,
							label: "Bio",
							description: "Attach some information to your profile so we can tailor trending insights to your preferences!",
							control,
							name: "bio",
							required: false,
							type: "textarea"
						},
						{
							variant: FormFieldVariant.Vertical,
							label: "Team Name",
							placeholder: "e.g. sage ai",
							control,
							name: "teamName",
							disabled: true
						},
						{
							variant: FormFieldVariant.Vertical,
							label: "Team Code",
							placeholder: "e.g. SAGE-AI",
							control,
							name: "teamCode",
							disabled: true
						},
						{
							variant: FormFieldVariant.Vertical,
							label: "Email",
							placeholder: "e.g. matt@sageai.dev",
							control,
							name: "email",
							disabled: true
						}
					]}
					actions={[
						{
							variant: ButtonVariant.Error,
							children: "Log Out",
							action: () => setLogOutModalVisible(true),
							borderShape: ButtonBorderShape.Round,
							testid: "log-out"
						},
						{
							variant: ButtonVariant.Primary,
							children: "Save",
							action: handleSubmit(saveSettings),
							loading,
							borderShape: ButtonBorderShape.Round,
							testid: "save"
						}
					]}
				/>
			</div>
			<Modal
				visible={logOutModalVisible}
				onDismiss={() => setLogOutModalVisible(false)}
				title={"Confirm Log Out"}
				subtitle={"Are you sure you want to log out?"}
				actions={[
					{
						variant: ButtonVariant.Secondary,
						children: "Cancel",
						action: () => setLogOutModalVisible(false),
						borderShape: ButtonBorderShape.Round,
						testid: "cancel-log-out"
					},
					{
						variant: ButtonVariant.Error,
						children: "Confirm",
						action: logout,
						borderShape: ButtonBorderShape.Round,
						testid: "confirm-log-out"
					}
				]}
			></Modal>
		</div>
	);
}
