import { ReactNode, useEffect, useRef, useState } from "react";
import { Button, ButtonVariant } from "../Button";
import { Col } from "../Col";
import { Modal } from "../Modal";
import { Row } from "../Row";
import "./Instructions.scss";

export function Instructions({
	visible,
	onDismiss,
	children,
	videos
}: {
	visible: boolean;
	onDismiss: () => void;
	children: ReactNode;
	videos: string[];
}) {
	const [activePage, setActivePage] = useState<number>(0);

	return (
		<Modal
			visible={visible}
			onDismiss={onDismiss}
			minWidth={"40rem"}
		>
			<Col gap="2rem">
				<div className="__sage-instructions-video-wrapper">
					{videos.map((src, idx) => (
						<Video
							key={`instructions-videos-${idx}`}
							src={src}
							playing={idx === activePage}
						/>
					))}
				</div>
				{children?.length > 1 ? children[activePage] : children}
				<Row
					horizontalAlign="center"
					verticalAlign="center"
					gap={"2rem"}
				>
					{children?.length > 1 && activePage > 0 && (
						<Button
							variant={ButtonVariant.Secondary}
							action={() => setActivePage((n) => n - 1)}
						>
							Previous
						</Button>
					)}
					{children?.length > 1 && activePage < children.length - 1 && (
						<Button action={() => setActivePage((n) => n + 1)}>Continue</Button>
					)}
					{children.length - 1 === activePage && <Button action={onDismiss}>Get Started</Button>}
				</Row>
				<Row horizontalAlign="center">
					<ProgressIndicator
						dots={children?.length || 1}
						active={activePage}
					/>
				</Row>
			</Col>
		</Modal>
	);
}

function ProgressIndicator({ dots, active }: { dots: number; active: number }) {
	return (
		<div className="__sage-progress-indicator">
			{new Array(dots).fill(0).map((_, idx) => (
				<div
					key={`${idx}`}
					className={`dot ${idx === active ? "active" : ""}`}
				/>
			))}
		</div>
	);
}

function Video({ src, playing }: { src: string; playing: boolean }) {
	const videoRef = useRef(null);

	useEffect(() => {
		if (playing) {
			videoRef.current.currentTime = 0;
			videoRef.current.play();
		} else {
			videoRef.current.currentTime = 0;
			videoRef.current.pause();
		}
	}, [playing]);
	return (
		<video
			muted
			controls={false}
			className={`__sage-instructions-video ${playing ? "active" : ""}`}
			src={src}
			preload="auto"
			ref={videoRef}
		></video>
	);
}
