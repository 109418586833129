import React, { useContext, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { IProject } from "@sage/types";
import { AuthContext, FsAction } from "@sage/state";
import { FsService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant, Modal, PageHeader, Table, TableColumn, TableColumnSpacing } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { DocsProject } from "../DataItems";
import "./Projects.scss";

export interface IProjectsProps {
	projects: IProject[];
	loading: boolean;
	title?: string;
	emptyText?: string;
	showMenu?: boolean;
}

export function Projects({ projects, loading, emptyText, title, showMenu }: IProjectsProps) {
	const authState = useContext(AuthContext);
	const { fsDispatch } = useOutletContext<{ fsDispatch }>();

	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

	const { control, reset, setValue } = useForm();
	const formValue = useWatch({ control });

	function refresh() {
		fsDispatch({
			type: FsAction.LoadSharedProjects,
			payload: {
				user_id: authState.user.user_id,
				teamCode: authState.user.teamCode
			},
			dispatch: fsDispatch
		});

		fsDispatch({
			type: FsAction.LoadRecentProjects,
			payload: {
				user_id: authState.user.user_id
			},
			dispatch: fsDispatch
		});

		fsDispatch({
			type: FsAction.LoadFavoritedProjects,
			payload: {
				user_id: authState.user.user_id
			},
			dispatch: fsDispatch
		});
	}

	async function createNewProject() {
		await FsService.CreateNewProject({
			user_id: authState.user.user_id,
			teamCode: authState.user.teamCode
		});
		refresh();
	}

	async function deleteProject() {
		setDeleteModalVisible(false);
		await FsService.DeleteProjects({
			project_ids: Object.keys(formValue)
				.filter((key) => formValue[key])
				.map((key) => key)
		});
		reset({});
		refresh();
	}

	function openDeleteModal() {
		if (Object.keys(formValue).filter((key) => formValue[key]).length) setDeleteModalVisible(true);
	}

	function handleToggleAll(e: boolean) {
		projects.forEach((project) => {
			setValue(project.project_id, e);
		});
	}

	return (
		<div className={"__projects-container"}>
			<PageHeader
				title={title}
				actions={
					showMenu === false
						? null
						: [
								{
									variant: ButtonVariant.Secondary,
									children: "Delete",
									action: openDeleteModal,
									borderShape: ButtonBorderShape.Round,
									testid: "delete-doc"
								},
								{
									variant: ButtonVariant.Primary,
									children: "New Doc",
									action: createNewProject,
									borderShape: ButtonBorderShape.Round,
									testid: "new-doc"
								}
							]
				}
			/>
			<Table
				items={projects}
				emptyText={emptyText}
				headers={
					<>
						{showMenu !== false && (
							<TableColumn
								header
								width={"0"}
							>
								<Checkbox
									name={"select_all"}
									control={control}
									onChange={(e: boolean) => handleToggleAll(e)}
								/>
							</TableColumn>
						)}
						<TableColumn header>Doc Name</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							User
						</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							Last Modified
						</TableColumn>
					</>
				}
				row={(project: IProject) => (
					<DocsProject
						key={project.project_id}
						doc={project}
						control={control}
						showMenu={showMenu}
					/>
				)}
			/>
			<Modal
				visible={deleteModalVisible}
				onDismiss={() => setDeleteModalVisible(false)}
				title={"Confirm Delete"}
				subtitle={"Are you sure you want to delete these docs? This action can not be undone."}
				actions={[
					{
						variant: ButtonVariant.Secondary,
						children: "Cancel",
						action: () => setDeleteModalVisible(false),
						borderShape: ButtonBorderShape.Round,
						testid: "cancel-delete-doc"
					},
					{
						variant: ButtonVariant.Error,
						children: "Confirm",
						action: () => deleteProject(),
						borderShape: ButtonBorderShape.Round,
						testid: "confirm-delete-doc"
					}
				]}
			>
				<ul>
					{Object.keys(formValue)
						.map((key) => projects.find((p) => p.project_id === key))
						.filter((project) => formValue[project?.project_id])
						.map((project) => (
							<DocsProject
								key={project.project_id}
								doc={project}
								showMenu={showMenu}
							/>
						))}
				</ul>
			</Modal>
		</div>
	);
}
