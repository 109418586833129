import { Dispatch, SetStateAction } from "react";

export interface IMouseHandlerProps {
	onMouseDown?: (...args: any[]) => void;
	onMouseMove: (e?: Event) => void;
	onMouseUp?: (e?: Event) => void;
	activeCursor?: string;
	activeToggle?: Dispatch<SetStateAction<boolean>>;
}

export function MouseHandler(props: IMouseHandlerProps) {
	function onMouseDown(...args: any[]) {
		if (props.activeToggle) {
			props.activeToggle(true);
		}

		document.body.style.userSelect = "none";

		if (props.activeCursor) {
			document.body.style.cursor = props.activeCursor;
		}

		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);

		if (props.onMouseDown) props.onMouseDown(...args);
	}

	function onMouseMove(e?: Event) {
		props.onMouseMove(e);
	}

	function onMouseUp(e?: Event) {
		if (props.activeToggle) {
			props.activeToggle(false);
		}

		document.body.style.userSelect = "";
		document.removeEventListener("mousemove", onMouseMove);
		document.removeEventListener("mouseup", onMouseUp);

		if (props.activeCursor) {
			document.body.style.cursor = "";
		}

		if (props.onMouseUp) props.onMouseUp(e);
	}

	return onMouseDown;
}

export const useMouseHandler = MouseHandler;
