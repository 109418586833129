import { ReactNode } from "react";
import "./Card.scss";

export function Card({ children, inline }: { children: ReactNode; inline?: boolean }) {
	return <div className={`__sage-card-container ${inline ? "inline" : ""}`}>{children}</div>;
}

export function CardTitle({ children }: { children: ReactNode }) {
	return <div className="__sage-card-title">{children}</div>;
}

export function CardList({ children }: { children: ReactNode }) {
	return <div className="__sage-card-list">{children}</div>;
}

export function CardListItem({ children }: { children: ReactNode }) {
	return <div className="__sage-card-list-item">{children}</div>;
}

export function CardListItemText({ children }: { children: ReactNode }) {
	return <div className="__sage-card-list-item-text">{children}</div>;
}

export function CardListItemTitle({ children }: { children: ReactNode }) {
	return <div className="__sage-card-list-item-title">{children}</div>;
}
