import React from "react";
import { Card, CardList, CardListItem, CardListItemText, CardListItemTitle, CardTitle } from "@sage/shared/core";

export function Events({ events }) {
	return events.length ? (
		<Card>
			<CardTitle>Events</CardTitle>
			<CardList>
				{events
					.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime())
					.map((event) => (
						<CardListItem key={event.name}>
							<CardListItemTitle>{event.name}</CardListItemTitle>
							<CardListItemText>
								{event.start_date}
								{event.start_date !== event.end_date ? " - " + event.end_date : ""}
							</CardListItemText>
							<CardListItemText>{event.details}</CardListItemText>
						</CardListItem>
					))}
			</CardList>
		</Card>
	) : null;
}
