import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function VerifyEmailForgotPasswordPage() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();

	const { control, handleSubmit, setError } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		const res = await UserService.VerifyResetPassword({
			user_id: welcomeState.welcomeForm.user_id,
			verificationCode: e.verificationCode
		});
		welcomeDispatch({
			type: WelcomeAction.UpdateWelcomeForm,
			payload: {
				...e
			}
		});
		if (res.response === true) {
			navigate("/app/welcome/forgot-password/reset-password");
		} else {
			setError("verificationCode", {
				type: "custom",
				message: "Invalid verification code"
			});
		}
	}

	return (
		<Form
			title={"Verify Email Address"}
			fields={[
				{
					label: "Verification Code",
					placeholder: "e.g. 11-22-33",
					control,
					name: "verificationCode",
					description: "Please check your email for a verification code, it may take up to 5 minutes.",
					required: true
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Secondary,
					children: "Back",
					action: () => {
						navigate("/app/welcome/forgot-password");
					},
					borderShape: ButtonBorderShape.Round
				},
				{
					variant: ButtonVariant.Primary,
					children: "Next",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-next"
				}
			]}
		/>
	);
}
