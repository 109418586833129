import React, { useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { AuthAction } from "@sage/state";

export function DecksGuard() {
	const { authDispatch, fsDispatch } = useOutletContext<{
		authDispatch;
		fsDispatch;
	}>();

	useEffect(() => {
		if (authDispatch)
			authDispatch({
				type: AuthAction.UpdateSearchSettings,
				payload: {
					settings: {
						use_deals: false,
						use_llm: true,
						use_news: false,
						use_sage_news: false,
						use_data: true,
						use_files: true
					}
				}
			});
	}, []);

	return <Outlet context={{ authDispatch, fsDispatch }} />;
}
