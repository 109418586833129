import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Col, FaIcon, HidePrint, Row, SageLogo } from "@sage/shared/core";
import { useDebounce, usePageMeta } from "@sage/utils";
import { UploadFilesModal } from "../../DealRoomHome/UploadFilesModal";
import "./Header.scss";

export function Header({ request_name, showInfoRequests, showUploadInfoRequests }) {
	const params = useParams();
	const { setUploadModalVisible } = useDealRoom();
	const [requestName, setRequestName] = useState<string>(request_name);
	usePageMeta({ title: requestName });

	async function saveRequestName(name: string) {
		await DealRoomService.UpdateInfoRequestName(params.info_request_id, name);
	}

	const saveRequestNameDebounced = useDebounce(saveRequestName, 1000);

	function updateRequestName(name: string) {
		setRequestName(name);
		saveRequestNameDebounced(name);
	}

	return (
		<HidePrint>
			<div className="__sage-deal-room-info-request-header">
				<Col
					height="100%"
					gap="0"
					verticalAlign="bottom"
					flex={"1"}
				>
					<Row
						horizontalAlign="between"
						verticalAlign="center"
					>
						<Row verticalAlign="center">
							<Link to={"../../"}>
								<SageLogo color="blue" />
							</Link>
							<input
								className="project-name"
								value={requestName}
								onChange={(e) => updateRequestName(e.target.value)}
								style={{ width: `${Math.max(requestName?.length * 1.3, 12)}ch` }}
							/>
						</Row>
						<Row
							horizontalAlign="right"
							verticalAlign="center"
						>
							<FaIcon
								icon={"memo"}
								color={"#00435c"}
								size={"lg"}
								onClick={() => showUploadInfoRequests(true)}
							>
								Upload Questions
							</FaIcon>
							<FaIcon
								icon={"list"}
								color={"#00435c"}
								size={"lg"}
								onClick={() => showInfoRequests(true)}
							>
								Past Requests
							</FaIcon>
							<FaIcon
								icon={"cloud-arrow-up"}
								color={"#00435c"}
								size={"lg"}
								onClick={() => setUploadModalVisible(true)}
							>
								Upload Data
							</FaIcon>
							<FaIcon
								icon={"print"}
								color={"#00435c"}
								size={"lg"}
								onClick={() => window.print()}
							>
								Export to PDF
							</FaIcon>
						</Row>
					</Row>
				</Col>
				<UploadFilesModal />
			</div>
		</HidePrint>
	);
}
