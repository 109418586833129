import React, { ReactNode, useMemo } from "react";
import { Row } from "../Row";
import { DateHeader } from "../Text";
import "./Col.scss";

export interface IColProps {
	children: ReactNode;
	gap?: string;
	scroll?: boolean;
	verticalAlign?: string;
	horizontalAlign?: string;
	flex?: string;
	height?: string;
	padding?: string;
	width?: string;
}

export function Col({ children, gap, padding, verticalAlign, horizontalAlign, flex, height, scroll, width }: IColProps) {
	return (
		<div
			className={`__sage-col-container vertical-${verticalAlign} horizontal-${horizontalAlign}`}
			style={{ gap, flex, height, overflowY: scroll ? "scroll" : "", padding, width }}
		>
			{children}
		</div>
	);
}

export interface ItemsColProps<T> extends Partial<IColProps> {
	items: T[];
	dateCol: string;
	useDateDelim?: boolean;
	render: (item: T) => ReactNode;
}

export function ItemsCol<T>({ items, dateCol, render, useDateDelim, ...colProps }: ItemsColProps<T>) {
	const elements = useMemo(() => {
		if (useDateDelim !== false) {
			const el = [];
			let currentDay = null;

			for (let item of items.sort((b, a) => a[dateCol] - b[dateCol])) {
				const day = new Date(item[dateCol]).toLocaleDateString("en-US");

				if (day !== currentDay) {
					currentDay = day;
					el.push(<DateHeader key={day}>{item[dateCol]}</DateHeader>);
				}

				el.push(render(item));
			}
			return el;
		}
		return items.map(render);
	}, [items]);
	return (
		<div>
			<Col {...colProps}>{elements}</Col>
		</div>
	);
}

export function ItemsRow<T>({ items, dateCol, render, useDateDelim, ...colProps }: ItemsColProps<T>) {
	const elements = useMemo(() => {
		if (useDateDelim !== false) {
			const el = [];
			let currentDay = null;
			let row = [];

			for (let item of items.sort((b, a) => a[dateCol] - b[dateCol])) {
				const day = new Date(item[dateCol]).toLocaleDateString("en-US");

				if (day !== currentDay) {
					currentDay = day;
					if (row.length) {
						el.push(<Row gap="2rem">{row}</Row>);
						row = [];
					}

					el.push(<DateHeader key={day}>{item[dateCol]}</DateHeader>);
				}

				row.push(render(item));
			}

			if (row.length) {
				el.push(<Row gap="2rem">{row}</Row>);
				row = [];
			}

			return el;
		}
		return items.map(render);
	}, [items]);
	return (
		<div>
			<Col {...colProps}>{elements}</Col>
		</div>
	);
}
