import { useForm } from "react-hook-form";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { ButtonVariant, Col, Modal, P, Pad } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";

export function AddWebData() {
	const { deal_id, addWebDataModalVisible, setAddWebDataModalVisible } = useDealRoom();
	const form = useForm();

	function dismiss() {
		setAddWebDataModalVisible(false);
		form.reset({});
	}

	async function startCrawl(e) {
		if (assertValidUrl(e.base_url)) {
			const crawl = await DealRoomService.SaveCrawl({
				starting_url: e.base_url,
				deal_id,
				host: new URL(e.base_url).hostname
			});
			await DealRoomService.StartCrawl(e.base_url, crawl.crawl_id);
			dismiss();
		} else {
			form.setError("base_url", { message: "This is not a valid URL" });
		}
	}

	function assertValidUrl(url: string): boolean {
		try {
			new URL(url);
			return true;
		} catch (e) {
			return false;
		}
	}

	return (
		<Modal
			visible={addWebDataModalVisible}
			onDismiss={dismiss}
			title="Add Web Data"
			minWidth="40rem"
			actions={[
				{ children: "Cancel", action: dismiss, variant: ButtonVariant.Secondary },
				{ children: "Crawl", action: form.handleSubmit(startCrawl), variant: ButtonVariant.Primary }
			]}
		>
			<Pad>
				<Col>
					<P>Add websites to use as source data to your deal.</P>
					<Form
						handleSubmit={form.handleSubmit(startCrawl)}
						fields={[
							{
								control: form.control,
								label: "Starting Url",
								description: "This is the url that the web crawler will start at.",
								name: "base_url",
								placeholder: "https://sageai.dev",
								variant: FormFieldVariant.Vertical,
								required: true
							}
						]}
					/>
				</Col>
			</Pad>
		</Modal>
	);
}
