export interface IStagesProps<T> {
	local?: T;
	beta?: T;
	gamma?: T;
	prod?: T;
}

export function useStages<T>({ local, beta, gamma, prod }: IStagesProps<T>): T {
	if (window.location.href.includes("localhost")) {
		return local || beta || gamma || prod;
	} else if (window.location.href.includes("beta")) {
		return beta || gamma || prod;
	} else if (window.location.href.includes("gamma")) {
		return gamma || prod;
	} else {
		return prod;
	}
}
