import { IInfoRequest, IInfoRequestQuestion, QuestionStatus } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetInfoRequestsByDeal(deal_id: string): Promise<IInfoRequest[]> {
	return HttpService.Get<IInfoRequest[]>({ path: `/dealrooms/info-requests/deal/${deal_id}` }).then((res) => res.payload);
}

export function GetInfoRequestsByPhase(phase_id: string): Promise<IInfoRequest[]> {
	return HttpService.Get<IInfoRequest[]>({ path: `/dealrooms/info-requests/phase/${phase_id}` }).then((res) => res.payload);
}

export function GetInfoRequestsByTeam(): Promise<IInfoRequest[]> {
	return HttpService.Get<IInfoRequest[]>({ path: "/dealrooms/info-requests/team" }).then((res) => res.payload);
}

export function GetInfoRequest(request_id: string): Promise<IInfoRequest> {
	return HttpService.Get<IInfoRequest>({ path: `/dealrooms/info-requests/${request_id}` }).then((res) => res.payload);
}

export function DeleteInfoRequest(request_id: string) {
	return HttpService.Delete({ path: `/dealrooms/info-requests/${request_id}` });
}

export function SaveInfoRequest(body: Partial<IInfoRequest>): Promise<IInfoRequest> {
	return HttpService.Post<IInfoRequest>({ path: "/dealrooms/info-requests", body }).then((res) => res.payload);
}

export function UpdateInfoRequestName(request_id: string, request_name: string): Promise<IInfoRequest> {
	return HttpService.Post<IInfoRequest>({ path: `/dealrooms/info-requests/${request_id}/update/name`, body: { request_name } }).then(
		(res) => res.payload
	);
}

export function UpdateInfoRequestQuestionIndex(question_id: string, question_index: number) {
	return HttpService.Post({ path: `/dealrooms/questions/${question_id}/index`, body: { question_index } });
}

export function UpdateInfoRequestQuestionStatus(question_id: string, status: QuestionStatus) {
	return HttpService.Post({ path: `/dealrooms/questions/${question_id}/status`, body: { status } });
}

export function UpdateInfoRequestQuestionName(question_id: string, question: string) {
	return HttpService.Post({ path: `/dealrooms/questions/${question_id}/name`, body: { question } });
}

export function UpdateInfoRequestQuestionAnswer(
	question_id: string,
	body: { answer: string; answer_source: string; status: QuestionStatus }
) {
	return HttpService.Post({ path: `/dealrooms/questions/${question_id}/answer`, body });
}

export function GetQuestionsByInfoRequest(request_id: string): Promise<IInfoRequestQuestion[]> {
	return HttpService.Get<IInfoRequestQuestion[]>({ path: `/dealrooms/questions/request/${request_id}` }).then((res) => res.payload);
}

export async function UploadInfoRequest({ request_id, file, onProgress }) {
	const presignedPost = await HttpService.Get({ path: `/dealrooms/info-requests/${request_id}/upload` }).then((res) => res.payload);

	const form = new FormData();

	Object.keys(presignedPost.fields).forEach((key) => {
		form.append(key, presignedPost.fields[key]);
	});

	form.append("file", file);

	await UploadFile(presignedPost.url, form, onProgress);
}

function UploadFile(url: string, request, onProgress?: (progress: number) => void): Promise<void> {
	return new Promise((resolve) => {
		const xhr = new XMLHttpRequest();

		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentComplete = (event.loaded / event.total) * 100;
				onProgress(percentComplete);
			}
		};

		xhr.onload = () => {
			resolve();
		};

		xhr.open("POST", url, true);

		xhr.send(request);
	});
}

export function ExtractInfoRequest(request_id: string): Promise<string[]> {
	return HttpService.Get<string[]>({ path: `/dealrooms/info-requests/${request_id}/extract` }).then((res) => res.payload);
}

export function GetQuestion(question_id: string): Promise<IInfoRequestQuestion> {
	return HttpService.Get<IInfoRequestQuestion>({ path: `/dealrooms/questions/${question_id}` }).then((res) => res.payload);
}

export function DeleteQuestion(question_id: string) {
	return HttpService.Delete({ path: `/dealrooms/questions/${question_id}` });
}

export function SaveQuestion(body: Partial<IInfoRequestQuestion>): Promise<IInfoRequestQuestion> {
	return HttpService.Post<IInfoRequestQuestion>({ path: "/dealrooms/questions", body }).then((res) => res.payload);
}
