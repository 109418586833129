import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";
import { WorkspaceAction, WorkspaceContext } from "@sage/state";
import { Companies, ProjectFiles, Templates, VaultFiles } from "../Panes";
import "./WorkspacePanel.scss";

export function WorkspacePanel() {
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	function updateActivePane(paneName: string) {
		let tools = [];
		if (workspaceState.openTools.includes(paneName)) {
			tools = [paneName, ...workspaceState.openTools.filter((name) => name !== paneName)];
		} else {
			tools = [paneName, ...workspaceState.openTools];
		}

		workspaceDispatch({
			type: WorkspaceAction.SetOpenTools,
			payload: tools
		});
	}

	function toggleToolsPaneOpen() {
		workspaceDispatch({
			type: WorkspaceAction.SetToolsPaneOpen,
			payload: !workspaceState.toolsPaneOpen
		});
	}

	function removeActivePane(paneName: string) {
		const tools = workspaceState.openTools.filter((name) => name !== paneName);

		workspaceDispatch({
			type: WorkspaceAction.SetOpenTools,
			payload: tools
		});
	}

	return (
		<div className={"__workspace-panel-container"}>
			<div className={"workspace-panel-fixed"}>
				<div
					className={"workspace-panel-toggle"}
					onClick={toggleToolsPaneOpen}
					data-testid={"toggle-panes"}
				>
					{workspaceState.toolsPaneOpen ? (
						<img
							alt={"arrow-left"}
							src={`https://cdn.ccm.vc/sage/icons/material-arrow-left.svg`}
							width={`24px`}
						/>
					) : (
						<img
							alt={"apps"}
							src={`https://cdn.ccm.vc/sage/icons/material-apps.svg`}
							width={`24px`}
						/>
					)}
				</div>
				<div className={`workspace-panel-apps ${workspaceState.toolsPaneOpen ? "open" : "closed"}`}>
					<div
						className={`workspace-panel-toggle ${workspaceState.openTools.includes("FILES") ? "active" : ""}`}
						onClick={() => updateActivePane("FILES")}
						title={"Project Files"}
						data-testid="workspace-pane-project-files"
					>
						<img
							alt={"files"}
							src={`https://cdn.ccm.vc/sage/icons/material-open.svg`}
							width={`24px`}
						/>
					</div>
					<div
						className={`workspace-panel-toggle ${workspaceState.openTools.includes("TEMPLATES") ? "active" : ""}`}
						onClick={() => updateActivePane("TEMPLATES")}
						title={"Templates"}
						data-testid="workspace-pane-templates"
					>
						<img
							alt={"templates"}
							src={`https://cdn.ccm.vc/sage/icons/intelligent-templates.svg`}
							width={`24px`}
						/>
					</div>
					<div
						className={`workspace-panel-toggle ${workspaceState.openTools.includes("VAULT") ? "active" : ""}`}
						onClick={() => updateActivePane("VAULT")}
						title={"Vault"}
						data-testid="workspace-pane-vault-files"
					>
						<img
							alt={"vault"}
							src={`https://cdn.ccm.vc/sage/icons/vault-blue.svg`}
							width={`24px`}
						/>
					</div>
					<div
						className={`workspace-panel-toggle ${workspaceState.openTools.includes("COMPANIES") ? "active" : ""}`}
						onClick={() => updateActivePane("COMPANIES")}
						title={"Companies"}
						data-testid="workspace-pane-companies"
					>
						<img
							alt={"companies"}
							src={`https://cdn.ccm.vc/sage/icons/companies-blue.svg`}
							width={`24px`}
						/>
					</div>
					<div className={"workspace-open-apps"}>
						{workspaceState.openTools.map((paneName) => {
							if (workspaceState.toolsPaneOpen) {
								switch (paneName) {
									case "TEMPLATES":
										return (
											<Templates
												key={paneName}
												onDismiss={() => removeActivePane("TEMPLATES")}
											/>
										);
									case "FILES":
										return (
											<ProjectFiles
												key={paneName}
												onDismiss={() => removeActivePane("FILES")}
											/>
										);
									case "VAULT":
										return (
											<VaultFiles
												key={paneName}
												onDismiss={() => removeActivePane("VAULT")}
											/>
										);
									case "COMPANIES":
										return (
											<Companies
												key={paneName}
												onDismiss={() => removeActivePane("COMPANIES")}
											/>
										);
									default:
										return null;
								}
							} else {
								return null;
							}
						})}
					</div>
				</div>
			</div>
		</div>
	);
}
