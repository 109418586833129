import { IDealRoomElementSource, IDealRoomSource, MimeToFileType } from "@sage/types";
import { HttpService } from "../../HttpService";

interface IDealRoomSourceFromRedis
	extends Omit<IDealRoomElementSource, "lastModifiedTimestamp" | "createdTimestamp" | "file_size" | "element_id"> {
	lastModifiedTimestamp: string;
	createdTimestamp: string;
	file_size: string;
	page_number: string;
}

export function SearchSources(deal_id: string, query: string, element_id?: string): Promise<IDealRoomElementSource[]> {
	return HttpService.Post<IDealRoomSourceFromRedis[]>({
		path: `/dealrooms/sources/deal/${deal_id}/search`,
		body: { query }
	}).then((res) =>
		res.statusCode === 200
			? res.payload.map((result) => ({
					...result,
					relevance_score: result.relevance_score,
					element_id,
					lastModifiedTimestamp: parseInt(result.lastModifiedTimestamp),
					createdTimestamp: parseInt(result.createdTimestamp),
					file_size: parseInt(result.file_size)
				}))
			: []
	);
}

export function GetSource(source_id: string): Promise<IDealRoomSource> {
	return HttpService.Get<IDealRoomSource>({ path: `/dealrooms/sources/${source_id}` }).then((res) => res.payload);
}

export function SaveSource(source: Partial<IDealRoomSource>): Promise<IDealRoomSource> {
	return HttpService.Post<IDealRoomSource>({ path: "/dealrooms/sources", body: source }).then((res) => res.payload);
}

export function DeleteSource(deal_id: string, source_id: string) {
	return HttpService.Delete({ path: `/dealrooms/sources/${deal_id}/${source_id}` }).then((res) => res.payload);
}

export function GetSourcesByDeal(deal_id: string): Promise<IDealRoomSource[]> {
	return HttpService.Get<IDealRoomSource[]>({ path: `/dealrooms/sources/deal/${deal_id}` }).then((res) => res.payload);
}

export function DownloadSource(source_id: string): Promise<string> {
	return HttpService.Get<{ url: string }>({ path: `/dealrooms/sources/${source_id}/download` }).then((res) => res.payload.url);
}

interface PresignedPost {
	fields: { [key: string]: string };
	url: string;
}

export function CreateSourcePresignedPost(source_id: string, content_type?: string): Promise<PresignedPost> {
	return HttpService.Post<PresignedPost>({ path: `/dealrooms/sources/${source_id}/upload`, body: { content_type } }).then(
		(res) => res.payload
	);
}

export interface ICreateSourceProps {
	deal_id: string;
	file: File;
	onProgress: (progress: number) => void;
}

export async function CreateSource({ deal_id, file, onProgress }): Promise<IDealRoomSource> {
	const source = await SaveSource({ deal_id, file_type: MimeToFileType(file.type), file_name: file.name, file_size: file.size });
	await UploadSource({ source_id: source.source_id, onProgress, file });
	return source;
}

export interface IUploadSourceProps {
	source_id: string;
	file: File;
	onProgress: (progress: number) => void;
}

export async function UploadSource({ source_id, file, onProgress }: IUploadSourceProps) {
	const presignedPost = await CreateSourcePresignedPost(source_id, file.type);

	const form = new FormData();

	Object.keys(presignedPost.fields).forEach((key) => {
		form.append(key, presignedPost.fields[key]);
	});

	form.append("file", file);

	await UploadFile(presignedPost.url, form, onProgress);
}

function UploadFile(url: string, request, onProgress?: (progress: number) => void): Promise<void> {
	return new Promise((resolve) => {
		const xhr = new XMLHttpRequest();

		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentComplete = (event.loaded / event.total) * 100;
				onProgress(percentComplete);
			}
		};

		xhr.onload = () => {
			resolve();
		};

		xhr.open("POST", url, true);

		xhr.send(request);
	});
}
