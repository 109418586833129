import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function StatusSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "FVUQWGJEDAZBWYCN"
									})
								);
							}}
						>
							Active Litigation Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "NKDUMNTSTESMAQHV"
									})
								);
							}}
						>
							Settlements
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "QWTQUXYDUJBWBBBL"
									})
								);
							}}
						>
							Legal Hold Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "UHSQNCBKTMTTARKN"
									})
								);
							}}
						>
							Discovery Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "XKTANNQSGBKFGULH"
									})
								);
							}}
						>
							Material Claims
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "AMCFBKZYVXDMJTXJ"
									})
								);
							}}
						>
							Class Action Status
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"gavel"}
					size={"2x"}
					color={"#0051a8"}
				>
					Legal Proceedings
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "FJUFWYMCCSNJLBSN"
									})
								);
							}}
						>
							License Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "QGKBGVRRJXQMZTNS"
									})
								);
							}}
						>
							Permit Updates
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "DWDFHNAHDUFSRPEY"
									})
								);
							}}
						>
							Filing Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "FNAUHVGULYTWSBAD"
									})
								);
							}}
						>
							Inspection Results
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZBDNGXUXXXBPKVQJ"
									})
								);
							}}
						>
							Violation History
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YCGHBGEDEKHTTLQM"
									})
								);
							}}
						>
							Corrective Actions
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "GBJSSHWTWUUXQUCS"
									})
								);
							}}
						>
							Agency Communications
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YUPQXCQAZRQKWHAT"
									})
								);
							}}
						>
							Registration Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HSPRXDTPVQNTPHCY"
									})
								);
							}}
						>
							Reporting Requirements
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EARMDHCZYUXRTNBZ"
									})
								);
							}}
						>
							Certification Status
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"clipboard-list-check"}
					size={"2x"}
					color={"#0051a8"}
				>
					Regulatory Affairs
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "FCMNLJQSYTLHSSMT"
									})
								);
							}}
						>
							Permit Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HRBYNJWGXCZUUPMQ"
									})
								);
							}}
						>
							Site Assessments
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EMRZYAEKLKTXCSAA"
									})
								);
							}}
						>
							Remediation Progress
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "WLQZSAUAHQFMQBRT"
									})
								);
							}}
						>
							Inspection Results
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"seedling"}
					size={"2x"}
					color={"#0051a8"}
				>
					Environmental Affairs
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "USPWWLZTRQDWSWWS"
									})
								);
							}}
						>
							Current Audit Phase
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "VRAPWFEZEVCPHFZG"
									})
								);
							}}
						>
							Material Findings
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "RNACQEKSDHKGFYSW"
									})
								);
							}}
						>
							Control Testing
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EGYZXJFXHBNZYKJJ"
									})
								);
							}}
						>
							Compliance Review
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "RKCVVXAWLGSACXSB"
									})
								);
							}}
						>
							Documentation Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "JZHKCPPMZPRXEZVR"
									})
								);
							}}
						>
							Prior Year Resolution
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "PZYSRGCGYRSHUVCH"
									})
								);
							}}
						>
							External Auditor Status
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "HAWULXQYKHCYKZHT"
									})
								);
							}}
						>
							Internal Audit Progress
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "MDCXMJNSBVXQYCEF"
									})
								);
							}}
						>
							Risk Assessment
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "VBGMQKXTBTXEERTJ"
									})
								);
							}}
						>
							Management Response
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"bars-progress"}
					size={"2x"}
					color={"#0051a8"}
				>
					Financial Audits
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "VJNKJBYWYGSTYLKL"
									})
								);
							}}
						>
							Patent Applications
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BAADZDANBGWZYPRN"
									})
								);
							}}
						>
							Trademark Registrations
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "MFSSEFNXDTHBKWAV"
									})
								);
							}}
						>
							Copyright Filings
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YMNXVEXESQPVNHEX"
									})
								);
							}}
						>
							IP Litigation
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "BRCWFAULHKWBTFJY"
									})
								);
							}}
						>
							License Agreements
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"scale-balanced"}
					size={"2x"}
					color={"#0051a8"}
				>
					Intellectual Property
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ZMKUXRHSEHQMGJUH"
									})
								);
							}}
						>
							Benefits Compliance
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "ATVKWQTDUJLLTWKR"
									})
								);
							}}
						>
							Safety Standards
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "DELVEZUEDHPNZPHM"
									})
								);
							}}
						>
							Union Relations
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"person-dolly"}
					size={"2x"}
					color={"#0051a8"}
				>
					Employment Compliance
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
