import { ITeam } from "@sage/types";
import { HttpService } from "../HttpService/HttpService";

export class TeamService {
	static UpdateSettings(settings: any) {
		return HttpService.Post({
			uri: `/workspace-api-v2/teams/settings`,
			body: settings
		}).then((response) => response.payload);
	}
	static async GetTeamById() {
		return HttpService.Get<ITeam>({
			uri: `/workspace-api-v2/teams`
		}).then((response) => response.payload);
	}
}
