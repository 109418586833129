import { IWorkflow } from "@sage/types";
import { gunzip, gzip } from "@sage/utils";
import { HttpService } from "../HttpService";

export class WorkflowService {
	/**
	 * LoadWorkflow
	 *
	 * Load the workflow data based on a workflow_id
	 * This is the form-state of the workflow.
	 *
	 * Workflow data is stored as a gzipped json string,
	 * because we must stay below 400k per dynamodb item.
	 * Compression happens on the frontend to reduce
	 * transport size, so it must be compressed after
	 * getting response from API.
	 *
	 * @param {string} workflow_id - The workflow_id to load
	 * @returns {IWorkflow} - Returns an unzipped workflow.
	 */
	static async LoadWorkflow(workflow_id: string) {
		const workflow = await HttpService.Get<IWorkflow>({
			path: `/workflows/${workflow_id}`
		}).then((r) => r.payload);

		return {
			...workflow,
			data: await gunzip(workflow.data),
			outline: workflow.outline ? await gunzip(workflow.outline) : null
		};
	}

	/**
	 * SaveWorkflow
	 *
	 * Save workflow state. This is the form-state or model
	 * outputs.
	 *
	 * Workflow data is stored as a gzipped json string,
	 * because we must stay below 400k per dynamodb item.
	 * Compression happens on the frontend to reduce
	 * transport size, so it must be compressed before
	 * sending to API.
	 *
	 * @param {string} workflow_id - the workflow to save
	 * @param {Object} data - the form state to zip and save
	 */
	static async SaveWorkflow(workflow_id: string, data: any) {
		if (data === null || Object.keys(data).length === 0) {
			return;
		}

		const gz = await gzip(data);

		return HttpService.Post({
			path: `/workflows/${workflow_id}`,
			body: { data: gz }
		});
	}

	static async SaveOutline(workflow_id: string, outline: any) {
		if (outline === null || outline.length === 0) {
			return;
		}
		const gz = await gzip(outline);

		return HttpService.Post({
			path: `/workflows/outline/${workflow_id}`,
			body: { data: gz }
		});
	}

	static async SaveName(workflow_id: string, name: string) {
		return HttpService.Post({
			path: `/workflows/name/${workflow_id}`,
			body: { name }
		});
	}

	static async LoadUserWorkflows() {
		return HttpService.Get<IWorkflow[]>({
			path: "/workflows"
		}).then((r) => r.payload);
	}

	static async LoadTeamWorkflows() {
		return HttpService.Get<IWorkflow[]>({
			path: "/workflows/team"
		}).then((r) => r.payload);
	}

	static async CreateWorkflow(workflow_type: string) {
		return HttpService.Post<IWorkflow>({
			path: "/workflows",
			body: { type: workflow_type }
		}).then((r) => r.payload);
	}
}
