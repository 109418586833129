import { WorkflowStateProvider } from "@sage/state";
import { CreateCIMWorkflowRouter } from "../Router";

export function CreateCIMStateProvider() {
	return (
		<WorkflowStateProvider
			defaultOutline={[
				{
					title: "Deal Details",
					href: "./details",
					pages: [
						{
							label: "Basic Details",
							href: "./basic-details"
						},
						{
							label: "Company",
							href: "./company"
						},
						{
							label: "Deep Crawl",
							href: "./crawl"
						}
					]
				},
				{
					title: "Templates",
					href: "./template",
					pages: [
						{
							label: "Use a Sage Template",
							href: "./sage-template"
						},
						// {
						// 	label: "Generate a Template",
						// 	href: "./generate-template"
						// },
						{
							label: "Use an Existing Template",
							href: "./select-template"
						}
					]
				},
				{
					title: "Data Sources",
					href: "./data-sources",
					pages: [
						{
							label: "Upload Files",
							href: "./upload-data-sources"
						},
						{
							label: "Select Sources",
							href: "./select-data-sources"
						}
					]
				},
				{
					title: "Review",
					href: "./review"
				},
				{
					title: "Results",
					href: "./results"
				}
			]}
		>
			<CreateCIMWorkflowRouter />
		</WorkflowStateProvider>
	);
}
