import React, { useContext } from "react";
import { FsContext } from "@sage/state";
import { TeamDetails } from "@sage/shared/core";
import { Projects } from "@sage/shared/other";
import "./Shared.scss";

export function DocsShared() {
	const fsState = useContext(FsContext);

	return (
		<div className={"__shared-page-container"}>
			<TeamDetails />
			<Projects
				title={"Shared Docs"}
				projects={fsState.sharedProjects.projects}
				loading={fsState.sharedProjects.loading}
				emptyText={"It looks like you don't have any shared docs yet."}
				showMenu={false}
			/>
		</div>
	);
}
