import { Citation } from "@sage/types";

export interface ILlmRequest {
	prompt: string;
	context?: string;
	preprompt?: string;
	thread?: string;
	sources?: ILlmSource[];
	image?: string;
}

export interface ILlmSource {
	knn?: boolean;
	index: string;
	pretty?: boolean;
	teamCode?: string;
	prefix?: string[];
	uids?: string[];
	label?: string;
	target_fields: string[];
	tag?: string;
	k?: number;
	size?: number;
	time_sensitive?: boolean;
	company_id?: string;
}

export interface ILlmResponse {
	generated_text: string;
	thread?: string;
	sources: string[];
}

export const LlmService = {
	Invoke: async (req: ILlmRequest): Promise<ILlmResponse> => {
		const options = {
			method: "POST",
			body: JSON.stringify(req),
			headers: {
				"Content-Type": "application/json"
			}
		};

		const res = await fetch("/llm/claude/invoke/messages/v3", options);

		return await res.json();
	},
	Stream: async (req: ILlmRequest, streamCallback: (e: string) => void): Promise<ILlmResponse> => {
		const options = {
			method: "POST",
			body: JSON.stringify(req),
			headers: {
				"Content-Type": "application/json"
			}
		};

		let sources = [];
		const res = await fetch("/llm/claude/stream/messages/v3", options);

		const reader = res.body.getReader();
		const decoder = new TextDecoder("utf-8");

		const delim = "<sage-llm-stack-delim>";
		let generated_text = "";
		let thread;

		while (true) {
			const { done, value } = await reader.read();
			const text = decoder.decode(value, { stream: true });

			if (done) {
				break;
			}

			const chunks = text
				.replaceAll("}{", `}${delim}{`)
				.split(delim)
				.map((st) => {
					try {
						return JSON.parse(st);
					} catch (e) {
						return null;
					}
				})
				.filter((e) => !!e);

			for (let chunk of chunks) {
				if (chunk.type === "content_block_start") {
					generated_text += chunk.content_block.text;
				} else if (chunk.type === "content_block_delta") {
					generated_text += chunk.delta.text;
				} else if (chunk.type === "thread") {
					thread = chunk.thread;
				} else if (chunk.type === "citation") {
					sources = [...sources, ...chunk.citations];
				}
			}
			streamCallback(generated_text);
		}

		return { generated_text: generated_text, sources: deduplicate_sources(sources), thread };
	}
};

const deduplicate_sources = (sources: Citation[]) => {
	const seen = {};

	const deduped = [];

	for (let source of sources) {
		if ("uid" in source) {
			if (!(source.uid in seen)) {
				seen[source.uid] = deduped.length;
				deduped.push(source);
			}
		} else if ("document_id" in source) {
			const hash_key = `${source.file_id}:${source.page_number}`;
			if (!(hash_key in seen)) {
				seen[hash_key] = deduped.length;
				deduped.push({
					...source,
					multi_text_content: [source.text_content]
				});
			} else {
				deduped[seen[hash_key]].multi_text_content.push(source.text_content);
			}
		} else {
			deduped.push(source);
		}
	}

	return deduped;
};
