import React, { useRef, useState } from "react";
import { Select } from "@sage/shared/core";
import "./StyleSelector.scss";

enum Style {
	Chat = "Chat",
	BulletPoints = "Bullet Points",
	ReportWriting = "Report Writing",
	NoPreprompt = "No Preprompt"
}

export function StyleSelector() {
	const [selectedStyle, setSelectedStyle] = useState<string>(localStorage.getItem("AthenaResponseStyle") || Style.Chat);
	const [showDetail, setShowDetail] = useState<boolean>(false);
	const showMoreRef = useRef(null);

	function handleChange(style: Style) {
		setSelectedStyle(style);
		localStorage.setItem("AthenaResponseStyle", style);
	}

	function mouseOver() {
		showMoreRef.current = true;
		setShowDetail(true);
	}

	function mouseOut() {
		showMoreRef.current = false;
		setTimeout(() => {
			if (showMoreRef.current === false) {
				setShowDetail(false);
			}
		}, 6000);
	}

	return (
		<div
			className="__sage-style-selector-container"
			onMouseOver={mouseOver}
			onMouseOut={mouseOut}
		>
			<Select
				option={(option: string) => (
					<div
						key={option}
						className={`style-option ${showDetail ? "more" : "less"}`}
					>
						<StyleIcon name={option} />
						{showDetail && option}
					</div>
				)}
				value={selectedStyle}
				onChange={handleChange}
				options={[Style.Chat, Style.BulletPoints, Style.ReportWriting, Style.NoPreprompt]}
			/>
		</div>
	);
}

function StyleIcon({ name }) {
	switch (name) {
		case Style.Chat:
			return (
				<div className="icon">
					<img src="https://cdn.ccm.vc/sage/icons/chat-style.svg" />
				</div>
			);
		case Style.ReportWriting:
			return (
				<div className="icon">
					<img src="https://cdn.ccm.vc/sage/icons/document-style.svg" />
				</div>
			);
		case Style.BulletPoints:
			return (
				<div className="icon">
					<img src="https://cdn.ccm.vc/sage/icons/presentation-style.svg" />
				</div>
			);
		case Style.NoPreprompt:
			return (
				<div className="icon">
					<img src="https://cdn.ccm.vc/sage/icons/material-assistant.svg" />
				</div>
			);
	}
}
