import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { AuthAction, AuthContext } from "@sage/state";
import { TeamService } from "@sage/services";
import { ButtonBorderShape, ButtonIcon, ButtonVariant, TeamDetails } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import { UserIcon, UserTag } from "@sage/shared/other";
import "./Organization.scss";

export function OrganizationPage() {
	const authState = useContext(AuthContext);
	const { authDispatch } = useOutletContext<{ authDispatch }>();
	const { control, handleSubmit, reset } = useForm();
	const [buttonCopy, setButtonCopy] = useState<boolean>(false);
	const [saveLoading, setSaveLoading] = useState<boolean>(false);

	function shareLink(teamCode) {
		navigator.clipboard.writeText("");
		navigator.clipboard.writeText(`https://${window.location.host}/app/welcome/create-account/email/?TeamCode=${teamCode}`);
		setButtonCopy(true);
	}

	async function save(formValue) {
		setSaveLoading(true);
		const new_settings = {
			...authState.team.settings,
			columns: formValue.settings.columns.map((c) => c.value.replace(/['"]/gi, ""))
		};

		await TeamService.UpdateSettings(new_settings);

		authDispatch({
			type: AuthAction.UpdateTeamSettings,
			payload: new_settings
		});
		setSaveLoading(false);
	}

	useEffect(() => {
		const shareLink = `https://${window.location.host}/app/welcome/create-account/email/?TeamCode=${authState.team.teamCode}`;
		reset({
			...authState.team,
			...authState.user,
			shareLink,
			settings: {
				...authState.team.settings,
				columns: authState.team.settings?.columns?.map((c) => ({
					value: c
				}))
			}
		});
	}, [authState.user]);

	return (
		<div className={"__organization-container"}>
			<TeamDetails />
			<div className={"form-section"}>
				<Form
					title={"Your Organization"}
					fields={[
						{
							variant: FormFieldVariant.Vertical,
							label: "Team Name",
							placeholder: "e.g. Sage AI",
							control,
							name: "teamName",
							disabled: true
						},
						{
							variant: FormFieldVariant.Vertical,
							label: "Team Code",
							placeholder: "e.g. SAGE-AI",
							control,
							name: "teamCode",
							disabled: true
						}
					]}
				/>

				<div className={"team-members"}>
					<div className={"title-team-members"}>Team Members</div>

					<ul>
						{authState.team.members.map((member) => (
							<li key={member.user_id}>
								<UserIcon
									user_id={member.user_id}
									size={"sm"}
								/>
								<UserTag
									user_id={member.user_id}
									transparent
								/>
							</li>
						))}
					</ul>
				</div>
				<div>
					<Form
						fields={[
							{
								variant: FormFieldVariant.Vertical,
								label: "Invitation Link",
								control,
								name: `shareLink`,
								disabled: true,
								actions: [
									{
										variant: buttonCopy ? ButtonVariant.IconSM : ButtonVariant.IconSecondarySM,
										icon: buttonCopy ? ButtonIcon.MaterialCheckWhite : ButtonIcon.MaterialCopy,
										action: () => shareLink(authState.team.teamCode),
										testid: "copy-share-link"
									}
								]
							},
							{
								variant: FormFieldVariant.Vertical,
								label: "Active Deal Columns",
								description: "This is the list of columns you can use to categorize your active deals.",
								control,
								name: "settings.columns",
								type: "list"
							}
						]}
						actions={[
							{
								children: "Save",
								borderShape: ButtonBorderShape.Round,
								action: handleSubmit(save),
								loading: saveLoading,
								testid: "save"
							}
						]}
					/>
				</div>
			</div>
		</div>
	);
}
