import { useRef, useState } from "react";
import { Row } from "@sage/shared/core";
import "./Table.scss";

export function Table({ children }) {
	const [copied, setCopied] = useState<boolean>(false);
	const tableRef = useRef(null);

	async function copyText(e) {
		e.preventDefault();
		e.stopPropagation();
		const outer = document.createElement("div");
		const element = tableRef.current.cloneNode(true);

		const tds = element.querySelectorAll("td");

		for (let td of tds) {
			td.style.fontSize = "9pt";
			td.style.width = "1.5in";
			td.style.paddingLeft = "0in";
			td.style.paddingTop = "0in";
			td.style.paddingBottom = "0in";
		}

		const ths = element.querySelectorAll("th");

		for (let th of ths) {
			th.style.fontSize = "9pt";
			th.style.fontWeight = "600";
			th.style.textAlign = "center";
			th.style.backgroundColor = th.style.backgroundColor || "#1c4587";
			th.style.color = th.style.color || "white";
		}

		const margins = element.querySelectorAll(".margin > td");

		for (let margin of margins) {
			margin.style.fontStyle = "italic";
		}

		const growthRates = element.querySelectorAll(".growth-rate");

		for (let growthRate of growthRates) {
			growthRate.style.fontStyle = "italic";
		}

		const borderBottoms = element.querySelectorAll(".border-bottom > td");

		for (let borderBottom of borderBottoms) {
			borderBottom.style.borderBottom = "solid 1px #000000";
		}

		const borderTops = element.querySelectorAll(".border-top > td");

		for (let borderTop of borderTops) {
			borderTop.style.borderTop = "solid 1px #000000";
			borderTop.style.fontWeight = 600;
		}

		const indents = element.querySelectorAll(".indent > td:nth-child(1)");

		for (let indent of indents) {
			indent.style.paddingLeft = "0.2in";
		}

		outer.appendChild(element);

		try {
			await navigator.clipboard.write([
				new ClipboardItem({
					"text/html": new Blob([outer.outerHTML], { type: "text/html" })
				})
			]);
			console.log("Copied with computed styles!");

			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 1500);
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	}

	function styleHtml(html) {
		const el = document.createElement("table");
		console.log(el);
		el.innerHTML = html;

		const thead = el.querySelector("thead");

		thead.style.color = "white";
		thead.style.fontWeight = "600";
		thead.style.backgroundColor = "#1c4587";

		return el.innerHTML;
	}

	return (
		<>
			<table
				ref={tableRef}
				className="__sage-athena-table-container"
				dangerouslySetInnerHTML={{ __html: children }}
			></table>
			<Row horizontalAlign="left">
				<div
					className={`copy-text ${copied ? "copied" : ""}`}
					onClick={copyText}
				>
					<img
						src={
							copied
								? "https://cdn.ccm.vc/sage/icons/material-check-white.svg"
								: "https://cdn.ccm.vc/sage/icons/material-copy.svg"
						}
					/>
				</div>
			</Row>
		</>
	);
}
