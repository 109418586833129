import { Dispatch } from "react";
import { ITeam, IUser } from "@sage/types";

export enum AuthAction {
	Authenticate = "Authenticate",
	AuthenticationComplete = "AuthenticationComplete",
	UpdateSearchSettings = "UpdateSearchSettings",
	UpdateTeamSettings = "UpdateTeamSettings",
	UpdateUserSettings = "UpdateUserSettings"
}

export interface IAuthenticateAction {
	type: AuthAction.Authenticate;
	dispatch: Dispatch<IAuthenticationCompleteAction>;
}

export interface IAuthenticationCompleteAction {
	type: AuthAction.AuthenticationComplete;
	payload: {
		user: IUser;
		team: ITeam;
	};
}

export interface IUpdateSearchSettings {
	type: AuthAction.UpdateSearchSettings;
	payload: {
		settings?: { [key: string]: boolean };
		searchFileScope?: string;
		searchFolderScope?: string;
		searchCompanyId?: string;
	};
}

export interface IUpdateTeamSettingsAction {
	type: AuthAction.UpdateTeamSettings;
	payload: { [key: string]: any };
}

export interface IUpdateUserSettingsAction {
	type: AuthAction.UpdateUserSettings;
	payload: IUser;
}

export type AuthActions =
	| IAuthenticateAction
	| IAuthenticationCompleteAction
	| IUpdateSearchSettings
	| IUpdateTeamSettingsAction
	| IUpdateUserSettingsAction;
