import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ICompanyCBD } from "@sage/types";
import { useWorkflowState } from "@sage/state";
import { DealstreamService } from "@sage/services";
import { CompanyNews, CompanyOverview, Employees, Events, Faqs, MAndAActivity, Products, Services } from "@sage/shared/company";
import { Button, ButtonBorderShape, ButtonVariant, Col, Collapsible, Row } from "@sage/shared/core";
import { Tiles } from "@sage/shared/forms";
import "./DeepCrawl.scss";

export function DeepCrawl() {
	const { getPageStatus, updatePageStatus, form } = useWorkflowState();
	const use_crawl = useWatch({ control: form.control, name: "use_deepcrawl" });
	const navigate = useNavigate();
	const company_id = useWatch({ control: form.control, name: "company_id" });
	const [company, setCompany] = useState<ICompanyCBD>(null);
	const [expandCompanyDetails, setExpandCompanyDetails] = useState<boolean>(false);
	const [lastCrawled, setLastCrawled] = useState(null);

	async function startCrawl() {
		await DealstreamService.RequestDeepCrawl(company_id, company.links.homepage);
		updatePageStatus("complete", "Deal Details", "Deep Crawl");
		navigate("../template");
	}

	function skipDeepCrawl() {
		updatePageStatus("skip", "Deal Details", "Deep Crawl");
		updatePageStatus("skip", "Deal Details", "Review Crawl Result");
		navigate("../template");
	}

	function handleNavigateAway() {
		if (getPageStatus("Deal Details", "Deep Crawl") !== "skip") {
			updatePageStatus("complete", "Deal Details", "Deep Crawl");
		}
	}

	async function handleNav() {
		if (use_crawl) {
			await startCrawl();
		} else {
			skipDeepCrawl();
		}
	}

	async function loadCompany() {
		if (company_id) {
			setCompany(await DealstreamService.GetCompanyCBD(company_id));
		}
	}

	async function getCrawlStatus() {
		const crawlStatusResponse = await DealstreamService.GetCrawlStatus(company_id);
		setLastCrawled(crawlStatusResponse.last_crawled);
	}

	useEffect(() => {
		loadCompany();
		getCrawlStatus();
	}, [company_id]);

	useEffect(() => {
		updatePageStatus("in-progress", "Deal Details", "Deep Crawl");
		return handleNavigateAway;
	}, []);

	return (
		<section>
			{company_id ? (
				<Col>
					<h2>Here's what we have on {company?.name} so far</h2>
					{company && (
						<>
							<CompanyOverview company={company} />
							<Collapsible
								visible={expandCompanyDetails}
								toggleHandle={
									<Button
										variant={ButtonVariant.Tertiary}
										action={() => setExpandCompanyDetails((e) => !e)}
									>
										{expandCompanyDetails ? "See Less" : "See More"}
									</Button>
								}
							>
								<MAndAActivity
									portcos={company.portcos}
									owner={company.owner_id}
								/>
								<Col>
									<Employees
										employees={company.employees}
										people={company.other_people}
									/>
									<Faqs faqs={company.faqs} />
									<Events events={company.events} />
									<Products products={company.products} />
									<Services services={company.services} />
									<CompanyNews />
									<Button
										variant={ButtonVariant.Tertiary}
										action={() => setExpandCompanyDetails(false)}
									>
										See Less
									</Button>
								</Col>
							</Collapsible>
						</>
					)}
					<h3>Would you like us to crawl this company's website?</h3>
					<p>
						This will gather a lot of useful information from their website that will enhance the quality of your CIM
						<br />
						<br />
						This company was last crawled on <strong>{new Date(lastCrawled).toLocaleDateString()}</strong>
					</p>
					<Tiles
						control={form.control}
						name="use_deepcrawl"
						setValue={form.setValue}
						tiles={[
							{
								id: true,
								label: "Yes",
								description: "Deep crawl your company"
							},
							{
								id: false,
								label: "No",
								description: "Skip deep crawl"
							}
						]}
					/>
					<Row>
						<Button
							borderShape={ButtonBorderShape.Round}
							action={handleNav}
							disabled={use_crawl === undefined}
						>
							Next
						</Button>
					</Row>
				</Col>
			) : (
				<Col>
					<h2>Looks like you haven't selected a company yet...</h2>
					<p>Please return to the company page to use deep crawl data.</p>
					<Row>
						<Button
							borderShape={ButtonBorderShape.Round}
							action={() => navigate("../company")}
						>
							Back
						</Button>
					</Row>
				</Col>
			)}
		</section>
	);
}
