import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ICompanyService } from "@sage/types";
import { DealstreamService } from "@sage/services";
import {
	Button,
	ButtonBorderShape,
	ButtonIcon,
	ButtonVariant,
	Card,
	CardList,
	CardListItem,
	CardListItemText,
	CardListItemTitle,
	CardTitle
} from "@sage/shared/core";

export function Services({ services }: { services: ICompanyService[] }) {
	return services.length ? (
		<Card>
			<CardTitle>Services</CardTitle>
			<CardList>
				{services.map((service) => (
					<Service
						service={service}
						key={service.name}
					/>
				))}
			</CardList>
		</Card>
	) : null;
}

function Service({ service }: { service: ICompanyService }) {
	let [expansion, setExpansion] = useState<string>(null);
	let [expansionLoading, setExpansionLoading] = useState<boolean>(false);

	const params = useParams();

	async function expand() {
		if (params.uid) {
			setExpansionLoading(true);
			const expansionPrompt = `Service Details:\nName: ${service.name}\nDescription: ${service.description}`;

			const response = await DealstreamService.Expand(params.uid, expansionPrompt, setExpansion);
			setExpansionLoading(false);
			console.log(response.sources);
		}
	}

	return (
		<CardListItem>
			<CardListItemTitle data-testid="company-services-name">{service.name}</CardListItemTitle>
			<CardListItemText data-testid="company-services-description">{service.description}</CardListItemText>
			{expansion ? (
				<CardListItemText data-testid="company-services-expansion">{expansion}</CardListItemText>
			) : (
				<Button
					variant={ButtonVariant.Secondary}
					icon={ButtonIcon.SageLogo}
					borderShape={ButtonBorderShape.Round}
					action={expand}
					loading={expansionLoading}
					disabled={expansionLoading}
				>
					Expand
				</Button>
			)}
		</CardListItem>
	);
}
