import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { AthenaContext, AuthContext, createAthenaState } from "@sage/state";
import { AthenaService, VaultService } from "@sage/services";
import { Uuid } from "@sage/utils";

export function AthenaGuard() {
	const authState = useContext(AuthContext);
	const athenaState = createAthenaState();
	const params = useParams();
	const navigate = useNavigate();

	async function getThread() {
		if (params.thread_id) {
			const { payload, statusCode } = await AthenaService.loadThread(params.thread_id);
			if (statusCode === 200) {
				return payload;
			}
		}

		const thread = {
			thread_id: Uuid.Nano(12),
			createdTimestamp: new Date().getTime(),
			lastModifiedTimestamp: new Date().getTime(),
			user_id: authState.user.user_id,
			teamCode: authState.user.teamCode,
			messages: []
		};

		const { statusCode } = await AthenaService.createThread(thread);

		if (statusCode === 200) {
			navigate(thread.thread_id);
		}
	}

	async function loadThread() {
		const thread = await getThread();
		if (thread) {
			athenaState.setThread(thread);
			athenaState.setMessages(thread.messages);
			if (thread.sourceFiles) {
				athenaState.setSourceFiles(await Promise.all(thread.sourceFiles.map((file_id) => VaultService.LoadFile(file_id))));
			}
			athenaState.setSourceCompanies(thread.sourceCompanies);
		}
	}

	useEffect(() => {
		loadThread();
	}, [params.thread_id]);

	return <AthenaContext.Provider value={athenaState}>{athenaState.thread && <Outlet />}</AthenaContext.Provider>;
}
