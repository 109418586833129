import React, { useEffect, useState } from "react";
import { DealstreamService } from "@sage/services";
import { InlineLoading } from "@sage/shared/core";
import "./CompanyCard.scss";

export interface ICompanyCardProps {
	company_id: string;
	onClick?: (company_id: string) => void;
}

export function CompanyCard({ company_id, onClick }: ICompanyCardProps) {
	const [company, setCompany] = useState(null);

	async function getCompany(company_id: string) {
		const company_ = await DealstreamService.GetCompany(company_id);
		const image_url = await DealstreamService.GetCompanyImage(company_.image_loc);

		setCompany({
			...company_,
			image: image_url.url
		});
	}

	useEffect(() => {
		if (company_id !== company?.company_id) {
			getCompany(company_id);
		}
	}, [company_id]);

	return (
		<div
			className={"company-card"}
			onClick={() => onClick && onClick(company_id)}
		>
			{company ? (
				<>
					{company.image ? (
						<div className={"image"}>
							<img src={company.image} />
						</div>
					) : null}
					<div className={"col"}>
						<div className={"label"}>
							<a
								className="link"
								href={`/app/fs/private-company/${company.uid}`}
								target={"_blank"}
							>
								{company.legal_name}
							</a>
						</div>
						{company.description ? <div className={"description"}>{company.description}</div> : null}
					</div>
				</>
			) : (
				<InlineLoading invert />
			)}
		</div>
	);
}
