import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DealRoomProjectElementType } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Button, Col, Modal, Row, SectionTitle } from "@sage/shared/core";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { isNullOrUndefined } from "@sage/utils";
import "./UpdateElementModal.scss";

export function UpdateElementModal() {
	const { updateElement, setUpdateElement } = useDealRoom();
	const form = useForm();

	function dismiss() {
		setUpdateElement(null);
	}

	async function submit(formValue) {
		const { prompt, search_terms, element_name, element_description, element_type, example } = formValue;
		await DealRoomService.SaveTemplate({
			...updateElement,
			element_name,
			element_description,
			prompt,
			search_terms: search_terms.map(({ value }) => value).filter((val) => !!val),
			element_type: updateElement.element_type,
			example
		});
		setUpdateElement(null);
	}

	useEffect(() => {
		if (!isNullOrUndefined(updateElement)) {
			form.reset({ ...updateElement, search_terms: updateElement.search_terms?.map((term) => ({ value: term })) });
		} else {
			form.reset(null);
		}
	}, [updateElement]);

	return (
		<Modal
			visible={!isNullOrUndefined(updateElement)}
			onDismiss={dismiss}
		>
			<div className="__sage-update-element-modal">
				<Col>
					<SectionTitle caps>Update {updateElement?.element_type} element</SectionTitle>
					<FormField
						control={form.control}
						placeholder={"Element Name..."}
						name="element_name"
						variant={FormFieldVariant.Vertical}
						label="Element Name"
						fill
					/>
					<FormField
						control={form.control}
						placeholder={"Element Description..."}
						name="element_description"
						variant={FormFieldVariant.Vertical}
						label="Element Description"
						type="textarea"
						fill
					/>
					<FormField
						control={form.control}
						placeholder={"Prompt..."}
						name="prompt"
						type="textarea"
						variant={FormFieldVariant.Vertical}
						label="Prompt"
						description="This prompt will be used to generate the content for your element."
						rows={15}
						fill
					/>
					{(updateElement?.element_type === DealRoomProjectElementType.Paragraph ||
						updateElement?.element_type === DealRoomProjectElementType.Bullets) && (
						<FormField
							control={form.control}
							placeholder={"Example..."}
							name="example"
							type="textarea"
							variant={FormFieldVariant.Vertical}
							label="Example"
							description="If you want, you can give an example of the writing style you are looking for"
							rows={5}
							fill
						/>
					)}
					<FormField
						control={form.control}
						name="search_terms"
						placeholder="Search term..."
						type={"list"}
						label="Search Terms"
						description="These do not have to be keywords. You can add anything to this list that might help us find additional relevant information that will help our models write your section."
					/>
					<Row horizontalAlign="right">
						<Button action={form.handleSubmit(submit)}>Save</Button>
					</Row>
				</Col>
			</div>
		</Modal>
	);
}
