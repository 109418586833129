import { useEffect, useState } from "react";
import { IWorkflow } from "@sage/types";
import { WorkflowService } from "@sage/services";
import { ButtonBorderShape, Link, PageHeader, Table, TableColumn, TableColumnSpacing, TableRow } from "@sage/shared/core";
import { UserIcon, UserTag } from "@sage/shared/other";
import { useCollection } from "@sage/utils";

export function WorkflowList() {
	const { items, loading } = useCollection<IWorkflow>({ load: WorkflowService.LoadUserWorkflows });

	return (
		<div className="__sage-workflow-list-container">
			<PageHeader title={"Your Workflows"} />
			<Table
				items={items || []}
				loading={loading}
				actions={[{ children: "New Workflow", borderShape: ButtonBorderShape.Round, to: "../run" }]}
				headers={
					<TableRow>
						<TableColumn header>Workflow Name</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							User
						</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							Date
						</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"3rem"}
						>
							Type
						</TableColumn>
					</TableRow>
				}
				row={(row: IWorkflow) => (
					<TableRow>
						<TableColumn>
							<Link to={`/app/fs/workflows/run/${row.workflow_id}/${row.type}`}>{row.name}</Link>
						</TableColumn>
						<TableColumn
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							<UserIcon
								user_id={row.user_id}
								size={"sm"}
							/>
							<UserTag
								user_id={row.user_id}
								transparent
							/>
						</TableColumn>
						<TableColumn
							relativeDate
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							{row.lastModifiedTimestamp}
						</TableColumn>
						<TableColumn
							spacing={TableColumnSpacing.Fixed}
							width={"3rem"}
						>
							{row.type.toUpperCase()}
						</TableColumn>
					</TableRow>
				)}
			/>
		</div>
	);
}
