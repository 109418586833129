import { VaultFileType } from "./Vault";

export interface IThread {
	title?: string;
	thread_id: string;
	teamCode: string;
	user_id: string;
	lastModifiedTimestamp: number;
	createdTimestamp: number;
	messages: IMessage[];
	sourceFiles?: string[];
	sourceCompanies?: string[];
}

export interface IMessage {
	message_id: string;
	timestamp: number;
	actor?: string;
	actorType?: Actor;
	text: string;
	external_id?: string;
	thread_id?: string;
	sources: Citation[];
	idx?: number;
}

export enum Actor {
	Assistant = "assistant",
	User = "user"
}

export interface IFileSource {
	createdTimestamp: number;
	lastModifiedTimestamp: number;
	createdBy: string;
	description?: string;
	document_id: string;
	file_id: string;
	file_name: string;
	sage_filetype_id?: string;
	file_type: VaultFileType;
	parent_folder_id: string;
	prefix: string;
	size: number;
	teamCode: string;
	text_content: string;
	multi_text_content: string[];
	page_number: number;
}

export interface ICompanySource {
	name: string;
	legal_name: string;
	uid: string;
	url: string;
	description: string;
	enriched_at: string;
	fact_summary?: string;
	facts?: string[];
	image_loc: string;
	information: any;
	json_ld: any;
}

export interface INewsSource {
	acquired_at: number;
	article?: string;
	description?: string;
	entities?: string[];
	headline?: string;
	published_at?: string;
	publisher?: string;
	uid: string;
	url: string;
	updatd_at?: string;
}

export interface IWebPageSource {
	l1_content: string;
	page_title: string;
}

export interface IDealSource {
	deal_id: string;
	name: string;
	description: string;
}

export type Citation = IFileSource | ICompanySource | IDealSource | INewsSource | IWebPageSource;
