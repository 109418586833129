import { ReactNode, createContext, useContext } from "react";

interface IChartColorsState {
	chart_colors: string[];
}

const InitialChartColorsState = {
	chart_colors: []
};

const ChartColorsContext = createContext<IChartColorsState>(InitialChartColorsState);

export function ChartColorsProvider({ children, chart_colors }: { children: ReactNode; chart_colors: string[] }) {
	const state = createChartColorsState(chart_colors);
	return <ChartColorsContext.Provider value={state}>{children}</ChartColorsContext.Provider>;
}

export function createChartColorsState(chart_colors: string[]) {
	return { chart_colors };
}

export function useChartColors() {
	return useContext(ChartColorsContext);
}
