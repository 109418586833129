import React from "react";
import { IArticle } from "@sage/types";
import { DealTags } from "@sage/shared/other";
import "./Article.scss";

const publisher_img_map = {
	"PR Newswire": "https://www.prnewswire.com/content/dam/prnewswire/icons/2019-Q4-PRN-Icon-32-32.png",
	"Canada Newswire": "https://www.prnewswire.com/content/dam/prnewswire/icons/2019-Q4-PRN-Icon-32-32.png",
	"Businesswire": "https://www.businesswire.com/favicon.ico",
	"ft": "https://www.ft.com/__origami/service/image/v2/images/raw/ftlogo-v1%3Abrand-ft-logo-square-coloured?source=update-logos&format=svg",
	"MarketWatch": "https://mw4.wsj.net/mw5/content/logos/favicon.ico",
	"Investors Business Daily": "https://www.investors.com/wp-content/uploads/2023/10/ibd-favicon-20231010.png",
	"The Street": "https://aap.thestreet.com/favicon.ico",
	"Zacks Commentary": "https://staticx.zacks.com/images/zacks/logos/favicon_1x_16x16.ico",
	"Benzinga": "https://www.benzinga.com/next-assets/images/favicon-32x32.png",
	"Reuters": "https://www.reuters.com/pf/resources/images/reuters/favicon/tr_fvcn_kinesis_32x32.ico?d=160",
	"cnbc": "https://www.cnbc.com/favicon.ico",
	"Business Insider": "https://markets.businessinsider.com/favicon-32x32.png",
	"Motley Fool": "https://g.foolcdn.com/misc-assets/logo-tmf-primary-favicon.ico",
	"forbes": "https://i.forbesimg.com/48X48-F.png",
	"wsj": "https://s.wsj.net/img/meta/wsj_favicon.svg"
};

const entities_to_filter_out = [
	"2a56fecfbdea905fef75a714e5bcb786",
	"da6d74fb9acdaa4aa8ced11a8b3f7e46",
	"97c6d1b42639936beee7bd0dd9fd5874",
	"9d998de81e1d2b8ce7802e10c5e8071b",
	"98112fc43836f8b481e3bc740452887f"
];

export function Article({ article, size }: { article: IArticle; size?: string }) {
	function htmlDecode(input) {
		let doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
	}

	function printDate() {
		if (article.publisher === "ft") {
			const date_st = article.updated_at || article.published_at;
			return new Date(date_st ? `${date_st}Z` : article.enriched_at).toLocaleDateString("en-US", {
				hour: "numeric",
				minute: "numeric"
			});
		} else {
			return new Date(article.updated_at || article.published_at || article.enriched_at).toLocaleDateString("en-US", {
				hour: "numeric",
				minute: "numeric"
			});
		}
	}

	return (
		<div className={`__edge-news-article-container ${size}`}>
			<div className={"article-header"}>
				<div className={"details"}>
					<a
						className={"headline"}
						target={"_blank"}
						href={article.target?.article_url || article.article_id}
					>
						{htmlDecode(article.headline)}
					</a>
					<p className={"dt"}>
						{article.publisher && Object.keys(publisher_img_map).includes(article.publisher) ? (
							<img
								className="publisher-image"
								src={publisher_img_map[article.publisher]}
							/>
						) : null}
						{article.publisher}
						{article.publisher ? " - " : ""}
						{printDate()}
					</p>
				</div>
				{size !== "sm" ? (
					<div className={"image-wrapper"}>
						{article.image ? (
							<img
								className="image"
								src={article.image}
							/>
						) : null}
					</div>
				) : null}
			</div>
			{article.keywords ? (
				<DealTags tags={article.keywords.slice(0, 4).map((keyword) => ({ name: keyword, color: "blue" }))} />
			) : null}
			<p className={"description"}>{htmlDecode(article.description)}</p>
		</div>
	);
}
