import React from "react";
import { AdvancedEditor } from "./AdvancedEditor/AdvancedEditor";
import "./DocumentEditor.scss";

export function DocumentEditor() {
	return (
		<div className={"__document-editor-container"}>
			<div className={"document-editor-body"}>
				<AdvancedEditor />
			</div>
		</div>
	);
}
