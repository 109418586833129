import { IDealRoomMessage } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetElementMessagesByThread(thread_id: string): Promise<IDealRoomMessage[]> {
	return HttpService.Get<IDealRoomMessage[]>({ path: `/dealrooms/element-messages/thread/${thread_id}` }).then((res) => res.payload);
}

export function SaveElementMessage(message: Partial<IDealRoomMessage>) {
	return HttpService.Post<IDealRoomMessage>({ path: "/dealrooms/element-messages", body: message }).then((res) => res.payload);
}
