import React, { useState } from "react";
import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Controller } from "react-hook-form";
import { Button, ButtonIcon, ButtonVariant } from "@sage/shared/core";

export function TemplateItemParagraph({ item, control, idx, remove }) {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: item.id,
		animateLayoutChanges: (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true })
	});

	const style = {
		transform: CSS.Transform.toString({
			...transform,
			scaleX: 1,
			scaleY: 1
		}),
		transition
	};

	const [text, setText] = useState<string>(item.text);

	return (
		<div
			ref={setNodeRef}
			style={style}
		>
			<div className={"template-item-wrapper"}>
				<div
					{...listeners}
					{...attributes}
				>
					<div className={"template-item-icon menu"}>
						<i></i>
					</div>
				</div>
				<div className={"template-item-icon paragraph filled"}>
					<i></i>
				</div>
				<Controller
					control={control}
					render={({ field }) => (
						<p
							contentEditable
							onInput={(e) => setText(e.currentTarget.textContent)}
							onBlur={(e) => {
								if (text.length === 0) {
									field.onChange("Paragraph");
									e.currentTarget.textContent = "Paragraph";
								} else {
									field.onChange(text);
								}
							}}
							suppressContentEditableWarning
						>
							{field.value}
						</p>
					)}
					name={`items.${idx}.text`}
				/>
				<div className={"template-item-remove-btn"}>
					<Button
						icon={ButtonIcon.MaterialRemove}
						variant={ButtonVariant.Secondary}
						action={remove}
					></Button>
				</div>
			</div>
		</div>
	);
}
