import React from "react";
import { Row } from "../Row";
import "./LoadingBar.scss";

export function LoadingBar({ progress }: { progress: number }) {
	return (
		<Row
			wrap={false}
			verticalAlign="center"
		>
			<div className="__sage-loading-bar-container">
				<div
					className={`progress ${progress === 100 ? "complete" : ""}`}
					style={{ width: `${progress}%` }}
				/>
			</div>
			<div className="__sage-loading-bar-progress-text">{progress}%</div>
		</Row>
	);
}
