import { createContext } from "react";
import { IProject, ITemplate, IVaultFile } from "@sage/types";
import { WorkspaceAction, WorkspaceActions } from "./WorkspaceActions";
import { LoadProject, LoadTemplates } from "./WorkspaceEffects";

export interface IWorkspaceState {
	loading: boolean;
	project: IProject;
	openTools: string[];
	toolsPaneOpen: boolean;
	activeDocument: string;
	activeSources: IVaultFile[];
	activeCompanies: string[];
	activeOperations: string[];
	documentInsert: {
		text?: string;
		actor?: "USER" | "ASSISTANT";
	};
	templates: ITemplate[];
	templatesLoading: boolean;
	editTemplate: string;
	generateTemplate: ITemplate;
	promptModalText: string;
	currentEditor?: string;
}

export const InitialWorkspaceState: IWorkspaceState = {
	loading: true,
	project: null,
	activeDocument: null,
	activeSources: [],
	activeCompanies: [],
	openTools: [],
	toolsPaneOpen: false,
	activeOperations: [],
	documentInsert: {},
	templates: [],
	templatesLoading: false,
	editTemplate: null,
	generateTemplate: null,
	promptModalText: null,
	currentEditor: null
};

export const WorkspaceReducer = (state: IWorkspaceState = InitialWorkspaceState, action: WorkspaceActions): IWorkspaceState => {
	switch (action.type) {
		case WorkspaceAction.LoadProject:
			LoadProject(action.payload.project_id).then((project) =>
				action.dispatch({
					type: WorkspaceAction.SetProject,
					payload: project
				})
			);

			return {
				...state,
				loading: true
			};
		case WorkspaceAction.SetProject:
			return {
				...state,
				loading: false,
				project: action.payload,
				activeDocument: state.activeDocument === null ? action.payload.documents[0].document_id : state.activeDocument
			};
		case WorkspaceAction.RefreshProject:
			LoadProject(action.payload.project_id).then((project) =>
				action.dispatch({
					type: WorkspaceAction.SetRefreshProject,
					payload: project
				})
			);
			return state;
		case WorkspaceAction.SetRefreshProject:
			return {
				...state,
				project: action.payload
			};
		case WorkspaceAction.SetCurrentEditor:
			return {
				...state,
				currentEditor: action.payload
			};
		case WorkspaceAction.SetActiveDocument:
			return {
				...state,
				activeDocument: action.payload
			};
		case WorkspaceAction.SetActiveOperations:
			return {
				...state,
				activeOperations: action.payload
			};
		case WorkspaceAction.SetOpenTools:
			return {
				...state,
				openTools: action.payload
			};
		case WorkspaceAction.SetToolsPaneOpen:
			return {
				...state,
				toolsPaneOpen: action.payload
			};
		case WorkspaceAction.SetActiveSources:
			return {
				...state,
				activeSources: action.payload
			};
		case WorkspaceAction.SetActiveCompanies:
			return {
				...state,
				activeCompanies: action.payload
			};
		case WorkspaceAction.InsertIntoDocument:
			return {
				...state,
				documentInsert: action.payload
			};
		case WorkspaceAction.SetEditTemplate:
			return {
				...state,
				editTemplate: action.payload
			};
		case WorkspaceAction.LoadTemplates:
			LoadTemplates().then((templates) =>
				action.dispatch({
					type: WorkspaceAction.SetTemplates,
					payload: templates
				})
			);

			return {
				...state,
				templates: [],
				templatesLoading: true
			};
		case WorkspaceAction.SetTemplates:
			return {
				...state,
				templates: action.payload,
				templatesLoading: false
			};
		case WorkspaceAction.TogglePromptModal:
			return {
				...state,
				promptModalText: action.payload
			};
		case WorkspaceAction.SetGenerateTemplate:
			return {
				...state,
				generateTemplate: action.payload
			};
		default:
			return state;
	}
};

export const WorkspaceContext = createContext(InitialWorkspaceState);
