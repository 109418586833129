import { createContext } from "react";
import { IWelcomeForm } from "@sage/types";
import { WelcomeAction, WelcomeActions } from "./WelcomeActions";

export interface IWelcomeState {
	welcomeForm: IWelcomeForm;
}

export const InitialWelcomeState: IWelcomeState = {
	welcomeForm: {}
};

export const WelcomeReducer = (state: IWelcomeState = InitialWelcomeState, action: WelcomeActions): IWelcomeState => {
	switch (action.type) {
		case WelcomeAction.UpdateWelcomeForm:
			return {
				...state,
				welcomeForm: {
					...state.welcomeForm,
					...action.payload
				}
			};
		default:
			return state;
	}
};

export const WelcomeContext = createContext(InitialWelcomeState);
