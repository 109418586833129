import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { AuthService, UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function VerifyEmail() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();

	const { control, handleSubmit, reset, setError } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		await UserService.VerifyEmail(e);

		let res = await AuthService.Login({
			email: e.email,
			password: e.password
		});

		if (res?.user_id) {
			welcomeDispatch({
				type: WelcomeAction.UpdateWelcomeForm,
				payload: e
			});
			if (welcomeState.welcomeForm.teamCode) {
				navigate("/app/welcome/create-account/join-team");
			} else {
				navigate("/app/welcome/create-account/create-team");
			}
		} else {
			setError("verificationCode", {
				type: "custom",
				message: "Invalid verification code"
			});
		}
	}

	React.useEffect(() => {
		if (welcomeState.welcomeForm) {
			reset(welcomeState.welcomeForm);
		}
	}, [welcomeState.welcomeForm]);

	return (
		<Form
			title={"Verify Email Address"}
			fields={[
				{
					label: "Verification Code",
					placeholder: "e.g. 11-22-33",
					control,
					name: "verificationCode",
					description: "Please check your email for a verification code, it may take up to 5 minutes.",
					required: true
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Secondary,
					children: "Back",
					to: "/app/welcome/create-account/email",
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-to-previous"
				},
				{
					variant: ButtonVariant.Primary,
					children: "Next",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-to-next"
				}
			]}
		/>
	);
}
