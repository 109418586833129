import { createContext } from "react";
import { IProject } from "@sage/types";
import { FsAction, FsActions } from "./FsActions";
import { LoadFavoritedProjects, LoadRecentProjects, LoadSharedProjects } from "./FsEffects";

export interface IFsState {
	recentProjects: {
		loading: boolean;
		projects: IProject[];
	};
	sharedProjects: {
		loading: boolean;
		projects: IProject[];
	};
	favoritedProjects: {
		loading: boolean;
		projects: IProject[];
	};
}

export const InitialFsState: IFsState = {
	recentProjects: {
		loading: false,
		projects: []
	},
	sharedProjects: {
		loading: false,
		projects: []
	},
	favoritedProjects: {
		loading: false,
		projects: []
	}
};

export const FsReducer = (state: IFsState = InitialFsState, action: FsActions): IFsState => {
	switch (action.type) {
		case FsAction.LoadRecentProjects:
			LoadRecentProjects().then((projects) =>
				action.dispatch({
					type: FsAction.SetRecentProjects,
					payload: projects
				})
			);

			return {
				...state,
				recentProjects: {
					loading: true,
					projects: []
				}
			};
		case FsAction.SetRecentProjects:
			return {
				...state,
				recentProjects: {
					loading: true,
					projects: action.payload
				}
			};
		case FsAction.LoadSharedProjects:
			LoadSharedProjects().then((projects) =>
				action.dispatch({
					type: FsAction.SetSharedProjects,
					payload: projects
				})
			);
			return {
				...state,
				sharedProjects: {
					loading: true,
					projects: []
				}
			};
		case FsAction.SetSharedProjects:
			return {
				...state,
				sharedProjects: {
					loading: true,
					projects: action.payload
				}
			};
		case FsAction.LoadFavoritedProjects:
			LoadFavoritedProjects().then((projects) =>
				action.dispatch({
					type: FsAction.SetFavoritedProjects,
					payload: projects
				})
			);
			return {
				...state,
				favoritedProjects: {
					loading: true,
					projects: []
				}
			};
		case FsAction.SetFavoritedProjects:
			return {
				...state,
				favoritedProjects: {
					loading: true,
					projects: action.payload
				}
			};

		default:
			return state;
	}
};

export const FsContext = createContext(InitialFsState);
