import React from "react";
import { AskSagePanel } from "../AskSagePanel/AskSagePanel";
import { DocumentEditor } from "../Panes/DocumentEditor/DocumentEditor";
import { EditTemplate } from "../Panes/EditTemplate/EditTemplate";
import { GenerateTemplate } from "../Panes/Templates/GenerateTemplate";
import { WorkspacePanel } from "../WorkspacePanel/WorkspacePanel";
import "./WorkspaceBody.scss";

export function WorkspaceBody() {
	return (
		<div className={"__workspace-body-container"}>
			<WorkspacePanel />
			<DocumentEditor />
			<AskSagePanel />
			<EditTemplate />
			<GenerateTemplate />
		</div>
	);
}
