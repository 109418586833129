import React from "react";
import { Splash, Watermark } from "@sage/shared/core";
import "./OnboardingLayout.scss";

export function OnboardingLayout({ children }) {
	return (
		<div className={"__onboarding-layout-container"}>
			<Watermark />
			<div className={"content"}>{children}</div>
			<Splash />
		</div>
	);
}
