import { IDealRoomProjectPageTemplate } from "@sage/types";
import { Bullet, Col, DateHeader, FaIcon, Link, P, Row, SearchResult, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import "./PageTemplate.scss";

export function PageTemplate({
	template,
	select,
	searchTerm
}: {
	template: IDealRoomProjectPageTemplate;
	select: () => void;
	searchTerm?: string;
}) {
	return (
		<div
			className="__sage-template-item"
			onClick={select}
		>
			<Col>
				<Row
					gap="1rem"
					wrap={false}
					verticalAlign="center"
				>
					<FaIcon
						color={"#00435c"}
						icon={"file-pen"}
						width="fit-content"
						size={"2x"}
						borderRadius={"0.5rem"}
						paddingInline="0.75rem"
						padding="0.5rem"
						border
						hideBg
						direction="row"
					/>
					<Col gap={"0.25rem"}>
						<SectionTitle inline>
							<Link>
								<SearchResult searchTerm={searchTerm}>{template.page_name}</SearchResult>
							</Link>
						</SectionTitle>
						<Row verticalAlign="center">
							<DateHeader
								size="sm"
								thin
							>
								{template.lastModifiedTimestamp}
							</DateHeader>
							<Bullet size="sm" />
							<TimeHeader size="sm">{template.lastModifiedTimestamp}</TimeHeader>
							<Bullet size="sm" />
							<Tag
								size="sm"
								caps
							>
								{template.page_layout}
							</Tag>
						</Row>
					</Col>
				</Row>
				{template.page_title !== "Custom Page" && (
					<P>
						<SearchResult searchTerm={searchTerm}>{template.page_title}</SearchResult>
					</P>
				)}
				{template.page_description && (
					<P>
						<SearchResult searchTerm={searchTerm}>{template.page_description}</SearchResult>
					</P>
				)}
			</Col>
		</div>
	);
}
