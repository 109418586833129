import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IDealRoomCrawl, IInfoRequest, IInfoRequestQuestion } from "@sage/types";
import { DealRoomProvider } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Col, DeleteConfirmation, FaIcon, HidePrint, LoadingBubble, Row } from "@sage/shared/core";
import { isNullOrUndefined, useDebounce } from "@sage/utils";
import { AllInfoRequestsModal } from "../AllInfoRequestsModal";
import { Header } from "../Header";
import { Question } from "../Question";
import { UploadQuestionsList } from "../UploadQuestionsList";
import "./InformationRequest.scss";

export function InformationRequest() {
	return (
		<DealRoomProvider>
			<InformationRequestPage />
		</DealRoomProvider>
	);
}

function InformationRequestPage() {
	const [searchTerm, setSearchTerm] = useState<string>("");

	const params = useParams();
	const searchRef = useRef(null);
	const [crawls, setCrawls] = useState<IDealRoomCrawl[]>([]);
	const [infoRequest, setInfoRequest] = useState<IInfoRequest>(null);
	const [allQuestions, setAllQuestions] = useState<IInfoRequestQuestion[]>([]);
	const [questions, setQuestions] = useState<IInfoRequestQuestion[]>([]);
	const [deleteConfirmation, setDeleteConfirmation] = useState<string>(null);
	const [allInfoRequestsModalVisible, setAllInfoRequestsModalVisible] = useState<boolean>(false);
	const [showUploadInfoRequests, setShowUploadInfoRequests] = useState<boolean>(false);

	async function importQuestions(request_id: string) {
		const qs = await DealRoomService.GetQuestionsByInfoRequest(request_id);
		const startingIdx = questions.length;
		await Promise.all(
			qs.map((q, idx) =>
				DealRoomService.SaveQuestion({
					request_id: params.info_request_id,
					question_index: idx + startingIdx,
					question: q.question
				})
			)
		);
		await loadQuestions();
		setAllInfoRequestsModalVisible(false);
	}

	async function confirmDelete() {
		await DealRoomService.DeleteQuestion(deleteConfirmation);
		const newAllQuestions = allQuestions
			.filter((q) => q.question_id !== deleteConfirmation)
			.sort((a, b) => a.question_index - b.question_index)
			.map((q, idx) => ({ ...q, question_index: idx }));
		setDeleteConfirmation(null);
		setAllQuestions(newAllQuestions);
		setQuestions(newAllQuestions);
		setSearchTerm("");
		await Promise.all(newAllQuestions.map((q) => DealRoomService.UpdateInfoRequestQuestionIndex(q.question_id, q.question_index)));
	}

	function cancelDelete() {
		setDeleteConfirmation(null);
	}

	function deleteQuestion(question_id: string) {
		setDeleteConfirmation(question_id);
	}

	async function loadInfoRequest() {
		setInfoRequest(await DealRoomService.GetInfoRequest(params.info_request_id));
	}

	async function loadQuestions() {
		const qs = await DealRoomService.GetQuestionsByInfoRequest(params.info_request_id);
		setAllQuestions(qs);
		setQuestions(qs.sort((a, b) => a.question_index - b.question_index));
	}

	async function loadCrawls() {
		setCrawls(await DealRoomService.GetCrawls(params.deal_id));
	}

	async function newQuestion() {
		await DealRoomService.SaveQuestion({
			request_id: params.info_request_id,
			question_index: questions.length,
			question: "New Question"
		});
		await loadQuestions();
	}

	useEffect(() => {
		loadInfoRequest();
		loadQuestions();
		loadCrawls();
	}, [params.info_request_id]);

	function updateQuestions(term) {
		if (isNullOrUndefined(term) || term === "") {
			setQuestions(allQuestions);
		} else {
			setQuestions(
				allQuestions
					.filter((q) => q.question.toLowerCase().includes(term.toLowerCase()) || term.length === 0)
					.sort((a, b) => a.question_index - b.question_index)
			);
		}
	}

	const updateQuestionsDebounce = useDebounce(updateQuestions, 1000);

	return !isNullOrUndefined(infoRequest) ? (
		<div className="__sage-info-request-container">
			<Header
				request_name={infoRequest?.request_name}
				showInfoRequests={() => setAllInfoRequestsModalVisible(true)}
				showUploadInfoRequests={() => setShowUploadInfoRequests(true)}
			/>
			<div className="request-editor">
				<Col horizontalAlign="center">
					<HidePrint>
						<input
							className="search-input"
							value={searchTerm}
							onChange={(e) => {
								setTimeout(() => {
									searchRef.current.focus();
								}, 0);
								console.log(e.target.value);
								setSearchTerm(e.target.value);
								updateQuestionsDebounce(e.target.value);
							}}
							placeholder="Search..."
							ref={searchRef}
						/>
					</HidePrint>
					{questions.map((q) => (
						<Question
							key={q.question_id}
							question={q}
							crawls={crawls}
							remove={() => deleteQuestion(q.question_id)}
						/>
					))}
					<HidePrint>
						<Row horizontalAlign="center">
							<FaIcon
								icon="layer-plus"
								size="2x"
								color="#5700a8"
								onClick={newQuestion}
							></FaIcon>
						</Row>
					</HidePrint>
				</Col>
			</div>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deleteConfirmation)}
				onConfirm={confirmDelete}
				onDismiss={cancelDelete}
			></DeleteConfirmation>
			<AllInfoRequestsModal
				visible={allInfoRequestsModalVisible}
				onDismiss={() => setAllInfoRequestsModalVisible(false)}
				select={importQuestions}
			/>
			<UploadQuestionsList
				uploadModalVisible={showUploadInfoRequests}
				setUploadModalVisible={setShowUploadInfoRequests}
				loadQuestions={loadQuestions}
				n_questions={questions.length}
			/>
		</div>
	) : (
		<Row horizontalAlign="center">
			<LoadingBubble />
		</Row>
	);
}
