export interface IDealRoomCrawl {
	crawl_id: string;
	deal_id: string;
	host: string;
	teamCode: string;
	starting_url: string;
	lastModifiedTimestamp: number;
	createdTimestamp: number;
	crawl_status: CrawlStatus;
}

export enum CrawlStatus {
	InProgress = "in-progress",
	Finished = "finished"
}

export interface IDealRoomCrawlSearchResult {
	url: string;
	host: string;
	page_title: string;
	page_id: string;
	host_id: string;
	l1_content: string;
	discovered_urls: string[];
	enriched_at: number;
}

export interface IDealRoomCrawlSource {
	url: string;
	host: string;
	page_title: string;
	page_id: string;
	host_id: string;
	l1_content: string;
	discovered_urls: string[];
	enriched_at: number;
	element_id: string;
	source_id: string;
	relevance_score: number;
}
