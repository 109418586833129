import { IArticle } from "../../Components/Pages/News/Article/Article";
import { HttpService } from "../HttpService/HttpService";

export class NewsService {
	static SearchNews({ prompt }: { prompt: string }) {
		return HttpService.Get<IArticle[]>({
			uri: `/workspace-api-v2/news/vector?q=${prompt}`
		}).then((response) => response.payload);
	}

	static GetNews() {
		return HttpService.Get<IArticle[]>({
			uri: "/workspace-api-v2/news"
		}).then((response) => response.payload);
	}

	static GetCompany(company_id: string) {
		return HttpService.Get<IArticle[]>({
			uri: `/workspace-api-v2/news/company/${company_id}`
		}).then((response) => response.payload);
	}
}
