import { ITeam } from "@sage/types";
import { HttpService } from "../HttpService";

export class AdminService {
	static GetAllTeams() {
		return HttpService.Get<ITeam[]>({ path: "/_admin/teams" }).then((res) => res.payload);
	}

	static BootstrapTeam(body) {
		return HttpService.Post({ path: "/_admin/teams/bootstrap", body }).then((res) => res.statusCode === 200);
	}
}
