import { useDealRoomProject } from "@sage/state";
import { Bullet, Col, DateHeader, DeleteConfirmation, FaIcon, Pad, Row, SectionTitle, TimeHeader } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import "./DeleteModals.scss";

export function DeleteModals() {
	const { deleteElement, setDeleteElement, confirmDeleteElement, deletePage, setDeletePage, confirmDeletePage } = useDealRoomProject();

	return (
		<>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deleteElement)}
				onDismiss={() => setDeleteElement(null)}
				onConfirm={confirmDeleteElement}
			>
				<Pad>
					<div className="__sage-project-delete-candidate">
						<Col>
							<Row
								gap="1rem"
								wrap={false}
							>
								<FaIcon
									icon={"chart-line-up"}
									color={"#0051a8"}
									width="fit-content"
									size={"2x"}
									borderRadius={"0.5rem"}
									paddingInline="0.75rem"
									padding="0.5rem"
									border
									hideBg
									direction="row"
								/>
								<Col gap={"0.25rem"}>
									<SectionTitle
										inline
										caps
									>
										{deleteElement?.element_type}
									</SectionTitle>
									<Row
										verticalAlign="center"
										wrap
									>
										<DateHeader
											size="sm"
											thin
										>
											{deleteElement?.lastModifiedTimestamp}
										</DateHeader>
										<Bullet size="sm" />
										<TimeHeader size="sm">{deleteElement?.lastModifiedTimestamp}</TimeHeader>
									</Row>
								</Col>
							</Row>
						</Col>
					</div>
				</Pad>
			</DeleteConfirmation>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deletePage)}
				onDismiss={() => setDeletePage(null)}
				onConfirm={confirmDeletePage}
			>
				<Pad>
					<div className="__sage-project-delete-candidate">
						<Col>
							<Row
								gap="1rem"
								wrap={false}
							>
								<FaIcon
									icon={"file-chart-pie"}
									color={"#0051a8"}
									width="fit-content"
									size={"2x"}
									borderRadius={"0.5rem"}
									paddingInline="0.75rem"
									padding="0.5rem"
									border
									hideBg
									direction="row"
								/>
								<Col gap={"0.25rem"}>
									<SectionTitle inline>{deletePage?.page_name}</SectionTitle>
									<Row
										verticalAlign="center"
										wrap
									>
										<DateHeader
											size="sm"
											thin
										>
											{deletePage?.lastModifiedTimestamp}
										</DateHeader>
										<Bullet size="sm" />
										<TimeHeader size="sm">{deletePage?.lastModifiedTimestamp}</TimeHeader>
									</Row>
								</Col>
							</Row>
						</Col>
					</div>
				</Pad>
			</DeleteConfirmation>
		</>
	);
}
