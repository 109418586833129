import React, { useContext } from "react";
import { FsContext } from "@sage/state";
import { AreaChart, LineChart } from "@sage/shared/charts";
import { TeamDetails } from "@sage/shared/core";
import { Projects } from "@sage/shared/other";
import "./Recent.scss";

export function DocsRecent() {
	const fsState = useContext(FsContext);

	return (
		<div className={"__recent-page-container"}>
			<TeamDetails />
			<Projects
				title={"Recent Docs"}
				projects={fsState.recentProjects.projects}
				loading={fsState.recentProjects.loading}
			/>
		</div>
	);
}
