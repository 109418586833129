import { IDealRoomProjectPageTemplate } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetPageTemplates(): Promise<IDealRoomProjectPageTemplate[]> {
	return HttpService.Get<IDealRoomProjectPageTemplate[]>({ path: "/dealrooms/page-templates/team" }).then((res) => res.payload);
}

export function SavePageTemplate(body: Partial<IDealRoomProjectPageTemplate>): Promise<IDealRoomProjectPageTemplate> {
	return HttpService.Post<IDealRoomProjectPageTemplate>({ path: "/dealrooms/page-templates", body }).then((res) => res.payload);
}
