import { IElementComment, IPageComment, IProjectComment } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetCommentsByElement(element_id: string): Promise<IElementComment[]> {
	return HttpService.Get<IElementComment[]>({ path: `/dealrooms/comments/element/${element_id}` }).then((res) => res.payload);
}

export function SaveElementComment(comment: Partial<IElementComment>): Promise<IElementComment> {
	return HttpService.Post<IElementComment>({ path: `/dealrooms/comments/element`, body: comment }).then((res) => res.payload);
}

export function DeleteElementComment(comment_id: string) {
	return HttpService.Delete({ path: `/dealrooms/comments/element/${comment_id}` }).then((res) => res.payload);
}

export function GetCommentsByPage(page_id: string): Promise<IPageComment[]> {
	return HttpService.Get<IPageComment[]>({ path: `/dealrooms/comments/page/${page_id}` }).then((res) => res.payload);
}

export function SavePageComment(comment: Partial<IPageComment>): Promise<IPageComment> {
	return HttpService.Post<IPageComment>({ path: `/dealrooms/comments/page`, body: comment }).then((res) => res.payload);
}

export function DeletePageComment(page_id: string) {
	return HttpService.Delete({ path: `/dealrooms/comments/page/${page_id}` }).then((res) => res.payload);
}

export function GetCommentsByProject(project_id: string): Promise<IProjectComment[]> {
	return HttpService.Get<IProjectComment[]>({ path: `/dealrooms/comments/project/${project_id}` }).then((res) => res.payload);
}

export function SaveProjectComment(comment: Partial<IProjectComment>): Promise<IProjectComment> {
	return HttpService.Post<IProjectComment>({ path: `/dealrooms/comments/project`, body: comment }).then((res) => res.payload);
}

export function DeleteProjectComment(project_id: string) {
	return HttpService.Delete({ path: `/dealrooms/comments/project/${project_id}` }).then((res) => res.payload);
}
