import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Control } from "react-hook-form";
import { DeckProcessingStatus, IVaultFile, VaultFileType, VaultProcessingStatus } from "@sage/types";
import { DecksService, VaultService } from "@sage/services";
import { InlineLoading, Link, TableColumn, TableColumnSpacing, TableRow } from "@sage/shared/core";
import { Radio } from "@sage/shared/forms";
import { UserIcon } from "../../UserIcon";
import { UserTag } from "../../UserTag";
import { VaultFSIcon, VaultIconName } from "../VaultIcon";

export interface IVaultFileProps {
	file: IVaultFile;
	control?: Control;
	onClick?: (file: IVaultFile) => void;
	selection?: ReactNode;
	variant?: VaultFileVariant;
}

export enum VaultFileVariant {
	Full = "full",
	Abrev = "abrev"
}

export function VaultFile({ file, control, onClick, selection, variant }: IVaultFileProps) {
	const [status, setStatus] = useState(null);
	const mountRef = useRef(true);

	async function checkStatus() {
		if (file && mountRef.current) {
			if (file?.file_type !== VaultFileType.DOC && file?.file_type !== VaultFileType.DECK) {
				const response = await VaultService.GetFileProcessingStatus(file.file_id);

				const processing_status = response?.processing_status;

				if (processing_status === VaultProcessingStatus.InProgress) {
					setTimeout(() => {
						checkStatus();
					}, 1500);
				} else {
					await VaultService.UpdateProcessingStatus({ file_id: file.file_id, processing_status });
				}

				setStatus(processing_status);
			} else if (file?.file_type === VaultFileType.DECK) {
				const { processing_status } = await DecksService.getProcessingStatus(file.sage_filetype_id);

				if (processing_status === DeckProcessingStatus.InProgress) {
					setTimeout(() => {
						checkStatus();
					}, 1500);
				}

				setStatus(processing_status);
			}
		}
	}

	function getIcon() {
		switch (file?.file_type) {
			case VaultFileType.FOLDER:
				return VaultIconName.Folder;
			case VaultFileType.DOC:
				return VaultIconName.Docs;
			case VaultFileType.DECK:
				return VaultIconName.Decks;
			case VaultFileType.HTML:
				return VaultIconName.Html;
			case VaultFileType.PDF:
				return VaultIconName.Pdf;
			case VaultFileType.EXCEL:
				return VaultIconName.Excel;
			case VaultFileType.POWERPOINT:
				return VaultIconName.Powerpoint;
			case VaultFileType.WORD:
				return VaultIconName.Word;
			default:
				return VaultIconName.Source;
		}
	}

	function handleClick() {
		if (onClick) onClick(file);
	}

	useEffect(() => {
		checkStatus();
	}, [file?.file_id]);

	useEffect(() => {
		return () => {
			mountRef.current = false;
		};
	}, []);

	return (
		file && (
			<TableRow>
				{selection && <TableColumn width={"0"}>{selection}</TableColumn>}
				{control && (
					<TableColumn width={"0"}>
						<Radio
							id={file.file_id}
							name={"SelectedFile"}
							control={control}
							testid="vault-file-radio"
						/>
					</TableColumn>
				)}
				<TableColumn
					icon={<VaultFSIcon iconName={getIcon()} />}
					onClick={handleClick}
				>
					<Link>{file.file_name}</Link>
				</TableColumn>
				<TableColumn width={"0"}>
					{status === VaultProcessingStatus.InProgress ? (
						<InlineLoading invert />
					) : status === VaultProcessingStatus.Failed ? (
						<span
							className="processing-status failed"
							title="We weren't able to process this file. You may want to try again."
						>
							Failed
						</span>
					) : status === VaultProcessingStatus.Finished ? (
						<span data-testid="vault-file-ready"></span>
					) : null}
				</TableColumn>
				{variant !== VaultFileVariant.Abrev && (
					<>
						<TableColumn
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							<UserIcon
								user_id={file.created_by}
								size={"sm"}
							/>
							<UserTag
								user_id={file.created_by}
								transparent
							/>
						</TableColumn>
						<TableColumn
							relativeDate
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							{file.lastModifiedTimestamp}
						</TableColumn>
						<TableColumn
							bytes
							spacing={TableColumnSpacing.Fixed}
							width={"4rem"}
						>
							{file.size}
						</TableColumn>
					</>
				)}
			</TableRow>
		)
	);
}
