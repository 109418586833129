import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { Button, ButtonBorderShape } from "@sage/shared/core";
import { TemplateAutosuggest } from "@sage/shared/other";
import "./SageTemplate.scss";

export function SageTemplate() {
	const { updatePageStatus, form } = useWorkflowState();
	const template = useWatch({ control: form.control, name: "template_id" });
	const navigate = useNavigate();

	function handleNavAway() {
		if (template) {
			updatePageStatus("complete", "Templates", "Use a Sage Template");
		} else {
			updatePageStatus("skip", "Templates", "Use a Sage Template");
		}
	}

	function handleNav() {
		handleNavAway();
		navigate("../data-sources");
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Templates", "Use a Sage Template");

		return handleNavAway;
	}, []);

	return (
		<section>
			<h2>Use a Sage Template</h2>
			<TemplateAutosuggest
				control={form.control}
				setValue={form.setValue}
			/>
			<Button
				borderShape={ButtonBorderShape.Round}
				action={handleNav}
				disabled={template === undefined}
			>
				Next
			</Button>
		</section>
	);
}
