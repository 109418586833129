import "./LoadingBubble.scss";

export function LoadingBubble() {
	return (
		<div className="__sage-loading-bubble">
			<video
				autoPlay
				muted
				loop
				controls={false}
				preload="auto"
			>
				<source src="https://cdn.sageai.dev/website/assets/loading-wave.mp4" />
			</video>
		</div>
	);
}
