export const ChartInstructions = `
# Chart Generation Instructions

When creating charts, use the following format to ensure consistency and accuracy:

\`\`\`json
<chart>
{
    "title": "My Chart Title",  // Optional. Example: "Revenue by Quarter"
    
    // Required. Specify one of these exact chart types:
    "type": "BarChart" | "StackedBarChart" | "WaterfallChart" | "AreaChart" | "LineChart" | "PieChart" | "ScatterPlot" | "BubblePlot",
    
    // Required. Array of data points
    "data": [
        {
            // Required. The category name (for Pie charts) or x-axis label
            "category": "Example Category",
            
            // Required. The numeric value to plot
            "value": 1000,
            
            // Optional. Custom label for the value
            // Examples: "1000MM", "£1.2Mn", "55%"
            "value_label": "1000MM",
            
            // Required for all except Pie charts & waterfall charts
            // Format: "YYYY-MM-DD" or "YYYY-MM"
            "date": "2024-01",

            // Optional. For charts like a bubble plot that need a secondary value
            // Like bubble size or circle radius.
            "secondary_value": 20

            // Note: Color is not supported here.
        }
    ],

    // Optional. Array of data points for trendline series [on Bar Charts only!]
    "trendline": [
        {
            // Required. The category name (for Pie charts) or x-axis label
            "category": String,
            
            // Required. The numeric value to plot
            "value": Number
            
            // Optional. Custom label for the value
            // Examples: "1000MM", "£1.2Mn", "55%"
            "value_label": String
            
            // Required for all except Pie charts & waterfall charts
            // Format: "YYYY-MM-DD" or "YYYY-MM"
            "date": "2024-01-01",

            // Note: Color is not supported here.
        }
    ],

    // Optional. Custom colors for categories, if you don't set these colors, it will fall back to the default pallete.
    "colors": {
        "Category1": "#FF0000",
        "Category2": "#00FF00"
    }
}
</chart>
\`\`\`

## Important Rules

1. The \`type\` field must be exactly one of: "BarChart", "StackedBarChart", "WaterfallChart", "AreaChart", "LineChart", "PieChart", "ScatterPlot" or "BubblePlot"
2. Date field:
   - Required for Bar, Area, Line, Scatter and Bubble charts
   - Must NOT be included for Pie charts or Waterfall Charts
   - Use ISO format: "YYYY-MM-DD" or "YYYY-MM"
3. Colors must be valid HTML hex color codes
4. All numeric values must be numbers, not strings
5. Dual-axis charts are not allowed
6. You must not use "in thousands" or "in millions" return the numbers directly, they will be rounded and given units automatically.
7. You must use full values for chart data! Do not preemptively round to thousands!
8. BarCharts support a "trendline" series that will overlay a line chart, can be used for growth rates or margins for example.


## Examples

### Line Chart Example
\`\`\`json
<chart>
{
    "title": "Monthly Revenue",
    "type": "LineChart",
    "data": [
        {
            "category": "Revenue",
            "value": 1000000,
            "value_label": "1000MM",
            "date": "2024-01"
        },
        {
            "category": "Revenue",
            "value": 1200000,
            "value_label": "1200MM",
            "date": "2024-02"
        }
    ]
}
</chart>
\`\`\`

### Pie Chart Example
\`\`\`json
<chart>
{
    "title": "Revenue by Product",
    "type": "PieChart",
    "data": [
        {
            "category": "Product A",
            "value": 30,
            "value_label": "30%"
        },
        {
            "category": "Product B",
            "value": 70,
            "value_label": "70%"
        }
    ],
    "colors": { // Important that you pay attention to how colors are set here.
        "Product A": "#FF0000",
        "Product B": "#00FF00"
    }
}
</chart>
\`\`\`

## Footnotes
- Add footnotes outside the chart using <footnote> tags
- Number footnotes sequentially starting from 1
- Place all footnotes after the chart 

### Example with Footnotes

<footnote number="1">Includes recurring and one-time revenue</footnote>
<footnote number="2">Calculated as (Revenue - COGS) / Revenue</footnote>

## Flags
- Purpose: Internal data quality control and workflow management
- Audience: Data analysts, report preparers, internal team
- format: <flag number="1">...details</flag>
- Use for:
  - Missing data that needs to be uploaded
  - Data sources requiring validation
  - Incomplete datasets needing additional input
  - Values requiring correction or verification
  - Highlighting data quality issues to be resolved
- Should be resolved before client delivery
- If you are asked to do something you are unable to do because of missing data, clearly highlight it in a flag. DO NOT ADD IN ASSUMPTIONS. Leave the field blank and flag it for users.

### Example with Flags

<flag number="1">Revenue was missing for 2024</flag>


If you are asked to do something you are unable to do because of missing data, clearly highlight it in a flag. DO NOT ADD IN ASSUMPTIONS. Leave the field blank and flag it for users.

## Explainations

If you are asked for an accompanying explaination, put it into a <para></para> element after the chart.

## Titles

If you are asked to add a section title (not a chart title), put it into a a <para></para> element before the chart using a ### header.

for example
\`\`\`
<para>### Revenue Growth Analysis</para>
<chart>
...
</chart>
\`\`\`

`;
