import { Tabs } from "@sage/shared/other";
import { useDecksState } from "../../DecksState";
import { Athena, ProjectFiles, ReferenceSlides, Template, Vault } from "../Panes";
import { ActiveTab, ToolsPaneContext, createToolsPaneState } from "../ToolsPaneState";
import "./ToolsPane.scss";

export function ToolsPane() {
	const { setToolsPaneWidth, toolsPaneWidth, toolsPaneResizing, toolsPaneResizeHandler } = useDecksState();
	const toolsPaneState = createToolsPaneState();

	function animate(end) {
		const start = toolsPaneWidth;
		const duration = 100;
		const framerate = 60;
		const n_steps = (framerate / 1000) * duration;
		const step_length = duration / n_steps;

		const direction = end > start ? 1 : -1;

		const distance = Math.max(end, start) - Math.min(end, start);
		const step_size = distance / n_steps;

		let idx = 0;
		let interval;

		function next() {
			idx++;

			setToolsPaneWidth(start + direction * idx * step_size);

			if (idx >= n_steps) {
				clearInterval(interval);
			}
		}

		interval = setInterval(next, step_length);
	}

	function handlePaneClick() {
		if (toolsPaneWidth <= 32) {
			animate(340);
		}
	}

	return (
		<ToolsPaneContext.Provider value={toolsPaneState}>
			<div
				className={`__tools-pane-container ${toolsPaneResizing ? "resize-active" : ""}`}
				style={{ width: `${toolsPaneWidth}px` }}
			>
				<div
					className={`tools-pane-content ${toolsPaneWidth > 32 ? "visible" : ""}`}
					onMouseDown={handlePaneClick}
				>
					<Tabs
						visible={toolsPaneWidth > 225}
						collapsed={toolsPaneWidth < 500}
						tabs={[
							{
								shortName: "T",
								fullName: "Templates",
								id: ActiveTab.Template
							}
						]}
						activeTab={toolsPaneState.activeTab}
						setActiveTab={toolsPaneState.setActiveTab}
					/>
					<button
						className={`collapse-pane`}
						onMouseDown={() => animate(32)}
					>
						<img
							alt="img-icon"
							src={`https://cdn.ccm.vc/sage/icons/material-arrow-left.svg`}
						/>
					</button>
					{toolsPaneWidth >= 340 && (
						<div className="pane-detail-section">
							<Template />
							<Vault />
							<ReferenceSlides />
							<ProjectFiles />
							<Athena />
						</div>
					)}
					<div
						className={`tools-pane-resize-handle`}
						onMouseDown={toolsPaneResizeHandler}
					/>
				</div>
			</div>
		</ToolsPaneContext.Provider>
	);
}
