export async function gzip(obj: any) {
	const jsonString = JSON.stringify(obj);
	const stream = new Blob([jsonString]).stream();
	const compressedStream = stream.pipeThrough(new CompressionStream("gzip"));
	const compressedBlob = await new Response(compressedStream).blob();
	const compressedArrayBuffer = await compressedBlob.arrayBuffer();
	return btoa(String.fromCharCode.apply(null, new Uint8Array(compressedArrayBuffer)));
}

export async function gunzip(gz) {
	if (gz === null) return {};

	const binaryString = atob(gz);
	const data = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		data[i] = binaryString.charCodeAt(i);
	}
	const stream = new Blob([data]).stream();
	const decompressedStream = stream.pipeThrough(new DecompressionStream("gzip"));
	const decompressedBlob = await new Response(decompressedStream).blob();
	const jsonString = await decompressedBlob.text();
	return JSON.parse(jsonString);
}
