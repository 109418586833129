import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { Chart, format_tick, format_value, useDims } from "../ChartProvider";
import "./WaterfallChart.scss";

export function WaterfallChart({
	title,
	data,
	colors
}: {
	title?: string;
	data: { category: string; value: number; value_label?: string; date: Date; date_label?: string }[];
	colors?: { [key: string]: string };
}) {
	const c = useMemo(
		() => d3.scaleOrdinal().domain(["Increase", "Decrease", "Remaining"]).range(["#007932", "#cc3433", "#007acc"]),
		[data]
	);

	return (
		<Chart title={title}>
			<Series
				data={data}
				c={c}
			/>
		</Chart>
	);
}

function Series({ data, c }) {
	const dims = useDims();
	const containerRef = useRef(null);
	const seriesRef = useRef(null);
	const labelsRef = useRef(null);
	const xAxisRef = useRef(null);
	const xGridRef = useRef(null);
	const yAxisRef = useRef(null);
	const yGridRef = useRef(null);

	const bridgedData = useMemo(() => {
		const bridged_data = [];
		let cumulative = 0;
		let idx = 0;

		for (let d of data) {
			idx++;
			if (idx === data.length) {
				bridged_data.push({ ...d, start: cumulative, end: 0, type: "Remaining" });
			} else {
				bridged_data.push({ ...d, start: cumulative, end: cumulative + d.value, type: d.value > 0 ? "Increase" : "Decrease" });
				cumulative = cumulative + d.value;
			}
		}
		console.log({ bridged_data });
		return bridged_data;
	}, [data]);

	const x = useMemo(
		() =>
			d3
				.scaleBand()
				.domain(new Set(data.map((d) => d.category)))
				.rangeRound([dims.marginLeft, dims.width - dims.marginRight])
				.paddingInner(0.15),
		[data, dims]
	);

	const y = useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([d3.min(bridgedData, (d) => Math.min(d.start, d.end)), d3.max(bridgedData, (d) => Math.max(d.start, d.end))])
				.range([dims.height - dims.marginBottom, dims.marginTop]),
		[bridgedData, dims]
	);

	useEffect(() => {
		if ((seriesRef.current, containerRef.current && x && y && c && bridgedData)) {
			d3.select(seriesRef.current)
				.selectAll("rect")
				.data(bridgedData)
				.join("rect")
				.attr("x", (d) => x(d.category))
				.attr("y", (d) => y(Math.max(d.start, d.end)))
				.attr("width", x.bandwidth())
				.attr("height", (d) => Math.abs(y(d.end) - y(d.start)))
				.attr("fill", (d) => c(d.type))
				.attr("rx", x.bandwidth() / 10)
				.attr("ry", x.bandwidth() / 10);

			d3.select(labelsRef.current)
				.selectAll("text")
				.data(bridgedData)
				.join("text")
				.attr("text-anchor", "middle")
				.attr("font-size", "0.85rem")
				.attr("x", (d) => x(d.category) + x.bandwidth() / 2)
				.attr("y", (d) => Math.min(y(d.end), y(d.start)))
				.attr("dy", "-0.35rem")
				.text((d) => d.value_label || format_value(d.value))
				.attr("fill", "black")
				.attr("font-weight", "500");

			d3.select(xAxisRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(d3.axisBottom(x));

			d3.select(yAxisRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(d3.axisLeft(y).ticks(6).tickFormat(format_tick));

			d3.select(yGridRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(
					d3
						.axisLeft(y)
						.tickSize(-(dims.width - dims.marginRight - dims.marginLeft))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);

			d3.select(xGridRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3
						.axisBottom(x)
						.tickSize(-(dims.height - dims.marginTop - dims.marginBottom))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);
		}
	}, [labelsRef.current, seriesRef.current, containerRef.current, x, y, c, dims, bridgedData]);

	return (
		<svg
			ref={containerRef}
			width={"100%"}
			height={dims.height}
		>
			<g
				className="rotate-ticks"
				ref={xAxisRef}
			/>
			<g ref={yAxisRef} />
			<g ref={yGridRef} />
			<g ref={xGridRef} />
			<g ref={seriesRef} />
			<g ref={labelsRef} />
		</svg>
	);
}
