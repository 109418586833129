import { DealRoomProvider } from "@sage/state";
import { Col, Instructions, P, SectionTitle, SplitPane } from "@sage/shared/core";
import { AddWebData } from "../AddWebData";
import { BrowseTemplatesModal } from "../BrowseTemplatesModal";
import { CreateElementModal } from "../CreateElementModal";
import { DealPhaseWindow } from "../DealPhaseWindow";
import { DealRoomThreads } from "../DealRoomThreads";
import { DealWebData } from "../DealWebData";
import { DeleteModals } from "../DeleteModals";
import { Header } from "../Header";
import { UpdateElementModal } from "../UpdateElementModal";
import { UploadFilesModal } from "../UploadFilesModal";
import { ViewDealSourcesModal } from "../ViewDealSources";
import "./DealRoomHome.scss";

export function DealRoomHome() {
	return (
		<DealRoomProvider>
			<Header />
			<div className="__sage-deal-room-home-container">
				<SplitPane defaultSplit={415 / document.body.clientWidth}>
					<DealPhaseWindow />
					<DealRoomThreads />
				</SplitPane>
			</div>
			<UploadFilesModal />
			<CreateElementModal />
			<BrowseTemplatesModal />
			<UpdateElementModal />
			<DeleteModals />
			<ViewDealSourcesModal />
			<AddWebData />
			<DealWebData />
		</DealRoomProvider>
	);
}
