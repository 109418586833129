import { useState } from "react";
import { useForm } from "react-hook-form";
import { DealstreamService } from "@sage/services";
import { Button, CopySection, PageHeader, Row, TeamDetails } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";

export function AddCompanyPage() {
	const form = useForm({ defaultValues: { name: "", legal_name: undefined, canonical_url: "https://" } });
	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);

	async function handleSubmit(formValue) {
		setLoading(true);

		if (!formValue.canonical_url.includes(".")) {
			form.setError("canonical_url", {
				type: "custom",
				message: "It looks like you're missing a top level domain. For example:\n - .com\n - .net\n - .org\n - .co.uk"
			});
			return;
		}

		const company = {
			...formValue,
			legal_name: formValue.legal_name?.length > 0 ? formValue.legal_name : undefined
		};

		const response = await DealstreamService.CreateCompany(company);
		if (response.statusCode === 200) {
			await DealstreamService.RequestDeepCrawl(response.payload.uid, formValue.canonical_url);
			setLoading(false);
			setSuccess(true);
		} else {
			form.setError("name", {
				type: "custom",
				message: "There was an error adding this company!\nPlease wait a moment and try again, or contact us and we can help."
			});
		}
	}

	function handleUrlChange(url: string) {
		if (url.length === 0) {
			return url;
		}

		const allowed_prefixes = ["http://", "https://"];

		let input = `${url}`;
		let lastMatch = null;
		let lastMatchingIdx = 0;
		let globalAllowed = false;

		for (let i = 0; i < Math.min(...allowed_prefixes.map((p) => p.length), input.length); i++) {
			const input_substring = input.substring(0, i + 1);
			let isAllowed = false;
			for (let prefix of allowed_prefixes) {
				const prefix_substring = prefix.substring(0, i + 1);
				if (input_substring === prefix_substring) {
					isAllowed = true;
					globalAllowed = true;
					lastMatch = prefix;
					lastMatchingIdx = i;
				}
			}
			if (!isAllowed && lastMatch) {
				return `${lastMatch}${input.substring(lastMatchingIdx + 1)}`;
			}
		}

		if (!globalAllowed) {
			return `https://${input}`;
		}

		return input;
	}

	if (success) {
		return (
			<section>
				<TeamDetails />
				<CopySection>
					<h2>Company Successfully Created</h2>
					<p>We are going to deep crawl them now. You will be able to use their public data soon.</p>
				</CopySection>
			</section>
		);
	}
	return (
		<section>
			<TeamDetails />
			<PageHeader title={"Add a Company"} />
			<Form
				handleSubmit={form.handleSubmit(handleSubmit)}
				fields={[
					{
						control: form.control,
						name: "name",
						label: "Company Name",
						description: "(required)",
						variant: FormFieldVariant.Vertical,
						placeholder: "Company Name",
						required: true
					},
					{
						control: form.control,
						name: "legal_name",
						label: "Legal Name",
						description: "(optional)",
						variant: FormFieldVariant.Vertical,
						placeholder: "Legal Name"
					},
					{
						control: form.control,
						name: "canonical_url",
						label: "Company Website",
						description:
							"(required)\nThis is required for us to web scrape this company to provide you with useful data.\nPlease double check that this url is correct so we can give you accurate web scrape data.",
						variant: FormFieldVariant.Vertical,
						placeholder: "e.g. https://sageai.dev",
						required: true,
						directive: handleUrlChange
					}
				]}
			/>
			<Row>
				<Button
					action={form.handleSubmit(handleSubmit)}
					loading={loading}
				>
					Create
				</Button>
			</Row>
			<CopySection>
				<h2>Data Use Disclosure</h2>
				<p>When you add a new company to Sage's database, it will be available to search for other users.</p>
				<br />
				<p>
					<strong>
						There will <u>not</u> be an indication to other users that you are working with this company.
					</strong>
				</p>
				<br />
				<p>There will not be an indication to other users that any investment bank is working with this company.</p>
				<br />
				<p>
					This company will appear to users exactly like any other company on the Sage platform. Sage does not use your deal
					information for our Acquisition database. We only add an acquisition when the announcement appears on the public web
					sources that we scrape, regardless of whether or not one of our users is involved.
				</p>
				<br />
				<p>
					<strong>
						The only data that will be used for this public profile <u>from you</u> are the three form fields above.
					</strong>{" "}
					We do not use Data Room information, or AI generated content based on data room information for these public profiles.
				</p>
			</CopySection>
		</section>
	);
}
