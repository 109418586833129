import React, { useEffect, useState } from "react";
import { ICompanyCBD } from "@sage/types";
import { DealstreamService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonVariant, Card, CardTitle, InlineLoading, P } from "@sage/shared/core";
import "./Crawl.scss";

enum CrawlStatus {
	NotFound,
	Finished,
	InProgress,
	Loading
}

export function Crawl({ company, refresh }: { company: ICompanyCBD; refresh?: () => void }) {
	const [crawlStatus, setCrawlStatus] = useState<CrawlStatus>(CrawlStatus.Loading);
	const [actionLoading, setActionLoading] = useState<boolean>(false);

	async function updateCrawlStatus() {
		let crawlStatusResponse = await DealstreamService.GetCrawlStatus(company.uid);

		if (crawlStatusResponse.is_crawling) {
			setCrawlStatus(CrawlStatus.InProgress);
			setTimeout(() => {
				updateCrawlStatus();
			}, 3000);
		} else if (crawlStatusResponse.last_crawled) {
			if (crawlStatus === CrawlStatus.InProgress && refresh) {
				refresh();
			}
			setCrawlStatus(CrawlStatus.Finished);
		} else {
			setCrawlStatus(CrawlStatus.NotFound);
		}
	}

	async function requestCrawl() {
		setActionLoading(true);
		await DealstreamService.RequestDeepCrawl(company.uid, company.links.homepage);
		setActionLoading(false);
		updateCrawlStatus();
	}

	useEffect(() => {
		updateCrawlStatus();
	}, [company.links.homepage]);

	switch (crawlStatus) {
		case CrawlStatus.NotFound:
			return (
				<Card>
					<CardTitle>Sage Deep Crawl</CardTitle>
					<P>Looks like this company hasn't been crawled yet!</P>
					<P>The crawl will take between 5 and 10 minutes depending on the size of the domain.</P>
					<P>Once it's done, you'll be able to use it as context when chatting with Athena,</P>
					<P>and you can request a new one whenever you need to refresh the content.</P>
					<Button
						variant={ButtonVariant.Primary}
						borderShape={ButtonBorderShape.Round}
						action={requestCrawl}
						loading={actionLoading}
						disabled={actionLoading}
					>
						Request Crawl
					</Button>
				</Card>
			);
		case CrawlStatus.Finished:
			return (
				<Card>
					<CardTitle>Sage Deep Crawl</CardTitle>
					<P>This company was last crawled on {new Date(company.last_crawled).toLocaleDateString()}</P>
					<P>If you think the information may be out of date, feel free to request another crawl.</P>
					<P>The crawl will take between 5 and 10 minutes depending on the size of the domain.</P>
					<Button
						variant={ButtonVariant.Primary}
						borderShape={ButtonBorderShape.Round}
						action={requestCrawl}
						loading={actionLoading}
						disabled={actionLoading}
					>
						Request Crawl
					</Button>
				</Card>
			);
		case CrawlStatus.Loading:
			return (
				<Card>
					<CardTitle>Sage Deep Crawl</CardTitle>
					<InlineLoading invert />
				</Card>
			);
		case CrawlStatus.InProgress:
			return (
				<Card>
					<CardTitle>Sage Deep Crawl</CardTitle>
					<P>Crawl in Progress...</P>
				</Card>
			);
	}
}
