import React from "react";
import { AuthContext } from "@sage/state";
import "./TeamDetails.scss";

export function TeamDetails() {
	const authState = React.useContext(AuthContext);

	return (
		<div className={"__team-details-container"}>
			{authState.loading ? (
				"loading"
			) : (
				<div className={"team-details-wrapper"}>
					<h3 className={"team-details"}>
						{authState.team.teamName} / {authState.user.email}
					</h3>
				</div>
			)}
		</div>
	);
}
