import { IDealRoomProject } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetProjects(phase_id: string): Promise<IDealRoomProject[]> {
	return HttpService.Get<IDealRoomProject[]>({ path: `/dealrooms/projects/phase/${phase_id}` }).then((res) => res.payload);
}

export function GetProject(project_id: string): Promise<IDealRoomProject> {
	return HttpService.Get<IDealRoomProject>({ path: `/dealrooms/projects/${project_id}` }).then((res) => res.payload);
}

export function SaveProject(project: Partial<IDealRoomProject>): Promise<IDealRoomProject> {
	return HttpService.Post<IDealRoomProject>({ path: "/dealrooms/projects", body: project }).then((res) => res.payload);
}

export function DeleteProject(project_id: string) {
	return HttpService.Delete({ path: `/dealrooms/projects/${project_id}` }).then((res) => res.payload);
}

export function UpdateProjectName(project_id: string, project_name: string) {
	return HttpService.Post({ path: `/dealrooms/projects/${project_id}/name`, body: { project_name } });
}

export function UpdatePrimaryColor(project_id: string, primary_color: string) {
	return HttpService.Post({ path: `/dealrooms/projects/${project_id}/primary-color`, body: { primary_color } });
}

export function UpdateSecondaryColor(project_id: string, secondary_color: string) {
	return HttpService.Post({ path: `/dealrooms/projects/${project_id}/secondary-color`, body: { secondary_color } });
}

export function UpdateFontColor(project_id: string, font_color: string) {
	return HttpService.Post({ path: `/dealrooms/projects/${project_id}/font-color`, body: { font_color } });
}

export function UpdateChartColors(project_id: string, chart_colors: string) {
	return HttpService.Post({ path: `/dealrooms/projects/${project_id}/chart-colors`, body: { chart_colors } });
}

export async function UploadLogo({ project_id, file, onProgress }) {
	const presignedPost = await HttpService.Post({
		path: `/dealrooms/projects/${project_id}/upload-logo`,
		body: { content_type: file.type }
	});

	const form = new FormData();

	Object.keys(presignedPost.payload.fields).forEach((key) => {
		form.append(key, presignedPost.payload.fields[key]);
	});

	form.append("file", file);

	await UploadFile(presignedPost.payload.url, form, onProgress);
}

function UploadFile(url: string, request, onProgress?: (progress: number) => void): Promise<void> {
	return new Promise((resolve) => {
		const xhr = new XMLHttpRequest();

		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentComplete = (event.loaded / event.total) * 100;
				onProgress(percentComplete);
			}
		};

		xhr.onload = () => {
			resolve();
		};

		xhr.open("POST", url, true);

		xhr.send(request);
	});
}

export function DownloadLogo(project_id: string): Promise<string> {
	return HttpService.Get<{ url: string }>({ path: `/dealrooms/projects/${project_id}/download-logo` }).then((res) => res.payload.url);
}

export async function UploadWatermark({ project_id, file, onProgress }) {
	const presignedPost = await HttpService.Post({
		path: `/dealrooms/projects/${project_id}/upload-watermark`,
		body: { content_type: file.type }
	});

	const form = new FormData();

	Object.keys(presignedPost.payload.fields).forEach((key) => {
		form.append(key, presignedPost.payload.fields[key]);
	});

	form.append("file", file);

	await UploadFile(presignedPost.payload.url, form, onProgress);
}

export function DownloadWatermark(project_id: string): Promise<string> {
	return HttpService.Get<{ url: string }>({ path: `/dealrooms/projects/${project_id}/download-watermark` }).then(
		(res) => res.payload.url
	);
}
