import { DealStatus, IDeal } from "@sage/types";
import { HttpService } from "../HttpService/HttpService";

export class DealsService {
	static GetDealsByStatus = ({ status }: { status?: DealStatus }): Promise<IDeal[]> => {
		return HttpService.Get<IDeal[]>({
			uri: `/workspace-api-v2/deals/status/${status}`
		}).then((response) => response.payload);
	};

	static SearchDeals({ prompt, teamCode }: { prompt: string; teamCode: string }): Promise<IDeal[]> {
		return HttpService.Post<IDeal[]>({
			uri: "/workspace-api-v2/deals/search",
			body: { prompt, teamCode }
		}).then((response) => response.payload);
	}

	static CreateDeal = (body: Partial<IDeal>) => {
		return HttpService.Post({
			uri: "/workspace-api-v2/deals",
			body
		}).then((response) => response.payload);
	};

	static UpdateDeal = (body: Partial<IDeal>) => {
		return HttpService.Post({
			uri: "/workspace-api-v2/deals",
			body
		}).then((response) => response.payload);
	};

	static DeleteDeal = (deal_id: string) => {
		return HttpService.Delete({
			uri: `/workspace-api-v2/deals/${deal_id}`
		}).then((response) => response.payload);
	};
}
