import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useOutletContext } from "react-router-dom";
import { Actor, Citation, IMessage } from "@sage/types";
import { AuthContext, WorkspaceContext } from "@sage/state";
import { ILlmSource, LlmService } from "@sage/services";
import { Chat } from "@sage/shared/chat";
import { Uuid } from "@sage/utils";
import { usePrompts } from "@sage/prompts";
import { FloatingPane } from "../FloatingPane/FloatingPane";
import "./AskSage.scss";

dayjs.extend(relativeTime);

export function AskSage({ onDismiss }) {
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	const authState = useContext(AuthContext);

	const [thread, setThread] = useState<string>(null);
	const [messages, setMessages] = useState<IMessage[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
	const [inProgress, setInProgress] = useState<string>(null);

	const prompts = usePrompts();

	function clearConversation() {
		setThread(null);
		setMessages([]);
	}

	function getSources() {
		const activeCompanies = workspaceState.activeCompanies;
		const companies = workspaceState.project.companies;
		const activeSources = workspaceState.activeSources.filter((e) => !!e).map((file) => file.prefix);
		const sources = workspaceState.project.sources.filter((e) => !!e).map((file) => file.prefix);

		let sourcesToUse = [];
		let companiesToUse = [];

		if (activeCompanies.length) {
			companiesToUse = activeCompanies;
		} else if (activeSources.length === 0) {
			companiesToUse = companies;
		}

		if (activeSources.length) {
			sourcesToUse = activeSources;
		} else if (activeCompanies.length === 0) {
			sourcesToUse = sources;
		}

		return { sourceFiles: sourcesToUse, companies: companiesToUse };
	}

	async function stream(message: string) {
		const preprompt = prompts.userDefault();

		const { sourceFiles, companies } = getSources();

		let dataSources: ILlmSource[] = [];

		if (sourceFiles.length) {
			dataSources = [
				{
					knn: true,
					index: "vault_data_v2",
					pretty: true,
					teamCode: authState.user.teamCode,
					prefix: sourceFiles,
					label: "Document Data:",
					target_fields: ["file_name", "file_id", "page_content"]
				}
			];
		} else {
			dataSources = [];
		}

		let companySources: ILlmSource[] = [];

		if (companies.length) {
			companySources = [
				{
					knn: false,
					index: "companies",
					pretty: true,
					teamCode: authState.user.teamCode,
					uids: companies,
					label: "Company Data:",
					target_fields: ["name", "deep_crawl_result"]
				},
				...companies.map((company_id) => ({
					knn: true,
					index: "company_pages",
					company_id,
					label: "Web Pages:",
					target_fields: ["l1_content", "url"],
					k: dataSources.length ? 5 : 10,
					size: dataSources.length ? 5 : 10
				}))
			];
		} else {
			companySources = [];
		}

		const request = {
			prompt: message,
			preprompt: preprompt,
			sources: [...dataSources, ...companySources]
		};

		const { generated_text, sources } = await LlmService.Stream(request, setInProgress);

		return { generated_text, sources };
	}

	async function send(message: string) {
		setMessages((m) => [
			...m,
			{
				message_id: Uuid.Nano(),
				actor: authState.user.user_id,
				actorType: Actor.User,
				timestamp: new Date().getTime(),
				text: message,
				sources: []
			}
		]);

		setLoading(true);

		const res = await stream(message);

		setLoading(false);
		setInProgress(null);
		setMessages((m) => [
			...m,
			{
				message_id: Uuid.Nano(),
				actor: "Athena",
				actorType: Actor.Assistant,
				timestamp: new Date().getTime(),
				text: res.generated_text,
				sources: res.sources as unknown as Citation[]
			}
		]);
	}

	if (workspaceState.openTools.includes("ASK_SAGE"))
		return (
			<FloatingPane
				title={"Athena"}
				resizeDirection={"left"}
			>
				<Chat
					messages={messages}
					inProgressMessage={inProgress}
					send={send}
					loading={loading}
				/>
			</FloatingPane>
		);
	return null;
}
