import React, { ReactNode, useEffect, useRef } from "react";
import { ButtonIcon } from "../Button";
import { InlineLoading } from "../InlineLoading";
import "./DropdownMenu.scss";

export interface IDropdownOptionProps {
	text?: string | ReactNode;
	type?: "option" | "divider";
	icon?: ButtonIcon;
	iconSlot?: ReactNode;
	action?: () => void;
	disabled?: boolean;
	loading?: boolean;
	testid?: string;
	visible?: boolean;
}

interface IDropdownMenuProps {
	options: IDropdownOptionProps[];
	dropdownPosition?: "left" | "right";
	onBlur?: () => void;
	visible?: boolean;
}

export function DropdownMenu({ options, dropdownPosition, onBlur, visible }: IDropdownMenuProps) {
	const dropdownRef = useRef(null);

	function handleClick(e, option) {
		if (option.disabled !== true) {
			e.stopPropagation();
			if (option.action) {
				option.action();
			}
		}
	}

	useEffect(() => {
		if (dropdownRef.current) {
			dropdownRef.current?.focus();
		}
	}, [dropdownRef.current]);

	return (
		<div
			ref={dropdownRef}
			tabIndex={0}
			className={`__dropdown-menu-container ${dropdownPosition || "right"} ${visible ? "open" : "closed"}`}
			onBlur={onBlur}
		>
			{options?.map((option, idx) =>
				option.type === "divider" ? (
					<div
						key={idx}
						className={"divider"}
					/>
				) : (
					<div
						key={idx}
						className={`dropdown-option ${option.disabled ? "disabled" : ""}`}
						onClick={(e) => handleClick(e, option)}
						data-testid={option.testid}
					>
						{option.iconSlot}
						{option.icon ? (
							<img
								alt={"dropdown icon"}
								src={`https://cdn.ccm.vc/sage/icons/${option.icon}.svg`}
								width={"16px"}
							/>
						) : null}
						{option.loading ? <InlineLoading invert /> : <div>{option.text}</div>}
					</div>
				)
			)}
		</div>
	);
}
