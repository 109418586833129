import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IVaultFile, VaultFileType } from "@sage/types";
import { AuthContext } from "@sage/state";
import { DecksService, VaultService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { VaultFSIcon, VaultIconName, VaultImagePreview } from "@sage/shared/other";
import { useDecksState } from "../../../DecksState";
import { ActiveTab, useToolsPaneState } from "../../ToolsPaneState";
import "./Vault.scss";

export function Vault() {
	const authState = useContext(AuthContext);
	const { vaultParentFolder, setVaultParentFolder, addSourceFiles, addReferenceDecks } = useDecksState();
	const { activeTab } = useToolsPaneState();
	const selectedFilesForm = useForm();
	const [files, setFiles] = useState<IVaultFile[]>([]);

	function calculatePosition() {
		switch (activeTab) {
			case ActiveTab.Template:
				return "200%";
			case ActiveTab.ProjectFiles:
				return "100%";
			case ActiveTab.Vault:
				return "0%";
			case ActiveTab.ReferenceSlides:
				return "-100%";
			case ActiveTab.Athena:
				return "-200%";
		}
	}

	async function loadVaultFolder() {
		if (vaultParentFolder) {
			const loadedFiles = await VaultService.LoadDirectory(vaultParentFolder.file_id);
			setFiles(loadedFiles.filter((f) => f.file_type !== VaultFileType.DOC));
		} else {
			const loadedFiles = await VaultService.LoadDirectory(authState.user.teamCode);
			setFiles(loadedFiles.filter((f) => f.file_type !== VaultFileType.DOC));
		}
	}

	async function navBack() {
		setVaultParentFolder(await VaultService.LoadFile(vaultParentFolder.parent_folder_id));
	}

	async function addSources() {
		const formValue = selectedFilesForm.getValues();
		const sourceFiles = files.filter((f) => formValue[f.file_id]);

		const sources = sourceFiles.filter((f) => f.file_type !== VaultFileType.DECK);
		const referenceDecks = sourceFiles.filter((f) => f.file_type === VaultFileType.DECK);

		addSourceFiles(sources);
		addReferenceDecks(await Promise.all(referenceDecks.map((file) => DecksService.getDeckByIdShort(file.sage_filetype_id))));

		selectedFilesForm.reset({});
	}

	useEffect(() => {
		loadVaultFolder();
	}, [vaultParentFolder]);

	return (
		<div
			className={`__decks-tools-pane-vault-container tab`}
			style={{ left: calculatePosition() }}
		>
			<div className="header">
				{vaultParentFolder && (
					<Button
						icon={ButtonIcon.MaterialArrowLeft}
						variant={ButtonVariant.IconSecondarySM}
						action={navBack}
					/>
				)}
				{vaultParentFolder?.file_name || "Team Files"}
			</div>
			<div className="files">
				{files.map((file) => (
					<div
						className={"file"}
						key={file.file_id}
						onClick={() => setVaultParentFolder(file)}
					>
						<Checkbox
							control={selectedFilesForm.control}
							name={file.file_id}
							defaultValue={false}
						/>
						{file.file_type === VaultFileType.IMAGE ? (
							<VaultImagePreview file={file} />
						) : (
							<VaultFSIcon
								iconName={
									file.file_type === VaultFileType.FOLDER
										? VaultIconName.Folder
										: file.file_type === VaultFileType.DOC
											? VaultIconName.Docs
											: file.file_type === VaultFileType.DECK
												? VaultIconName.Decks
												: file.file_type === VaultFileType.HTML
													? VaultIconName.Html
													: VaultIconName.Source
								}
							/>
						)}
						<div className={"file-name"}>{file.file_name}</div>
					</div>
				))}
			</div>
			<div className="vault-actions">
				<Button
					action={addSources}
					borderShape={ButtonBorderShape.Round}
					children="Add"
				/>
			</div>
		</div>
	);
}
