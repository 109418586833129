import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "@sage/state";
import { VaultService } from "@sage/services";
import { LoadingBar } from "@sage/shared/core";
import { VaultFSIcon } from "@sage/shared/other";
import "./DragUpload.scss";

export function DragUpload({
	parent_folder_id,
	destination,
	onUpload
}: {
	parent_folder_id?: string;
	destination?: string;
	onUpload?: (file_id: string) => void;
}) {
	const fileInputRef = useRef(null);
	const [files, setFiles] = useState([]);

	function dropHandler(e) {
		e.preventDefault();

		if (e.dataTransfer.items) {
			[...e.dataTransfer.items].forEach((item, i) => {
				if (item.kind === "file") {
					const file = item.getAsFile();
					setFiles((f) => [...f, file]);
				}
			});
		} else {
			[...e.dataTransfer.files].forEach((file, i) => {
				setFiles((f) => [...f, file]);
			});
		}
	}

	function formInputHandler(e) {
		setFiles((f) => [...f, ...e.target.files]);
	}

	function clickHandler() {
		fileInputRef.current.click();
	}

	return (
		<div
			className="__sage-drag-upload-container"
			onDrop={dropHandler}
			onDragOver={(e) => e.preventDefault()}
		>
			{files.map((file) => (
				<PendingFile
					file={file}
					destination={destination}
					parent_folder_id={parent_folder_id}
					onUpload={onUpload}
				/>
			))}
			{files.length === 0 ? (
				<div
					className="upload-btn"
					onClick={clickHandler}
				>
					Upload
				</div>
			) : null}
			<input
				className={"hidden-input"}
				type={"file"}
				ref={fileInputRef}
				onChange={formInputHandler}
				data-testid="file-form-control"
			/>
		</div>
	);
}

function PendingFile({ file, parent_folder_id, destination, onUpload }) {
	const authState = useContext(AuthContext);

	const [progress, setProgress] = useState<number>(0);

	async function uploadFile() {
		const vaultFile = await VaultService.CreateSource({
			teamCode: authState.user.teamCode,
			created_by: authState.user.user_id,
			parent_folder_id: parent_folder_id || authState.user.teamCode,
			prefix: destination || `/${authState.user.teamCode}`,
			description: "",
			file_name: file.name,
			mime_type: file.type,
			size: file.size,
			file: file,
			original_filename: file.file_name,
			progress: setProgress
		});
		if (onUpload) onUpload(vaultFile.file_id);
	}

	useEffect(() => {
		uploadFile();
	}, [file]);

	return (
		<div className="__sage-pending-file-upload">
			<VaultFSIcon
				fileType={VaultService.MimeToFileType(file.type)}
				size={48}
			/>
			{file.name}
			<LoadingBar progress={progress} />
		</div>
	);
}
