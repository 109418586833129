import { IDealRoomMessage, IDealRoomThread } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetThreadsByPhase(phase_id: string): Promise<IDealRoomThread[]> {
	return HttpService.Get<IDealRoomThread[]>({ path: `/dealrooms/threads/phase/${phase_id}` }).then((res) => res.payload);
}

export function GetThreadsByDeal(deal_id: string): Promise<IDealRoomThread[]> {
	return HttpService.Get<IDealRoomThread[]>({ path: `/dealrooms/threads/deal/${deal_id}` }).then((res) => res.payload);
}

export function GetThread(thread_id: string): Promise<IDealRoomThread> {
	return HttpService.Get<IDealRoomThread>({ path: `/dealrooms/threads/messages/${thread_id}` }).then((res) => res.payload);
}

export function SaveDealRoomThread(thread: Partial<IDealRoomThread>): Promise<IDealRoomThread> {
	return HttpService.Post<IDealRoomThread>({ path: "/dealrooms/threads", body: thread }).then((res) => res.payload);
}

export function DeletePhaseThread(thread_id: string) {
	return HttpService.Delete({ path: `/dealrooms/threads/${thread_id}` }).then((res) => res.payload);
}

export function RemoveThreadFromPhase(thread_id: string) {
	return HttpService.Delete({ path: `/dealrooms/threads/${thread_id}/phase` }).then((res) => res.payload);
}

export function DeleteDealThread(thread_id: string) {
	return HttpService.Delete({ path: `/dealrooms/threads/${thread_id}` }).then((res) => res.payload);
}

export function AddThreadToPhase(thread_id: string, phase_id: string) {
	return HttpService.Post({ path: `/dealrooms/threads/${thread_id}/phase/${phase_id}` }).then((res) => res.payload);
}

export function UpdateThreadName(thread_id: string, thread_name: string) {
	return HttpService.Post({ path: `/dealrooms/threads/${thread_id}/name`, body: { thread_name } }).then((res) => res.payload);
}

export function SaveMessage(message: Partial<IDealRoomMessage>): Promise<IDealRoomMessage> {
	return HttpService.Post<IDealRoomMessage>({ path: `/dealrooms/messages`, body: message }).then((res) => res.payload);
}

export function GetMessagesByThread(thread_id: string): Promise<IDealRoomMessage[]> {
	return HttpService.Get<IDealRoomMessage[]>({ path: `/dealrooms/messages/thread/${thread_id}` }).then((res) => res.payload);
}
