import React from "react";
import { NavLink } from "react-router-dom";
import { DropdownMenu, IDropdownOptionProps } from "../DropdownMenu";
import { InlineLoading } from "../InlineLoading";
import "./Button.scss";

export enum ButtonVariant {
	Primary = "primary",
	Secondary = "secondary",
	Tertiary = "tertiary",
	Icon = "icon",
	IconSecondary = "icon-secondary",
	IconSM = "icon-sm",
	IconSecondarySM = "icon-secondary-sm",
	Nav = "nav",
	Error = "error"
}

export enum ButtonIcon {
	Plus = "plus_white",
	ArrowRight = "arrow_right_white",
	MaterialShare = "material-share",
	MaterialShareWhite = "material-share-white",
	MaterialUpload = "material-upload",
	MaterialRename = "material-rename",
	MaterialPrint = "material-print",
	MaterialExport = "material-export",
	MaterialCopy = "material-copy",
	MaterialCheckWhite = "material-check-white",
	MaterialOpen = "material-open",
	MaterialNew = "material-new",
	MaterialStar = "material-star",
	MaterialStarFilled = "material-star-filled",
	MaterialSave = "material-save",
	MaterialReset = "material-reset",
	MaterialEditDocument = "material-edit-document",
	MaterialAssistant = "material-assistant",
	MaterialBolt = "material-bolt",
	MaterialAudio = "material-audio",
	MaterialAudioWhite = "material-audio-white",
	MaterialNewTab = "material-new-tab",
	MaterialLightbulb = "material-lightbulb",
	MaterialApps = "material-apps",
	MaterialArrowLeft = "material-arrow-left",
	MaterialAdd = "material-add",
	MaterialAddWhite = "material-add-white",
	MaterialRemove = "material-remove",
	MaterialClose = "material-close",
	MaterialTune = "material-tune",
	MaterialSearchWhite = "material-search-white",
	MaterialSearch = "material-search",
	Data = "material-data",
	Sec = "sec",
	Bls = "bls",
	SageLogo = "logo-cropped",
	Document = "document-new",
	Deck = "decks-new",
	Vault = "vault-blue",
	Folder = "folder_color",
	Source = "new-source",
	Template = "new-template",
	IntelligentTemplates = "intelligent-templates"
}

export enum ButtonBorderShape {
	Round = "round",
	Default = "default",
	Sharp = "sharp"
}

export interface IButtonProps {
	children?: any;
	variant?: ButtonVariant;
	to?: string;
	action?: (e?) => void;
	icon?: ButtonIcon;
	iconSize?: number | string;
	external?: boolean;
	loading?: boolean;
	borderShape?: ButtonBorderShape;
	disabled?: boolean;
	options?: IDropdownOptionProps[];
	dropdownPosition?: "left" | "right";
	testid?: string;
	width?: string;
}

export function Button({
	children,
	variant,
	to,
	action,
	icon,
	iconSize,
	external,
	loading,
	borderShape,
	disabled,
	options,
	dropdownPosition,
	testid,
	width
}: IButtonProps) {
	const [dropdownVisible, setDropdownVisible] = React.useState(false);

	function getIcon() {
		if (icon) {
			return (
				<img
					alt="img-icon"
					src={`https://cdn.ccm.vc/sage/icons/${icon}.svg`}
					width={`${iconSize || 16}px`}
				/>
			);
		} else {
			return null;
		}
	}

	function handleClick(e) {
		if (action) {
			action(e);
		} else if (options) {
			setDropdownVisible((e) => !e);
		}
	}

	if (to) {
		return (
			<NavLink
				className={({ isActive, isPending }) =>
					`__button-container ${variant || ButtonVariant.Primary} ${borderShape || "default"} ${isPending ? "pending" : isActive ? "active" : ""}`
				}
				to={to}
				target={external ? "_blank" : ""}
				data-testid={testid}
			>
				{children}
				{getIcon()}
			</NavLink>
		);
	} else {
		return (
			<div
				className={"__button-outer"}
				style={{ width }}
			>
				<button
					className={`__button-container ${variant || ButtonVariant.Primary} ${borderShape || "default"} ${disabled ? "disabled" : ""}`}
					style={{ width }}
					onClick={handleClick}
					disabled={loading || disabled}
					data-testid={testid}
				>
					{loading ? (
						<InlineLoading
							invert={
								variant === ButtonVariant.Secondary ||
								variant === ButtonVariant.IconSecondary ||
								variant === ButtonVariant.IconSecondarySM
							}
						/>
					) : (
						<>
							{children}
							{getIcon()}
						</>
					)}
				</button>
				<DropdownMenu
					options={options}
					dropdownPosition={dropdownPosition}
					onBlur={() => setDropdownVisible(false)}
					visible={options && dropdownVisible}
				/>
			</div>
		);
	}
}
