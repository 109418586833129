import React from "react";
import { Toggle } from "@sage/shared/forms";
import { DecksSelectMode, useDecksState } from "../../DecksState";
import "./EditorTools.scss";

export function EditorTools() {
	const { editorSettings, setEditorSettings } = useDecksState();

	return (
		<div className="__editor-tools-container">
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-mouse-black.svg"}
				tooltip={"Default Mode (Esc)"}
				active={editorSettings.selectMode === DecksSelectMode.Default}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Default }))}
			/>
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-eyeball-black.svg"}
				tooltip={"View Only Mode (Ctrl+o)"}
				active={editorSettings.selectMode === DecksSelectMode.View}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.View }))}
			/>
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-pencil-black.svg"}
				tooltip={"Edit Text Mode (Ctrl+e)"}
				active={editorSettings.selectMode === DecksSelectMode.Write}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Write, canEdit: true }))}
			/>
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-remove.svg"}
				tooltip={"Delete Mode (Ctrl+d)"}
				active={editorSettings.selectMode === DecksSelectMode.Delete}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Delete }))}
			/>
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-copy.svg"}
				tooltip={"Copy Mode (Ctrl+c)"}
				active={editorSettings.selectMode === DecksSelectMode.Copy}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Copy }))}
			/>
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-title-black.svg"}
				tooltip={"Create Headers (Ctrl+h)"}
				active={editorSettings.selectMode === DecksSelectMode.Header}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Header }))}
			/>
			<SelectTool
				icon={"https://cdn.ccm.vc/sage/icons/material-list-black.svg"}
				tooltip={"Create Sections (Ctrl+g)"}
				active={editorSettings.selectMode === DecksSelectMode.Section}
				update={() => setEditorSettings((s) => ({ ...s, selectMode: DecksSelectMode.Section }))}
			/>
			<ToolbarDivider />
			<ToggleTool
				icon={"https://cdn.ccm.vc/sage/icons/material-edit-document.svg"}
				option={editorSettings.canEdit}
				update={(e) =>
					setEditorSettings((s) => ({
						...s,
						canEdit: e,
						selectMode: s.selectMode !== DecksSelectMode.Write ? s.selectMode : DecksSelectMode.Default
					}))
				}
			/>
			<ToolbarSpacer />
			<ToggleTool
				icon={"https://cdn.ccm.vc/sage/icons/material-palette-black.svg"}
				option={editorSettings.colorful}
				update={(e) => setEditorSettings((s) => ({ ...s, colorful: e }))}
			/>
		</div>
	);
}

function ToggleTool({ icon, option, update }: { icon?: string; option: boolean; update: (e: boolean) => void }) {
	return (
		<div className="editor-tools-tool-item">
			<Toggle
				active={option}
				update={(e) => update(e)}
				toggle={() => update(!option)}
			/>
			{icon && (
				<img
					src={icon}
					className="tool-icon"
				/>
			)}
		</div>
	);
}

function SelectTool({ icon, active, update, tooltip }: { tooltip?: string; icon: string; active: boolean; update: () => void }) {
	return (
		<div
			className={`editor-tools-tool-item clickable ${active ? "active" : ""}`}
			onMouseDown={update}
			title={tooltip}
		>
			<img
				src={icon}
				className="tool-icon sm"
			/>
		</div>
	);
}

function ToolbarDivider() {
	return <div className="toolbar-divider" />;
}

function ToolbarSpacer() {
	return <div className="toolbar-spacer" />;
}
