import { IBaseProps } from "./Base";
import { IDealRoomProjectPage } from "./Page";

export interface IDealRoomProject extends IBaseProps {
	project_id: string;
	project_name: string;
	phase_id: string;
	user_id: string;
	project_status: ProjectStatus;
	project_type: ProjectType;
	primary_color: string;
	secondary_color: string;
	font_color: string;
	chart_colors: string[];
	pages: IDealRoomProjectPage[];
}

export enum ProjectStatus {
	Finished = "finished",
	InProgress = "in-progress",
	None = "none"
}

export enum ProjectType {
	CIM = "cim",
	Teaser = "teaser",
	BakeOff = "bake-off",
	Memo = "memo",
	MP = "mp",
	Other = "other"
}
