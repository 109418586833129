import React from "react";
import { IArticle } from "@sage/types";
import "./ArticleWithImage.scss";

const publisher_img_map = {
	"PR Newswire": "https://www.prnewswire.com/content/dam/prnewswire/icons/2019-Q4-PRN-Icon-32-32.png",
	"Canada Newswire": "https://www.prnewswire.com/content/dam/prnewswire/icons/2019-Q4-PRN-Icon-32-32.png",
	"Businesswire": "https://www.businesswire.com/favicon.ico",
	"ft": "https://www.ft.com/__origami/service/image/v2/images/raw/ftlogo-v1%3Abrand-ft-logo-square-coloured?source=update-logos&format=svg",
	"MarketWatch": "https://mw4.wsj.net/mw5/content/logos/favicon.ico",
	"Investors Business Daily": "https://www.investors.com/wp-content/uploads/2023/10/ibd-favicon-20231010.png",
	"The Street": "https://aap.thestreet.com/favicon.ico",
	"Zacks Commentary": "https://staticx.zacks.com/images/zacks/logos/favicon_1x_16x16.ico",
	"Benzinga": "https://www.benzinga.com/next-assets/images/favicon-32x32.png",
	"Reuters": "https://www.reuters.com/pf/resources/images/reuters/favicon/tr_fvcn_kinesis_32x32.ico?d=160",
	"cnbc": "https://www.cnbc.com/favicon.ico",
	"Business Insider": "https://markets.businessinsider.com/favicon-32x32.png",
	"Motley Fool": "https://g.foolcdn.com/misc-assets/logo-tmf-primary-favicon.ico",
	"forbes": "https://i.forbesimg.com/48X48-F.png",
	"wsj": "https://s.wsj.net/img/meta/wsj_favicon.svg"
};

export function ArticleWithImage({ article }: { article: IArticle }) {
	function htmlDecode(input: string) {
		let doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
	}

	return (
		<div
			className="__news-article-image-container"
			style={{ backgroundImage: `url('${article.image}')` }}
		>
			<div className="bg-overlay" />
			<div className="article-details">
				<a
					className={"headline"}
					target={"_blank"}
					href={article.target?.article_url || article.article_id}
				>
					{htmlDecode(article.headline)}
				</a>
				<div className="publisher">
					<img
						className="publisher-img"
						src={publisher_img_map[article.publisher]}
					/>
					<p className="publisher-name">{article.publisher}</p>
				</div>
			</div>
		</div>
	);
}
