import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IVaultFile } from "@sage/types";
import { AuthContext } from "@sage/state";
import { ProjectService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant, InlineLoading, Modal } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import "./GenerateTemplate.scss";

export interface IGenerateTemplateProps {
	visible: boolean;
	onDismiss: () => void;
	selectedFile: string;
	files: IVaultFile[];
}

export function GenerateTemplate({ visible, onDismiss, selectedFile, files }: IGenerateTemplateProps) {
	const authState = useContext(AuthContext);

	const [inProgress, setInProgress] = useState(false);
	const [finished, setFinished] = useState(false);
	const [status, setStatus] = useState(false);

	const form = useForm();
	const templateName = useWatch({
		control: form.control,
		name: "templateName"
	});

	async function generate() {
		setInProgress(true);
		setFinished(false);
		setStatus(false);
		try {
			await ProjectService.GenerateTemplate({
				templateName,
				teamCode: authState.user.teamCode,
				user_id: authState.user.user_id,
				file_id: selectedFile
			});

			setStatus(true);
			setFinished(true);
		} catch (e) {
			setStatus(false);
			setFinished(true);
		}
	}

	function close() {
		form.reset({});
		setStatus(false);
		setInProgress(false);
		setFinished(false);
		onDismiss();
	}

	useEffect(() => {
		if (selectedFile) {
			const file = files.find((file) => file.file_id === selectedFile);
			form.setValue("templateName", file.file_name);
		}
	}, [selectedFile]);

	if (finished) {
		return (
			<Modal
				visible={visible}
				onDismiss={onDismiss}
				title={status ? "Your Template is Ready" : "An Error Occoured"}
				actions={[
					{
						children: "Close",
						action: close,
						variant: ButtonVariant.Secondary,
						borderShape: ButtonBorderShape.Round,
						testid: "vault-generate-template-close"
					},
					status === false ? (
						{
							children: "Try Again",
							action: generate,
							variant: ButtonVariant.Primary,
							borderShape: ButtonBorderShape.Round,
							testid: "vault-generate-template-try-again"
						}
					) : (
						<span></span>
					)
				]}
			>
				{status ? (
					<p>Your template is ready, you can now use it in docs</p>
				) : (
					<p> There was a problem generating your template, you can try again.</p>
				)}
			</Modal>
		);
	}

	if (inProgress) {
		return (
			<Modal
				visible={visible}
				onDismiss={onDismiss}
				title="Template Generating..."
				actions={[
					{
						children: "Cancel",
						action: close,
						variant: ButtonVariant.Error,
						borderShape: ButtonBorderShape.Round,
						testid: "vault-generate-template-cancel"
					}
				]}
			>
				<div className="__generate-template-loading">
					<InlineLoading invert />
				</div>
			</Modal>
		);
	}

	return (
		<Modal
			visible={visible}
			onDismiss={onDismiss}
			title="Generate a Template"
			subtitle={
				<div className="__generate-template-subtitle">
					Generate a template using this document. We will read the document, split it into sections that make sense, and create
					LLM prompts that can be used to produce the same document type but using different sources in Docs.
					<br />
					<br />
					Try to limit the size of this document. You will tend to get better results if you compose your final deliverable with
					multiple templates, one for each section.
					<br />
					<br />
					It might make sense to split a final deliverable into multiple files, and generate a template for each using this
					method. That way you can have more fine grain control during generation.
				</div>
			}
			actions={[
				{
					children: "Close",
					variant: ButtonVariant.Secondary,
					borderShape: ButtonBorderShape.Round,
					action: onDismiss,
					testid: "vault-generate-template-close"
				},
				{
					children: "Generate",
					variant: ButtonVariant.Primary,
					borderShape: ButtonBorderShape.Round,
					action: generate,
					testid: "vault-generate-template-generate"
				}
			]}
		>
			<Form
				fields={[
					{
						control: form.control,
						label: "Template Name",
						name: "templateName",
						placeholder: "Template Name",
						variant: FormFieldVariant.Vertical
					}
				]}
			/>
		</Modal>
	);
}
