import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function ConfirmationPage() {
	const navigate = useNavigate();

	return (
		<Form
			title={"Password Successfully Reset"}
			description="Your password has been successfully reset. Please login to continue."
			fields={[]}
			actions={[
				{
					variant: ButtonVariant.Primary,
					children: "Login",
					action: () => navigate("/app/welcome/login"),
					borderShape: ButtonBorderShape.Round
				}
			]}
		/>
	);
}
