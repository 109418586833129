import { AuthService } from "../AuthService/AuthService";
import { Credentials } from "../Credentials/Credentials";

export class HttpService {
	static async FormatRequestPayload({
		path,
		useProd,
		uri,
		query
	}: {
		path?: string;
		useProd?: boolean;
		uri?: string;
		query?: { [key: string]: string | number | boolean };
	}) {
		let baseUrl;

		if (path) {
			baseUrl = "/workspace-api-v2";

			if (path.startsWith("/")) {
				path = path.substring(1);
			}
		} else {
			path = null;
			if (uri) {
				baseUrl = uri;
			}
		}

		let queryString = "";
		if (query) {
			queryString = Object.entries(query)
				.map(([k, v]) => `${k}=${v}`)
				.join("&");
			if (queryString.length) {
				queryString = `?${queryString}`;
			}
		}

		await AuthService.Authenticate();

		const token = Credentials.RetrieveToken();

		return {
			path,
			baseUrl,
			token,
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "4LT6AZToEJ3dGIvqkqao44frHjZarqJF607yETcl",
				"x-sage-authorization": token && token["AccessToken"],
				"x-sage-identity": token && token["IdToken"]
			},
			queryString,
			query
		};
	}

	static async UpdateCredentialsEncryptionKey(token: any) {
		const enc_key_res = await fetch(`/workspace-api-v2/users/key`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "4LT6AZToEJ3dGIvqkqao44frHjZarqJF607yETcl",
				"x-sage-authorization": token && token["AccessToken"],
				"x-sage-identity": token && token["IdToken"]
			}
		});

		const enc_key_json = await enc_key_res.json();
		Credentials.StoreEncryptionKey(enc_key_json.key);
	}

	static async Get<T>({
		path,
		query,
		useProd,
		uri
	}: {
		path?: string;
		query?: { [key: string]: string | boolean | number };
		useProd?: boolean;
		uri?: string;
	}): Promise<{ payload?: T; statusCode?: number }> {
		const requestPayload = await HttpService.FormatRequestPayload({
			path,
			useProd,
			uri,
			query
		});

		let request_uri = requestPayload.baseUrl;

		if (requestPayload.path) {
			request_uri = `${request_uri}/${requestPayload.path}`;
		}

		const res = await fetch(`${request_uri}${requestPayload.queryString}`, {
			method: "GET",
			headers: requestPayload.headers
		});

		try {
			return { payload: await res.json(), statusCode: res.status };
		} catch (e) {
			return { payload: null, statusCode: res.status };
		}
	}

	static async Delete<T>({
		path,
		query,
		useProd,
		uri
	}: {
		path?: string;
		query?: { [key: string]: string | boolean | number };
		useProd?: boolean;
		uri?: string;
	}): Promise<{ payload?: T; statusCode?: number }> {
		const requestPayload = await HttpService.FormatRequestPayload({
			path,
			useProd,
			uri,
			query
		});

		let request_uri = requestPayload.baseUrl;

		if (requestPayload.path) {
			request_uri = `${request_uri}/${requestPayload.path}`;
		}

		const res = await fetch(`${request_uri}${requestPayload.queryString}`, {
			method: "DELETE",
			headers: requestPayload.headers
		});
		try {
			return { payload: await res.json(), statusCode: res.status };
		} catch (e) {
			return { payload: null, statusCode: res.status };
		}
	}

	static async Post<T>({
		path,
		body,
		useProd,
		uri
	}: {
		path?: string;
		body?: any;
		useProd?: boolean;
		uri?: string;
	}): Promise<{ payload?: T; statusCode?: number }> {
		const requestPayload = await HttpService.FormatRequestPayload({
			path,
			useProd,
			uri
		});

		let request_uri = requestPayload.baseUrl;

		if (requestPayload.path) {
			request_uri = `${request_uri}/${requestPayload.path}`;
		}

		const res = await fetch(`${request_uri}`, {
			method: "POST",
			headers: requestPayload.headers,
			body: JSON.stringify(body)
		});

		try {
			return { payload: await res.json(), statusCode: res.status };
		} catch (e) {
			return { payload: null, statusCode: res.status };
		}
	}

	static async Put<T>({
		path,
		body,
		useProd,
		uri
	}: {
		path?: string;
		body?: any;
		useProd?: boolean;
		uri?: string;
	}): Promise<{ payload?: T; statusCode?: number }> {
		const requestPayload = await HttpService.FormatRequestPayload({
			path,
			useProd,
			uri
		});

		let request_uri = requestPayload.baseUrl;

		if (requestPayload.path) {
			request_uri = `${request_uri}/${requestPayload.path}`;
		}

		const res = await fetch(`${request_uri}`, {
			method: "PUT",
			headers: requestPayload.headers,
			body: JSON.stringify(body)
		});

		try {
			return { payload: await res.json(), statusCode: res.status };
		} catch (e) {
			return { payload: null, statusCode: res.status };
		}
	}
}
