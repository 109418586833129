import React from "react";
import { ITag } from "@sage/types";
import "./DealTags.scss";

export function DealTags({ tags }: { tags: ITag[] }) {
	return (
		<div className={"__deal-tags-container"}>
			{tags.map((tag, idx) => (
				<div
					className={`tag ${tag.color}`}
					key={idx}
				>
					{tag.name}
				</div>
			))}
		</div>
	);
}
