import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { QuestionStatus } from "@sage/types";
import { useAuthState } from "@sage/state";
import { DealRoomService, LlmService } from "@sage/services";
import { Button, ButtonVariant, Col, Collapsible, FaIcon, HidePrint, Pad, Row, SearchResult } from "@sage/shared/core";
import { SourceCrawl, SourceFile } from "@sage/shared/dealrooms";
import { UserIcon } from "@sage/shared/other";
import { useDebounce } from "@sage/utils";
import { usePrompts } from "@sage/prompts";
import "./Question.scss";

export function Question({ question, crawls, remove }) {
	const authState = useAuthState();
	const params = useParams();
	const [expanded, setExpanded] = useState<boolean>(false);
	const [seeMoreSources, setSeeMoreSources] = useState<boolean>(false);
	const [editing, setEditing] = useState<boolean>(false);
	const [questionName, setQuestionName] = useState<string>(question.question);
	const [questionStatus, setQuestionStatus] = useState<QuestionStatus>(question.status);
	const [answer, setAnswer] = useState<string>(question.answer);
	const [answerSource, setAnswerSource] = useState<string>(question.answer_source);
	const [showStatusOptions, setShowStatusOptions] = useState<boolean>(false);
	const [editingAnswer, setEditingAnswer] = useState<boolean>(false);
	const [sources, setSources] = useState([]);
	const prompts = usePrompts();
	const questionNameRef = useRef(null);
	const triesRef = useRef(null);
	triesRef.current = 0;
	const generatingRef = useRef(null);
	generatingRef.current = false;
	const customAnswerRef = useRef(null);

	function editAnswer() {
		setEditingAnswer(true);
		setExpanded(true);
		setTimeout(() => {
			customAnswerRef.current.focus();
		}, 0);
	}

	async function deleteQuestion() {
		await DealRoomService.DeleteQuestion(question.question_id);
	}

	async function saveQuestionName(newName: string) {
		setQuestionName(newName);
		await DealRoomService.UpdateInfoRequestQuestionName(question.question_id, newName);
	}

	async function saveCustomAnswer() {
		const newAnswer = customAnswerRef.current.outerText;
		if (newAnswer !== answer) {
			const newAnswerSource = `${authState.user.firstName} ${authState.user.lastName}`;
			setAnswer(newAnswer);
			setAnswerSource(newAnswerSource);
			setQuestionStatus(QuestionStatus.Answered);
			await DealRoomService.UpdateInfoRequestQuestionAnswer(question.question_id, {
				answer: newAnswer,
				answer_source: newAnswerSource,
				status: QuestionStatus.Answered
			});
		}
		setEditingAnswer(false);
	}

	const saveQuestionNameDebounced = useDebounce(saveQuestionName, 1000);

	async function updateQuestionName(newName: string) {
		setQuestionName(newName);
		saveQuestionNameDebounced(newName);
	}

	async function loadQuestionSources() {
		setSources([
			...(await DealRoomService.GetSourcesByElement(question.question_id)),
			...(await DealRoomService.GetCrawlSources(question.question_id))
		]);
	}

	useEffect(() => {
		if (editing) {
			setTimeout(() => {
				questionNameRef.current.focus();
			}, 0);
		}
	}, [editing]);

	useEffect(() => {
		if (question.question_id) {
			loadQuestionSources();
		}
	}, [question.question_id]);

	async function updateQuestionStatus(status: QuestionStatus) {
		setQuestionStatus(status);
		await DealRoomService.UpdateInfoRequestQuestionStatus(question.question_id, status);
		setShowStatusOptions(false);
	}

	function generateText(request, cb): Promise<string> {
		if (triesRef.current < 20) {
			triesRef.current = triesRef.current + 1;
			return new Promise(async (resolve) => {
				try {
					const { generated_text } = await LlmService.Stream(request, cb);

					if (generated_text.length === 0) {
						setTimeout(async () => {
							resolve(await generateText(request, cb));
						}, 2000);
					} else {
						resolve(generated_text);
					}
				} catch (e) {
					setTimeout(async () => {
						resolve(await generateText(request, cb));
					}, 2000);
				}
			});
		} else {
			return new Promise((resolve) => resolve('<flag number="1">An Error ocoured, please try again</flag>'));
		}
	}

	async function generateAnswer() {
		setExpanded(true);
		generatingRef.current = true;

		const results = (
			await Promise.all([
				DealRoomService.SearchSources(params.deal_id, questionName, question.question_id),
				...crawls.map((crawl) => DealRoomService.SearchWebData(crawl.host, questionName))
			])
		).flat();

		const searchResultsMap = {};

		for (let result of results.flat()) {
			if ("chunk_id" in result) {
				if (result.chunk_id in searchResultsMap) {
					searchResultsMap[result.chunk_id] = {
						...searchResultsMap[result.chunk_id],
						relevance_score: Math.max(searchResultsMap[result.chunk_id].relevance_score, result.relevance_score),
						fragment_text: [...new Set([...result.fragment_text, ...searchResultsMap[result.chunk_id].fragment_text])]
					};
				} else {
					searchResultsMap[result.chunk_id] = result;
				}
			} else {
				searchResultsMap[result.page_id] = result;
			}
		}

		const searchResults = Object.values(searchResultsMap)
			.sort((a, b) => b.relevance_score - a.relevance_score)
			.slice(0, 5);

		setAnswerSource("Sage");
		const generated_text = await generateText(
			{
				prompt: questionName,
				context: JSON.stringify(searchResults),
				preprompt: prompts.infoRequest()
			},
			setAnswer
		);
		setAnswer(generated_text);
		const answer_status = generated_text.toLowerCase().includes("this information is unavailable")
			? QuestionStatus.UnableToAnswer
			: QuestionStatus.Answered;
		setQuestionStatus(answer_status);
		await DealRoomService.UpdateInfoRequestQuestionAnswer(question.question_id, {
			answer: generated_text,
			answer_source: "Sage",
			status: answer_status
		});
		if (answer_status === QuestionStatus.Answered) {
			const sourceList = await Promise.all(
				searchResults.map((result) => {
					if ("chunk_id" in result) {
						return DealRoomService.CreateElementSource(result);
					} else {
						return DealRoomService.CreateCrawlSource({ ...result, element_id: question.question_id });
					}
				})
			);
			setSources(sourceList);
		}
		generatingRef.current = false;
	}

	function moveUp() {}
	function moveDown() {}

	return (
		<div
			className={`__sage-dealroom-info-request-question ${questionStatus === "answered" ? "green" : questionStatus === "unable-to-answer" ? "red" : "blue"}`}
		>
			<Col>
				<Row
					horizontalAlign="between"
					verticalAlign="top"
				>
					<div className="question-number">
						{question.question_index < 9 ? "0" : ""}
						{question.question_index + 1}
					</div>
					{editing ? (
						<input
							className="question-title-editing"
							value={questionName}
							onChange={(e) => updateQuestionName(e.target.value)}
							ref={questionNameRef}
							onBlur={() => setEditing(false)}
						/>
					) : (
						<div
							className="question-title"
							onMouseDown={() => setEditing(true)}
						>
							{questionName}
						</div>
					)}
					<div
						className={`status ${questionStatus === "answered" ? "green" : questionStatus === "unable-to-answer" ? "red" : "blue"}`}
					>
						<FaIcon
							icon={
								questionStatus === "answered"
									? "check"
									: questionStatus === "unable-to-answer"
										? "xmark-large"
										: "circle-notch"
							}
							color="#FFFFFF"
							size="xl"
							secondaryOpacity="1"
							hideBg
							onClick={() => setShowStatusOptions((e) => !e)}
						/>
					</div>
					<div className={`update-status-options ${showStatusOptions ? "open" : "closed"}`}>
						<Row wrap={false}>
							<div className={"status green"}>
								<FaIcon
									icon={"check"}
									color="#FFFFFF"
									size="xl"
									secondaryOpacity="1"
									hideBg
									onClick={() => updateQuestionStatus(QuestionStatus.Answered)}
								/>
							</div>
							<div className={"status red"}>
								<FaIcon
									icon={"xmark-large"}
									color="#FFFFFF"
									size="xl"
									secondaryOpacity="1"
									hideBg
									onClick={() => updateQuestionStatus(QuestionStatus.UnableToAnswer)}
								/>
							</div>
							<div className={"status blue"}>
								<FaIcon
									icon={"circle-notch"}
									color="#FFFFFF"
									size="xl"
									secondaryOpacity="1"
									hideBg
									onClick={() => updateQuestionStatus(QuestionStatus.NotAnswered)}
								/>
							</div>
						</Row>
					</div>
				</Row>
				<Collapsible
					visible={expanded}
					printable
				>
					<Pad>
						<Col>
							<HidePrint>{(answer || editingAnswer) && <div className="section-title">Answer</div>}</HidePrint>
							{editingAnswer && (
								<div
									className="answer"
									contentEditable
									onBlur={saveCustomAnswer}
									ref={customAnswerRef}
								>
									{answer}
								</div>
							)}
							{answer && !editingAnswer && (
								<div
									className="answer"
									onClick={editAnswer}
								>
									{answer}
								</div>
							)}
							{answerSource && (
								<HidePrint>
									<div className={`answer-from ${answerSource === "Sage" ? "blue" : "orange"}`}>
										<Row
											verticalAlign="center"
											gap={"0.5rem"}
										>
											<UserIcon
												useSage={answerSource === "Sage"}
												user_id={authState.user.user_id}
												size="md"
											/>
											<div className="answer-from-name">Answered by {answerSource}</div>
										</Row>
									</div>
								</HidePrint>
							)}
							{sources?.length > 0 && (
								<HidePrint>
									<div className="section-title">Sources</div>
								</HidePrint>
							)}
							{sources?.length > 0 && (
								<HidePrint>
									<Col>
										{sources
											.sort((b, a) => a.relevance_score - b.relevance_score)
											.slice(0, 1)
											.map((s) =>
												"chunk_id" in s ? (
													<SourceFile
														key={s.chunk_id || s.element_source_id || s.source_id}
														source={s}
													/>
												) : (
													<SourceCrawl
														key={s.source_id}
														crawl={s}
													/>
												)
											)}
										<Collapsible visible={seeMoreSources}>
											<Col padding="2px">
												{sources
													.sort((b, a) => a.relevance_score - b.relevance_score)
													.slice(1)
													.map((s) =>
														"chunk_id" in s ? (
															<SourceFile
																key={s.chunk_id || s.element_source_id || s.source_id}
																source={s}
															/>
														) : (
															<SourceCrawl
																key={s.source_id}
																crawl={s}
															/>
														)
													)}
											</Col>
										</Collapsible>
										<Row horizontalAlign="center">
											<Button
												variant={ButtonVariant.Tertiary}
												action={() => setSeeMoreSources((e) => !e)}
											>
												{seeMoreSources ? "Less Sources" : "More Sources"}
											</Button>
										</Row>
									</Col>
								</HidePrint>
							)}
						</Col>
					</Pad>
				</Collapsible>
				<HidePrint>
					<Row
						horizontalAlign="between"
						verticalAlign="center"
					>
						<Row verticalAlign="center">
							{false && (
								<>
									<FaIcon
										icon={"up"}
										color={"#00435c"}
										size={"lg"}
										onClick={moveUp}
									></FaIcon>
									<FaIcon
										icon={"down"}
										color={"#00435c"}
										size={"lg"}
										onClick={moveDown}
									></FaIcon>
								</>
							)}
							<FaIcon
								icon={expanded ? "compress" : "expand"}
								color={"#00435c"}
								size={"lg"}
								onClick={() => setExpanded((e) => !e)}
							></FaIcon>
							<FaIcon
								icon={"brain-circuit"}
								color={"#00435c"}
								size={"lg"}
								onClick={generateAnswer}
							></FaIcon>
							<FaIcon
								icon={"pen-to-square"}
								color={"#00435c"}
								size={"lg"}
								onClick={editAnswer}
							></FaIcon>
						</Row>
						<Row>
							<FaIcon
								icon={"trash-can"}
								color={"#a80300"}
								animation={"fa-shake"}
								size={"lg"}
								onClick={remove}
							></FaIcon>
						</Row>
					</Row>
				</HidePrint>
			</Col>
		</div>
	);
}
