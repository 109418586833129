import { IVaultFile, VaultFileType, VaultProcessingStatus } from "@sage/types";
import { DecksService } from "../DecksService";
import { FsService } from "../FsService/FsService";
import { HttpService } from "../HttpService/HttpService";
import { ProjectService } from "../ProjectService/ProjectService";

export class VaultService {
	static LoadFile(file_id) {
		return HttpService.Get<IVaultFile>({
			uri: `/workspace-api-v2/vault/${file_id}`
		}).then((response) => response.payload);
	}

	static LoadDirectory(parent) {
		return HttpService.Get<IVaultFile[]>({
			uri: `/workspace-api-v2/vault/parent/${parent}`
		}).then((response) => response.payload);
	}

	static MoveFile(from: string, to: string) {
		return HttpService.Post({
			uri: "/workspace-api-v2/vault/move",
			body: { from, to }
		}).then((response) => response.payload);
	}

	static GetFileProcessingStatus(file_id: string) {
		return HttpService.Get<{ processing_status?: VaultProcessingStatus }>({
			uri: `/workspace-api-v2/vault/processing-status/${file_id}`
		}).then((response) => response.payload);
	}

	static async UpdateSource(file: IVaultFile) {
		if (file.file_type === VaultFileType.DOC) {
			await ProjectService.UpdateProjectName({
				project_id: file.sage_filetype_id,
				projectName: file.file_name
			});
		}

		if (file.file_type === VaultFileType.DECK) {
			await DecksService.updateName({
				deck_id: file.sage_filetype_id,
				deck_name: file.file_name
			});
		}

		return HttpService.Post({
			uri: "/workspace-api-v2/vault",
			body: file
		}).then((response) => response.payload);
	}

	static async UpdateProcessingStatus({ file_id, processing_status }: { file_id: string; processing_status: VaultProcessingStatus }) {
		return HttpService.Post({ path: `/vault/update/status/${file_id}`, body: { processing_status } });
	}

	static CreateFolder({
		teamCode,
		created_by,
		parent_folder_id,
		prefix,
		filename
	}: {
		teamCode: string;
		created_by: string;
		parent_folder_id: string;
		prefix: string;
		filename?: string;
	}) {
		return HttpService.Post<IVaultFile>({
			uri: "/workspace-api-v2/vault",
			body: {
				teamCode,
				created_by,
				file_name: filename ? filename : "New Folder",
				file_type: VaultFileType.FOLDER,
				prefix,
				parent_folder_id
			}
		}).then((response) => response.payload);
	}

	static ExistFolder(prefix: string) {
		return HttpService.Post<IVaultFile[]>({
			uri: `/workpace-api-v2/prefix/?prefix=${prefix}`
		}).then((response) => response.payload);
	}

	static async CreateDoc({
		teamCode,
		created_by,
		parent_folder_id,
		prefix
	}: {
		teamCode: string;
		created_by: string;
		parent_folder_id: string;
		prefix: string;
	}) {
		const doc = await FsService.CreateNewProject({
			user_id: created_by,
			teamCode
		});

		return HttpService.Post<IVaultFile>({
			uri: "/workspace-api-v2/vault",
			body: {
				teamCode,
				created_by,
				file_name: doc.projectName,
				file_type: VaultFileType.DOC,
				sage_filetype_id: doc.project_id,
				prefix,
				parent_folder_id
			}
		}).then((response) => response.payload);
	}

	static async CreateDeck({
		teamCode,
		created_by,
		parent_folder_id,
		prefix,
		file_name
	}: {
		teamCode: string;
		created_by: string;
		parent_folder_id: string;
		prefix: string;
		file_name: string;
	}): Promise<IVaultFile> {
		const deck = await DecksService.createDeck(file_name);

		return HttpService.Post<IVaultFile>({
			path: "/vault",
			body: {
				teamCode,
				created_by,
				file_name: deck.deck_name,
				file_type: VaultFileType.DECK,
				sage_filetype_id: deck.deck_id,
				prefix,
				parent_folder_id
			}
		}).then((response) => response.payload);
	}

	static async CreateSource({
		teamCode,
		created_by,
		parent_folder_id,
		prefix,
		file_name,
		description,
		mime_type,
		size,
		file,
		original_filename,
		progress
	}: {
		teamCode: string;
		created_by: string;
		parent_folder_id: string;
		prefix: string;
		file_name: string;
		description: string;
		mime_type: string;
		size: number;
		file: File;
		original_filename: string;
		progress?: (e: number) => void;
	}) {
		const file_type = VaultService.MimeToFileType(mime_type);

		const source = await HttpService.Post<IVaultFile>({
			uri: "/workspace-api-v2/vault",
			body: {
				teamCode,
				created_by,
				file_name,
				file_type,
				prefix,
				parent_folder_id,
				description,
				size,
				original_filename
			}
		}).then((response) => response.payload);

		const presignedPost = await HttpService.Post<{
			fields: { [key: string]: string };
			url: string;
		}>({
			uri: `/workspace-api-v2/vault/upload`,
			body: {
				content_type: mime_type,
				path: source.path
			}
		}).then((response) => response.payload);

		const form = new FormData();

		Object.keys(presignedPost.fields).forEach((key) => {
			form.append(key, presignedPost.fields[key]);
		});

		form.append("file", file);

		if (progress) {
			await VaultService.UploadFile(presignedPost.url, form, progress);
		} else {
			await fetch(presignedPost.url, {
				method: "POST",
				body: form
			});
		}

		return source;
	}

	static async UploadFile(url, request, cb): Promise<void> {
		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest();

			xhr.upload.onprogress = (event) => {
				if (event.lengthComputable) {
					const percentComplete = (event.loaded / event.total) * 100;
					cb(percentComplete);
				}
			};

			xhr.onload = () => {
				resolve();
			};

			xhr.open("POST", url, true);

			xhr.send(request);
		});
	}

	static async DeleteFile(file: IVaultFile) {
		if (file.file_type === VaultFileType.DOC) {
			await FsService.DeleteProjects({
				project_ids: [file.sage_filetype_id]
			});
		}

		if (file.file_type === VaultFileType.DECK) {
			await DecksService.deleteDecks([file.sage_filetype_id]);
		}

		if (file.file_type === VaultFileType.FOLDER) {
			await HttpService.Delete({
				uri: `/workspace-api-v2/vault/prefix?prefix=${file.prefix}`
			}).then((response) => response.payload);
		}

		return HttpService.Delete({
			uri: `/workspace-api-v2/vault/${file.file_id}`
		}).then((response) => response.payload);
	}

	static async GetFileUrl(file: IVaultFile): Promise<string> {
		const { url } = await HttpService.Get<{
			url: string;
		}>({
			uri: `/workspace-api-v2/vault/download?path=${encodeURIComponent(file.path)}`
		}).then((response) => response.payload);

		return url;
	}

	static MimeToFileType(mime_type: string): VaultFileType {
		const mappings = {
			"text/html": VaultFileType.HTML,
			"application/pdf": VaultFileType.PDF,
			"text/plain": VaultFileType.PLAINTEXT,
			"application/msword": VaultFileType.WORD,
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": VaultFileType.WORD,
			"application/vnd.ms-powerpoint": VaultFileType.POWERPOINT,
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": VaultFileType.POWERPOINT,
			"application/vnd.ms-excel": VaultFileType.EXCEL,
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": VaultFileType.EXCEL,
			"image/webp": VaultFileType.IMAGE,
			"image/tiff": VaultFileType.IMAGE,
			"image/svg+xml": VaultFileType.IMAGE,
			"image/png": VaultFileType.IMAGE,
			"image/jpeg": VaultFileType.IMAGE,
			"image/vnd.microsoft.icon": VaultFileType.IMAGE,
			"image/gif": VaultFileType.IMAGE,
			"image/bmp": VaultFileType.IMAGE,
			"image/avif": VaultFileType.IMAGE,
			"video/x-msvideo": VaultFileType.VIDEO,
			"video/mp4": VaultFileType.VIDEO,
			"video/mpeg": VaultFileType.VIDEO,
			"video/ogg": VaultFileType.VIDEO,
			"video/mp2t": VaultFileType.VIDEO,
			"video/webm": VaultFileType.VIDEO,
			"video/3gpp": VaultFileType.VIDEO,
			"video/3gpp2": VaultFileType.VIDEO
		};

		if (Object.keys(mappings).includes(mime_type)) return mappings[mime_type];

		return VaultFileType.OTHER;
	}
}
