import { IBaseProps } from "./Base";

export interface IDealRoomSource extends IBaseProps {
	source_id: string;
	user_id: string;
	file_name: string;
	file_size: number;
	processing_status: ProcessingStatus;
	file_type: FileType;
}

export enum ProcessingStatus {
	Uploading = "uploading",
	Uploaded = "uploaded",
	Processing = "processing",
	Indexing = "indexing",
	Finished = "finished",
	Failed = "failed",
	NotApplicable = "not-applicable"
}

export enum FileType {
	Folder = "FOLDER",
	Word = "WORD",
	Excel = "EXCEL",
	PowerPoint = "POWERPOINT",
	HTML = "HTML",
	PDF = "PDF",
	Image = "IMAGE",
	Video = "VIDEO",
	PlainText = "PLAINTEXT",
	Other = "OTHER"
}

export const MimeToFileTypeMappings = {
	"text/html": FileType.HTML,
	"application/pdf": FileType.PDF,
	"text/plain": FileType.PlainText,
	"application/msword": FileType.Word,
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document": FileType.Word,
	"application/vnd.ms-powerpoint": FileType.PowerPoint,
	"application/vnd.openxmlformats-officedocument.presentationml.presentation": FileType.PowerPoint,
	"application/vnd.ms-excel": FileType.Excel,
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": FileType.Excel,
	"image/webp": FileType.Image,
	"image/tiff": FileType.Image,
	"image/svg+xml": FileType.Image,
	"image/png": FileType.Image,
	"image/jpeg": FileType.Image,
	"image/vnd.microsoft.icon": FileType.Image,
	"image/gif": FileType.Image,
	"image/bmp": FileType.Image,
	"image/avif": FileType.Image,
	"video/x-msvideo": FileType.Video,
	"video/mp4": FileType.Video,
	"video/mpeg": FileType.Video,
	"video/ogg": FileType.Video,
	"video/mp2t": FileType.Video,
	"video/webm": FileType.Video,
	"video/3gpp": FileType.Video,
	"video/3gpp2": FileType.Video
};

export function MimeToFileType(mime: string) {
	if (mime in MimeToFileTypeMappings) {
		return MimeToFileTypeMappings[mime];
	} else {
		return FileType.Other;
	}
}

export interface IDealRoomElementSource extends IBaseProps {
	source_id: string;
	element_source_id: string;
	element_id: string;
	file_name: string;
	fragment_text: string[];
	chunk_text: string;
	chunk_id: string;
	file_type: FileType;
	page_number?: string;
	relevance_score?: number;
}
