import React, { ReactNode } from "react";
import { ICompanyLinks } from "@sage/types";
import { Row } from "@sage/shared/core";
import "./CompanyLinks.scss";

export function CompanyLinks({ links, image }: { links: ICompanyLinks; image: string }) {
	return (
		<Row>
			{links.homepage && (
				<CompanyLink
					icon={image}
					href={links.homepage}
				>
					{links.homepage.replace(/http(s?):\/\//gi, "")}
				</CompanyLink>
			)}
			{links.linkedin && (
				<CompanyLink
					icon={"https://cdn.sageai.dev/assets/social/linkedin.svg"}
					href={links.linkedin}
				>
					linkedin
				</CompanyLink>
			)}
			{links.crunchbase && (
				<CompanyLink
					icon={"https://cdn.sageai.dev/assets/social/crunchbase"}
					href={links.crunchbase}
				>
					crunchbase
				</CompanyLink>
			)}
			{links.twitter && (
				<CompanyLink
					icon={"https://cdn.sageai.dev/assets/social/twitter.ico"}
					href={links.twitter}
				>
					twitter
				</CompanyLink>
			)}
			{links.instagram && (
				<CompanyLink
					icon={"https://cdn.sageai.dev/assets/social/instagram"}
					href={links.instagram}
				>
					instagram
				</CompanyLink>
			)}
			{links.facebook && (
				<CompanyLink
					icon={"https://cdn.sageai.dev/assets/social/facebook"}
					href={links.facebook}
				>
					facebook
				</CompanyLink>
			)}
		</Row>
	);
}

export function CompanyLink({ icon, href, children }: { icon: string; href: string; children: ReactNode }) {
	return (
		<a
			href={href}
			target={"_blank"}
			className="__sage-company-link"
			data-testid="sage-company-link"
		>
			<img
				src={icon}
				width={16}
			/>
			<div>{children}</div>
		</a>
	);
}
