import { useEffect, useState } from "react";
import { IInfoRequest } from "@sage/types";
import { DealRoomService } from "@sage/services";
import { Col, Modal, P, Pad } from "@sage/shared/core";
import { InfoRequestCard } from "@sage/shared/dealrooms";

export function AllInfoRequestsModal({ visible, onDismiss, select }) {
	const [requests, setRequests] = useState<IInfoRequest[]>([]);

	async function loadInfoRequests() {
		setRequests((await DealRoomService.GetInfoRequestsByTeam()).sort((b, a) => a.lastModifiedTimestamp - b.lastModifiedTimestamp));
	}

	useEffect(() => {
		loadInfoRequests();
	}, [visible]);

	return (
		<Modal
			visible={visible}
			onDismiss={onDismiss}
			title={"Use Previous Info Request"}
			minWidth={"40rem"}
		>
			<Col scroll>
				<Pad>
					<Col>
						<P>Import the questions from a previous info request.</P>

						{requests.map((request) => (
							<InfoRequestCard
								key={request.request_id}
								request={request}
								select={() => select(request.request_id)}
							/>
						))}
					</Col>
				</Pad>
			</Col>
		</Modal>
	);
}
