export function validatePassword(password: string) {
	const specialCharacters = `\`!@#$%^&*()-_=+~<>?,./:";'{}[]|\\`.split("");
	const numbers = "1234567890".split("");

	if (password.length < 8) return false;
	if (!specialCharacters.some((v) => password.includes(v))) return false;
	if (!numbers.some((v) => password.includes(v))) return false;
	if (password.toLowerCase() === password) return false;
	if (password.toUpperCase() === password) return false;
	if (password.trim() !== password) return false;

	return true;
}
