import React, { useEffect, useState } from "react";
import { ISlide } from "@sage/types";
import { useDecksState } from "../DecksState";
import "./Slide.scss";

export function Slide({ slide }: { slide: ISlide }) {
	const { updateActiveSlide, imageWidth, activeSlide, deleteSlide, reorderSlides, setDraggingSlide, draggingSlide, getSlideImage } =
		useDecksState();
	const [imageUrl, setImageUrl] = useState<string>(null);

	function handleDragStart() {
		setDraggingSlide(slide);
	}

	function handleDragOver() {
		reorderSlides(draggingSlide, slide.idx);
	}

	function handleDrop() {
		setDraggingSlide(null);
	}

	async function getImageUrl() {
		if (slide?.slide_id) {
			setImageUrl(await getSlideImage(slide.deck_id, slide.slide_id, "lq"));
		}
	}

	useEffect(() => {
		getImageUrl();
	}, [slide]);

	return (
		<div
			className={`__slide-container ${activeSlide.slide_id === slide.slide_id ? "active" : ""}`}
			onClick={() => updateActiveSlide(slide)}
			style={{ width: `${imageWidth}rem`, height: `${imageWidth * (9 / 16)}rem` }}
			draggable
			onDragStart={handleDragStart}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<div className="slide-number">{slide.idx + 1}</div>
			<button
				className="delete-slide"
				onClick={() => deleteSlide(slide.slide_id)}
			>
				<img
					alt="img-icon"
					src={`https://cdn.ccm.vc/sage/icons/material-close.svg`}
				/>
			</button>
			{imageUrl && (
				<img
					src={imageUrl}
					onError={(e) => (e.target.src = "https://cdn.ccm.vc/sage/icons/material-edit-document.svg")}
					style={{ height: `${imageWidth * (9 / 16)}rem` }}
				/>
			)}
		</div>
	);
}
