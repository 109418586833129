import React, { useEffect, useState } from "react";
import { IDeck } from "@sage/types";
import { DecksService } from "@sage/services";
import { TeamDetails } from "@sage/shared/core";
import { DecksTable } from "../DecksTable/DecksTable";

export function DecksRecent() {
	const [decks, setDecks] = useState<IDeck[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	async function loadDecks() {
		setLoading(true);
		setDecks(await DecksService.getRecent());
		setLoading(false);
	}

	useEffect(() => {
		loadDecks();
	}, []);

	return (
		<div>
			<TeamDetails />
			<DecksTable
				decks={decks}
				refresh={loadDecks}
				title={"Recent Decks"}
				emptyText={"Looks like you haven't created any Decks yet"}
				loading={loading}
			/>
		</div>
	);
}
