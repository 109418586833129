import React, { useContext, useEffect, useState } from "react";
import { IDeal } from "@sage/types";
import { DealsContext } from "@sage/state";
import { ButtonBorderShape, ButtonIcon, InlineLoading, PageHeader, TeamDetails } from "@sage/shared/core";
import { CreateDealModal, DateBar, UserIcon } from "@sage/shared/other";
import "./DealTimeline.scss";

export function DealTimeline() {
	const dealsState = useContext(DealsContext);

	const [createDealModalVisible, setCreateDealModalVisible] = useState<boolean>(false);
	const [activeDeal, setActiveDeal] = useState<IDeal>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [activeOwners, setActiveOwners] = useState([]);
	const [data, setData] = useState([]);
	const [owners, setOwners] = useState([]);
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);

	function dismissModal() {
		setCreateDealModalVisible(false);
		setActiveDeal(null);
	}

	function editDeal(deal: IDeal) {
		setCreateDealModalVisible(true);
		setActiveDeal(deal);
	}

	async function updateTimeline(newOwners) {
		setLoading(true);
		setOwners([...new Set(dealsState.deals.map((deal) => deal.owner))]);
		let deals = dealsState.deals;
		if (newOwners.length) {
			deals = dealsState.deals.filter((deal) => newOwners.includes(deal.owner));
		}

		setStartTime(
			deals.reduce((starting_deal, current_deal) =>
				starting_deal.createdTimestamp > current_deal.createdTimestamp ? current_deal : starting_deal
			).createdTimestamp
		);
		setEndTime(
			deals.reduce((ending_deal, current_deal) => (ending_deal.endTimestamp < current_deal.endTimestamp ? current_deal : ending_deal))
				.endTimestamp
		);

		setData(deals.sort((a, b) => a.endTimestamp - b.endTimestamp));
		setLoading(false);
	}

	function updateActiveOwners(owner) {
		setActiveOwners((o) => {
			let newOwners;
			if (o.includes(owner)) {
				newOwners = o.filter((owner_) => owner_ !== owner);
			} else {
				newOwners = [...o, owner];
			}

			updateTimeline(newOwners);
			return newOwners;
		});
	}

	function handleSelect(deal) {
		editDeal(deal);
	}

	useEffect(() => {
		if (dealsState.deals?.length) updateTimeline([]);
	}, [dealsState.deals]);

	return (
		<div className={"__deal-timeline-container"}>
			<TeamDetails />
			{createDealModalVisible ? (
				<CreateDealModal
					visible={createDealModalVisible}
					onDismiss={dismissModal}
					activeDeal={activeDeal}
				/>
			) : null}
			<PageHeader
				title={"Deal Timeline"}
				size={"md"}
				actions={[
					{
						children: "New Deal",
						icon: ButtonIcon.MaterialAddWhite,
						action: () => setCreateDealModalVisible(true),
						borderShape: ButtonBorderShape.Round
					}
				]}
			/>
			<div className={"deal-timeline-wrapper"}>
				<div className={"filters"}>
					{owners.map((owner) => (
						<UserIcon
							user_id={owner}
							key={owner}
							active={activeOwners.includes(owner)}
							action={() => updateActiveOwners(owner)}
						/>
					))}
				</div>
				{loading ? (
					<InlineLoading invert />
				) : (
					<div className={"timeline"}>
						<div
							className={"timeline-current-date"}
							style={{
								left: `calc(10rem + ${((new Date().getTime() - startTime) / (endTime - startTime)) * 100}%)`
							}}
						/>
						{data.map((deal) => (
							<div
								className={"timeline-item"}
								key={deal.deal_id}
								onClick={() => handleSelect(deal)}
							>
								<div className={"timeline-label"}>{deal.name}</div>
								<div className={"timeline-gutter"}>
									<div
										className={"timeline-gutter-fill"}
										style={{
											width: `${((deal.endTimestamp - startTime) / (endTime - startTime) - (deal.createdTimestamp - startTime) / (endTime - startTime)) * 100}%`,
											left: `${((deal.createdTimestamp - startTime) / (endTime - startTime)) * 100}%`
										}}
									/>
								</div>
							</div>
						))}
						<div className={"date-bar-wrapper"}>
							<DateBar
								startTime={startTime}
								endTime={endTime}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
