import React from "react";
import "./LoadingSplash.scss";

export function LoadingSplash({ invert }: { invert?: boolean }) {
	return (
		<div className={`__loading-splash-container ${invert ? "invert" : ""}`}>
			<img
				className={"img"}
				src={invert ? "https://cdn.ccm.vc/sage/logo-lg.svg" : "https://cdn.ccm.vc/sage/logo-white.svg"}
				height="260px"
			/>
			<div className="bg"></div>
			<div className="bg bg2"></div>
			<div className="bg bg3"></div>
		</div>
	);
}
