import React, { useContext, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { FloatingPane } from "../Panes/FloatingPane/FloatingPane";
import { WorkspaceBody } from "../WorkspaceBody/WorkspaceBody";
import { WorkspaceHeader } from "../WorkspaceHeader/WorkspaceHeader";
import "./Workspace.scss";

export function Docs() {
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	const { control } = useForm();
	const formValue = useWatch({ control });

	const [showOpenTabs, setShowOpenTabs] = useState<boolean>(false);

	async function saveDocumentName(document_id: string) {
		const documentName = formValue[document_id];
		if (documentName && documentName !== workspaceState.project.documents.find((doc) => doc.document_id === document_id).documentName) {
			const documentsCopy = JSON.parse(JSON.stringify(workspaceState.project.documents));

			const modifiedIdx = documentsCopy.findIndex((doc) => doc.document_id === document_id);

			documentsCopy[modifiedIdx] = {
				...documentsCopy[modifiedIdx],
				documentName
			};

			workspaceDispatch({
				type: WorkspaceAction.SetProject,
				payload: {
					...workspaceState.project,
					documents: documentsCopy
				}
			});

			await ProjectService.UpdateDocumentName(document_id, documentName);
		}
	}

	async function createDocument() {
		const newDocument = await ProjectService.CreateDocument({
			user_id: workspaceState.project.user_id,
			project_id: workspaceState.project.project_id,
			teamCode: workspaceState.project.teamCode
		});

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: {
				...workspaceState.project,
				documents: [...workspaceState.project.documents, newDocument]
			}
		});

		workspaceDispatch({
			type: WorkspaceAction.SetActiveDocument,
			payload: newDocument.document_id
		});
	}

	function switchDocument(document_id: string) {
		workspaceDispatch({
			type: WorkspaceAction.SetActiveDocument,
			payload: document_id
		});
	}

	return (
		<div className={"__workspace-container"}>
			<WorkspaceHeader />
			<WorkspaceBody />
			<div className={"document-editor-tabs"}>
				{showOpenTabs ? (
					<div className={"open-tabs"}>
						<FloatingPane
							title={"Documents"}
							actions={[
								{
									variant: ButtonVariant.Secondary,
									borderShape: ButtonBorderShape.Round,
									children: "Close",
									action: () => setShowOpenTabs(false)
								},
								{
									variant: ButtonVariant.Primary,
									borderShape: ButtonBorderShape.Round,
									children: "New",
									action: createDocument
								}
							]}
						>
							{workspaceState.project.documents.map((doc) => (
								<div
									key={doc.document_id}
									className={`document-item`}
									onClick={() => switchDocument(doc.document_id)}
								>
									<img
										alt={"document"}
										src={`https://cdn.ccm.vc/sage/icons/document-new.svg`}
										width={`20px`}
									/>
									<div className={"document-name"}>{doc.documentName}</div>
								</div>
							))}
						</FloatingPane>
					</div>
				) : null}
				<div className={"tab-icon"}>
					<img
						alt="document list"
						src={`https://cdn.ccm.vc/sage/icons/material-menu.svg`}
						width={`22px`}
						onClick={() => setShowOpenTabs((e) => !e)}
					/>
				</div>
				{workspaceState.project.documents.map((doc) => (
					<Controller
						key={doc.document_id}
						control={control}
						render={({ field }) => (
							<div
								className={`tab ${doc.document_id === workspaceState.activeDocument ? "active" : ""}`}
								onClick={() => switchDocument(doc.document_id)}
								contentEditable
								onBlur={() => saveDocumentName(doc.document_id)}
								onInput={(e) => field.onChange(e.currentTarget.textContent)}
								suppressContentEditableWarning
							>
								{doc.documentName}
							</div>
						)}
						name={doc.document_id}
					/>
				))}
				<div
					className={"tab-icon"}
					onClick={createDocument}
				>
					<img
						alt="new document"
						src={`https://cdn.ccm.vc/sage/icons/material-add.svg`}
						width={`22px`}
					/>
				</div>
			</div>
		</div>
	);
}
