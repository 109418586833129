import React, { useContext, useMemo, useState } from "react";
import { Actor, Citation } from "@sage/types";
import { AuthContext, useAthenaState } from "@sage/state";
import { LlmService } from "@sage/services";
import { Chat } from "@sage/shared/chat";
import { Tabs } from "@sage/shared/other";
import { Animate, MouseHandler, Uuid } from "@sage/utils";
import { usePrompts } from "@sage/prompts";
import "./Athena.scss";
import { Companies, Sources, Threads, Vault } from "./Panes";

enum AthenaPage {
	Vault = "vault",
	Sources = "sources",
	Threads = "threads",
	Companies = "companies"
}

export function Athena() {
	const authState = useContext(AuthContext);
	const { thread, messages, addMessage, getRagSources } = useAthenaState();
	const [inProgressMessage, setInProgressMessage] = useState<string>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [width, setWidth] = useState<number>(28);
	const [resizing, setResizing] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>(AthenaPage.Sources);

	const prompts = usePrompts();

	function handleMouseMove(e) {
		setWidth((w) => {
			const newWidth = w - e.movementX;

			if (newWidth < 2 * 16) {
				return 28;
			}

			return newWidth;
		});
	}

	function handleMouseDown() {
		setResizing(true);
	}

	function handleMouseUp() {
		setResizing(false);
	}

	const onMouseDown = MouseHandler({
		onMouseMove: handleMouseMove,
		onMouseUp: handleMouseUp,
		onMouseDown: handleMouseDown
	});

	function handleClickToolbar() {
		if (width < 4 * 16) {
			Animate(width, 500, setWidth);
		}
	}

	function renderPane() {
		switch (activeTab) {
			case AthenaPage.Vault:
				return <Vault />;
			case AthenaPage.Sources:
				return <Sources />;
			case AthenaPage.Threads:
				return <Threads />;
			case AthenaPage.Companies:
				return <Companies />;
		}
	}

	async function send(message: string) {
		const starting_idx = messages.length;

		setLoading(true);
		addMessage({
			thread_id: thread.thread_id,
			message_id: Uuid.Nano(12),
			actor: authState.user.user_id,
			actorType: Actor.User,
			text: message,
			timestamp: new Date().getTime(),
			sources: [],
			idx: starting_idx
		});

		const llm_request = {
			prompt: message,
			preprompt: prompts.userDefault(),
			sources: getRagSources(),
			thread: thread.thread_id
		};

		const llm_response = await LlmService.Stream(llm_request, setInProgressMessage);

		addMessage({
			thread_id: thread.thread_id,
			message_id: Uuid.Nano(12),
			actor: "Athena",
			actorType: Actor.Assistant,
			text: llm_response.generated_text,
			timestamp: new Date().getTime(),
			sources: [],
			idx: starting_idx + 1
		});
		setInProgressMessage(null);
		setLoading(false);
	}

	return (
		<div className="__athena-container">
			{messages && (
				<div className="__athena-chat-container">
					<Chat
						messages={messages}
						send={send}
						loading={loading}
						inProgressMessage={inProgressMessage}
					/>
				</div>
			)}
			<div
				className={`tools ${resizing ? "active" : ""}`}
				style={{ width: `${width}px` }}
				onClick={handleClickToolbar}
			>
				<div
					className="tools-resize-handle"
					onMouseDown={onMouseDown}
				/>
				<Tabs
					visible={width > 225}
					collapsed={width < 700}
					tabs={[
						{
							fullName: "Sources",
							shortName: "S",
							id: AthenaPage.Sources
						},
						{
							fullName: "Vault",
							shortName: "V",
							id: AthenaPage.Vault
						},
						{
							fullName: "Companies",
							shortName: "C",
							id: AthenaPage.Companies
						},
						{
							fullName: "Threads",
							shortName: "T",
							id: AthenaPage.Threads
						}
					]}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
				/>
				{width > 225 && renderPane()}
			</div>
		</div>
	);
}
