import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export function TemplateOptionHeading1() {
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: "h1"
	});

	const style = {
		transform: CSS.Transform.toString({
			...transform,
			scaleX: 1,
			scaleY: 1
		})
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		>
			<div className={"template-item-option"}>
				<div className={"template-item-option-icon h1"}>
					<i></i>
				</div>
				<div className={"template-item-option-title"}>Heading 1</div>
			</div>
		</div>
	);
}
