import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { CompanyAutosuggest } from "@sage/shared/company";
import { Button, ButtonBorderShape, ButtonVariant, Row } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";
import "./Company.scss";

export function Company() {
	const { updatePageStatus, getPageStatus, form } = useWorkflowState();
	const navigate = useNavigate();

	function handleNav() {
		if (getPageStatus("Deal Details", "Deep Crawl") === "skip") {
			updatePageStatus(null, "Deal Details", "Deep Crawl");
		}

		if (getPageStatus("Deal Details", "Review Crawl Result") === "skip") {
			updatePageStatus(null, "Deal Details", "Review Crawl Result");
		}

		if (Object.keys(form.formState.errors).length) {
			updatePageStatus("errors", "Deal Details", "Company");
		} else {
			updatePageStatus("complete", "Deal Details", "Company");
		}

		navigate("../crawl");
	}

	function skipCompanyInformation() {
		updatePageStatus("skip", "Deal Details", "Company");
		updatePageStatus("skip", "Deal Details", "Deep Crawl");
		updatePageStatus("skip", "Deal Details", "Review Crawl Result");
		navigate("../template");
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Deal Details", "Company");

		return () => {
			console.log(getPageStatus("Deal Details", "Company"));
		};
	}, []);

	return (
		<section>
			<h2>Company Information</h2>
			<p>Would you like to add your company from our Dealstream database?</p>
			<p>This will let you add information found from web scraping to your notes, or to the CIM sections directly.</p>
			<Form
				fields={[
					<CompanyAutosuggest
						control={form.control}
						setValue={form.setValue}
						name={"company"}
						label={"Search for your company"}
					/>
				]}
			/>
			<Row>
				<Button
					borderShape={ButtonBorderShape.Round}
					variant={ButtonVariant.Secondary}
					action={skipCompanyInformation}
				>
					Skip
				</Button>
				<Button
					borderShape={ButtonBorderShape.Round}
					action={handleNav}
				>
					Next
				</Button>
			</Row>
		</section>
	);
}
