import React from "react";
import { Control } from "react-hook-form";
import { IDeck } from "@sage/types";
import { TableColumn, TableColumnSpacing, TableRow } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { UserIcon } from "../../UserIcon";
import { UserTag } from "../../UserTag";
import { VaultFSIcon, VaultIconName } from "../VaultIcon";

export interface IDecksProjectProps {
	deck: IDeck;
	control?: Control;
	showMenu?: boolean;
}

export function DecksProject({ deck, control, showMenu }: IDecksProjectProps) {
	return (
		<TableRow>
			<TableColumn width={"0"}>
				{control && showMenu !== false && (
					<Checkbox
						name={deck.deck_id}
						control={control}
					/>
				)}
			</TableColumn>
			<TableColumn icon={<VaultFSIcon iconName={VaultIconName.Decks} />}>{deck.deck_name}</TableColumn>
			<TableColumn
				spacing={TableColumnSpacing.Fixed}
				width={"10rem"}
			>
				<UserIcon
					user_id={deck.user_id}
					size={"sm"}
				/>
				<UserTag
					user_id={deck.user_id}
					transparent
				/>
			</TableColumn>
			<TableColumn
				relativeDate
				spacing={TableColumnSpacing.Fixed}
				width={"10rem"}
			>
				{deck.lastModifiedTimestamp}
			</TableColumn>
		</TableRow>
	);
}
