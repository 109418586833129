import { IDealRoom, PhaseStatus } from "@sage/types";
import { HttpService } from "../../HttpService";
import { SavePhase } from "../Phases";

export function GetDealRooms(): Promise<IDealRoom[]> {
	return HttpService.Get<IDealRoom[]>({ path: "/dealrooms" }).then((res) => res.payload);
}

export function GetDealRoom(deal_id: string): Promise<IDealRoom> {
	return HttpService.Get<IDealRoom>({ path: `/dealrooms/${deal_id}` }).then((res) => res.payload);
}

export function SaveDealRoom(dealroom: IDealRoom) {
	return HttpService.Post({ path: "/dealrooms", body: dealroom }).then((res) => res.payload);
}

export async function CreateDealRoom(): Promise<IDealRoom> {
	const deal = await HttpService.Post<IDealRoom>({ path: "/dealrooms", body: {} }).then((res) => res.payload);

	await Promise.all(
		[
			{
				phase_index: 0,
				phase_name: "Initial Diligence",
				phase_status: PhaseStatus.None,
				phase_icon: "book-open-cover",
				phase_color: "#a80300",
				deal_id: deal.deal_id
			},
			{
				phase_index: 1,
				phase_name: "Bake Off",
				phase_status: PhaseStatus.None,
				phase_icon: "screen-users",
				phase_color: "#f7a501",
				deal_id: deal.deal_id
			},
			{
				phase_index: 2,
				phase_name: "Teaser",
				phase_status: PhaseStatus.None,
				phase_icon: "memo",
				phase_color: "#0051a8",
				deal_id: deal.deal_id
			},
			{
				phase_index: 3,
				phase_name: "CIM/CIP",
				phase_status: PhaseStatus.None,
				phase_icon: "file-chart-column",
				phase_color: "#a80300",
				deal_id: deal.deal_id
			},
			{
				phase_index: 4,
				phase_name: "Management Presentation",
				phase_status: PhaseStatus.None,
				phase_icon: "screen-users",
				phase_color: "#f7a501",
				deal_id: deal.deal_id
			},
			{
				phase_index: 5,
				phase_name: "Buyer Questions",
				phase_status: PhaseStatus.None,
				phase_icon: "handshake",
				phase_color: "#5700a8",
				deal_id: deal.deal_id
			}
		].map((phase) => SavePhase(phase))
	);
	return deal;
}

export function DeleteDealRoom(deal_id: string) {
	return HttpService.Delete({ path: `/dealrooms/${deal_id}` }).then((res) => res.payload);
}

export function UpdateDealRoomName(deal_id: string, deal_name: string) {
	return HttpService.Post({ path: `/dealrooms/${deal_id}/name`, body: { deal_name } });
}
