import { useForm, useWatch } from "react-hook-form";
import { DealRoomProjectElementType } from "@sage/types";
import { useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Button, Modal, Pad, Row } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";

export function NewElementModal() {
	const { showNewElementModal, setShowNewElementModal, addElement } = useDealRoomProject();
	const form = useForm();
	const elementType = useWatch({ control: form.control, name: "element_type" });

	async function submitForm(e) {
		const { prompt, search_terms, element_name, element_description, element_type } = e;
		const template = await DealRoomService.SaveTemplate({
			element_name,
			element_description,
			prompt,
			search_terms: search_terms.map(({ value }) => value).filter((val) => !!val),
			element_type: element_type || DealRoomProjectElementType.Paragraph
		});
		await addElement(element_type, template.element_id);
		form.reset({});
		setShowNewElementModal(false);
	}

	return (
		<Modal
			visible={showNewElementModal}
			onDismiss={() => setShowNewElementModal(false)}
			title={"New Element"}
			minWidth={"40rem"}
		>
			<Pad>
				<Form
					fields={[
						{
							control: form.control,
							name: "element_type",
							variant: FormFieldVariant.Vertical,
							label: "Element Type",
							type: "select",
							options: [
								DealRoomProjectElementType.Paragraph,
								DealRoomProjectElementType.Bullets,
								DealRoomProjectElementType.Table,
								DealRoomProjectElementType.Chart
							]
						},
						{
							control: form.control,
							name: "element_name",
							variant: FormFieldVariant.Vertical,
							label: "Element Name",
							required: true,
							placeholder: "Element Name..."
						},
						{
							control: form.control,
							name: "element_description",
							variant: FormFieldVariant.Vertical,
							label: "Description",
							placeholder: "Element Description...",
							required: false,
							type: "textarea"
						},
						{
							control: form.control,
							name: "prompt",
							variant: FormFieldVariant.Vertical,
							label: "Prompt",
							placeholder: "Prompt...",
							type: "textarea",
							required: true,
							rows: 10
						},
						...(elementType === DealRoomProjectElementType.Bullets || elementType === DealRoomProjectElementType.Paragraph
							? [
									{
										control: form.control,
										name: "example",
										variant: FormFieldVariant.Vertical,
										label: "Example",
										placeholder: "Example...",
										type: "textarea",
										required: true,
										rows: 5
									}
								]
							: []),
						{
							control: form.control,
							name: "search_terms",
							variant: FormFieldVariant.Vertical,
							label: "Search Terms (optional)",
							type: "list",
							placeholder: "Search Term..."
						}
					]}
				/>
				<Row
					horizontalAlign="right"
					width={"100%"}
				>
					<Button action={form.handleSubmit(submitForm)}>Save</Button>
				</Row>
			</Pad>
		</Modal>
	);
}
