import React, { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./LinkCard.scss";

export interface ILinkCardProps {
	title?: string;
	subtitle?: string;
	image?: string;
	content?: string | ReactNode;
	href?: string;
	onCick?: () => void;
	cta?: string;
}

export function LinkCard({ title, subtitle, content, href, onClick, image, cta }: ILinkCardProps) {
	return (
		<div className="__sage-link-card-container">
			<Link
				href={href}
				onClick={onClick}
			>
				<div className="card-inner">
					{image && (
						<div className="image">
							<img src={image} />
						</div>
					)}
					{title && <div className="title">{title}</div>}
					{subtitle && <div className="subtitle">{subtitle}</div>}
					{content && <div className="content">{content}</div>}
					{(href || onClick) && (
						<div className="action-arrow">
							<div className="read-more">{cta ?? "Read More"}</div>
							<img src={"https://cdn.ccm.vc/sage/icons/material-arrow-right-alt.svg"} />
						</div>
					)}
				</div>
			</Link>
		</div>
	);
}

function Link({ href, children, onClick }: { onClick?: () => void; href?: string; children: ReactNode }) {
	if (href?.includes("#")) {
		return <a href={href}>{children}</a>;
	} else if (href) {
		return (
			<RouterLink
				to={href}
				target={href?.includes("https") ? "_blank" : undefined}
			>
				{children}
			</RouterLink>
		);
	} else if (onClick) {
		return <div onClick={onClick}>{children}</div>;
	} else {
		return children;
	}
}
