import React, { ReactNode, useRef, useState } from "react";
import { Control } from "react-hook-form";
import { Button, ButtonIcon, ButtonVariant, InlineLoading } from "@sage/shared/core";
import { FormField, FormFieldVariant } from "../FormField";
import "./Autosuggest.scss";

export interface IAutosuggestProps {
	control: Control;
	name: string;
	label?: string;
	description?: string;
	options: IAutosuggestOption[];
	setValue?: (name: string, value: any) => any | void;
	onChange?: (value: string) => void;
	onClick?: (option: IAutosuggestOption) => void;
	onRefresh?: () => void;
	onClear?: () => void;
	loading?: boolean;
	actions?: ReactNode;
}

export interface IAutosuggestOption {
	value: string;
	label?: string;
	description?: string;
	image?: string;
	url?: string;
	_id: string;
	icon?: ReactNode;
}
[];

export function Autosuggest({
	control,
	name,
	options,
	label,
	description,
	setValue,
	onChange,
	onClick,
	onRefresh,
	onClear,
	loading,
	actions
}: IAutosuggestProps) {
	const [optionsOpen, setOptionsOpen] = useState(false);
	const focusRef = useRef(null);

	function handleClick(option) {
		if (setValue) setValue(name, option.value);
		if (onClick) onClick(option);
		setOptionsOpen(false);
	}

	return (
		<div className={"__autosuggest-container"}>
			<FormField
				control={control}
				name={name}
				label={label}
				description={description}
				refocusRef={focusRef}
				onFocus={() => setOptionsOpen(true)}
				variant={FormFieldVariant.Vertical}
				onChange={onChange}
				actions={[
					...(onRefresh ? [{ action: onRefresh, icon: ButtonIcon.MaterialReset, variant: ButtonVariant.IconSecondary }] : []),
					...(onClear ? [{ action: onClear, icon: ButtonIcon.MaterialClose, variant: ButtonVariant.IconSecondary }] : [])
				]}
			/>
			<div className={"options-wrapper"}>
				<div className={`collapsable ${optionsOpen ? "" : "collapsed"}`}>
					<div className={"collapse-wrapper"}>
						<div className={"options"}>
							{loading ? (
								<div className={"option-item"}>
									<InlineLoading invert />
								</div>
							) : null}
							{options.length === 0 && !loading ? <div className={"option-item"}>No suggestions yet...</div> : null}
							{options.map((option) => (
								<div
									key={option._id}
									className={"option-item"}
									onClick={() => handleClick(option)}
									data-testid={`${option._id}-autosuggest-option`}
								>
									{option.image ? (
										<div className={"image"}>
											<img src={option.image} />
										</div>
									) : null}
									{option.icon ? option.icon : null}
									<div className={"col"}>
										<div className={"label"}>
											{option.url ? (
												<a
													className="link"
													href={option.url}
													target={"_blank"}
												>
													{option.label || option.value}
												</a>
											) : (
												option.label || option.value
											)}
										</div>
										{option.description ? <div className={"description"}>{option.description}</div> : null}
									</div>
								</div>
							))}
							<div className="autosuggest-actions">
								{actions}
								<Button
									variant={ButtonVariant.Tertiary}
									action={() => setOptionsOpen(false)}
									testid="close-autosuggest"
								>
									Close
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
