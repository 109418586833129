import React from "react";
import { Outlet } from "react-router-dom";
import { useAuthState } from "@sage/state";

export function AdminGuard() {
	const authState = useAuthState();

	if (authState.user?.is_admin) {
		return <Outlet />;
	}
}
