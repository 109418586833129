import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DecksService } from "@sage/services";
import { ActiveSlide } from "./ActiveSlide";
import { DecksWorkspaceContext, createDecksState } from "./DecksState";
import { useDecksState } from "./DecksState";
import "./DecksWorkspace.scss";
import { Slide } from "./Slide";
import { Toolbar } from "./Toolbar";
import { ToolsPane } from "./ToolsPane";

export function Decks() {
	const decksState = createDecksState();

	const params = useParams();

	async function loadDeck() {
		const deck_id = params?.deck_id;

		if (deck_id) {
			const deck = await DecksService.getDeckById(deck_id);
			decksState.setDeck(deck);
			decksState.setActiveSlide(deck.slides[0]);
		}
	}

	useEffect(() => {
		loadDeck();
	}, [params?.deck_id]);

	return <DecksWorkspaceContext.Provider value={decksState}>{decksState.deck && <DecksWorkspace />}</DecksWorkspaceContext.Provider>;
}
export function DecksWorkspace() {
	const { deck, slideSectionResizing, slideSectionWidth, slidePaneResizeHandler, imageWidth, updateImageSize } = useDecksState();

	return (
		<div className="__decks-container">
			<Toolbar />
			<div className="workspace">
				<div
					className={`slides-wrapper ${slideSectionResizing ? "resize-active" : ""}`}
					style={{ width: `${slideSectionWidth}px` }}
				>
					<div className="slides">
						{deck.slides.map((slide) => (
							<Slide
								key={slide.slide_id}
								slide={slide}
							/>
						))}
						{/*< button
							className="add-slide"
							onClick={() => newSlide()}
						>
							<img
								alt="img-icon"
								src={`https://cdn.ccm.vc/sage/icons/material-add.svg`}
							/>
						</button> */}
					</div>
					<div
						className={`slides-handle`}
						onMouseDown={slidePaneResizeHandler}
					></div>
					<div className="zoom">
						<input
							type="range"
							value={imageWidth}
							onChange={(e) => updateImageSize(parseInt(e.target.value))}
							min={8}
							max={40}
						/>
					</div>
				</div>
				<div className="active-slide">
					<ActiveSlide />
				</div>
			</div>
			<ToolsPane />
		</div>
	);
}
