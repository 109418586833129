import { ReactNode } from "react";
import "./Pad.scss";

export function Pad({ children, padding }: { children: ReactNode; padding?: string }) {
	return (
		<div
			className="__sage-pad-container"
			style={{ padding }}
		>
			{children}
		</div>
	);
}
