export const BulletsInstructions = `
Generate bullet points that are clear and impactful by following these rules:

1. FORMAT:
- Wrap all bullet points in <bullets></bullets> tags
- Each bullet must be 1-2 complete sentences
- You are able to write markdown syntax for italics, bold, headers and making lists
- No sentence fragments

2. STYLE:
- Be concise but comprehensive
- Include specific metrics when possible
- Use active voice
- Focus on one clear point per bullet
- Maintain professional tone

Example:

<bullets>
- The company achieved 45% year-over-year revenue growth, reaching $50M in annual recurring revenue while maintaining 80% gross margins.
- Proprietary AI technology has created significant barriers to entry, with 12 patents filed and a proven 40% reduction in customer acquisition costs.
- Strategic partnerships with five Fortune 500 clients have expanded the total addressable market to $2B, representing a 10x growth opportunity.
</bullets>


3. Footnotes
- Add footnotes outside the bullets using <footnote> tags
- Reference footnotes in bullets using superscript numbers in <sup> tags
- Example: <bullets> - Revenue was $20M in 2023 <sup>1</sup></bullets>
- Number footnotes sequentially starting from 1
- Place all footnotes after all bullets 

Example with Footnotes:

<bullets>
- Revenue was $20M in 2023 <sup>1</sup>
</bullets>

<footnote number="1">Includes recurring and one-time revenue</footnote>

4. Flags
- Purpose: Internal data quality control and workflow management
- Audience: Data analysts, report preparers, internal team
- format: <flag number="1">...details</flag>
- Use for:
  - Missing data that needs to be uploaded
  - Data sources requiring validation
  - Incomplete datasets needing additional input
  - Values requiring correction or verification
  - Highlighting data quality issues to be resolved
- Should be resolved before client delivery
- If you are asked to do something you are unable to do because of missing data, clearly highlight it in a flag. DO NOT ADD IN ASSUMPTIONS. Leave the field blank and flag it for users.

Example with Flags:

<para>Revenue was $20M in 2023 <sup>1</sup></para>


Example with Flags:

<bullets>
- Revenue was $20M in 2023 <sup>1</sup>
</bullets>

<footnote number="1">Includes recurring and one-time revenue</footnote>

<flag number="1">Revenue was missing for 2024</flag>

If you are asked to do something you are unable to do because of missing data, clearly highlight it in a flag. DO NOT ADD IN ASSUMPTIONS. Leave the field blank and flag it for users.

## Titles

If you are asked to add a section title, put it into a a <para></para> element before the chart using a ### header.

for example
\`\`\`
<para>### Growth Drivers</para>
<bullets>
...
</bullets>
`;
