import React from "react";
import { Controller } from "react-hook-form";
import { Button, IButtonProps } from "../Button";
import "./PageHeader.scss";

interface IPageHeaderProps {
	title: string;
	subtitle?: string;
	actions?: IButtonProps[];
	size?: string;
	onClick?: () => void;
	editTitle?: boolean;
	form?: any;
	save?: (formValue: { title: string }) => void;
}

export function PageHeader({ title, subtitle, actions, size, onClick, editTitle, form, save }: IPageHeaderProps) {
	function handleClick() {
		if (onClick) onClick();
	}

	return (
		<div className={"__page-header-container"}>
			<div className={"page-header-title-row"}>
				{editTitle ? (
					<Controller
						control={form.control}
						render={({ field }) => (
							<div
								className={`page-header-title ${size} ${onClick ? "clickable" : ""}`}
								contentEditable
								onInput={(e) => field.onChange(e.currentTarget.textContent)}
								onBlur={form.handleSubmit(save)}
								suppressContentEditableWarning
							>
								{title}
							</div>
						)}
						name={"title"}
					/>
				) : (
					<div
						className={`page-header-title ${size} ${onClick ? "clickable" : ""}`}
						onClick={handleClick}
					>
						{title}
					</div>
				)}
				{actions ? (
					<div className={"page-header-actions"}>
						{actions.map((action, idx) => (
							<Button
								key={idx}
								{...action}
							/>
						))}
					</div>
				) : null}
			</div>
			{subtitle ? <h2 className={"page-header-subtitle"}>{subtitle}</h2> : null}
		</div>
	);
}
