import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthAction, AuthContext } from "@sage/state";
import { LoadingSplash } from "@sage/shared/core";

export function AuthGuard({ authDispatch }) {
	const authState = useContext(AuthContext);

	const navigate = useNavigate();

	useEffect(() => {
		if (authDispatch)
			authDispatch({
				type: AuthAction.Authenticate,
				dispatch: authDispatch
			});
	}, [authDispatch]);

	if (authState.loading) return <LoadingSplash />;
	else if (authState.user) return <Outlet context={{ authDispatch }} />;
	else navigate("/app/welcome");
}
