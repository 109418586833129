import { DeckProcessingStatus, IDeck, ISlide, IVaultFile } from "@sage/types";
import { HttpService } from "../HttpService";
import { VaultService } from "../VaultService";

export class DecksService {
	static async getDeckById(deck_id: string): Promise<IDeck> {
		return HttpService.Get<IDeck>({ path: `/decks/${deck_id}` }).then(({ payload }) => payload);
	}

	static async getDeckByIdShort(deck_id: string): Promise<IDeck> {
		return HttpService.Get<IDeck>({ path: `/decks/short/${deck_id}` }).then(({ payload }) => payload);
	}

	static async getSlidesShort(deck_id: string): Promise<{ deck_id: string; slide_id: string; idx: number }[]> {
		return HttpService.Get<{ deck_id: string; slide_id: string; idx: number }[]>({ path: `/decks/slides/short/${deck_id}` }).then(
			({ payload }) => payload
		);
	}

	static async getSlideById(slide_id: string): Promise<ISlide> {
		return HttpService.Get<ISlide>({ path: `/decks/slide/${slide_id}` }).then(({ payload }) => payload);
	}

	static async getImage(deck_id: string, slide_id: string, variant: string): Promise<string> {
		return HttpService.Get<{ url: string }>({ path: `/decks/image?path=decks/${deck_id}/${slide_id}-${variant}.png` }).then(
			({ payload }) => payload?.url
		);
	}

	static async createDeck(deck_name?: string): Promise<IDeck> {
		return HttpService.Post<IDeck>({ path: "/decks", body: { slides: [], deck_name } }).then(({ payload }) => payload);
	}

	static async deleteDecks(deck_ids: string[]) {
		return Promise.all(deck_ids.map((deck_id) => HttpService.Delete({ path: `/decks/${deck_id}` })));
	}

	static async getRecent(): Promise<IDeck[]> {
		return HttpService.Get<IDeck[]>({ path: `/decks/user` }).then(({ payload }) => payload);
	}

	static async getShared(): Promise<IDeck[]> {
		return HttpService.Get<IDeck[]>({ path: `/decks/team` }).then(({ payload }) => payload);
	}

	static async getFavorites(): Promise<IDeck[]> {
		return HttpService.Get<IDeck[]>({ path: `/decks/favorites` }).then(({ payload }) => payload);
	}

	static async updateName({ deck_id, deck_name }: { deck_id: string; deck_name: string }) {
		return HttpService.Post({ path: `/decks/update/name/${deck_id}`, body: { deck_name } });
	}

	static async getProcessingStatus(deck_id: string): Promise<{ processing_status: DeckProcessingStatus }> {
		return HttpService.Get<{ processing_status: DeckProcessingStatus }>({ path: `/decks/get-status/${deck_id}` }).then(
			({ payload }) => payload
		);
	}

	static async createDeckFromFile({
		teamCode,
		created_by,
		parent_folder_id,
		prefix,
		reference_file
	}: {
		teamCode: string;
		created_by: string;
		parent_folder_id: string;
		prefix: string;
		reference_file: IVaultFile;
	}) {
		const vault_file = await VaultService.CreateDeck({
			teamCode,
			created_by,
			parent_folder_id,
			prefix,
			file_name: reference_file.file_name
		});

		await HttpService.Post({
			path: "/decks/create-from-file",
			body: {
				deck_id: vault_file.sage_filetype_id,
				vault_file_id: reference_file.file_id
			}
		});
	}
}
