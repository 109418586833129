export enum DealStatus {
	ACTIVE = "ACTIVE",
	UPCOMING = "UPCOMING",
	FINISHED = "FINISHED",
	DELETED = "DELETED"
}

export interface ITag {
	name: string;
	color: "red" | "green" | "purple" | "blue";
}

export enum DealFileType {
	DOC = "DOC",
	DECK = "DECK",
	SOURCE = "SOURCE",
	FOLDER = "FOLDER"
}

export interface IDealFile {
	file_type: DealFileType;
	file_id: string;
}

export interface IDeal {
	deal_id: string;
	owner: string;
	created_by: string;
	teamCode: string;
	status_teamCode: string;
	name: string;
	tags: ITag[];
	description: string;
	status: DealStatus;
	endTimestamp: number;
	createdTimestamp: number;
	lastModifiedTimestamp: number;
	column?: string;
	deal_files: IDealFile[];
	company_id?: string;
}
