import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IVaultFile, VaultFileType } from "@sage/types";
import { AuthContext, useWorkflowState } from "@sage/state";
import { VaultService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, Table, TableColumn, TableColumnSpacing, TableRow } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { VaultFile, VaultFileVariant } from "@sage/shared/other";
import { useVaultHandler } from "@sage/utils";
import "./SelectDataSources.scss";

export function SelectDataSources() {
	const authState = useContext(AuthContext);
	const { form, updatePageStatus, setActiveFiles } = useWorkflowState();
	const nav = useNavigate();
	const vaultHandler = useVaultHandler({ navigate });
	const [filesLoading, setFilesLoading] = useState<boolean>(false);
	const [vaultFiles, setVaultFiles] = useState<IVaultFile[]>([]);
	const [parentFile, setParentFile] = useState<IVaultFile>(null);
	const fsForm = useForm();

	async function navigate(parent?: string) {
		setFilesLoading(true);

		if (parent && parent !== authState.user.teamCode) {
			setVaultFiles((await VaultService.LoadDirectory(parent)).filter((file) => file.file_type !== VaultFileType.DOC));
			setParentFile(await VaultService.LoadFile(parent));
		} else {
			setVaultFiles(
				(await VaultService.LoadDirectory(authState.user.teamCode)).filter((file) => file.file_type !== VaultFileType.DOC)
			);
			setParentFile(null);
		}

		setFilesLoading(false);
	}

	function navBack() {
		navigate(parentFile.parent_folder_id);
	}

	function handleNavAway() {
		const formValue = fsForm.getValues();
		setActiveFiles(formValue);

		if (Object.keys(formValue).filter((k) => formValue[k]).length) {
			updatePageStatus("complete", "Data Sources", "Select Sources");
			updatePageStatus("complete", "Data Sources");
		} else {
			updatePageStatus("errors", "Data Sources", "Select Sources");
			updatePageStatus("errors", "Data Sources");
		}
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Data Sources", "Select Sources");
		const formValue = form.getValues();

		if (formValue.activeFiles) {
			for (const file of formValue.activeFiles) {
				fsForm.setValue(file.file_id, true);
			}
		}

		if (!parentFile && vaultFiles.length === 0) {
			navigate();
		}
		return () => {
			handleNavAway();
		};
	}, []);

	function handleNav() {
		nav("../review");
	}

	return (
		<section className="__sage-select-data-sources-container">
			<h2>Select Data Sources</h2>
			<Table
				items={vaultFiles}
				emptyText={"This folder is empty"}
				loading={filesLoading}
				headers={
					<TableRow>
						<TableColumn
							spacing={TableColumnSpacing.Fixed}
							width={"28px"}
						>
							{parentFile ? (
								<Button
									icon={ButtonIcon.MaterialArrowLeft}
									variant={ButtonVariant.IconSecondarySM}
									action={navBack}
									testid="vault-files-pane-nav-back"
								/>
							) : null}
						</TableColumn>
						<TableColumn header>{parentFile?.file_name || "Team Files"}</TableColumn>
					</TableRow>
				}
				row={(file: IVaultFile) => (
					<VaultFile
						selection={
							<Checkbox
								control={fsForm.control}
								name={file.file_id}
								defaultValue={false}
								testid="select-vault-file"
							/>
						}
						onClick={() => vaultHandler.open(file)}
						key={file.file_id}
						file={file}
						variant={VaultFileVariant.Abrev}
					/>
				)}
				actions={[{ children: "Next", borderShape: ButtonBorderShape.Round, action: handleNav }]}
			/>
		</section>
	);
}
