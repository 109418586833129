import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IDeck } from "@sage/types";
import { DecksService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant, Modal, PageHeader, Table, TableColumn, TableColumnSpacing } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { DecksProject } from "@sage/shared/other";
import "./DecksTable.scss";

interface IDecksTableProps {
	showMenu?: boolean;
	decks: IDeck[];
	loading?: boolean;
	title?: string;
	emptyText?: string;
	refresh: () => void;
}

export function DecksTable({ title, showMenu, refresh, loading, decks, emptyText }: IDecksTableProps) {
	const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

	const { control, reset, setValue } = useForm();
	const formValue = useWatch({ control });

	async function createDeck() {
		await DecksService.createDeck();
		refresh();
	}

	async function deleteDeck() {
		setDeleteModalVisible(false);
		await DecksService.deleteDecks(Object.keys(formValue).filter((key) => formValue[key]));
		reset({});
		refresh();
	}

	function openDeleteModal() {
		if (Object.keys(formValue).filter((key) => formValue[key]).length) setDeleteModalVisible(true);
	}

	function handleToggleAll(e: boolean) {
		decks.forEach((deck) => {
			setValue(deck.deck_id, e);
		});
	}

	return (
		<div className={"__decks-table-container"}>
			<PageHeader
				title={title}
				actions={[
					{
						variant: ButtonVariant.Secondary,
						children: "Delete",
						action: openDeleteModal,
						borderShape: ButtonBorderShape.Round,
						testid: "delete-deck"
					},
					{
						variant: ButtonVariant.Primary,
						children: "New Deck",
						action: createDeck,
						borderShape: ButtonBorderShape.Round,
						testid: "create-deck"
					}
				]}
			/>
			<Table
				items={decks}
				emptyText={emptyText}
				headers={
					<>
						{showMenu !== false && (
							<TableColumn
								header
								width={"0"}
							>
								<Checkbox
									name={"select_all"}
									control={control}
									onChange={(e: boolean) => handleToggleAll(e)}
								/>
							</TableColumn>
						)}
						<TableColumn header>Deck Name</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							User
						</TableColumn>
						<TableColumn
							header
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							Last Modified
						</TableColumn>
					</>
				}
				row={(deck: IDeck) => (
					<DecksProject
						key={deck.deck_id}
						deck={deck}
						control={control}
						showMenu={showMenu}
					/>
				)}
			/>

			<Modal
				visible={deleteModalVisible}
				onDismiss={() => setDeleteModalVisible(false)}
				title={"Confirm Delete"}
				subtitle={"Are you sure you want to delete these docs? This action can not be undone."}
				actions={[
					{
						variant: ButtonVariant.Secondary,
						children: "Cancel",
						action: () => setDeleteModalVisible(false),
						borderShape: ButtonBorderShape.Round,
						testid: "cancel-delete-deck"
					},
					{
						variant: ButtonVariant.Error,
						children: "Confirm",
						action: () => deleteDeck(),
						borderShape: ButtonBorderShape.Round,
						testid: "confirm-delete-deck"
					}
				]}
			>
				<ul>
					{Object.keys(formValue)
						.map((key) => decks.find((p) => p.deck_id === key))
						.filter((deck) => formValue[deck?.deck_id])
						.map((deck) => (
							<DecksProject
								key={deck.deck_id}
								deck={deck}
								showMenu={false}
							/>
						))}
				</ul>
			</Modal>
		</div>
	);
}
