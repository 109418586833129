import { useRef } from "react";
import { Uuid } from "../Uuid";

export function useDebounce(fn: (...args) => void, timeout: number = 200) {
	const lastInvokedRef = useRef(null);

	function call(...args) {
		const key = Uuid.Nano();
		lastInvokedRef.current = key;

		setTimeout(() => {
			if (key === lastInvokedRef.current) {
				console.log("[Debounce] - Took:", key, args);
				fn(...args);
			} else {
				console.log("[Debounce] - Skipped:", key, args);
			}
		}, timeout);
	}

	return call;
}
