import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";
import { WorkspaceAction, WorkspaceContext } from "@sage/state";
import { AskSage } from "../Panes";
import "./AskSagePanel.scss";

export function AskSagePanel() {
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	function toggleAskSage() {
		if (workspaceState.openTools.includes("ASK_SAGE")) {
			workspaceDispatch({
				type: WorkspaceAction.SetOpenTools,
				payload: workspaceState.openTools.filter((tool) => tool !== "ASK_SAGE")
			});
		} else {
			workspaceDispatch({
				type: WorkspaceAction.SetOpenTools,
				payload: ["ASK_SAGE", ...workspaceState.openTools]
			});
		}
	}

	return (
		<div className={"__ask-sage-panel-container"}>
			<div className={"ask-sage-panel-fixed"}>
				<div className={"ask-sage-panel-apps"}>
					<div
						className={`ask-sage-panel-toggle ${workspaceState.openTools.includes("ASK_SAGE") ? "active" : ""}`}
						onClick={toggleAskSage}
						data-testid="toggle-ask-sage-panel"
					>
						<img
							src={`https://cdn.ccm.vc/sage/logo-cropped.svg`}
							width={`24px`}
						/>
					</div>
				</div>
				<div className={"ask-sage-open-apps"}>
					<AskSage onDismiss={toggleAskSage} />
				</div>
			</div>
		</div>
	);
}
