import React, { ReactElement, isValidElement } from "react";
import { Button, IButtonProps } from "@sage/shared/core";
import { FormField, IFormFieldProps } from "../FormField/FormField";
import "./Form.scss";

export interface IFormProps {
	title?: string;
	description?: string;
	fields: (IFormFieldProps | ReactElement)[];
	actions?: IButtonProps[];
	headerAction?: IButtonProps;
	handleSubmit?: any;
	fill?: boolean;
	center?: boolean;
}

export function Form({ fields, actions, title, description, headerAction, handleSubmit, fill, center }: IFormProps) {
	return (
		<div className={`__form-container ${fill ? "fill" : ""} ${center ? "center" : ""}`}>
			{title || headerAction ? (
				<div className={"form-title"}>
					{title}
					{headerAction ? <Button {...headerAction} /> : null}
				</div>
			) : null}
			{description ? <div className={"form-description"}>{description}</div> : null}
			<div className={"form-fields"}>
				{fields.map((field, idx) => {
					if (isValidElement(field)) return field;
					else
						return (
							<FormField
								key={idx}
								{...(field as IFormFieldProps)}
								handleSubmit={handleSubmit}
							/>
						);
				})}
			</div>
			{actions ? (
				<div className={"form-actions"}>
					{actions.map((action, idx) => (
						<Button
							key={idx}
							{...action}
						/>
					))}
				</div>
			) : null}
		</div>
	);
}
