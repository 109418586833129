import { IDealRoomPhase, PhaseStatus } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetPhases(deal_id: string): Promise<IDealRoomPhase[]> {
	return HttpService.Get<IDealRoomPhase[]>({ path: `/dealrooms/phases/deal/${deal_id}` }).then((res) => res.payload);
}

export function SavePhase(phase: Partial<IDealRoomPhase>) {
	return HttpService.Post({ path: "/dealrooms/phases", body: phase }).then((res) => res.payload);
}

export function DeletePhase(phase_id: string) {
	return HttpService.Delete({ path: `/dealrooms/phases/${phase_id}` }).then((res) => res.payload);
}

export function UpdatePhaseStatus(phase_id: string, phase_status: PhaseStatus) {
	return HttpService.Post({ path: `/dealrooms/phases/${phase_id}/status/${phase_status}` }).then((res) => res.payload);
}

export function UpdatePhaseIndex(phase_id: string, phase_index: number) {
	return HttpService.Post({ path: `/dealrooms/phases/${phase_id}/index/${phase_index}` }).then((res) => res.payload);
}
