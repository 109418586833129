import { Dispatch } from "react";
import { DealStatus, IDeal } from "@sage/types";

export enum DealsAction {
	GetDealsByStatus = "SearchDeals",
	SetDeals = "SetDeals"
}

export interface IGetDealsByStatusAction {
	type: DealsAction.GetDealsByStatus;
	payload: {
		status?: DealStatus;
	};
	dispatch: Dispatch<ISetDealsAction>;
}

export interface ISetDealsAction {
	type: DealsAction.SetDeals;
	payload: IDeal[];
}

export type DealsActions = ISetDealsAction | IGetDealsByStatusAction;
