import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ICompanyPerson } from "@sage/types";
import { DealstreamService } from "@sage/services";
import {
	Button,
	ButtonBorderShape,
	ButtonIcon,
	ButtonVariant,
	Card,
	CardList,
	CardListItem,
	CardListItemText,
	CardListItemTitle,
	CardTitle
} from "@sage/shared/core";

export function Employees({ employees, people, inline }: { employees: ICompanyPerson[]; people: ICompanyPerson[]; inline?: boolean }) {
	return (
		<>
			{employees.length ? (
				<Card inline={inline}>
					{inline !== true && <CardTitle>Employees</CardTitle>}
					<CardList>
						{employees?.map((employee) => (
							<Employee
								inline={inline}
								key={employee.name}
								employee={employee}
							/>
						))}
					</CardList>
				</Card>
			) : null}
			{people.length ? (
				<Card inline={inline}>
					{inline !== true && <CardTitle>Related People</CardTitle>}
					<div className="card-subtitle">
						These people may not be direct employees, but references were found to these people durring this company's deep
						crawl
					</div>
					<CardList>
						{people.map((person) => (
							<OtherPerson
								inline={inline}
								key={person.name + person.job_title}
								person={person}
							/>
						))}
					</CardList>
				</Card>
			) : null}
		</>
	);
}

function Employee({ employee, inline }: { employee: ICompanyPerson; inline?: boolean }) {
	let [expansion, setExpansion] = useState<string>(null);
	let [expansionLoading, setExpansionLoading] = useState<boolean>(false);

	const params = useParams();

	async function expand() {
		if (params.uid) {
			setExpansionLoading(true);
			const expansionPrompt = `Employee Details:\nName: ${employee.name}\nJob Title: ${employee.job_title}\nBio: ${employee.bio}`;

			const response = await DealstreamService.Expand(params.uid, expansionPrompt, setExpansion);
			setExpansionLoading(false);
			console.log(response.sources);
		}
	}

	return (
		<CardListItem>
			<CardListItemTitle data-testid="company-employees-employee-name">{employee.name}</CardListItemTitle>
			<CardListItemText data-testid="company-employees-employee-job-title">{employee.job_title}</CardListItemText>
			{employee.email_address ? (
				<CardListItemText data-testid="company-employees-employee-email-address">{employee.email_address}</CardListItemText>
			) : null}
			{employee.phone_number ? (
				<CardListItemText data-testid="company-employees-employee-phone-number">{employee.phone_number}</CardListItemText>
			) : null}
			<CardListItemText data-testid="company-employees-employee-bio">{employee.bio}</CardListItemText>
			{expansion ? (
				<CardListItemText data-testid="company-employees-expansion">{expansion}</CardListItemText>
			) : inline !== true ? (
				<Button
					variant={ButtonVariant.Secondary}
					icon={ButtonIcon.SageLogo}
					borderShape={ButtonBorderShape.Round}
					action={expand}
					loading={expansionLoading}
					disabled={expansionLoading}
				>
					Expand
				</Button>
			) : null}
		</CardListItem>
	);
}

function OtherPerson({ person, inline }: { person: ICompanyPerson; inline?: boolean }) {
	let [expansion, setExpansion] = useState<string>(null);
	let [expansionLoading, setExpansionLoading] = useState<boolean>(false);

	const params = useParams();

	async function expand() {
		if (params.uid) {
			setExpansionLoading(true);
			const expansionPrompt = `Person Details:\nNote, we have not verified whether or not this person is an employee, if you find evidence that they are, feel free to say so.\nName: ${person.name}\nJob Title: ${person.job_title}\nBio: ${person.bio}`;

			const response = await DealstreamService.Expand(params.uid, expansionPrompt, setExpansion);
			setExpansionLoading(false);
			console.log(response.sources);
		}
	}

	return (
		<CardListItem>
			<CardListItemTitle data-testid="company-employees-person-name">{person.name}</CardListItemTitle>
			<CardListItemText data-testid="company-employees-person-job-title">{person.job_title}</CardListItemText>
			{person.email_address ? (
				<CardListItemText data-testid="company-employees-person-email-address">{person.email_address}</CardListItemText>
			) : null}
			{person.phone_number ? (
				<CardListItemText data-testid="company-employees-person-phone-number">{person.phone_number}</CardListItemText>
			) : null}
			<CardListItemText data-testid="company-employees-person-bio">{person.bio}</CardListItemText>
			{expansion ? (
				<CardListItemText data-testid="company-employees-expansion">{expansion}</CardListItemText>
			) : inline !== true ? (
				<Button
					variant={ButtonVariant.Secondary}
					icon={ButtonIcon.SageLogo}
					borderShape={ButtonBorderShape.Round}
					action={expand}
					loading={expansionLoading}
					disabled={expansionLoading}
				>
					Expand
				</Button>
			) : null}
		</CardListItem>
	);
}
