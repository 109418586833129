import { IInfoRequest } from "@sage/types";
import { Bullet, Col, DateHeader, FaIcon, Link, Row, SearchResult, SectionTitle, TimeHeader } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import "./InfoRequest.scss";

export function InfoRequestCard({
	request,
	select,
	searchTerm,
	deleteRequest
}: {
	request: IInfoRequest;
	select?: () => void;
	searchTerm?: string;
	delete?: () => void;
	deleteRequest?: () => void;
}) {
	function handleSelect() {
		if (!isNullOrUndefined(select)) {
			select();
		}
	}

	function handleDelete(e) {
		e.stopPropagation();
		e.preventDefault();
		if (!isNullOrUndefined(deleteRequest)) {
			deleteRequest();
		}
	}

	return (
		<div
			className="__sage-deal-room-info-reqeust-card"
			onClick={handleSelect}
		>
			<Col>
				<Row
					gap="1rem"
					wrap={false}
					verticalAlign="center"
				>
					<FaIcon
						color={"#00435c"}
						icon={"list"}
						width="fit-content"
						size={"2x"}
						borderRadius={"0.5rem"}
						paddingInline="0.75rem"
						padding="0.5rem"
						border
						hideBg
						direction="row"
					/>
					<Col gap={"0.25rem"}>
						<SectionTitle inline>
							<Link to={`/app/dealroom/${request.deal_id}/phase/${request.phase_id}/info/${request.request_id}`}>
								<SearchResult searchTerm={searchTerm}>{request.request_name}</SearchResult>
							</Link>
						</SectionTitle>
						<Row verticalAlign="center">
							<DateHeader
								size="sm"
								thin
							>
								{request.lastModifiedTimestamp}
							</DateHeader>
							<Bullet size="sm" />
							<TimeHeader size="sm">{request.lastModifiedTimestamp}</TimeHeader>
						</Row>
					</Col>
				</Row>
				<Row horizontalAlign="right">
					{!isNullOrUndefined(deleteRequest) && (
						<FaIcon
							icon="trash-can"
							color="#a80300"
							size="lg"
							onClick={handleDelete}
						/>
					)}
				</Row>
			</Col>
		</div>
	);
}
