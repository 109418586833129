import { IDealRoomElementSource } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetSourcesByElement(element_id: string): Promise<IDealRoomElementSource[]> {
	return HttpService.Get<IDealRoomElementSource[]>({ path: `/dealrooms/element-sources/element/${element_id}` }).then(
		(res) => res.payload
	);
}

export function DeleteElementSource(source_id: string) {
	return HttpService.Delete({ path: `/dealrooms/element-sources/${source_id}` });
}

export function CreateElementSource(source: Partial<IDealRoomElementSource>) {
	return HttpService.Post({ path: "/dealrooms/element-sources", body: source }).then((res) => res.payload);
}
