import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IAnnouncement } from "@sage/types";
import { AnnouncementService } from "@sage/services";
import { Col, Instructions, P, SectionTitle } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";

export function AnnouncementLayout({ children }: { children: ReactNode }) {
	const location = useLocation();
	const [announcements, setAnnouncements] = useState<IAnnouncement[]>([]);
	const [announcement, setAnnouncement] = useState<IAnnouncement>(null);

	async function loadAnnouncements() {
		try {
			setAnnouncements(await AnnouncementService.GetAnnouncements());
		} catch (e) {}
	}

	function setActiveAnnouncement() {
		if (announcements) {
			const candidates = announcements.filter((a) => a.active_pages.some((page) => new RegExp(page).test(location.pathname)));
			setAnnouncement(candidates[0]);
		}
	}

	async function dismiss() {
		setAnnouncement(null);
		await AnnouncementService.SaveAnnouncementReciept(announcement.announcement_id);
		await loadAnnouncements();
	}

	useEffect(() => {
		loadAnnouncements();
	}, []);

	useEffect(() => {
		loadAnnouncements();
	}, [location]);

	useEffect(() => {
		setActiveAnnouncement();
	}, [location, announcements]);

	return (
		<>
			{children}
			{announcement && (
				<Instructions
					visible={!isNullOrUndefined(announcement)}
					onDismiss={dismiss}
					videos={announcement.pages.map((p) => p.video)}
				>
					{announcement.pages.map((page, idx) => (
						<Col key={`${page.title}-${idx}`}>
							<SectionTitle>{page.title}</SectionTitle>
							<P maxWidth={"30rem"}>{page.text}</P>
						</Col>
					))}
				</Instructions>
			)}
		</>
	);
}
