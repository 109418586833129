import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { Button, ButtonBorderShape } from "@sage/shared/core";
import "./DealDetails.scss";

export function DealDetails() {
	const { updatePageStatus } = useWorkflowState();
	const navigate = useNavigate();

	function handleNav() {
		updatePageStatus("complete", "Deal Details");
		navigate("../basic-details");
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Deal Details");
		return () => {
			updatePageStatus("complete", "Deal Details");
		};
	}, []);

	return (
		<section>
			<h2>CIM Section Workflow</h2>
			<p>
				In this workflow you can follow this guided walkthrough to create a CIM section with Sage. There are three broad sections.
			</p>
			<ol>
				<li>Deal Details</li>
				<li>Templates</li>
				<li>Data Sources</li>
			</ol>
			<p>
				You can provide details about your deal, collect data on the company you are working on, create or select templates, upload
				or select data sources, and create your CIM.
			</p>
			<p>
				<strong>Your progress will be saved</strong> as you walk through these steps, so you can get started now and come back when
				you are able to finish.
			</p>
			<Button
				borderShape={ButtonBorderShape.Round}
				action={handleNav}
			>
				Get Started
			</Button>
		</section>
	);
}
