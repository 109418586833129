import React from "react";
import "./Tabs.scss";

export interface ITabsProps {
	visible?: boolean;
	collapsed?: boolean;
	activeTab: string;
	setActiveTab: (tab: string) => void;
	tabs: ITab[];
}

export interface ITab {
	fullName: string;
	shortName?: string;
	id: string;
}

export function Tabs({ visible, collapsed, activeTab, setActiveTab, tabs }) {
	function calculateOffset() {
		const activeIdx = tabs.findIndex((tab) => tab.id === activeTab);
		return `${(activeIdx / tabs.length) * 100}%`;
	}

	function calculateWidth() {
		return `calc(${(1 / tabs.length) * 100}% - 0.675rem)`;
	}

	if (visible !== false) {
		return (
			<div className="__decks-tools-pane-tabs-container">
				{tabs.map((tab) => (
					<button
						key={tab.id}
						onMouseDown={() => setActiveTab(tab.id)}
					>
						{collapsed && tab.shortName ? tab.shortName : tab.fullName}
					</button>
				))}
				<div
					className="active"
					style={{ left: calculateOffset(), width: calculateWidth() }}
				/>
			</div>
		);
	}
	return null;
}
