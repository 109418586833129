import React, { useEffect, useState } from "react";
import { ICompanyCBD } from "@sage/types";
import { CompanyLinks } from "@sage/shared/company";
import { Card, CardList, CardListItem, CardListItemText, CardListItemTitle, Col, H1, P, Row } from "@sage/shared/core";
import "./CompanyOverview.scss";

export function CompanyOverview({ company }: { company: ICompanyCBD }) {
	const [addressString, setAddressString] = useState<string>(null);

	function createAddressString() {
		let address_str = "";

		if (company.address) {
			if (company.address.locality) {
				address_str = company.address.locality;
			}

			if (company.address.region) {
				address_str = `${address_str}${address_str.length ? ", " : ""}${company.address.region}`;
			}

			if (company.address.country) {
				address_str = `${address_str}${address_str.length ? ", " : ""}${company.address.country}`;
			}
		}

		setAddressString(address_str);
	}

	useEffect(() => {
		createAddressString();
	}, [company.address]);

	return (
		<>
			<Card>
				<Row
					wrap={false}
					horizontalAlign={"between"}
				>
					<Col>
						<H1 data-testid="company-overview-name">{company.name}</H1>
						<P data-testid="company-overview-short-description">{company.short_description}</P>
						{company.industry || company.sector ? (
							<P data-testid="company-overview-industry">
								{company.sector}
								{company.sector && company.industry ? " - " : ""}
								{company.industry}
							</P>
						) : null}
						{addressString ? <P data-testid="company-overview-address">{addressString}</P> : null}
						{company.founding_date ? (
							<P data-testid="company-overview-founding-date">
								Founded{" "}
								{new Date(`${company.founding_date}T00:00:00`).toLocaleDateString("en-US", {
									year: "numeric",
									month: "long",
									timeZone: "UTC"
								})}
							</P>
						) : null}
						<CompanyLinks
							links={company.links}
							image={company.image}
						/>
					</Col>
					<Col>
						<img
							className="__sage-company-logo"
							src={company.image}
						/>
					</Col>
				</Row>
			</Card>
			{(company.long_description || company.industry_description || company.company_size) && (
				<Card>
					<CardList>
						{company.long_description && (
							<CardListItem>
								<CardListItemTitle>Description</CardListItemTitle>
								<P data-testid="company-overview-long-description">{company.long_description}</P>
							</CardListItem>
						)}
						{company.long_description && (
							<CardListItem>
								<CardListItemTitle>Industry Description</CardListItemTitle>
								<P data-testid="company-overview-industry-description">{company.industry_description}</P>
							</CardListItem>
						)}
						{company.long_description && (
							<CardListItem>
								<CardListItemTitle>Company Size</CardListItemTitle>
								<P data-testid="company-overview-company-size">{company.company_size}</P>
							</CardListItem>
						)}
					</CardList>
				</Card>
			)}
		</>
	);
}
