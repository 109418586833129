import React from "react";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import "posthog-js/dist/exception-autocapture";
import "posthog-js/dist/recorder";
import "posthog-js/dist/surveys";
import "posthog-js/dist/tracing-headers";
import "posthog-js/dist/web-vitals";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom/client";
import { Router } from "./Router";
import "./index.scss";

window.useDecks = false;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<PostHogProvider
		apiKey="phc_TjDJG42aDZiO5dbdJNbGr9uMgAbXmJv808qdPCggqkR"
		options={{
			api_host: "/analytics",
			person_profiles: "always",
			disable_external_dependency_loading: true
		}}
	>
		<FpjsProvider
			loadOptions={{
				apiKey: "S3pKrMcez6MPABwpOCyJ",
				endpoint: ["/fp"],
				scriptUrlPattern: ["/fp/web/v3/S3pKrMcez6MPABwpOCyJ/loader_v3.9.10.js"]
			}}
		>
			<Router />
		</FpjsProvider>
	</PostHogProvider>
);
