import * as React from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import Editor from "./Editor";
import "./Editor.scss";
import { HandleInsert } from "./HandleInsert";
import { SharedAutocompleteContext } from "./context/SharedAutocompleteContext";
import { SharedHistoryContext } from "./context/SharedHistoryContext";
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";

export const AdvancedEditor = () => {
	const initialConfig = {
		editable: true,
		editorState: undefined,
		namespace: "SageAI",
		nodes: [...PlaygroundNodes],
		onError: (error: Error) => {
			throw error;
		},
		theme: PlaygroundEditorTheme
	};

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<HandleInsert />
			<SharedHistoryContext>
				<TableContext>
					<SharedAutocompleteContext>
						<div className="editor-shell">
							<Editor />
						</div>
					</SharedAutocompleteContext>
				</TableContext>
			</SharedHistoryContext>
		</LexicalComposer>
	);
};
