import { useEffect, useState } from "react";
import { IDealRoomCrawl } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Button, Col, DeleteConfirmation, Modal, P, Pad, Row } from "@sage/shared/core";
import { Crawl } from "@sage/shared/dealrooms";
import { isNullOrUndefined } from "@sage/utils";

export function DealWebData() {
	const { deal_id, viewDealWebData, setViewDealWebData, setAddWebDataModalVisible } = useDealRoom();
	const [crawls, setCrawls] = useState<IDealRoomCrawl[]>([]);
	const [deletePendingCrawl, setDeletePendingCrawl] = useState<IDealRoomCrawl>(null);

	async function loadCrawls() {
		setCrawls(await DealRoomService.GetCrawls(deal_id));
	}

	async function startCrawl(crawl: IDealRoomCrawl) {
		await DealRoomService.StartCrawl(crawl.starting_url, crawl.crawl_id);
		await loadCrawls();
	}

	async function deleteCrawl(crawl_id: string) {
		setDeletePendingCrawl(null);
		await DealRoomService.DeleteCrawl(crawl_id);
		await loadCrawls();
	}

	function newCrawl() {
		setAddWebDataModalVisible(true);
		setViewDealWebData(false);
	}

	useEffect(() => {
		loadCrawls();
	}, [deal_id, viewDealWebData]);

	useEffect(() => {
		const interval = setInterval(async () => {
			if (viewDealWebData) {
				await loadCrawls();
			}
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<Modal
			title={"Deal Web Crawls"}
			visible={viewDealWebData}
			onDismiss={() => setViewDealWebData(false)}
			minWidth="40rem"
		>
			<Pad>
				{crawls.length === 0 && (
					<Col horizontalAlign="center">
						<P>You haven't created any web crawls for this deal yet.</P>
						<Button action={newCrawl}>New Crawl</Button>
					</Col>
				)}
				{crawls.map((crawl) => (
					<Crawl
						key={crawl.crawl_id}
						crawl={crawl}
						startCrawl={() => startCrawl(crawl)}
						deleteCrawl={() => setDeletePendingCrawl(crawl)}
					/>
				))}
			</Pad>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deletePendingCrawl)}
				onDismiss={() => setDeletePendingCrawl(null)}
				onConfirm={() => deleteCrawl(deletePendingCrawl.crawl_id)}
			>
				<Pad>
					{deletePendingCrawl && (
						<Crawl
							key={deletePendingCrawl.crawl_id}
							crawl={deletePendingCrawl}
						/>
					)}
				</Pad>
			</DeleteConfirmation>
		</Modal>
	);
}
