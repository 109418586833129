import * as React from "react";
import { useEffect, useState } from "react";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import useLexicalEditable from "@lexical/react/useLexicalEditable";
import { useSettings } from "./context/SettingsContext";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import TableCellNodes from "./nodes/TableCellNodes";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeActionMenuPlugin from "./plugins/CodeActionMenuPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import CollapsiblePlugin from "./plugins/CollapsiblePlugin";
import ComponentPickerPlugin from "./plugins/ComponentPickerPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import EquationsPlugin from "./plugins/EquationsPlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import KeywordsPlugin from "./plugins/KeywordsPlugin";
import LinkPlugin from "./plugins/LinkPlugin";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin";
import { MaxLengthPlugin } from "./plugins/MaxLengthPlugin";
import SpeechToTextPlugin from "./plugins/SpeechToTextPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import { TablePlugin as NewTablePlugin } from "./plugins/TablePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import ContentEditable from "./ui/ContentEditable";
import Placeholder from "./ui/Placeholder";

const skipCollaborationInit =
	// @ts-ignore
	window.parent != null && window.parent.frames.right === window;

export default function Editor(): JSX.Element {
	const { historyState } = useSharedHistoryContext();
	const {
		settings: { isCollab, isMaxLength, isRichText, showTreeView, tableCellMerge, tableCellBackgroundColor }
	} = useSettings();
	const isEditable = useLexicalEditable();
	const text = isCollab ? "Enter some collaborative rich text..." : isRichText ? "Enter some text..." : "Enter some plain text...";
	const placeholder = <Placeholder>{text}</Placeholder>;
	const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
	const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);

	const onRef = (_floatingAnchorElem: HTMLDivElement) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const cellEditorConfig = {
		namespace: "Playground",
		nodes: [...TableCellNodes],
		onError: (error: Error) => {
			throw error;
		},
		theme: PlaygroundEditorTheme
	};

	useEffect(() => {
		const updateViewPortWidth = () => {
			const isNextSmallWidthViewport = window.matchMedia("(max-width: 1025px)").matches;

			if (isNextSmallWidthViewport !== isSmallWidthViewport) {
				setIsSmallWidthViewport(isNextSmallWidthViewport);
			}
		};
		updateViewPortWidth();
		window.addEventListener("resize", updateViewPortWidth);

		return () => {
			window.removeEventListener("resize", updateViewPortWidth);
		};
	}, [isSmallWidthViewport]);

	return (
		<>
			{isRichText && <ToolbarPlugin />}
			<div className={`editor-container ${showTreeView ? "tree-view" : ""} ${!isRichText ? "plain-text" : ""}`}>
				{isMaxLength && <MaxLengthPlugin maxLength={30} />}
				<DragDropPaste />
				<AutoFocusPlugin />
				<ClearEditorPlugin />
				<ComponentPickerPlugin />
				<HashtagPlugin />
				<KeywordsPlugin />
				<SpeechToTextPlugin />
				<AutoLinkPlugin />
				{isRichText ? (
					<>
						<HistoryPlugin externalHistoryState={historyState} />
						<RichTextPlugin
							contentEditable={
								<div className="editor-scroller">
									<div
										className="editor"
										ref={onRef}
									>
										<ContentEditable />
									</div>
								</div>
							}
							placeholder={placeholder}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<MarkdownShortcutPlugin />
						<CodeHighlightPlugin />
						<ListPlugin />
						<CheckListPlugin />
						<TablePlugin
							hasCellMerge={tableCellMerge}
							hasCellBackgroundColor={tableCellBackgroundColor}
						/>
						<TableCellResizer />
						<NewTablePlugin cellEditorConfig={cellEditorConfig}>
							<AutoFocusPlugin />
							<RichTextPlugin
								contentEditable={<ContentEditable className="TableNode__contentEditable" />}
								placeholder={null}
								ErrorBoundary={LexicalErrorBoundary}
							/>
							<HistoryPlugin />
							<ImagesPlugin captionsEnabled={false} />
							<LinkPlugin />
							<LexicalClickableLinkPlugin />
							<FloatingTextFormatToolbarPlugin />
						</NewTablePlugin>
						<ImagesPlugin />
						<LinkPlugin />
						{!isEditable && <LexicalClickableLinkPlugin />}
						<HorizontalRulePlugin />
						<EquationsPlugin />
						<TabFocusPlugin />
						<TabIndentationPlugin />
						<CollapsiblePlugin />
						{floatingAnchorElem && !isSmallWidthViewport && (
							<>
								<DraggableBlockPlugin anchorElem={floatingAnchorElem} />
								<CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
								<FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
								<FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
							</>
						)}
					</>
				) : (
					<>
						<PlainTextPlugin
							contentEditable={<ContentEditable />}
							placeholder={placeholder}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<HistoryPlugin externalHistoryState={historyState} />
					</>
				)}
				{/*<AutocompletePlugin />*/}
			</div>
		</>
	);
}
