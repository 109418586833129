import React, { ReactNode } from "react";
import "./WorkflowLayout.scss";

export interface IWorkflowLayoutProps {
	children: ReactNode;
	status: ReactNode;
}

export function WorkflowLayout({ children, status }: IWorkflowLayoutProps) {
	return (
		<article className="__sage-workflow-layout-container">
			<section className="workflow-form">{children}</section>
			{status}
		</article>
	);
}
