import React from "react";
import { IDeal } from "@sage/types";
import { Card } from "../Card/Card";
import "./Col.scss";

export interface ICol {
	items: IDeal[];
	title: string;
	id: string;
}

export function Col({ items, title, editDeal }) {
	return (
		<div className={`__deals-active-deals-board-col-container`}>
			<div className={"col-title"}>{title}</div>
			{items.map((item) => (
				<Card
					key={item.deal_id}
					deal={item}
					editDeal={editDeal}
				/>
			))}
		</div>
	);
}
