import React, { useContext, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { FsService, ProjectService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, DropdownMenu, IDropdownOptionProps } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { UserIcon } from "@sage/shared/other";
import { FloatingPane } from "../Panes/FloatingPane/FloatingPane";
import { Share } from "../Panes/Share/Share";
import "./WorkspaceHeader.scss";

export function WorkspaceHeader() {
	const authState = useContext(AuthContext);
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	const [openTab, setOpenTab] = useState<string>(null);
	const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

	const { control, setValue, reset } = useForm();
	const formValue = useWatch({ control });

	const navigate = useNavigate();

	const projectNameRef = useRef(null);

	function navBack() {
		if (window.history?.state?.idx) {
			navigate(-1);
		} else {
			navigate("/app/");
		}
	}

	async function toggleFavorite() {
		await ProjectService.UpdateProjectFavorited({
			project_id: workspaceState.project.project_id,
			favorited: !workspaceState.project.favorited
		});

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: {
				...workspaceState.project,
				favorited: !workspaceState.project.favorited
			}
		});
	}

	function switchDocument(document_id: string) {
		workspaceDispatch({
			type: WorkspaceAction.SetActiveDocument,
			payload: document_id
		});
	}

	async function createDocument() {
		const newDocument = await ProjectService.CreateDocument({
			user_id: workspaceState.project.user_id,
			project_id: workspaceState.project.project_id,
			teamCode: workspaceState.project.teamCode
		});

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: {
				...workspaceState.project,
				documents: [...workspaceState.project.documents, newDocument]
			}
		});

		workspaceDispatch({
			type: WorkspaceAction.SetActiveDocument,
			payload: newDocument.document_id
		});
	}

	async function copyProject() {
		const project = await ProjectService.DuplicateProject(workspaceState.project.project_id);
		navigate(`/app/workspace/${project.project_id}`);
	}

	async function newProject() {
		const project = await FsService.CreateNewProject({
			user_id: authState.user.user_id,
			teamCode: authState.team.teamCode
		});
		navigate(`/app/workspace/${project.project_id}`);
	}

	function updateActivePane(paneName: string) {
		let tools = [];
		if (workspaceState.openTools.includes(paneName)) {
			tools = [paneName, ...workspaceState.openTools.filter((name) => name !== paneName)];
		} else {
			tools = [paneName, ...workspaceState.openTools];
		}

		workspaceDispatch({
			type: WorkspaceAction.SetOpenTools,
			payload: tools
		});

		if (!["ASK_SAGE", "SHARE"].includes(paneName))
			workspaceDispatch({
				type: WorkspaceAction.SetToolsPaneOpen,
				payload: true
			});
	}

	function toggleNotification(idx) {
		if (formValue["notifications"] && formValue["notifications"][`${idx}`])
			setValue(`notifications.${idx}`, !formValue["notifications"][`${idx}`]);
		else setValue(`notifications.${idx}`, true);
	}

	function clearNotifications() {
		const notificationsToClear = Object.keys(formValue["notifications"]).filter((key) => formValue["notifications"][key]);

		const newOperations = workspaceState.activeOperations.filter((_, i) => !notificationsToClear.includes(`${i}`));

		if (newOperations.length === 0) {
			setNotificationsOpen(false);
		}

		workspaceDispatch({
			type: WorkspaceAction.SetActiveOperations,
			payload: newOperations
		});
		reset({});
	}

	async function saveProjectName() {
		const projectName = formValue["projectName"];

		if (projectName && projectName !== workspaceState.project.projectName) {
			workspaceDispatch({
				type: WorkspaceAction.SetProject,
				payload: {
					...workspaceState.project,
					projectName
				}
			});

			await ProjectService.UpdateProjectName({
				project_id: workspaceState.project.project_id,
				projectName
			});
		}
	}

	function updateOpenTab(tab) {
		if (tab === openTab) {
			setOpenTab(null);
		} else {
			setOpenTab(tab);
		}
	}

	return (
		<div className={"__workspace-header-container"}>
			<img
				alt={"logo"}
				className={"header-img"}
				src={"https://cdn.ccm.vc/sage/logo-lg.svg"}
				onClick={navBack}
			/>
			<div className={"workspace-toolbar"}>
				<div className={"workspace-row"}>
					<div className={"workspace-col"}>
						<div className={"project-name"}>
							<Controller
								control={control}
								render={({ field }) => (
									<div
										contentEditable
										onInput={(e) => field.onChange(e.currentTarget.textContent)}
										onBlur={saveProjectName}
										suppressContentEditableWarning
										ref={projectNameRef}
									>
										{workspaceState.project.projectName}
									</div>
								)}
								name={"projectName"}
							/>
						</div>
						<div className={"top-actions"}>
							<img
								alt={"star"}
								src={`https://cdn.ccm.vc/sage/icons/material-star${workspaceState.project.favorited ? "-filled" : ""}.svg`}
								onClick={toggleFavorite}
							/>
						</div>
					</div>
				</div>
				<div className={"workspace-row"}>
					<div className={"workspace-col"}>
						<div className={"workspace-actions"}>
							<div
								className={`workspace-action ${openTab === "File" ? "active" : ""}`}
								onClick={() => updateOpenTab("File")}
							>
								File
								<DropdownMenu
									visible={openTab === "File"}
									options={[
										{
											text: "New",
											type: "option",
											icon: ButtonIcon.MaterialNew,
											action: newProject
										},
										{
											text: "Make a Copy",
											type: "option",
											icon: ButtonIcon.MaterialCopy,
											action: copyProject
										},
										{
											type: "divider"
										},
										{
											text: "Share",
											type: "option",
											icon: ButtonIcon.MaterialShare,
											action: () => updateActivePane("SHARE")
										},
										{
											type: "divider"
										},
										{
											text: workspaceState.project.favorited ? "Un-Favorite" : "Favorite",
											type: "option",
											icon: workspaceState.project.favorited
												? ButtonIcon.MaterialStarFilled
												: ButtonIcon.MaterialStar,
											action: toggleFavorite
										},
										{
											text: "Rename",
											type: "option",
											icon: ButtonIcon.MaterialRename,
											action: () => projectNameRef.current.focus()
										}
									]}
								/>
							</div>
							<div
								className={`workspace-action ${openTab === "Tools" ? "active" : ""}`}
								onClick={() => updateOpenTab("Tools")}
							>
								Tools
								<DropdownMenu
									visible={openTab === "Tools"}
									options={[
										{
											text: "Ask Sage",
											type: "option",
											icon: ButtonIcon.SageLogo,
											action: () => updateActivePane("ASK_SAGE")
										},
										{
											text: "Templates",
											type: "option",
											icon: ButtonIcon.IntelligentTemplates,
											action: () => updateActivePane("TEMPLATES")
										},
										{
											type: "divider"
										},
										{
											text: "Project Files",
											type: "option",
											icon: ButtonIcon.MaterialOpen,
											action: () => updateActivePane("FILES")
										},
										{
											text: "Vault Files",
											type: "option",
											icon: ButtonIcon.Vault,
											action: () => updateActivePane("UPLOAD_FILE")
										}
									]}
								/>
							</div>
							<div
								className={`workspace-action ${openTab === "Tabs" ? "active" : ""}`}
								onClick={() => updateOpenTab("Tabs")}
							>
								Pages
								<DropdownMenu
									visible={openTab === "Tabs"}
									options={[
										...workspaceState.project?.documents.map(
											(tab) =>
												({
													text: tab.documentName,
													type: "option",
													icon: ButtonIcon.Document,
													action: () => switchDocument(tab.document_id)
												}) as IDropdownOptionProps
										),
										{
											type: "divider"
										},
										{
											text: "New",
											type: "option",
											icon: ButtonIcon.MaterialNewTab,
											action: createDocument
										}
									]}
								/>
							</div>
							<div
								className={"workspace-action"}
								onClick={() => window.open("https://sageai.dev/tutorials", "_blank")}
							>
								Help
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={"workspace-social"}>
				{workspaceState.activeOperations.length ? (
					<div className={"notification-toggle"}>
						<img
							alt={"notifications"}
							className="notification-icon"
							src={"https://cdn.ccm.vc/sage/icons/material-progress.svg"}
							width={"24px"}
							onClick={() => setNotificationsOpen((e) => !e)}
						/>
						<div className={"notification-badge"}>{workspaceState.activeOperations.length}</div>
						{notificationsOpen ? (
							<div className={"notification-list"}>
								<FloatingPane
									title={"Notifications"}
									actions={[
										{
											children: "Close",
											variant: ButtonVariant.Secondary,
											borderShape: ButtonBorderShape.Round,
											action: () => setNotificationsOpen(false)
										},
										{
											children: "Clear",
											variant: ButtonVariant.Error,
											borderShape: ButtonBorderShape.Round,
											action: clearNotifications
										}
									]}
								>
									{workspaceState.activeOperations.map((op, idx) => (
										<div
											className={"notification-item"}
											key={idx}
											onClick={() => toggleNotification(idx)}
										>
											<Checkbox
												control={control}
												name={`notifications.${idx}`}
												defaultValue={false}
											/>
											<div className={"notification-text"}>{op}</div>
										</div>
									))}
								</FloatingPane>
							</div>
						) : null}
					</div>
				) : null}
				<Button
					borderShape={ButtonBorderShape.Round}
					icon={ButtonIcon.MaterialShareWhite}
					action={() => updateActivePane("SHARE")}
				>
					Share
				</Button>
				<UserIcon />
				<Share />
			</div>
		</div>
	);
}
