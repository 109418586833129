import React, { useContext, useEffect, useReducer } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { AuthContext, FsAction, FsContext, FsReducer, InitialFsState } from "@sage/state";
import { AppLayout } from "@sage/shared/layouts";

export function FsGuard() {
	const { authDispatch } = useOutletContext<{ authDispatch }>();
	const [fsState, fsDispatch] = useReducer(FsReducer, InitialFsState);
	const authState = useContext(AuthContext);

	function loadFs() {
		if (authState.user) {
			fsDispatch({
				type: FsAction.LoadSharedProjects,
				dispatch: fsDispatch
			});

			fsDispatch({
				type: FsAction.LoadRecentProjects,
				dispatch: fsDispatch
			});

			fsDispatch({
				type: FsAction.LoadFavoritedProjects,
				dispatch: fsDispatch
			});
		}
	}

	useEffect(() => {
		loadFs();
	}, [authState.user]);

	return (
		<FsContext.Provider value={fsState}>
			<AppLayout>
				<Outlet context={{ fsDispatch, authDispatch }} />
			</AppLayout>
		</FsContext.Provider>
	);
}
