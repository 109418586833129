import { DealRoomService } from "@sage/services";
import { Bullet, Bytes, Col, DateHeader, FaIcon, Link, P, Row, SearchResult, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import { VaultFSIcon } from "@sage/shared/other";
import { isNullOrUndefined } from "@sage/utils";
import "./SourceFile.scss";

export interface ISourceFileProps {
	source: any;
	deleteSource?: (source_id: string) => void;
	searchTerm?: string;
}

export function SourceFile({ source, deleteSource, searchTerm }: ISourceFileProps) {
	async function downloadSource() {
		const url = await DealRoomService.DownloadSource(source.source_id);

		window.open(url, "_blank");
	}

	function handleClick(fn) {
		function inner(e, ...args) {
			e.preventDefault();
			e.stopPropagation();
			fn(...args);
		}
		return inner;
	}

	return (
		<div
			className="__sage-deal-room-source-file"
			onClick={downloadSource}
		>
			<Col>
				<Row
					verticalAlign={"center"}
					wrap={false}
					gap={"1rem"}
				>
					<VaultFSIcon
						fileType={source.file_type}
						size={30}
						bg
					/>
					<Col gap={"0.125rem"}>
						<SectionTitle inline>
							<SearchResult searchTerm={searchTerm}>{source.file_name}</SearchResult>
						</SectionTitle>
					</Col>
				</Row>
				<Row verticalAlign="center">
					{!isNullOrUndefined(source.page_number) && (
						<>
							<Tag size="sm">Page {parseInt(source.page_number) + 1}</Tag>
							<Bullet size="sm" />
						</>
					)}
					<DateHeader
						size="sm"
						thin
					>
						{parseInt(source.lastModifiedTimestamp)}
					</DateHeader>
					<Bullet size="sm" />
					<TimeHeader size="sm">{parseInt(source.lastModifiedTimestamp)}</TimeHeader>
					{!isNullOrUndefined(source.file_size) && (
						<>
							<Bullet size="sm" />
							<Bytes size="sm">{parseInt(source.file_size)}</Bytes>
						</>
					)}
					{!isNullOrUndefined(source.relevance_score) && (
						<>
							<Bullet size="sm" />
							<Tag size="sm">{Math.round(source.relevance_score * 10000) / 100}% Match</Tag>
						</>
					)}
				</Row>
				{false && !isNullOrUndefined(source.fragment_text) && (
					<Col gap="1rem">
						{source.fragment_text.map((fragment, idx) => (
							<P key={idx}>
								<SearchResult searchTerm={searchTerm}>{fragment}</SearchResult>
							</P>
						))}
					</Col>
				)}
				{!isNullOrUndefined(deleteSource) && (
					<Row horizontalAlign="right">
						<FaIcon
							icon="trash-can"
							color="#a80300"
							size="lg"
							onClick={(e) => handleClick(deleteSource)(e, source.source_id)}
						/>
					</Row>
				)}
			</Col>
		</div>
	);
}
