import { useEffect, useState } from "react";
import { template } from "cypress/types/lodash";
import { useForm } from "react-hook-form";
import { IDealRoomElementTemplate } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Col, LoadingBubble, Modal, Row, SectionTitle } from "@sage/shared/core";
import { TemplateListItem } from "@sage/shared/dealrooms";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { useDebounce } from "@sage/utils";
import "./BrowseTemplates.scss";

export function BrowseTemplatesModal() {
	const { browseTemplateModalVisible, setBrowseTemplateModalVisible, setUpdateElement } = useDealRoom();
	const [templates, setTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [allTemplates, setAllTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const form = useForm();

	async function search(term: string) {
		setLoading(true);
		setSearchTerm(term);
		const response = await DealRoomService.SearchTeamTemplates(term);
		if (response) {
			setTemplates(response);
		} else {
			setTemplates(allTemplates);
		}
		setLoading(false);
	}

	const searchDebounce = useDebounce(search, 1000);

	async function loadTemplates() {
		let _templates = await DealRoomService.GetTemplateByTeam();
		const _recent_templates = await DealRoomService.GetRecentTeamElements();
		const _recent_ids = _recent_templates.map((el) => el.element_id);
		_templates = _templates.filter((el) => !_recent_ids.includes(el.element_id));
		setTemplates([..._recent_templates, ..._templates]);
		setAllTemplates([..._recent_templates, ..._templates]);
	}

	async function selectElement(template_id: string) {
		const _template = await DealRoomService.GetTemplate(template_id);
		setUpdateElement(_template);
	}

	useEffect(() => {
		loadTemplates();
	}, []);

	function dismiss() {
		setBrowseTemplateModalVisible(false);
		setTemplates([]);
	}

	useEffect(() => {
		if (browseTemplateModalVisible) {
			loadTemplates();
		}
	}, [browseTemplateModalVisible]);

	return (
		<Modal
			visible={browseTemplateModalVisible}
			onDismiss={dismiss}
		>
			<div className="__browse-template-modal">
				<SectionTitle>Elements</SectionTitle>
				<Col>
					<FormField
						control={form.control}
						name="search"
						variant={FormFieldVariant.Vertical}
						placeholder="Search..."
						onChange={(e) => {
							setLoading(true);
							searchDebounce(e);
						}}
					/>
					{loading ? (
						<Row horizontalAlign="center">
							<LoadingBubble />
						</Row>
					) : (
						templates.map((template) => (
							<TemplateListItem
								key={template.element_id}
								template={template}
								select={() => selectElement(template.element_id)}
								searchTerm={searchTerm}
							/>
						))
					)}
				</Col>
			</div>
		</Modal>
	);
}
