import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Button, ButtonBorderShape } from "@sage/shared/core";
import { InlineSource, UserIcon } from "@sage/shared/other";
import { ActiveTab, useToolsPaneState } from "../../ToolsPaneState";
import "./Athena.scss";

dayjs.extend(relativeTime);

export function Athena() {
	const { activeTab } = useToolsPaneState();
	const userInputRef = useRef(null);
	const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false);

	const scrollRef = useRef(null);
	const [inProgress, setInProgress] = useState(null);

	const [messages, setMessages] = useState([
		{
			actor: "ASSISTANT",
			actorName: "Athena",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			timestamp: new Date().getTime(),
			sources: []
		},
		{
			actor: "USER",
			actorName: "Matt Carlson",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			timestamp: new Date().getTime(),
			sources: []
		},
		{
			actor: "ASSISTANT",
			actorName: "Athena",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			timestamp: new Date().getTime(),
			sources: []
		},
		{
			actor: "USER",
			actorName: "Matt Carlson",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			timestamp: new Date().getTime(),
			sources: []
		}
	]);

	function calculatePosition() {
		switch (activeTab) {
			case ActiveTab.Template:
				return "400%";
			case ActiveTab.ProjectFiles:
				return "300%";
			case ActiveTab.Vault:
				return "200%";
			case ActiveTab.ReferenceSlides:
				return "100%";
			case ActiveTab.Athena:
				return "0%";
		}
	}

	useEffect(() => {
		if (activeTab === ActiveTab.Athena) scrollRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [messages, inProgress]);

	return (
		<div
			className={`__decks-tools-pane-athena-container tab`}
			style={{ left: calculatePosition() }}
		>
			<div className="chat-window">
				{messages.map((message, idx) => (
					<>
						<div
							key={idx}
							className={`interaction ${message.actor === "ASSISTANT" ? "assistant" : "user"}`}
						>
							<div className={"interaction-title"}>
								<UserIcon useSage={message.actor === "ASSISTANT"} />
								<div className={"interaction-title-right"}>
									<div className={"interaction-username"}>{message.actorName}</div>
									<div className={"interaction-dt"}>{dayjs(message.timestamp).fromNow()}</div>
								</div>
							</div>
							<div className={"interaction-content"}>{message.text}</div>
						</div>
						{message.sources?.length ? (
							<div className={"sources"}>
								{message.sources.map((source, idx) => (
									<InlineSource
										key={idx}
										citation={source}
									/>
								))}
							</div>
						) : null}
					</>
				))}
				<div
					className={"scroll-follower"}
					ref={scrollRef}
				/>
			</div>
			<div className="user-input">
				<div
					className={`text-input-inner ${showPlaceholder ? "placeholder" : ""}`}
					contentEditable
					ref={userInputRef}
					onFocus={() => setShowPlaceholder(false)}
					onBlur={() => {
						if (userInputRef.current.textContent === "") {
							setShowPlaceholder(true);
						}
					}}
					data-testid="ask-sage-form-control"
				/>
				<Button
					children={"Send"}
					borderShape={ButtonBorderShape.Round}
				/>
			</div>
		</div>
	);
}
