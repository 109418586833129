import React, { useContext, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { ICompanyCBD } from "@sage/types";
import { AuthAction, AuthContext } from "@sage/state";
import { DealstreamService } from "@sage/services";
import {
	CompanyNews,
	CompanyOverview,
	ComparableDeals,
	Crawl,
	Employees,
	Events,
	Faqs,
	MAndAActivity,
	Products,
	Services
} from "@sage/shared/company";
import { Col, InlineLoading, TeamDetails } from "@sage/shared/core";

export function PrivateCompany() {
	const authState = useContext(AuthContext);
	const { authDispatch } = useOutletContext<{ authDispatch }>();
	const params = useParams();

	const [company, setCompany] = useState<ICompanyCBD>(null);

	async function loadCompany() {
		setCompany(await DealstreamService.GetCompanyCBD(params.uid));
		authDispatch({
			type: AuthAction.UpdateSearchSettings,
			payload: {
				searchFolderScope: null,
				searchFileScope: null,
				searchCompanyId: params.uid,
				settings: {
					...authState.searchSettings,
					use_data: false,
					use_deals: false,
					use_news: false,
					use_scope: false,
					use_file_scope: false,
					use_company: true
				}
			}
		});
	}

	useEffect(() => {
		if (params.uid) loadCompany();
	}, [params.uid]);

	return (
		<article>
			<TeamDetails />
			{company ? (
				<Col>
					<CompanyOverview company={company} />
					<Crawl
						company={company}
						refresh={loadCompany}
					/>
					<MAndAActivity
						portcos={company.portcos}
						owner={company.owner_id}
					/>
					<Employees
						employees={company.employees}
						people={company.other_people}
					/>
					<ComparableDeals />
					<Faqs faqs={company.faqs} />
					<Events events={company.events} />
					<Products products={company.products} />
					<Services services={company.services} />
					<CompanyNews />
				</Col>
			) : (
				<InlineLoading invert />
			)}
		</article>
	);
}
