import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { VaultService, WorkflowService } from "@sage/services";

export interface IWorkflowState {
	workflow_id: string;
	form: any;
	outline: any;
}

export const InitialWorkflowState = {
	workflow_id: null,
	form: {},
	outline: []
};

export const WorkflowContext = createContext(InitialWorkflowState);

export function createWorkflowState(defaultOutline) {
	const params = useParams();
	const form = useForm();
	const formValue = useWatch({ control: form.control });
	const [outline, setOutline] = useState(defaultOutline);
	const [loading, setLoading] = useState<boolean>(true);

	async function loadWorkflow() {
		const workflow = await WorkflowService.LoadWorkflow(params.workflow_id);
		form.reset(workflow.data);
		setOutline(workflow.outline || defaultOutline);
		setLoading(false);
	}

	async function saveForm() {
		await WorkflowService.SaveWorkflow(params.workflow_id, formValue);
	}

	useEffect(() => {
		if (params.workflow_id) {
			saveForm();
		}
	}, [formValue]);

	useEffect(() => {
		if (params.workflow_id) loadWorkflow();
	}, []);

	return {
		workflow_id: params.workflow_id,
		form,
		outline,
		setOutline,
		loading
	};
}

export function WorkflowStateProvider({ children, defaultOutline }: { children: ReactNode; defaultOutline: any }) {
	const workflowState = createWorkflowState(defaultOutline);

	return <WorkflowContext.Provider value={workflowState}>{workflowState.loading === false ? children : null}</WorkflowContext.Provider>;
}

export function useWorkflowState() {
	const workflowState = useContext(WorkflowContext);

	async function savePageStatus() {
		await WorkflowService.SaveOutline(workflowState.workflow_id, workflowState.outline);
	}

	function updatePageStatus(status: string, section_name: string, page_name?: string) {
		workflowState.setOutline((outline) => {
			const newOutline = [...outline];
			for (let section of newOutline) {
				if (section.title === section_name) {
					if (page_name && section.pages) {
						for (let page of section.pages) {
							if (page.label === page_name) {
								page.status = status;
							}
						}
					} else {
						section.status = status;
					}
				}
			}
			return newOutline;
		});
	}

	function getPageStatus(section_name: string, page_name?: string) {
		const section = workflowState.outline.find((section) => section.title === section_name);

		if (section && page_name) {
			const page = section.pages.find((page) => page.label === page_name);
			return page?.status;
		}

		return section?.status;
	}

	async function setActiveFiles(activeFiles) {
		const activeFileIds = Object.keys(activeFiles).filter((k) => activeFiles[k]);

		const files = await Promise.all(activeFileIds.map((file_id) => VaultService.LoadFile(file_id)));

		workflowState.form.setValue("activeFiles", files);
	}

	async function addActiveFile(file_id) {
		const file = await VaultService.LoadFile(file_id);

		const formValue = workflowState.form.getValues();
		workflowState.form.setValue("activeFiles", [...(formValue?.activeFiles || []), file]);
	}

	useEffect(() => {
		savePageStatus();
	}, [workflowState.outline]);

	return {
		...workflowState,
		updatePageStatus,
		getPageStatus,
		setActiveFiles,
		addActiveFile
	};
}
