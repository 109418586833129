import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { IDocument, IVaultFile, VaultFileType } from "@sage/types";
import { WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService, VaultService } from "@sage/services";
import { CompanyCard } from "@sage/shared/company";
import { ButtonBorderShape, ButtonVariant, Link, Table, TableColumn, TableRow, TableVariant } from "@sage/shared/core";
import { Checkbox } from "@sage/shared/forms";
import { VaultFSIcon, VaultFile, VaultFileVariant, VaultIconName } from "@sage/shared/other";
import { FloatingPane } from "../FloatingPane/FloatingPane";
import "./ProjectFiles.scss";

export function ProjectFiles({ onDismiss }) {
	const workspaceState = useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();

	const companiesForm = useForm();
	const companiesFormValue = useWatch({ control: companiesForm.control });

	const pagesForm = useForm();
	const pagesFormValue = useWatch({ control: pagesForm.control });

	const { control, setValue, reset } = useForm();
	const formValue = useWatch({ control });

	const [removeLoading, setRemoveLoading] = useState<boolean>(false);

	async function remove() {
		setRemoveLoading(true);
		const sourcesToRemove = Object.keys(formValue).filter((key) => !!formValue[key]);
		const pagesToRemove = Object.keys(pagesFormValue).filter((key) => !!pagesFormValue[key]);
		const companiesToRemove = Object.keys(companiesFormValue).filter((key) => !!companiesFormValue[key]);

		reset({});
		pagesForm.reset({});

		const newProject = {
			...workspaceState.project,
			companies: workspaceState.project.companies.filter((company_id) => !companiesToRemove.includes(company_id)),
			sources: workspaceState.project.sources.filter((source) => !sourcesToRemove.includes(source.file_id)),
			documents: workspaceState.project.documents.filter((doc) => !pagesToRemove.includes(doc.document_id))
		};

		workspaceDispatch({
			type: WorkspaceAction.SetProject,
			payload: newProject
		});

		await Promise.all([
			ProjectService.UpdateProjectCompanies({
				project_id: newProject.project_id,
				companies: newProject.companies
			}),
			ProjectService.UpdateProjectSources({
				project_id: newProject.project_id,
				sources: newProject.sources.map(({ file_id }) => file_id)
			}),
			ProjectService.DeleteDocuments({ document_ids: pagesToRemove })
		]);
		setRemoveLoading(false);
	}

	function openDocument(document_id: string) {
		workspaceDispatch({
			type: WorkspaceAction.SetActiveDocument,
			payload: document_id
		});
	}

	async function handleVaultClick(file: IVaultFile) {
		if (file.file_type === VaultFileType.FOLDER) {
			window.open(`/app/fs/vault/files/${file.file_id}`, "_blank");
		} else {
			const url = await VaultService.GetFileUrl(file);
			window.open(url, "_blank");
		}
	}

	function updateForm() {
		const active = {};

		workspaceState.project.sources.forEach((file) => {
			active[file?.file_id] = false;
		});

		workspaceState.activeSources.forEach((file) => {
			active[file?.file_id] = true;
		});

		if (JSON.stringify(workspaceState.activeSources.sort()) !== JSON.stringify(Object.keys(formValue).sort())) reset(active);
	}

	function updateActiveSources() {
		const active = Object.keys(formValue)
			.filter((key) => !!formValue[key])
			.map((file_id) => workspaceState.project.sources.find((f) => f.file_id === file_id));

		if (JSON.stringify(workspaceState.activeSources.sort()) !== JSON.stringify(active.sort()))
			workspaceDispatch({
				type: WorkspaceAction.SetActiveSources,
				payload: active
			});
	}

	function updateActiveCompanies() {
		const active = Object.keys(companiesFormValue).filter((key) => !!companiesFormValue[key]);

		if (JSON.stringify(workspaceState.activeCompanies.sort()) !== JSON.stringify(active.sort()))
			workspaceDispatch({
				type: WorkspaceAction.SetActiveCompanies,
				payload: active
			});
	}

	function handleSelectCompany(company_id: string) {
		const currently_checked = !!companiesForm.getValues(company_id);

		companiesForm.setValue(company_id, !currently_checked);
	}

	useEffect(() => {
		updateActiveSources();
	}, [formValue]);

	useEffect(() => {
		updateActiveCompanies();
	}, [companiesFormValue]);

	useEffect(() => {
		updateForm();
	}, [workspaceState.activeSources]);

	return (
		<FloatingPane
			title={"Project Files"}
			actions={[
				{
					children: "Close",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Secondary,
					action: onDismiss,
					testid: "project-files-pane-close"
				},
				{
					children: "Remove",
					borderShape: ButtonBorderShape.Round,
					variant: ButtonVariant.Error,
					action: remove,
					loading: removeLoading,
					testid: "project-files-pane-remove-file"
				}
			]}
			resizable
		>
			<Table
				items={workspaceState.project.documents}
				variant={TableVariant.Inline}
				row={(doc: IDocument) => (
					<TableRow key={doc.document_id}>
						<TableColumn width={"0"}>
							<Checkbox
								control={pagesForm.control}
								name={doc.document_id}
								defaultValue={false}
								testid="select-project-file"
							/>
						</TableColumn>
						<TableColumn
							icon={<VaultFSIcon iconName={VaultIconName.Docs} />}
							onClick={() => openDocument(doc.document_id)}
						>
							<Link>{doc.documentName}</Link>
						</TableColumn>
					</TableRow>
				)}
			/>
			<Table
				items={workspaceState.project.sources}
				variant={TableVariant.Inline}
				row={(file: IVaultFile) => (
					<VaultFile
						key={file.file_id}
						file={file}
						variant={VaultFileVariant.Abrev}
						onClick={handleVaultClick}
						selection={
							<Checkbox
								control={control}
								name={file.file_id}
								defaultValue={false}
								testid="select-project-files-vault-file"
							/>
						}
					/>
				)}
			/>
			<Table
				items={workspaceState.project.companies}
				variant={TableVariant.Inline}
				row={(company_id: string) => (
					<TableRow key={company_id}>
						<TableColumn width={"0"}>
							<Checkbox
								control={companiesForm.control}
								name={company_id}
								defaultValue={false}
							/>
						</TableColumn>
						<TableColumn>
							<CompanyCard
								company_id={company_id}
								onClick={handleSelectCompany}
							/>
						</TableColumn>
					</TableRow>
				)}
			/>
		</FloatingPane>
	);
}
