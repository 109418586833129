import React, { useContext } from "react";
import { IVaultFile } from "@sage/types";
import { AuthContext } from "@sage/state";
import { TeamDetails } from "@sage/shared/core";
import { VaultFS } from "../FS";
import "./VaultUser.scss";

export function VaultUserPage() {
	const authState = useContext(AuthContext);

	return (
		<div className={"__vault-home-page-container"}>
			<TeamDetails />
			<VaultFS
				defaultParent={
					{
						file_id: authState.user.user_id,
						prefix: `/${authState.user.teamCode}`,
						file_name: `Your Files`
					} as IVaultFile
				}
			/>
		</div>
	);
}
