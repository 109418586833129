import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { IMessage, IThread, IVaultFile, VaultFileType } from "@sage/types";
import { AthenaService, ILlmSource } from "@sage/services";
import { buildRagSources } from "@sage/utils";
import { AuthContext, IAuthState } from "../AuthState";

export interface IAthenaState {
	thread: IThread;
	setThread: Dispatch<SetStateAction<IThread>>;
	messages: IMessage[];
	setMessages: Dispatch<SetStateAction<IMessage[]>>;
	parentFile: IVaultFile;
	setParentFile: Dispatch<SetStateAction<IVaultFile>>;
	vaultFiles: IVaultFile[];
	setVaultFiles: Dispatch<SetStateAction<IVaultFile[]>>;
	sourceFiles: IVaultFile[];
	setSourceFiles: Dispatch<SetStateAction<IVaultFile[]>>;
	sourceCompanies: string[];
	setSourceCompanies: Dispatch<SetStateAction<string[]>>;
	activeSourceFiles: string[];
	setActiveSourceFiles: Dispatch<SetStateAction<string[]>>;
	activeSourceCompanies: string[];
	setActiveSourceCompanies: Dispatch<SetStateAction<string[]>>;
	threads: IThread[];
	setThreads: Dispatch<SetStateAction<IThread[]>>;
}

const InitialAthenaState: IAthenaState = {
	thread: null,
	setThread: null,
	messages: [],
	setMessages: null,
	parentFile: null,
	setParentFile: null,
	vaultFiles: [],
	setVaultFiles: null,
	sourceFiles: [],
	setSourceFiles: null,
	activeSourceFiles: [],
	setActiveSourceFiles: null,
	threads: [],
	setThreads: null,
	sourceCompanies: [],
	setSourceCompanies: null,
	activeSourceCompanies: [],
	setActiveSourceCompanies: null
};

export const AthenaContext = createContext(InitialAthenaState);

export function createAthenaState(): IAthenaState {
	const [thread, setThread] = useState<IThread>(null);
	const [messages, setMessages] = useState<IMessage[]>([]);
	const [parentFile, setParentFile] = useState<IVaultFile>(null);
	const [vaultFiles, setVaultFiles] = useState<IVaultFile[]>([]);
	const [sourceFiles, setSourceFiles] = useState<IVaultFile[]>([]);
	const [activeSourceFiles, setActiveSourceFiles] = useState<string[]>([]);
	const [threads, setThreads] = useState<IThread[]>([]);
	const [sourceCompanies, setSourceCompanies] = useState<string[]>([]);
	const [activeSourceCompanies, setActiveSourceCompanies] = useState<string[]>([]);

	async function saveThread() {
		if (thread) {
			await AthenaService.updateThread({
				thread_id: thread.thread_id,
				sourceFiles: sourceFiles.map((f) => f.file_id),
				sourceCompanies
			});
		}
	}

	useEffect(() => {
		saveThread();
	}, [thread?.thread_id, sourceFiles, sourceCompanies]);

	return {
		thread,
		setThread,
		messages,
		setMessages,
		parentFile,
		setParentFile,
		vaultFiles,
		setVaultFiles,
		sourceFiles,
		setSourceFiles,
		activeSourceFiles,
		setActiveSourceFiles,
		threads,
		setThreads,
		sourceCompanies,
		setSourceCompanies,
		activeSourceCompanies,
		setActiveSourceCompanies
	};
}

export interface IAthenaStateExtended extends IAthenaState {
	updateThread: (thread: IThread) => void;
	addMessage: (message: IMessage) => void;
	addSourceFiles: (files: IVaultFile[]) => void;
	removeSourceFiles: (files: string[]) => void;
	addSourceCompany: (company_id: string) => void;
	removeSourceCompanies: (company_ids: string[]) => void;
	toggleActiveCompanies: (company_id: string, active: boolean) => void;
	toggleActiveSource: (file_ids: string, active: boolean) => void;
	getRagSources: () => ILlmSource[];
}

export function useAthenaState(): IAthenaStateExtended {
	const authState = useContext<IAuthState>(AuthContext);
	const athenaState = useContext<IAthenaState>(AthenaContext);

	function updateThread(thread: IThread) {
		athenaState.setThread(thread);
		athenaState.setMessages(thread.messages);
	}

	async function addMessage(message: IMessage) {
		athenaState.setMessages((m) => [...m, message]);
		await AthenaService.saveMessage(message);
	}

	function addSourceFiles(files: IVaultFile[]) {
		athenaState.setSourceFiles((sourceFiles) => {
			const existingSources = sourceFiles.map((file) => file.file_id);
			const filesToAdd = files.filter((file) => !existingSources.includes(file.file_id));
			const newSources = [...sourceFiles, ...filesToAdd];

			newSources.sort((a, b) => {
				if (a.file_type === VaultFileType.FOLDER && b.file_type === VaultFileType.FOLDER) {
					return a.file_name.localeCompare(b.file_name);
				}
				if (a.file_type === VaultFileType.FOLDER) {
					return -1;
				}
				if (b.file_type === VaultFileType.FOLDER) {
					return 1;
				}
				return a.file_name.localeCompare(b.file_name);
			});

			return newSources;
		});
	}

	function removeSourceFiles(file_ids: string[]) {
		athenaState.setSourceFiles((files) => files.filter((file) => !file_ids.includes(file.file_id)));
	}

	function addSourceCompany(company_id: string) {
		athenaState.setSourceCompanies((companies) => {
			if (!companies.includes(company_id)) {
				return [...companies, company_id];
			} else {
				return companies;
			}
		});
	}

	function removeSourceCompanies(company_ids: string[]) {
		athenaState.setSourceCompanies((companies) => companies.filter((company_id) => !company_ids.includes(company_id)));
	}

	function toggleActiveSource(file_id: string, active: boolean) {
		athenaState.setActiveSourceFiles((sources) => {
			if (active) {
				return [...sources, file_id];
			} else {
				return sources.filter((source_id) => source_id !== file_id);
			}
		});
	}

	function toggleActiveCompanies(company_id: string, active: boolean) {
		athenaState.setActiveSourceCompanies((sources) => {
			if (active) {
				return [...sources, company_id];
			} else {
				return sources.filter((source_id) => source_id !== company_id);
			}
		});
	}

	function getRagSources(): ILlmSource[] {
		return buildRagSources({
			teamCode: authState.user.teamCode,
			sourceFiles: athenaState.sourceFiles || [],
			sourceCompanies: athenaState.sourceCompanies || [],
			activeSourceFiles: athenaState.activeSourceFiles || [],
			activeSourceCompanies: athenaState.activeSourceCompanies || []
		});
	}

	return {
		...athenaState,
		updateThread,
		addMessage,
		addSourceFiles,
		removeSourceFiles,
		toggleActiveSource,
		getRagSources,
		addSourceCompany,
		removeSourceCompanies,
		toggleActiveCompanies
	};
}
