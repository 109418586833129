import { Row } from "@sage/shared/core";
import { DealPhaseProjects } from "../DealPhaseProjects";
import { DealPhaseThreads } from "../DealPhaseThreads";
import "./DealRoomPhaseWindow.scss";

export function DealPhaseWindow() {
	return (
		<div className="__sage-deal-room-phase-window">
			<Row gap="1rem">
				<DealPhaseProjects />
				<DealPhaseThreads />
			</Row>
		</div>
	);
}
