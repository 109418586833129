import React, { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { AuthContext } from "@sage/state";
import { LlmService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, Collapsible } from "@sage/shared/core";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { useDecksState } from "../../../DecksState";
import { ActiveTab, useToolsPaneState } from "../../ToolsPaneState";
import "./Template.scss";

const rewrite_preprompt = `
You are an advanced Mergers & Acquisitions Associate.

It is your job to write a highly professional set of powerpoint bullet points for a Confidential Information Profile.

Be extremely Susinct and straight to the point. Limit your output to {{word_limit}} words.

Use specific but brief language. Make sure you compress the information as much as possible as a lot of data is going on this slide.

Make sure to listen to your word count.
`;

export function Template() {
	const { spanGroups } = useDecksState();
	const { activeTab } = useToolsPaneState();
	const [prepromptVisible, setPrepromptVisible] = useState<boolean>(false);
	const prepromptForm = useForm({ defaultValues: { preprompt: rewrite_preprompt } });
	const preprompt = useWatch({ control: prepromptForm.control, name: "preprompt" });

	function calculatePosition() {
		switch (activeTab) {
			case ActiveTab.Template:
				return "0%";
			case ActiveTab.ProjectFiles:
				return "-100%";
			case ActiveTab.Vault:
				return "-200%";
			case ActiveTab.ReferenceSlides:
				return "-300%";
			case ActiveTab.Athena:
				return "-400%";
		}
	}

	return (
		<div
			className={`__decks-tools-pane-template-container tab`}
			style={{ left: calculatePosition() }}
		>
			<Collapsible
				visible={prepromptVisible}
				toggle={() => setPrepromptVisible((v) => !v)}
				toggleHandle={
					<Button
						children="Edit Preprompt"
						variant={ButtonVariant.Tertiary}
					/>
				}
			>
				<FormField
					control={prepromptForm.control as any}
					name="preprompt"
					type="textarea"
					variant={FormFieldVariant.Vertical}
					rows={20}
					label="Preprompt"
				/>
			</Collapsible>
			<div className="templates-header">Sections</div>
			{spanGroups.map((group) => (
				<TemplateSection
					key={group.join(",")}
					group={group}
					preprompt={preprompt}
				/>
			))}
		</div>
	);
}

const reverse_engineer_preprompt = `
You are an advanced document template builder. The templates you build will be used to write documents as part of an advanced Finance RAG Model.

For each given page you are shown you need to write a series of prompts and static sections that would be able to reproduce the page for a different source company.

Remember, you are writing completely generic templates that are industry agnostic and may be used for a variety of different companies or sets of source data.

If there are industry specific terms, try to generalize them so they could be used on an entirely new industry if needed.

Use the following schema
\`\`\`json
{
    "sections": [
        {
            "prompt": String,
            "search_terms": String[]
        }
    ]
}
\`\`\`

For example:
\`\`\`json
{
    "sections": [
        {
            "prompt": "Describe the company's primary markets and what percentage of total sales they account for over the past 5 years on average.",
            "search_terms": ["primary market", "net sales", "financial statements", "market share report"]
        }
    ]
}
\`\`\`
`;

function TemplateSection({ group, preprompt }) {
	const authState = useContext(AuthContext);
	const {
		activeSlide,
		activeSpanGroup,
		setActiveSpanGroup,
		activeHeader,
		setActiveHeader,
		headerMapping,
		sourceFiles,
		breakSpanGroup,
		breakHeader,
		setRewrittenSections
	} = useDecksState();

	const [template, setTemplate] = useState(null);
	const [templateText, setTemplateText] = useState<string>("");
	const [headerText, setHeaderText] = useState<string>(null);
	const [promptsLoading, setPromptsLoading] = useState<boolean>(false);
	const [rewriteLoading, setRewriteLoading] = useState<boolean>(false);

	async function rewrite() {
		setRewriteLoading(true);
		function update(text: string) {
			setRewrittenSections((s) => ({ ...s, [group.join(",")]: text }));
		}

		const request = {
			preprompt: preprompt
				.replace("{{word_limit}}", templateText.split(" ").length)
				.replace("{{example}}", templateText)
				.replace("{{section_title}}", headerText)
				.replace("{{prompt}}", template.sections.map(({ prompt }) => prompt).join("\n")),
			prompt: `${headerText ? `Section Header: ${headerText}` : ""}\nPrompt: ${template.sections.map(({ prompt }) => prompt).join("\n")}`,
			sources: [
				{
					knn: true,
					index: "vault_data_v2",
					pretty: true,
					teamCode: authState.user.teamCode,
					prefix: sourceFiles.map((file) => file.prefix),
					label: "Document Data:",
					target_fields: ["file_name", "page_content"],
					k: 10,
					size: 10
				}
			]
		};

		await LlmService.Stream(request, update);
		setRewriteLoading(false);
	}

	async function generateTemplate() {
		setPromptsLoading(true);

		const { generated_text } = await LlmService.Invoke({
			preprompt: reverse_engineer_preprompt,
			prompt: `${headerText ? `Title: ${headerText}` : ""}\nContent: ${templateText}`
		});

		setTemplate(JSON.parse(generated_text.replace("```json", "").replace("```", "")));

		setPromptsLoading(false);
	}

	function removeSection() {
		breakSpanGroup(group.join(","));
		breakHeader(headerMapping[group.join(",")]);
	}

	function handleSelectSection() {
		setActiveHeader((h) => {
			const new_active_header = group.join(",");
			if (new_active_header !== h) {
				return new_active_header;
			}
			return null;
		});
		setActiveSpanGroup((g) => {
			const new_active_span_group = group.join(",");
			if (new_active_span_group !== g) {
				return new_active_span_group;
			}
			return null;
		});
	}

	useEffect(() => {
		let spans = [];

		activeSlide.blocks.forEach((block) => {
			block.lines.forEach((line) => {
				line.spans.forEach((span) => {
					if (group.includes(span.span_id)) {
						spans.push(span.text);
					}
				});
			});
		});
		setTemplateText(spans.join(" "));
	}, [group]);

	useEffect(() => {
		const headerGroup = headerMapping[group.join(",")];
		if (headerGroup) {
			let spans = [];

			activeSlide.blocks.forEach((block) => {
				block.lines.forEach((line) => {
					line.spans.forEach((span) => {
						if (headerGroup.includes(span.span_id)) {
							spans.push(span.text);
						}
					});
				});
			});
			setHeaderText(spans.join(" "));
		}
	}, [headerMapping]);

	return (
		<div className={`template-section`}>
			<div
				className={`template-text ${activeHeader === group.join(",") ? "active" : ""}`}
				onClick={handleSelectSection}
			>
				{headerText || "+ Header"}
			</div>
			<div
				className={`template-text ${activeSpanGroup === group.join(",") ? "active" : ""}`}
				onClick={handleSelectSection}
			>
				{templateText.substring(0, 100)}...
			</div>
			<div className="section-actions">
				<Button
					icon={ButtonIcon.MaterialRemove}
					variant={ButtonVariant.Secondary}
					action={removeSection}
					children={"Remove Section"}
					borderShape={ButtonBorderShape.Round}
				/>
				<Button
					icon={ButtonIcon.SageLogo}
					variant={ButtonVariant.Secondary}
					action={generateTemplate}
					children={"Generate Template"}
					borderShape={ButtonBorderShape.Round}
					loading={promptsLoading}
				/>
			</div>
			{template && (
				<div className="template-sections">
					<div className="template-sections-title">Prompts</div>
					{template.sections.map((section) => (
						<div className="template-section-prompt">{section.prompt}</div>
					))}
					<Button
						icon={ButtonIcon.SageLogo}
						variant={ButtonVariant.Secondary}
						action={rewrite}
						children={"Rewrite Section"}
						borderShape={ButtonBorderShape.Round}
						loading={rewriteLoading}
					/>
				</div>
			)}
		</div>
	);
}
