import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Citation } from "@sage/types";
import { VaultService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import "./InlineSource.scss";

export function InlineSource({ citation, capped }: { citation: Citation; capped?: boolean }) {
	if ("legal_name" in citation) {
		return (
			<CompanySource
				citation={citation}
				capped={capped}
			/>
		);
	} else if ("deal_id" in citation) {
		return (
			<DealSource
				citation={citation}
				capped={capped}
			/>
		);
	} else if ("headline" in citation) {
		return (
			<NewsSource
				citation={citation}
				capped={capped}
			/>
		);
	} else if ("l1_content" in citation) {
		return (
			<WebPageSource
				citation={citation}
				capped={capped}
			/>
		);
	} else {
		return (
			<FileSource
				citation={citation}
				capped={capped}
			/>
		);
	}
}

function FileSource({ citation, capped }) {
	const [popoverCoords, setPopoverCoords] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
	const [decorated_page_content, set_decorated_page_content] = useState(citation.page_content);

	const sourceRef = useRef();

	function openPopover() {
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords({ top: 0, left: 0, right: 0, bottom: 0 });
			setPopoverOpen(true);
		}, 0);
	}

	function getRect() {
		return getAbsolutePosition(sourceRef.current);
	}

	function closePopover() {
		setPopoverOpen(false);
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords(null);
		}, 200);
	}

	async function more() {
		const url = await VaultService.GetFileUrl(citation);

		window.open(`${url}#page=${citation.page_number + 1}`, "_blank");
	}

	function decorate() {
		let new_page_content = citation.page_content;
		for (let text_content of citation.multi_text_content) {
			new_page_content = new_page_content.replaceAll(
				text_content,
				`<span style='background-color: #0084da; color: #F9FAFB;'>${text_content}</span>`
			);
		}

		set_decorated_page_content(new_page_content);
	}

	useEffect(() => {
		if (citation) decorate();
	}, [citation]);

	return (
		<>
			<div
				className="__inline-source-container"
				ref={sourceRef}
				onClick={openPopover}
			>
				{citation.file_name}: {parseInt(citation.page_number) + 1}
			</div>
			{popoverCoords && (
				<Popover
					coords={popoverCoords}
					close={closePopover}
					open={popoverOpen}
					more={more}
					capped={capped}
				>
					<>
						<p className="citation-title">
							{citation.file_name}: {parseInt(citation.page_number) + 1}
						</p>
						<div
							className="citation-description"
							dangerouslySetInnerHTML={{
								__html: decorated_page_content
							}}
						></div>
					</>
				</Popover>
			)}
		</>
	);
}

function NewsSource({ citation, capped }) {
	const [popoverCoords, setPopoverCoords] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const sourceRef = useRef();

	function openPopover() {
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords({ top: 0, left: 0, right: 0, bottom: 0 });
			setPopoverOpen(true);
		}, 0);
	}

	function getRect() {
		return getAbsolutePosition(sourceRef.current);
	}

	function closePopover() {
		setPopoverOpen(false);
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords(null);
		}, 200);
	}

	function more() {
		window.open(citation.target?.article_url, "_blank");
	}

	return (
		<>
			<div
				className="__inline-source-container"
				ref={sourceRef}
				onClick={openPopover}
			>
				{citation.publisher} | {dayjs(citation.published_at || citation.acquired_at).fromNow()}
			</div>
			{popoverCoords && (
				<Popover
					coords={popoverCoords}
					close={closePopover}
					open={popoverOpen}
					capped={capped}
					more={more}
				>
					<>
						<p className="citation-title">{citation.headline}</p>
						<p className="citation-description">{citation.body || citation.article || citation.description}</p>
					</>
				</Popover>
			)}
		</>
	);
}

function DealSource({ citation, capped }) {
	const [popoverCoords, setPopoverCoords] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const sourceRef = useRef();

	function openPopover() {
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords({ top: 0, left: 0, right: 0, bottom: 0 });
			setPopoverOpen(true);
		}, 0);
	}

	function getRect() {
		return getAbsolutePosition(sourceRef.current);
	}

	function closePopover() {
		setPopoverOpen(false);
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords(null);
		}, 200);
	}

	return (
		<>
			<div
				className="__inline-source-container"
				ref={sourceRef}
				onClick={openPopover}
			>
				{citation.name}
			</div>
			{popoverCoords && (
				<Popover
					coords={popoverCoords}
					close={closePopover}
					open={popoverOpen}
					capped={capped}
				>
					<>
						<p className="citation-title">{citation.name}</p>
						<p className="citation-description">{citation.description}</p>
					</>
				</Popover>
			)}
		</>
	);
}

function CompanySource({ citation, capped }) {
	const [popoverCoords, setPopoverCoords] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const sourceRef = useRef();

	function openPopover() {
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords({ top: 0, left: 0, right: 0, bottom: 0 });
			setPopoverOpen(true);
		}, 0);
	}

	function getRect() {
		return getAbsolutePosition(sourceRef.current);
	}

	function closePopover() {
		setPopoverOpen(false);
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords(null);
		}, 200);
	}

	function more() {
		window.open(`/app/fs/private-company/${citation.uid}`, "_blank");
	}

	return (
		<>
			<div
				className="__inline-source-container"
				ref={sourceRef}
				onClick={openPopover}
			>
				{citation.legal_name}
			</div>
			{popoverCoords && (
				<Popover
					coords={popoverCoords}
					close={closePopover}
					open={popoverOpen}
					more={more}
					capped={capped}
				>
					<>
						<p className="citation-title">{citation.legal_name}</p>
						<p className="citation-description">{citation.fact_summary || citation.description}</p>
					</>
				</Popover>
			)}
		</>
	);
}

function WebPageSource({ citation, capped }) {
	const [popoverCoords, setPopoverCoords] = useState(null);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const sourceRef = useRef();

	function openPopover() {
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords({ top: 0, left: 0, right: 0, bottom: 0 });
			setPopoverOpen(true);
		}, 0);
	}

	function getRect() {
		return getAbsolutePosition(sourceRef.current);
	}

	function closePopover() {
		setPopoverOpen(false);
		setPopoverCoords(getRect());
		setTimeout(() => {
			setPopoverCoords(null);
		}, 200);
	}

	function more() {
		window.open(citation.url, "_blank");
	}

	return (
		<>
			<div
				className="__inline-source-container"
				ref={sourceRef}
				onClick={openPopover}
			>
				{citation.page_title}
			</div>
			{popoverCoords && (
				<Popover
					coords={popoverCoords}
					close={closePopover}
					open={popoverOpen}
					more={more}
					capped={capped}
				>
					<>
						<p className="citation-title">{citation.page_title}</p>
						<p className="citation-description">{citation.l1_content}</p>
					</>
				</Popover>
			)}
		</>
	);
}

export function Popover({
	children,
	coords,
	close,
	open,
	more,
	capped
}: {
	children?: any;
	coords: any;
	close: any;
	open: any;
	more?: any;
	capped?: boolean;
}) {
	const [innerCoords, setInnerCoords] = useState(coords);
	const [innerOpen, setInnerOpen] = useState(open);

	useEffect(() => {
		setInnerCoords(coords);
	}, [coords]);

	useEffect(() => {
		setInnerOpen(open);
	}, [open]);

	return (
		<div
			className={`__inline-source-popover-container ${innerOpen ? "open" : "closed"} ${capped ? "capped" : ""}`}
			style={{
				top: innerCoords.top,
				right: innerCoords.right,
				left: innerCoords.left,
				bottom: innerCoords.bottom
			}}
		>
			<div className={`inner  ${innerOpen ? "open" : "closed"}`}>
				<div className="citation">{children}</div>

				<div className="popover-actions">
					<Button
						variant={ButtonVariant.Secondary}
						borderShape={ButtonBorderShape.Round}
						action={close}
						testid={"inline-source-close"}
					>
						Close
					</Button>
					{more && (
						<Button
							variant={ButtonVariant.Tertiary}
							action={more}
							testid={"inline-source-see-more"}
						>
							See More
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

function getAbsolutePosition(element) {
	let parent = element.parentNode;

	while (parent && window.getComputedStyle(parent).position !== "relative") {
		parent = parent.parentNode;
	}

	if (parent) {
		const childRect = element.getBoundingClientRect();
		const parentRect = parent.getBoundingClientRect();

		return {
			top: childRect.top - parentRect.top,
			left: childRect.left - parentRect.left,
			right: parentRect.right - childRect.right,
			bottom: parentRect.bottom - childRect.bottom
		};
	}
}
