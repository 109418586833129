export const Teaser = `
EXTREMELY IMPORTANT REQUIREMENTS:

This document is a TEASER. That means you must not mention the Name of the company, or any identifyable information.

You must not mention the name of the founders, and be more general about the company details. Round all finacial figures so they are not identifyable.

It is Extremely important that your output does not contain any identifyable details.

Use sections like "The company operates in the broadcast industry" or "The company generated over $10M revenue last year" instead of directly quoting.
`;
