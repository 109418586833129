import { IBaseProps } from "./Base";

export interface IDealRoomPhase extends IBaseProps {
	phase_id: string;
	phase_index: number;
	phase_name: string;
	phase_status: PhaseStatus;
	phase_icon: string;
	phase_color: string;
}

export enum PhaseStatus {
	Finished = "finished",
	InProgress = "in-progress",
	None = "none"
}
