import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IThread } from "@sage/types";
import { AuthContext, useAthenaState } from "@sage/state";
import { AthenaService } from "@sage/services";
import { ButtonBorderShape, Link, Table, TableColumn, TableColumnSpacing, TableRow, TableVariant } from "@sage/shared/core";
import { Uuid } from "@sage/utils";
import "./Threads.scss";

export function Threads() {
	const authState = useContext(AuthContext);
	const { messages, setThread, setMessages, threads, setThreads } = useAthenaState();
	const navigate = useNavigate();

	async function loadThreads() {
		setThreads(await AthenaService.getThreads());
	}

	async function newThread() {
		const thread = {
			thread_id: Uuid.Nano(12),
			createdTimestamp: new Date().getTime(),
			lastModifiedTimestamp: new Date().getTime(),
			user_id: authState.user.user_id,
			teamCode: authState.user.teamCode,
			messages: []
		};

		await AthenaService.createThread(thread);
		setThread(thread);
		setMessages([]);
		navigate(`/app/fs/athena/${thread.thread_id}`);
	}

	useEffect(() => {
		loadThreads();
	}, [messages]);

	return (
		<div className="__athena-threads-panel">
			<Table
				items={threads}
				variant={TableVariant.Inline}
				row={(thread: IThread) => (
					<TableRow key={thread.thread_id}>
						<TableColumn>
							<Link to={`/app/fs/athena/${thread.thread_id}`}>{thread.title?.substring(0, 25)}...</Link>
						</TableColumn>
						<TableColumn
							relativeDate
							spacing={TableColumnSpacing.Fixed}
							width={"8rem"}
						>
							{thread.lastModifiedTimestamp}
						</TableColumn>
					</TableRow>
				)}
				actions={[
					{
						children: "New Thread",
						borderShape: ButtonBorderShape.Round,
						action: newThread
					}
				]}
			/>
		</div>
	);
}
