import React, { ReactNode, useEffect, useState } from "react";
import "./Collapsible.scss";

export function Collapsible({
	children,
	visible,
	toggle,
	toggleHandle,
	printable,
	allowOverflow
}: {
	children: ReactNode;
	visible: boolean;
	toggle?: () => void;
	toggleHandle?: ReactNode;
	printable?: boolean;
	allowOverflow?: boolean;
}) {
	const [overflowHidden, setOverflowHidden] = useState(!visible);

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				setOverflowHidden(false);
			}, 100);
		} else {
			setTimeout(() => {
				setOverflowHidden(true);
			}, 0);
		}
	}, [visible]);

	return (
		<div className={`__collapsible-container ${printable ? "printable" : ""}`}>
			<div onClick={toggle}>{toggleHandle}</div>
			<div
				className={`collapsible-inner ${visible ? "" : "collapsed"} ${allowOverflow ? (overflowHidden ? "overflow-hidden" : "") : visible ? "" : "overflow-hidden"}`}
			>
				<div className="wrapper">{children}</div>
			</div>
		</div>
	);
}
