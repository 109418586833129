import { ReactNode } from "react";
import { Button, ButtonVariant } from "../Button";
import { Modal } from "../Modal";
import { Row } from "../Row";
import { P, SectionTitle } from "../Text";
import "./DeleteConfirmation.scss";

export function DeleteConfirmation({
	children,
	visible,
	onDismiss,
	onConfirm
}: {
	children: ReactNode;
	visible: boolean;
	onDismiss: () => void;
	onConfirm: () => void;
}) {
	return (
		<Modal
			visible={visible}
			onDismiss={onDismiss}
		>
			<div className="__sage-delete-confirmation-modal">
				<SectionTitle>Confirm Delete</SectionTitle>
				<P>Are you sure you want to delete this?</P>
				<P>This action is permanent and can not be undone.</P>
				{children}
				<Row
					horizontalAlign="right"
					verticalAlign="center"
				>
					<Button
						action={onDismiss}
						variant={ButtonVariant.Secondary}
					>
						Cancel
					</Button>
					<Button
						action={onConfirm}
						variant={ButtonVariant.Error}
					>
						Confirm
					</Button>
				</Row>
			</div>
		</Modal>
	);
}
