import { useEffect, useRef, useState } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useNavigate } from "react-router-dom";
import { ProjectType } from "@sage/types";
import { AuthService, DealRoomService, UserService } from "@sage/services";
import { H1, Splash } from "@sage/shared/core";
import "./LeadMagnetGuard.scss";

export function LeadMagnetGuard() {
	const [loadingText, setLoadingText] = useState("");
	const startedRef = useRef(null);
	const navigate = useNavigate();
	const fp = useVisitorData({ timeout: 300000, extendedResult: true });

	async function createTrialAccount(user_id: string) {
		startedRef.current = true;

		setLoadingText("Creating Trial Account");

		await UserService.CreateLeadMagnetUser(user_id);

		await AuthService.Login({ email: user_id, password: "Password!1" });

		const deal = await DealRoomService.CreateDealRoom();
		const phases = await DealRoomService.GetPhases(deal.deal_id);
		const project = await DealRoomService.SaveProject({
			deal_id: deal.deal_id,
			phase_id: phases[0].phase_id,
			project_type: ProjectType.CIM,
			project_name: "Trial CIM"
		});
		navigate(`/app/dealroom/${deal.deal_id}/phase/${phases[0].phase_id}/project/${project.project_id}`);
	}

	useEffect(() => {
		if (!fp.isLoading && !startedRef.current) {
			const user_id = fp.data?.visitorId;
			if (user_id) {
				createTrialAccount(user_id);
			} else if (window.location.href.includes("localhost")) {
				createTrialAccount(`Localhost-${Math.random()}`);
			}
		}
	}, [fp.data, fp.isLoading, fp.error]);

	return (
		<div className="__sage-lead-magnet-container">
			<H1 xl>{loadingText}</H1>
			<video
				autoPlay
				muted
				loop
				controls={false}
				preload="auto"
				className="loading-wave"
			>
				<source src="https://cdn.sageai.dev/website/assets/loading-wave.mp4" />
			</video>
			<video
				autoPlay
				muted
				loop
				controls={false}
				preload="auto"
				className="loading-video"
			>
				<source src="https://cdn.sageai.dev/website/assets/slide-deck.mp4" />
			</video>
			<Splash />
		</div>
	);
}
