import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./WorkflowStatus.scss";

export interface IWorkflowStatusProps {
	title: string;
	onNav?: (to: string, section_name: string, page_name: string) => void;
	sections: {
		title: string;
		href: string;
		status?: string;
		pages?: {
			label: string;
			href: string;
			status?: string;
		}[];
	}[];
}

export function WorkflowStatus({ title, sections, onNav }: IWorkflowStatusProps) {
	return (
		<aside className="__sage-workflow-status-container">
			<div className="workflow-status-title">{title}</div>
			<div className="workflow-status-sections">
				{sections.map(({ title, pages, href, status }) => (
					<div
						className="workflow-status-section"
						key={title}
					>
						<div className="workflow-status-section-title">
							<StatusLink
								status={status}
								href={href}
								label={title}
								onNav={onNav}
							/>
						</div>
						<div className="workflow-status-pages">
							{pages?.map((page) => (
								<div
									className="workflow-status-page"
									key={page.label}
								>
									<StatusLink
										status={page.status}
										href={page.href}
										label={page.label}
										onNav={onNav}
									/>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</aside>
	);
}

function StatusLink({ status, label, href, onNav }) {
	return (
		<RouterLink
			className={`workflow-status-link ${status}`}
			to={href}
			onClick={onNav}
		>
			<StatusIcon status={status} />
			{label}
		</RouterLink>
	);
}

export function StatusIcon({ status }) {
	function iconName() {
		switch (status) {
			case "complete":
				return "https://cdn.ccm.vc/sage/icons/material-success-color.svg";
			case "in-progress":
				return "https://cdn.ccm.vc/sage/icons/material-pending-color.svg";
			case "errors":
				return "https://cdn.ccm.vc/sage/icons/material-error-color.svg";
			case "skip":
				return "https://cdn.ccm.vc/sage/icons/material-skip-color.svg";
			default:
				return "https://cdn.ccm.vc/sage/icons/material-circle.svg";
		}
	}

	return (
		<img
			className="workflow-status-icon"
			src={iconName()}
			width={16}
		/>
	);
}
