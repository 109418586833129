import React from "react";
import { useDecksState } from "../../DecksState";

export function Header({ group }: { group: string[] }) {
	const { activeSlide, scale, breakHeader, spanGroupConnectorHandler, activeHeader, headerMapping, setHeaderMapping } = useDecksState();

	const [bbox, setBBox] = React.useState({});

	function createBBox() {
		const resolvedSpans = [];

		for (let block of activeSlide.blocks) {
			for (let line of block.lines) {
				for (let span of line.spans) {
					if (group.includes(span.span_id)) {
						resolvedSpans.push(span);
					}
				}
			}
		}

		const ys = [];
		const xs = [];

		for (let span of resolvedSpans) {
			ys.push(span.bbox.y1);
			ys.push(span.bbox.y2);
			xs.push(span.bbox.x1);
			xs.push(span.bbox.x2);
		}

		let _bbox = { x1: Math.min(...xs) - 2, x2: Math.max(...xs) + 2, y1: Math.min(...ys) - 2, y2: Math.max(...ys) + 2 };

		_bbox.height = _bbox.y2 - _bbox.y1;
		_bbox.width = _bbox.x2 - _bbox.x1;

		setBBox(_bbox);
	}

	function breakGroup() {
		breakHeader(group.join(","));
	}

	function startGroupConnector(e) {
		const rect = e.target.getBoundingClientRect();
		spanGroupConnectorHandler(rect.x, rect.y, group.sort().join());
	}

	function handleClickHeader() {
		if (activeHeader) {
			setHeaderMapping((h) => ({
				...h,
				[activeHeader]: group.join(",")
			}));
		}
	}

	React.useEffect(() => {
		createBBox();
	}, []);

	return (
		<div
			className={`span-group header ${headerMapping[activeHeader] === group.join(",") ? "active" : ""}`}
			style={{
				top: `${bbox.y1 * scale}pt`,
				left: `${bbox.x1 * scale}pt`,
				height: `${bbox.height * scale}pt`,
				width: `${bbox.width * scale}pt`
			}}
			onClick={handleClickHeader}
		>
			<div className="span-group-title">Header</div>
			<button
				className="delete-group"
				onClick={breakGroup}
			>
				<img
					alt="img-icon"
					src={`https://cdn.ccm.vc/sage/icons/material-close.svg`}
				/>
			</button>
		</div>
	);
}
