import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { useChartColors } from "@sage/state";
import { Chart, format_tick, format_value, useDims } from "../ChartProvider";

export function StackedBarChart({
	title,
	data,
	colors,
	updateColor
}: {
	title?: string;
	data: { category: string; value: number; value_label?: string; date: Date; date_label?: string }[];
	colors?: { [key: string]: string };
	updateColor?: (category: string, color: string) => void;
}) {
	const { chart_colors } = useChartColors();
	const c = useMemo(
		() =>
			d3
				.scaleOrdinal()
				.domain([
					...Object.keys(colors || {}),
					...data.map((d) => d.category).filter((c) => !Object.keys(colors || {}).includes(c))
				])
				.range([
					...Object.keys(colors || {}).map((c) => colors[c]),
					...(chart_colors?.length > 0
						? chart_colors
						: ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056", "#8dddd0"]),
					...d3.schemeTableau10
				]),
		[data, colors]
	);

	const legend = useMemo(
		() => [...new Set(data.map((d) => d.category))].sort().map((category) => ({ category, color: c(category) })),
		[data, c]
	);

	return (
		<Chart
			legend={legend}
			title={title}
			updateColor={updateColor ? updateColor : undefined}
			data={data}
		>
			<Series
				data={data}
				c={c}
			/>
		</Chart>
	);
}

function Series({ data, c }) {
	const dims = useDims();
	const containerRef = useRef(null);
	const seriesRef = useRef(null);
	const labelsRef = useRef(null);
	const totalsRef = useRef(null);
	const xAxisRef = useRef(null);
	const xGridRef = useRef(null);
	const yAxisRef = useRef(null);
	const yGridRef = useRef(null);

	const stackedData = useMemo(() => {
		const grouped = d3.group(data, (d) => d.date);
		const categories = [...new Set(data.map((d) => d.category))];

		// Convert grouped data to format needed for stacking
		const prepared = Array.from(grouped, ([date, values]) => {
			const obj = { date };
			categories.forEach((cat) => {
				const match = values.find((v) => v.category === cat);
				obj[cat] = match ? match.value : 0;
			});
			return obj;
		});

		return d3.stack().keys(categories)(prepared);
	}, [data]);

	const x = useMemo(
		() =>
			d3
				.scaleBand()
				.domain(Array.from(new Set(data.map((d) => d.date))))
				.range([dims.marginLeft, dims.width - dims.marginRight])
				.paddingInner(0.1),
		[data, dims]
	);

	const y = useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([0, d3.max(stackedData, (d) => d3.max(d, (d) => d[1])) * 1.07])
				.range([dims.height - dims.marginBottom, dims.marginTop]),
		[stackedData, dims]
	);

	useEffect(() => {
		if (seriesRef.current && containerRef.current && x && y && c) {
			// Create stacked bars
			const series = d3
				.select(seriesRef.current)
				.selectAll("g")
				.data(stackedData)
				.join("g")
				.attr("fill", (d) => c(d.key));

			series
				.selectAll("rect")
				.data((d) => d)
				.join("rect")
				.attr("x", (d) => x(d.data.date) + x.bandwidth() / 4)
				.attr("y", (d) => y(d[1]))
				.attr("height", (d) => y(d[0]) - y(d[1]) - 2)
				.attr("width", x.bandwidth() / 2)
				.attr("rx", 4)
				.attr("ry", 4);

			if (stackedData.length > 1) {
				d3.select(labelsRef.current)
					.selectAll("g")
					.data(stackedData)
					.join("g")
					.selectAll("text")
					.data((d) => d)
					.join("text")
					.attr("text-anchor", "middle")
					.attr("font-size", "0.85rem")
					.attr("x", (d) => x(d.data.date) + x.bandwidth() / 2)
					.attr("y", (d) => y(d[1]) + 20)
					.attr("dy", "-0.25rem")
					.text((d) => format_value(d[1] - d[0]))
					.attr("fill", "white")
					.attr("font-weight", "500")
					.attr("opacity", (d) => (d[1] - d[0] > 0 ? 1 : 0));
			}

			const dateGroups = d3.group(data, (d) => d.date);
			const totals = Array.from(dateGroups, ([date, values]) => ({
				date,
				total: d3.sum(values, (d) => d.value)
			}));

			d3.select(totalsRef.current)
				.selectAll("text")
				.data(totals)
				.join("text")
				.attr("text-anchor", "middle")
				.attr("font-size", "0.85rem")
				.attr("font-weight", "500")
				.attr("x", (d) => x(d.date) + x.bandwidth() / 2)
				.attr("y", (d) => y(d.total))
				.attr("dy", "-0.5rem")
				.text((d) => format_value(d.total))
				.attr("fill", "black");

			// X-axis
			d3.select(xAxisRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3.axisBottom(x).tickFormat((d) => {
						try {
							const datestring = new Date(d).toLocaleDateString("en-US", {
								month: "long",
								day: "numeric",
								year: "numeric",
								timeZone: "UTC"
							});
							if (datestring.toLowerCase() === "invalid date") {
								return d;
							}
							if (datestring.includes("January 1") || datestring.includes("December 31")) {
								return new Date(d).toLocaleDateString("en-US", { year: "numeric", timeZone: "UTC" });
							}
							return datestring;
						} catch (e) {
							return d.toLocaleString("en-US", { timeZone: "UTC" });
						}
					})
				)
				.style("stroke-opacity", 0.6);

			// Y-axis
			d3.select(yAxisRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(d3.axisLeft(y).ticks(6).tickFormat(format_tick))
				.style("stroke-opacity", 0.6);

			// Grid lines
			d3.select(yGridRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(
					d3
						.axisLeft(y)
						.tickSize(-(dims.width - dims.marginRight - dims.marginLeft))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);

			d3.select(xGridRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3
						.axisBottom(x)
						.tickSize(-(dims.height - dims.marginTop - dims.marginBottom))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);
		}
	}, [seriesRef.current, containerRef.current, x, y, c, dims, stackedData]);

	return (
		<svg
			ref={containerRef}
			width={"100%"}
			height={dims.height}
		>
			<g ref={xAxisRef} />
			<g ref={xGridRef} />
			<g ref={yAxisRef} />
			<g ref={yGridRef} />
			<g ref={seriesRef} />
			<g ref={labelsRef} />
			<g ref={totalsRef} />
		</svg>
	);
}
