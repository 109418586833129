import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Actor, IMessage } from "@sage/types";
import { AuthContext } from "@sage/state";
import { Col, Row } from "@sage/shared/core";
import { UserIcon } from "@sage/shared/other";
import { Uuid, isNullOrUndefined, useDebounce } from "@sage/utils";
import { Chart } from "../Chart";
import { Flag } from "../Flag";
import { Source } from "../Source";
import { Table } from "../Table";
import "./Message.scss";

dayjs.extend(relativeTime);

export interface IMessageProps {
	message: IMessage;
	inline?: boolean;
	onClick?: (message: IMessage) => void;
	messageActions?: (message: IMessage) => ReactNode;
	filters?: string[];
	done?: boolean;
	updateMessage?: (text: string) => void;
}

export function Message({ message, onClick, messageActions, inline, filters, done, updateMessage }: IMessageProps) {
	const authState = useContext(AuthContext);
	const messageBodyRef = useRef(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [resultNodes, setResultNodes] = useState<ReactNode[]>([]);
	const elCountRef = useRef(0);

	const updateMessageText = useCallback(
		(prev, current) => {
			if (updateMessage) updateMessage(message.text.replace(prev, `<chart>${JSON.stringify(current)}</chart>`));
		},
		[updateMessage]
	);

	const updateMessageP = useCallback(
		(prev, e) => {
			if (updateMessage) {
				let text = "";

				for (let child of e.childNodes) {
					for (let sub of child.childNodes) {
						if (sub.nodeName === "H1") {
							text += `# ${sub.textContent}\n`;
						} else if (sub.nodeName === "H2") {
							text += `## ${sub.textContent}\n`;
						} else if (sub.nodeName === "H3") {
							text += `### ${sub.textContent}\n`;
						} else if (sub.nodeName === "P") {
							if (
								(sub.innerHTML.includes("<strong>") || sub.innerHTML.includes("<b>")) &&
								(sub.innerHTML.includes("<em>") || sub.innerHTML.includes("<i>"))
							) {
								text += `***${sub.textContent}***\n`;
							} else if (sub.innerHTML.includes("<strong>") || sub.innerHTML.includes("<b>")) {
								text += `**${sub.textContent}**\n`;
							} else if (sub.innerHTML.includes("<em>") || sub.innerHTML.includes("<i>")) {
								text += `*${sub.textContent}*\n`;
							} else {
								text += `${sub.textContent}\n`;
							}
						} else if (sub.nodeName === "#text") {
							text += `${sub.textContent}\n`;
						} else if (sub.nodeName === "UL") {
							for (let li of sub.childNodes) {
								if (li.nodeName === "LI") {
									text += `* ${li.textContent}\n`;
								}
							}
						}
					}
				}
				updateMessage(message.text.replace(prev, text));
			}
		},
		[updateMessage]
	);

	const updateMessagePDebounced = useDebounce(updateMessageP, 1000);

	function renderText(text: string) {
		const element = document.createElement("div");
		element.innerHTML = text;

		const result = [];
		elCountRef.current = 0;

		const nodes = [...element.childNodes];

		for (let child of nodes) {
			elCountRef.current++;
			if (!isNullOrUndefined(filters)) {
				const nonTextNodes = nodes.filter((e) => e.nodeName !== "#text").length;
				const flagNodes = nodes.filter((e) => e.nodeName === "FLAG").length;

				if (!filters.includes(child.nodeName) && nonTextNodes !== flagNodes) {
					continue;
				}
			}
			if (child.nodeName === "#text") {
				if (child.textContent?.trim().length > 0) {
					result.push(
						<div
							className="plain-message"
							key={elCountRef.current}
						>
							{child.textContent}
						</div>
					);
				}
			} else if (child.nodeName === "PARA" || child.nodeName === "BULLETS") {
				result.push(
					<div
						contentEditable={updateMessage ? true : false}
						key={elCountRef.current}
						onInput={(e) => updateMessagePDebounced(child.textContent, e.currentTarget)}
					>
						<Col gap={"0"}>
							<ReactMarkdown
								key={elCountRef.current}
								remarkPlugins={[remarkGfm]}
							>
								{child.textContent}
							</ReactMarkdown>
						</Col>
					</div>
				);
			} else if (child.nodeName === "FOOTNOTE") {
				const number = child.getAttribute("number");
				result.push(
					<div
						style={{ marginBottom: "-0.5rem" }}
						key={elCountRef.current}
					>
						<i>
							{number}) {child.textContent}
						</i>
					</div>
				);
			} else if (child.nodeName === "FLAG") {
				const number = child.getAttribute("number");

				result.push(
					<Flag
						number={number}
						key={elCountRef.current}
					>
						{child.textContent}
					</Flag>
				);
			} else if (child.nodeName === "TABLE") {
				result.push(<Table key={elCountRef.current}>{child.innerHTML}</Table>);
			} else if (child.nodeName === "CHART") {
				result.push(
					<Chart
						data={child.innerHTML}
						key={elCountRef.current}
						done={done}
						updateChart={updateMessage ? updateMessageText : undefined}
					/>
				);
			} else if (child.nodeName === "COMMAND") {
				console.log(child);
			} else if (child.nodeName === "CITATION") {
				const provenance = child.getAttribute("provenance");
				const sourceType = child.getAttribute("type");
				const label = child.getAttribute("label");
				const text = child.textContent;

				result.push(
					<Source
						key={elCountRef.current}
						provenance={provenance}
						sourceType={sourceType}
						text={text}
						label={label}
					/>
				);
			}
		}

		return result;
	}

	function getPlainText(text: string): string {
		const element = document.createElement("div");
		element.innerHTML = text;
		return element.textContent;
	}

	async function copyText(e) {
		e.preventDefault();
		e.stopPropagation();

		const outer = document.createElement("div");

		const element = messageBodyRef.current.cloneNode(true);

		element.style.width = "8in";

		const ps = element.querySelectorAll("p");

		for (let p of ps) {
			p.style.fontSize = "10pt";
			p.after(document.createElement("br"));
		}

		const lis = element.querySelectorAll("li");

		for (let li of lis) {
			li.style.fontSize = "10pt";
			li.style.listStyleType = "disc";
			li.style.display = "list-item";
			li.style.paddingBottom = "0.2in";
			li.style.textIndent = "-0.2in";
			li.style.marginLeft = "0.2in";
		}

		const footnotes = element.querySelectorAll("i");

		for (let footnote of footnotes) {
			footnote.style.fontSize = "10pt";
		}

		const icons = element.querySelectorAll(".copy-text");

		for (let icon of icons) {
			icon.remove();
		}

		const charts = element.querySelectorAll(".__sage-chart-provider-container");

		for (let chart of charts) {
			chart.remove();
		}

		const chartBtns = element.querySelectorAll(".__sage-chart-save-btn");

		for (let chartBtn of chartBtns) {
			chartBtn.remove();
		}

		const tables = element.querySelectorAll("table");

		for (let table of tables) {
			const tds = table.querySelectorAll("td");

			for (let td of tds) {
				td.style.fontSize = "9pt";
				td.style.width = "1.5in";
				td.style.paddingLeft = "0in";
				td.style.paddingTop = "0in";
				td.style.paddingBottom = "0in";
			}

			const ths = element.querySelectorAll("th");

			for (let th of ths) {
				th.style.fontSize = "9pt";
				th.style.fontWeight = "600";
				th.style.textAlign = "center";
				th.style.backgroundColor = th.style.backgroundColor || "#1c4587";
				th.style.color = th.style.color || "white";
			}

			const margins = element.querySelectorAll(".margin > td");

			for (let margin of margins) {
				margin.style.fontStyle = "italic";
			}

			const growthRates = element.querySelectorAll(".growth-rate");

			for (let growthRate of growthRates) {
				growthRate.style.fontStyle = "italic";
			}

			const borderBottoms = element.querySelectorAll(".border-bottom > td");

			for (let borderBottom of borderBottoms) {
				borderBottom.style.borderBottom = "solid 1px #000000";
			}

			const borderTops = element.querySelectorAll(".border-top > td");

			for (let borderTop of borderTops) {
				borderTop.style.borderTop = "solid 1px #000000";
				borderTop.style.fontWeight = 600;
			}

			const indents = element.querySelectorAll(".indent > td:nth-child(1)");

			for (let indent of indents) {
				indent.style.paddingLeft = "0.2in";
			}
		}

		outer.appendChild(element);

		try {
			await navigator.clipboard.write([
				new ClipboardItem({
					"text/html": new Blob([outer.outerHTML], { type: "text/html" })
				})
			]);
			console.log("Copied with computed styles!");

			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 1500);
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	}

	function handleClick() {
		if (onClick) onClick(message);
	}

	function getUserName() {
		if (message.actorType === Actor.Assistant) return "Athena";

		const user = authState.team.members?.find((user) => user.user_id === message.actor);
		return `${user.firstName} ${user.lastName}`;
	}

	useEffect(() => {
		setResultNodes(renderText(message.text));
	}, [message?.text, done, updateMessageText, updateMessage]);

	return (
		<div className={`__sage-message-container ${inline ? "inline" : ""}`}>
			{message.actor && message.actorType && (
				<div className={`message-header ${message.actorType}`}>
					<div className="message-icon">
						<UserIcon
							user_id={message.actor}
							useSage={message.actorType === Actor.Assistant}
						/>
					</div>
					<div className="message-title">
						<div className="message-username">{getUserName()}</div>
						<div className="message-dt">{dayjs(message.timestamp).fromNow()}</div>
					</div>
				</div>
			)}
			<div
				className={`message-body ${message.actorType}`}
				id={message.message_id}
				onClick={handleClick}
				ref={messageBodyRef}
			>
				<Col gap="1rem">{resultNodes}</Col>
			</div>
			<Row
				horizontalAlign="right"
				verticalAlign="center"
			>
				{!isNullOrUndefined(messageActions) && messageActions(message)}
				<div
					className={`copy-text ${copied ? "copied" : ""}`}
					onClick={copyText}
				>
					<img
						src={
							copied
								? "https://cdn.ccm.vc/sage/icons/material-check-white.svg"
								: "https://cdn.ccm.vc/sage/icons/material-copy.svg"
						}
					/>
				</div>
			</Row>
		</div>
	);
}
