import { IDocument, IProject, ITemplate } from "@sage/types";
import { HttpService } from "../HttpService/HttpService";

export class ProjectService {
	static UpdateProjectName(body: { project_id: string; projectName: string }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/projects/update/name",
			body
		}).then((response) => response.payload);
	}

	static UpdateProjectFavorited(body: { project_id: string; favorited: boolean }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/projects/update/favorited",
			body
		}).then((response) => response.payload);
	}

	static UpdateProjectSharedWith(body: { project_id: string; sharedWith: string[] }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/projects/update/shared-with",
			body
		}).then((response) => response.payload);
	}

	static UpdateProjectSources(body: { project_id: string; sources: string[] }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/projects/update/sources",
			body
		}).then((response) => response.payload);
	}

	static UpdateProjectCompanies(body: { project_id: string; companies: string[] }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/projects/update/companies",
			body
		}).then((response) => response.payload);
	}

	static UpdateDocumentName(document_id: string, documentName: string) {
		return HttpService.Post<IDocument>({
			uri: `/workspace-api-v2/documents/update/name`,
			body: { document_id, documentName }
		}).then((response) => response.payload);
	}

	static DeleteDocuments(body: { document_ids: string[] }) {
		return Promise.all(
			body.document_ids.map((document_id) =>
				HttpService.Delete({
					uri: `/workspace-api-v2/documents/${document_id}`
				}).then((response) => response.payload)
			)
		);
	}

	static DeleteTemplate(template_id: string) {
		return HttpService.Delete({ path: `/templates/${template_id}` });
	}

	static async GetEditorState(document_id: string) {
		const { url } = await HttpService.Get<{ url: string }>({
			uri: `/workspace-api-v2/documents/state/${document_id}`
		}).then((response) => response.payload);

		const download_res = await fetch(url);
		const editorState = await download_res.json();
		return (
			editorState ||
			JSON.parse(
				'{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
			)
		);
	}

	static async SaveEditorState(document_id: string, editorState: string) {
		const signedPost = await HttpService.Post<{
			fields: { [key: string]: string };
			url: string;
		}>({
			uri: "/workspace-api-v2/documents/state",
			body: { document_id }
		}).then((response) => response.payload);

		if (signedPost) {
			const form = new FormData();
			Object.keys(signedPost.fields).forEach((key) => {
				form.append(key, signedPost.fields[key]);
			});

			const blob = new Blob([editorState], { type: "application/json" });

			form.append("file", blob);

			return await fetch(signedPost.url, {
				method: "POST",
				body: form
			});
		}
	}

	static CreateDocument(body: { user_id: string; project_id: string; teamCode: string; documentName?: string }) {
		return HttpService.Post<IDocument>({
			uri: `/workspace-api-v2/documents`,
			body
		}).then((response) => response.payload);
	}

	static async GetProjectById(project_id: string): Promise<IProject> {
		return HttpService.Get<IProject>({
			uri: `/workspace-api-v2/projects/${project_id}`
		}).then((response) => response.payload);
	}

	static async LoadProject(project_id: string): Promise<IProject> {
		return HttpService.Get<IProject>({
			uri: `/workspace-api-v2/projects/${project_id}`
		}).then((response) => response.payload);
	}

	static async LoadProjectWithSources(project_id: string): Promise<IProject> {
		return HttpService.Get<IProject>({
			uri: `/workspace-api-v2/projects/${project_id}`
		}).then((response) => response.payload);
	}

	static async LoadDocumentsByProject(project_id: string): Promise<IDocument[]> {
		return HttpService.Get<IDocument[]>({
			uri: `/workspace-api-v2/documents/project/${project_id}`
		}).then((response) => response.payload);
	}

	static DuplicateProject(project_id: string): Promise<IProject> {
		return HttpService.Post<IProject>({
			uri: `/workspace-api-v2/projects/duplicate/${project_id}`
		}).then((response) => response.payload);
	}

	static async GetTemplates() {
		return HttpService.Get<ITemplate[]>({
			uri: `/workspace-api-v2/templates/team`
		}).then((response) => response.payload);
	}

	static async GetTemplate(template_id: string) {
		return HttpService.Get<ITemplate>({
			path: `/templates/${template_id}`
		}).then((response) => response.payload);
	}

	static async CreateTemplate(body: Partial<ITemplate>) {
		return HttpService.Post<ITemplate>({
			uri: `/workspace-api-v2/templates`,
			body
		}).then((response) => response.payload);
	}

	static GenerateTemplate({ templateName, teamCode, user_id, file_id }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/templates/generate",
			body: {
				templateName,
				teamCode,
				user_id,
				file_id
			}
		}).then((response) => response.payload);
	}

	static async SaveTemplate(template) {
		return HttpService.Post<ITemplate>({
			uri: "/workspace-api-v2/templates",
			body: template
		}).then((response) => response.payload);
	}

	static async AcquireDocumentLock(document_id: string, user_id: string) {
		return HttpService.Post({
			uri: `/workspace-api-v2/documents/lock/acquire`,
			body: {
				document_id: document_id,
				editor: user_id
			}
		}).then((response) => response.payload);
	}

	static async GetDocumentLock(document_id: string) {
		return HttpService.Get<{ editor: string }>({
			uri: `/workspace-api-v2/documents/lock/${document_id}`
		}).then((response) => response.payload);
	}

	static async PutDocumentLock(document_id: string, user_id: string) {
		return HttpService.Post({
			uri: "/workspace-api-v2/documents/lock",
			body: {
				document_id: document_id,
				editor: user_id
			}
		}).then((response) => response.payload);
	}
}
