import { Dispatch, MouseEvent, SetStateAction, createContext, useContext, useState } from "react";
import { IDeck, IHeaderMapping, IRewrittenSections, ISlide, ISlideData, IVaultFile } from "@sage/types";
import { DecksService } from "@sage/services";
import { MouseHandler } from "@sage/utils";

export interface IDecksState {
	activeSlide: ISlide;
	setActiveSlide: Dispatch<SetStateAction<ISlide>>;
	activeSpanGroup: any;
	setActiveSpanGroup: Dispatch<SetStateAction<any>>;
	connections: any[];
	setConnections: Dispatch<SetStateAction<any[]>>;
	scale: number;
	setScale: Dispatch<SetStateAction<number>>;
	xShift: number;
	setXShift: Dispatch<SetStateAction<number>>;
	yShift: number;
	setYShift: Dispatch<SetStateAction<number>>;
	activeHeader: any;
	setActiveHeader: Dispatch<SetStateAction<any>>;
	activeSectionWidth: number;
	setActiveSectionWidth: Dispatch<SetStateAction<number>>;
	activeSpans: any[];
	setActiveSpans: Dispatch<SetStateAction<any>>;
	connector: any;
	setConnector: Dispatch<SetStateAction<any>>;
	deck: IDeck;
	setDeck: Dispatch<SetStateAction<IDeck>>;
	headerMapping: IHeaderMapping;
	setHeaderMapping: Dispatch<SetStateAction<IHeaderMapping>>;
	headers: any[];
	setHeaders: Dispatch<SetStateAction<any[]>>;
	imageWidth: number;
	setImageWidth: Dispatch<SetStateAction<number>>;
	isConnecting: boolean;
	setIsConnecting: Dispatch<SetStateAction<boolean>>;
	isSelecting: boolean;
	setIsSelecting: Dispatch<SetStateAction<boolean>>;
	rewrittenSections: IRewrittenSections;
	setRewrittenSections: Dispatch<SetStateAction<IRewrittenSections>>;
	selection: any;
	setSelection: Dispatch<SetStateAction<any>>;
	slideSectionResizing: boolean;
	setSlideSectionResizing: Dispatch<SetStateAction<boolean>>;
	slideSectionWidth: number;
	setSlideSectionWidth: Dispatch<SetStateAction<number>>;
	spanGroups: any[];
	setSpanGroups: Dispatch<SetStateAction<any[]>>;
	toolsPaneResizing: boolean;
	setToolsPaneResizing: Dispatch<SetStateAction<boolean>>;
	toolsPaneWidth: number;
	setToolsPaneWidth: Dispatch<SetStateAction<number>>;
	vaultParentFolder: IVaultFile;
	setVaultParentFolder: Dispatch<SetStateAction<IVaultFile>>;
	sourceFiles: IVaultFile[];
	setSourceFiles: Dispatch<SetStateAction<IVaultFile[]>>;
	slideData: { [key: string]: ISlideData };
	setSlideData: Dispatch<SetStateAction<{ [key: string]: ISlideData }>>;
	draggingSlide: ISlide;
	setDraggingSlide: Dispatch<SetStateAction<ISlide>>;
	slideImages: { [key: string]: { lq?: string; hq?: string } };
	setSlideImages: Dispatch<SetStateAction<{ [key: string]: { lq?: string; hq?: string } }>>;
	editorSettings: IEditorSettings;
	setEditorSettings: Dispatch<SetStateAction<IEditorSettings>>;
	referenceDecks: IDeck[];
	setReferenceDecks: Dispatch<SetStateAction<IDeck[]>>;
	activeReferenceDeck: IDeck;
	setActiveReferenceDeck: Dispatch<SetStateAction<IDeck>>;
}

const InitialDecksState: IDecksState = {
	activeSlide: null,
	setActiveSlide: null,
	activeSpanGroup: null,
	setActiveSpanGroup: null,
	connections: null,
	setConnections: null,
	scale: null,
	setScale: null,
	xShift: null,
	setXShift: null,
	yShift: null,
	setYShift: null,
	activeHeader: null,
	setActiveHeader: null,
	activeSectionWidth: null,
	setActiveSectionWidth: null,
	activeSpans: null,
	setActiveSpans: null,
	connector: null,
	setConnector: null,
	deck: null,
	setDeck: null,
	headerMapping: null,
	setHeaderMapping: null,
	headers: null,
	setHeaders: null,
	imageWidth: null,
	setImageWidth: null,
	isConnecting: null,
	setIsConnecting: null,
	isSelecting: null,
	setIsSelecting: null,
	rewrittenSections: null,
	setRewrittenSections: null,
	selection: null,
	setSelection: null,
	slideSectionResizing: null,
	setSlideSectionResizing: null,
	slideSectionWidth: null,
	setSlideSectionWidth: null,
	spanGroups: null,
	setSpanGroups: null,
	toolsPaneResizing: null,
	setToolsPaneResizing: null,
	toolsPaneWidth: null,
	setToolsPaneWidth: null,
	vaultParentFolder: null,
	setVaultParentFolder: null,
	sourceFiles: null,
	setSourceFiles: null,
	slideData: null,
	setSlideData: null,
	draggingSlide: null,
	setDraggingSlide: null,
	slideImages: null,
	setSlideImages: null,
	editorSettings: null,
	setEditorSettings: null,
	referenceDecks: null,
	setReferenceDecks: null,
	activeReferenceDeck: null,
	setActiveReferenceDeck: null
};

export const DecksWorkspaceContext = createContext(InitialDecksState);

export interface IEditorSettings {
	colorful: boolean;
	canEdit: boolean;
	selectMode: DecksSelectMode;
}

export enum DecksSelectMode {
	Section = "section",
	Header = "header",
	Write = "write",
	View = "view",
	Default = "default",
	Delete = "delete",
	Copy = "copy"
}

export function createDecksState(): IDecksState {
	const [activeSectionWidth, setActiveSectionWidth] = useState<number>(window.innerWidth - 18 * 16);
	const [activeSlide, setActiveSlide] = useState<ISlide>(null);
	const [activeSpanGroup, setActiveSpanGroup] = useState<any>(null);
	const [activeSpans, setActiveSpans] = useState<any[]>([]);
	const [connections, setConnections] = useState<any[]>([]);
	const [connector, setConnector] = useState<any>({});
	const [deck, setDeck] = useState<IDeck>(null);
	const [headers, setHeaders] = useState<any[]>([]);
	const [imageWidth, setImageWidth] = useState<number>(12);
	const [isConnecting, setIsConnecting] = useState<boolean>(false);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);
	const [scale, setScale] = useState<number>(1.2);
	const [selection, setSelection] = useState<any>({});
	const [slideSectionResizing, setSlideSectionResizing] = useState<boolean>(false);
	const [slideSectionWidth, setSlideSectionWidth] = useState<number>(18 * 16);
	const [spanGroups, setSpanGroups] = useState<any[]>([]);
	const [toolsPaneResizing, setToolsPaneResizing] = useState<boolean>(false);
	const [toolsPaneWidth, setToolsPaneWidth] = useState<number>(32);
	const [xShift, setXShift] = useState<number>(0);
	const [yShift, setYShift] = useState<number>(60);
	const [activeHeader, setActiveHeader] = useState<any>(null);
	const [headerMapping, setHeaderMapping] = useState<IHeaderMapping>({});
	const [rewrittenSections, setRewrittenSections] = useState<IRewrittenSections>({});
	const [slideData, setSlideData] = useState<{ [key: string]: ISlideData }>({});
	const [draggingSlide, setDraggingSlide] = useState<ISlide>(null);

	const [vaultParentFolder, setVaultParentFolder] = useState<IVaultFile>(null);
	const [sourceFiles, setSourceFiles] = useState<IVaultFile[]>([]);
	const [referenceDecks, setReferenceDecks] = useState<IDeck[]>([]);
	const [activeReferenceDeck, setActiveReferenceDeck] = useState<IDeck>(null);
	const [slideImages, setSlideImages] = useState<{ [key: string]: { lq?: string; hq?: string } }>({});

	const [editorSettings, setEditorSettings] = useState<IEditorSettings>({
		colorful: true,
		canEdit: false,
		selectMode: DecksSelectMode.Default
	});

	return {
		activeSectionWidth,
		setActiveSectionWidth,
		activeSlide,
		setActiveSlide,
		activeSpanGroup,
		setActiveSpanGroup,
		activeSpans,
		setActiveSpans,
		connections,
		setConnections,
		connector,
		setConnector,
		deck,
		setDeck,
		headers,
		setHeaders,
		imageWidth,
		setImageWidth,
		isConnecting,
		setIsConnecting,
		isSelecting,
		setIsSelecting,
		scale,
		setScale,
		selection,
		setSelection,
		slideSectionResizing,
		setSlideSectionResizing,
		slideSectionWidth,
		setSlideSectionWidth,
		spanGroups,
		setSpanGroups,
		toolsPaneResizing,
		setToolsPaneResizing,
		toolsPaneWidth,
		setToolsPaneWidth,
		xShift,
		setXShift,
		yShift,
		setYShift,
		activeHeader,
		setActiveHeader,
		headerMapping,
		setHeaderMapping,
		rewrittenSections,
		setRewrittenSections,
		vaultParentFolder,
		setVaultParentFolder,
		sourceFiles,
		setSourceFiles,
		slideData,
		setSlideData,
		draggingSlide,
		setDraggingSlide,
		slideImages,
		setSlideImages,
		editorSettings,
		setEditorSettings,
		referenceDecks,
		setReferenceDecks,
		activeReferenceDeck,
		setActiveReferenceDeck
	};
}

export interface IDecksStateExtended extends IDecksState {
	addActiveSpan: (span_id: string) => void;
	addHeader: () => void;
	addSpanGroup: () => void;
	addSourceFile: (file: IVaultFile) => void;
	addSourceFiles: (files: IVaultFile[]) => void;
	addReferenceDecks: (decks: IDeck[]) => void;
	breakHeader: (header: any) => void;
	breakSpanGroup: (spanGroup: any) => void;
	deleteSlide: (slide_id: string) => void;
	panCanvasHandler: (e: MouseEvent<HTMLDivElement>) => void;
	removeActiveSpan: (span_id: string) => void;
	removeSourceFile: (file: IVaultFile) => void;
	removeReferenceDeck: (deck: IDeck) => void;
	selectBoxHandler: (e: MouseEvent<HTMLDivElement>) => void;
	slidePaneResizeHandler: (e: MouseEvent<HTMLDivElement>) => void;
	spanGroupConnectorHandler: (x: number, y: number, span_id: string) => void;
	updateActiveSlide: (slide: ISlide) => void;
	updateImageSize: (imageSize: number) => void;
	toolsPaneResizeHandler: (e: MouseEvent<HTMLDivElement>) => void;
	reorderSlides: (slide: ISlide, idx: number) => void;
	getSlideImage: (deck_id: string, slide_id: string, variant: string) => Promise<string>;
}

export function useDecksState(): IDecksStateExtended {
	const decksState = useContext<IDecksState>(DecksWorkspaceContext);

	function toolsPaneResizeMouseMove(e) {
		decksState.setToolsPaneWidth((w) => {
			const new_width = w - e.movementX;
			const max_width = window.innerWidth - decksState.slideSectionWidth - 16;
			const min_width = 340;
			const closed_width = 32;

			if (new_width <= min_width && e.movementX < 0) {
				return new_width;
			}

			if (new_width >= max_width && e.movementX > 0) {
				return new_width;
			}

			if (new_width < min_width) {
				return closed_width;
			}

			if (new_width > max_width) {
				return max_width;
			}

			return new_width;
		});
	}

	const toolsPaneResizeHandler = MouseHandler({
		onMouseDown: () => decksState.setToolsPaneResizing(true),
		onMouseMove: toolsPaneResizeMouseMove,
		onMouseUp: () => decksState.setToolsPaneResizing(false)
	});

	function slidePaneResizeMove(e) {
		decksState.setSlideSectionWidth((w) => {
			const new_width = w + e.movementX;
			if (new_width < (decksState.imageWidth + 6) * 16) return w;

			if (window.innerWidth - new_width < 250) return w;

			decksState.setActiveSectionWidth(window.innerWidth - new_width);

			return new_width;
		});
	}

	const slidePaneResizeHandler = MouseHandler({
		onMouseDown: () => decksState.setSlideSectionResizing(false),
		onMouseMove: slidePaneResizeMove,
		onMouseUp: () => decksState.setSlideSectionResizing(false)
	});

	function panCanvasMouseMove(e) {
		decksState.setXShift((x) => x + e.movementX);
		decksState.setYShift((y) => y + e.movementY);
	}

	const panCanvasHandler = MouseHandler({ onMouseMove: panCanvasMouseMove });

	function selectBoxMouseDown(e: MouseEvent<HTMLDivElement>) {
		e.stopPropagation();
		decksState.setIsSelecting(true);
		decksState.setSelection({
			x0: e.clientX,
			y0: e.clientY,
			x1: e.clientX,
			x2: e.clientX,
			y1: e.clientY,
			y2: e.clientY,
			h: 0,
			w: 0
		});
	}

	function selectBoxMouseMove(e) {
		const new_x = e.clientX;
		const new_y = e.clientY;

		decksState.setSelection((s) => {
			const x1 = Math.min(s.x0, new_x);
			const x2 = Math.max(s.x0, new_x);
			const y1 = Math.min(s.y0, new_y);
			const y2 = Math.max(s.y0, new_y);

			const newSelection = { ...s, x1, y1, x2, y2, h: y2 - y1, w: x2 - x1 };

			return newSelection;
		});
	}

	function breakActive() {
		decksState.setActiveSpans((activeSpans) => {
			decksState.spanGroups.forEach((group) => {
				activeSpans.forEach((span) => {
					if (group.includes(span)) {
						breakSpanGroup(group.join(","));
					}
				});
			});
			decksState.headers.forEach((group) => {
				activeSpans.forEach((span) => {
					if (group.includes(span)) {
						breakHeader(group.join(","));
					}
				});
			});
			return [];
		});
	}

	function copyActive() {
		decksState.setActiveSpans((activeSpans) => {
			const spansToCopy = [];

			decksState.spanGroups.forEach((group) => {
				activeSpans.forEach((span) => {
					if (group.includes(span)) {
						spansToCopy.push(span);
					}
				});
			});
			decksState.headers.forEach((group) => {
				activeSpans.forEach((span) => {
					if (group.includes(span)) {
						spansToCopy.push(span);
					}
				});
			});

			let copyText = "";

			for (let block of decksState.activeSlide.blocks) {
				for (let line of block.lines) {
					for (let span of line.spans) {
						copyText += span.text += "";
					}
				}
			}

			navigator.clipboard.writeText(copyText);

			return [];
		});
	}

	function selectBoxMouseUp() {
		decksState.setIsSelecting(false);
		switch (decksState.editorSettings.selectMode) {
			case DecksSelectMode.Section:
				addSpanGroup();
				break;
			case DecksSelectMode.Header:
				addHeader();
				break;
			case DecksSelectMode.Delete:
				breakActive();
				break;
			case DecksSelectMode.Copy:
				copyActive();
				break;
		}
	}

	const selectBoxHandler = MouseHandler({
		onMouseDown: selectBoxMouseDown,
		onMouseMove: selectBoxMouseMove,
		onMouseUp: selectBoxMouseUp
	});

	function addActiveSpan(span_id: string) {
		if (!decksState.activeSpans.includes(span_id)) {
			decksState.setActiveSpans((s) => [...s, span_id]);
		}
	}

	function removeActiveSpan(span_id: string) {
		if (decksState.activeSpans.includes(span_id)) {
			decksState.setActiveSpans((s) => s.filter((s_id) => s_id !== span_id));
		}
	}

	function addSpanGroup() {
		decksState.setActiveSpans((spans) => {
			decksState.setActiveHeader(spans.join(","));
			decksState.setActiveSpanGroup(spans.join(","));
			decksState.setSpanGroups((groups) => {
				return [...groups, spans];
			});
			return [];
		});
	}

	function breakSpanGroup(group) {
		decksState.setSpanGroups((groups) => {
			return groups.filter((grp) => grp.join(",") !== group);
		});
	}

	function addHeader() {
		decksState.setActiveSpans((spans) => {
			decksState.setHeaders((groups) => {
				return [...groups, spans];
			});

			decksState.setActiveHeader((activeHeader) => {
				if (activeHeader) {
					decksState.setHeaderMapping((h) => ({
						...h,
						[activeHeader]: spans.join(",")
					}));
				}

				return activeHeader;
			});
			return [];
		});
	}

	function breakHeader(group) {
		decksState.setHeaders((groups) => {
			return groups.filter((grp) => grp.join(",") !== group);
		});
	}

	function spanGroupConnectorMouseDown(x: number, y: number, span_id: string) {
		decksState.setConnector({ x0: x, y0: y, x1: x, y1: y, x2: x, y2: y, start_span: span_id, end_span: null });
		decksState.setIsConnecting(true);
	}

	function spanGroupConnectorMouseUp() {
		decksState.setConnector({});
		decksState.setIsConnecting(false);
	}

	function spanGroupConnectorMouseMove(e) {
		decksState.setConnector((c) => {
			const new_x = e.clientX;
			const new_y = e.clientY;

			const x1 = Math.min(c.x0, new_x);
			const x2 = Math.max(c.x0, new_x);
			const y1 = Math.min(c.y0, new_y);
			const y2 = Math.max(c.y0, new_y);

			const newSelection = { ...c, x1, y1, x2, y2, h: y2 - y1, w: x2 - x1 };

			return newSelection;
		});
	}

	const spanGroupConnectorHandler = MouseHandler({
		onMouseDown: spanGroupConnectorMouseDown,
		onMouseMove: spanGroupConnectorMouseMove,
		onMouseUp: spanGroupConnectorMouseUp
	});

	function updateImageSize(new_size: number) {
		if ((new_size + 6) * 16 > decksState.slideSectionWidth) {
			decksState.setSlideSectionWidth((new_size + 6) * 16);
			decksState.setActiveSectionWidth(window.innerWidth - (new_size + 6) * 16);
		}
		decksState.setImageWidth(new_size);
	}

	function deleteSlide(slide_id: string) {
		decksState.setDeck((d) => {
			const cur_idx = d.slides.findIndex((p) => p.slide_id === slide_id);
			let next_idx = cur_idx - 1;

			if (next_idx < 0) {
				next_idx = 0;
			}

			if (next_idx > d.slides.length - 1) {
				next_idx = d.slides.length - 2;
			}

			decksState.setActiveSlide(d.slides[next_idx]);

			return {
				...d,
				slides: d.slides.filter((p) => p.slide_id !== slide_id)
			};
		});
	}

	function addSourceFile(file: IVaultFile) {
		decksState.setSourceFiles((current) => {
			if (!current.some((f) => f.file_id === file.file_id)) {
				return [...current, file];
			}
			return current;
		});
	}

	function addSourceFiles(files: IVaultFile[]) {
		decksState.setSourceFiles((current) => {
			const cur_ids = current.map((f) => f.file_id);
			return [...current, ...files.filter((f) => !cur_ids.includes(f.file_id))];
		});
	}

	function removeSourceFile(file: IVaultFile) {
		decksState.setSourceFiles((current) => current.filter((f) => f.file_id !== file.file_id));
	}

	function addReferenceDecks(decks: IDeck[]) {
		decksState.setReferenceDecks((current) => {
			const cur_ids = current.map((f) => f.deck_id);
			return [...current, ...decks.filter((f) => !cur_ids.includes(f.deck_id))];
		});
	}

	function removeReferenceDeck(deck: IDeck) {
		decksState.setReferenceDecks((current) => current.filter((f) => f.deck_id !== deck.deck_id));
	}

	function updateActiveSlide(slide: ISlide) {
		decksState.setSlideData((data) => ({
			...data,
			[decksState.activeSlide.slide_id]: {
				groups: decksState.spanGroups,
				headers: decksState.headers,
				headerMapping: decksState.headerMapping,
				rewrittenSections: decksState.rewrittenSections
			}
		}));
		decksState.setActiveSlide(slide);
		decksState.setSpanGroups(decksState.slideData[slide.slide_id]?.groups || []);
		decksState.setHeaders(decksState.slideData[slide.slide_id]?.headers || []);
		decksState.setHeaderMapping(decksState.slideData[slide.slide_id]?.headerMapping || {});
		decksState.setRewrittenSections(decksState.slideData[slide.slide_id]?.rewrittenSections || {});
		decksState.setSelection({});
		decksState.setActiveSpans([]);
		decksState.setActiveHeader([]);
		decksState.setActiveSpanGroup([]);
	}

	function reorderSlides(slide: ISlide, idx: number) {
		const newSlides = decksState.deck.slides.filter((slide_inner) => slide_inner.slide_id !== slide.slide_id);
		newSlides.splice(idx, 0, slide);

		decksState.setDeck((d) => ({ ...d, slides: newSlides.map((s, idx) => ({ ...s, idx })) }));
	}

	async function getSlideImage(deck_id: string, slide_id: string, variant: string): Promise<string> {
		if (decksState.slideImages[slide_id] && decksState.slideImages[slide_id][variant]) {
			return decksState.slideImages[slide_id][variant];
		} else {
			const url = await DecksService.getImage(deck_id, slide_id, variant);
			decksState.setSlideImages((s) => ({
				...s,
				[slide_id]: {
					...s[slide_id],
					[variant]: url
				}
			}));
			return url;
		}
	}

	return {
		...decksState,
		addActiveSpan,
		addHeader,
		addSpanGroup,
		addSourceFile,
		addSourceFiles,
		breakHeader,
		breakSpanGroup,
		deleteSlide,
		panCanvasHandler,
		removeActiveSpan,
		removeSourceFile,
		slidePaneResizeHandler,
		spanGroupConnectorHandler,
		updateActiveSlide,
		updateImageSize,
		toolsPaneResizeHandler,
		selectBoxHandler,
		reorderSlides,
		getSlideImage,
		removeReferenceDeck,
		addReferenceDecks
	};
}
