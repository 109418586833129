import { IWelcomeForm } from "@sage/types";

export enum WelcomeAction {
	UpdateWelcomeForm = "UpdateWelcomeForm",
	CreateUserRequest = "CreateUserRequest",
	CreateUserResponse = "CreateUserResponse",
	VerifyEmailRequest = "VerifyEmailRequest",
	VerifyEmailResponse = "VerifyEmailResponse"
}

export interface IUpdateWelcomeForm {
	type: WelcomeAction.UpdateWelcomeForm;
	payload: IWelcomeForm;
}

export type WelcomeActions = IUpdateWelcomeForm;
