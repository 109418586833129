import React from "react";
import { Control, Controller } from "react-hook-form";
import { Button, IButtonProps } from "@sage/shared/core";
import { sanitizeFormInput } from "@sage/utils";
import { Autosuggest, IAutosuggestOption } from "../Autosuggest/Autosuggest";
import { Checkbox } from "../Checkbox/Checkbox";
import { FormList } from "../FormList/FormList";
import { Toggle } from "../Toggle";
import "./FormField.scss";

export enum FormFieldVariant {
	Vertical = "Vertical",
	Horizontal = "Horizontal",
	HotizontalReverse = "HorizontalReverse"
}

export interface IFormFieldProps {
	label?: string;
	description?: string;
	placeholder?: string;
	control: Control;
	name: string;
	required?: boolean;
	type?:
		| "password"
		| "text"
		| "number"
		| "email"
		| "file"
		| "textarea"
		| "date"
		| "select"
		| "check"
		| "list"
		| "autosuggest"
		| "datetime-local"
		| "toggle"
		| "color";
	autosuggestOptions?: IAutosuggestOption[];
	options?: string[];
	disabled?: boolean;
	variant?: FormFieldVariant;
	rows?: number;
	actions?: IButtonProps[];
	handleSubmit?: any;
	fill?: boolean;
	horizontalAlign?: "left" | "right" | "center";
	refocusRef?: any;
	onFocus?: () => void;
	onBlur?: () => void;
	setValue?: (name: string, value: any) => any | void;
	onChange?: (value: string) => void;
	directive?: (e: string) => string;
}

export function FormField({
	label,
	description,
	placeholder,
	onFocus,
	onChange,
	onBlur,
	setValue,
	directive,
	control,
	name,
	required,
	type,
	disabled,
	variant,
	rows,
	actions,
	handleSubmit,
	options,
	fill,
	horizontalAlign,
	refocusRef,
	autosuggestOptions
}: IFormFieldProps) {
	function handleKeyPress(e) {
		if (e.key === "Enter" && handleSubmit) {
			handleSubmit();
		}
	}

	function handleFormAction(field, target) {
		switch (type) {
			case "file":
				field.onChange({
					value: target.value,
					file: target.files[0]
				});
				break;
			default:
				const value = directive ? directive(target.value) : target.value;
				if (onChange) onChange(value);
				field.onChange(value);
				break;
		}
	}

	if (type === "autosuggest") {
		return (
			<Autosuggest
				options={autosuggestOptions}
				control={control}
				name={name}
				label={label}
				description={description}
				setValue={setValue}
				onChange={onChange}
			/>
		);
	} else if (type === "list") {
		return (
			<div className={`__form-field-container vertical`}>
				{label ? <div className={`label ${horizontalAlign ? horizontalAlign : ""}`}>{label}</div> : null}
				<div className={`input-section ${fill ? "fill" : ""}`}>
					{description ? <div className={"description"}>{description}</div> : null}
					<div className={"input-row"}>
						<FormList
							control={control}
							name={name}
							placeholder={placeholder}
						/>
						{actions ? (
							<div className={"form-actions-vertical"}>
								{actions.map((action, idx) => (
									<Button
										key={idx}
										{...action}
									/>
								))}
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	} else if (variant === FormFieldVariant.Vertical) {
		return (
			<Controller
				render={({ field, fieldState }) => (
					<div className={`__form-field-container vertical ${fieldState.error ? "error" : ""}`}>
						<div className={`input-section ${fill ? "fill" : ""}`}>
							{label ? <div className={`label ${horizontalAlign ? horizontalAlign : ""}`}>{label}</div> : null}
							{description ? (
								<div
									className={"description"}
									data-testid={`${name}-form-control-description`}
								>
									{description}
								</div>
							) : null}
							<div className={"input-row"}>
								{type === "toggle" ? (
									<Toggle
										active={field.value}
										update={field.onChange}
										toggle={() => field.onChange(!field.value)}
									/>
								) : type === "check" ? (
									<Checkbox
										control={control}
										name={name}
										defaultValue={false}
										refocusRef={refocusRef}
									/>
								) : type === "select" ? (
									<select
										className={"input"}
										value={sanitizeFormInput(field.value)}
										onChange={(e) => field.onChange(e.target.value)}
										data-testid={`${name}-form-control`}
									>
										{options?.map((option) => <option key={option}>{option}</option>)}
									</select>
								) : type === "textarea" ? (
									<textarea
										className={"input textarea-input"}
										placeholder={placeholder}
										value={sanitizeFormInput(field.value)}
										onChange={({ target }) => handleFormAction(field, target)}
										disabled={disabled}
										rows={rows}
										ref={refocusRef}
										onFocus={onFocus}
										onBlur={onBlur}
										data-testid={`${name}-form-control`}
									/>
								) : (
									<input
										className={"input"}
										placeholder={placeholder}
										value={sanitizeFormInput(type === "file" ? field.value?.value : field.value)}
										onChange={({ target }) => handleFormAction(field, target)}
										type={type || "text"}
										disabled={disabled}
										onKeyDown={handleKeyPress}
										ref={refocusRef}
										onFocus={onFocus}
										onBlur={onBlur}
										data-testid={`${name}-form-control`}
									/>
								)}
								{actions ? (
									<div className={"form-actions-vertical"}>
										{actions.map((action, idx) => (
											<Button
												key={idx}
												{...action}
											/>
										))}
									</div>
								) : null}
							</div>

							{fieldState.error ? (
								<div
									className={"error"}
									data-testid={`${name}-form-control-error`}
								>
									{fieldState.error?.message}
								</div>
							) : null}
						</div>
					</div>
				)}
				name={name}
				control={control}
				rules={{ required: required || false }}
			/>
		);
	} else if (variant === FormFieldVariant.HotizontalReverse) {
		return (
			<Controller
				render={({ field, fieldState }) => (
					<div className={`__form-field-container ${fieldState.error ? "error" : ""}`}>
						<div className={`input-section ${fill ? "fill" : ""}`}>
							{type === "toggle" && (
								<Toggle
									active={field.value}
									update={field.onChange}
									toggle={() => field.onChange(!field.value)}
								/>
							)}
							{type === "check" && (
								<Checkbox
									control={control}
									name={name}
									defaultValue={false}
									refocusRef={refocusRef}
								/>
							)}
							{type === "select" && (
								<select
									className={"input"}
									value={sanitizeFormInput(field.value)}
									onChange={(e) => field.onChange(e.target.value)}
									data-testid={`${name}-form-control`}
								>
									{options?.map((option) => <option key={option}>{option}</option>)}
								</select>
							)}
							{type === "textarea" && (
								<textarea
									className={"input"}
									placeholder={placeholder}
									value={sanitizeFormInput(field.value)}
									onChange={({ target }) => handleFormAction(field, target)}
									disabled={disabled}
									ref={refocusRef}
									onFocus={onFocus}
									onBlur={onBlur}
									data-testid={`${name}-form-control`}
								/>
							)}
							{!["toggle", "check", "select", "textarea"].includes(type) && (
								<input
									className={"input"}
									placeholder={placeholder}
									value={sanitizeFormInput(type === "file" ? field.value?.value : field.value)}
									onChange={({ target }) => handleFormAction(field, target)}
									type={type || "text"}
									disabled={disabled}
									onKeyDown={handleKeyPress}
									ref={refocusRef}
									onFocus={onFocus}
									onBlur={onBlur}
									data-testid={`${name}-form-control`}
								/>
							)}
							{fieldState.error ? (
								<div
									className={"error"}
									data-testid={`${name}-form-control-error`}
								>
									{fieldState.error?.message}
								</div>
							) : null}
							{description ? (
								<div
									className={"description"}
									data-testid={`${name}-form-control-description`}
								>
									{description}
								</div>
							) : null}
						</div>
						{actions ? (
							<div className={"form-actions"}>
								{actions.map((action, idx) => (
									<Button
										key={idx}
										{...action}
									/>
								))}
							</div>
						) : null}
						<div className={`label ${horizontalAlign ? horizontalAlign : ""}`}>{label}</div>
					</div>
				)}
				name={name}
				control={control}
				rules={{ required: required || false }}
			/>
		);
	} else {
		return (
			<Controller
				render={({ field, fieldState }) => (
					<div className={`__form-field-container ${fieldState.error ? "error" : ""}`}>
						<div className={`label ${horizontalAlign ? horizontalAlign : ""}`}>{label}</div>
						<div className={`input-section ${fill ? "fill" : ""}`}>
							{type === "toggle" ? (
								<Toggle
									active={field.value}
									update={field.onChange}
									toggle={() => field.onChange(!field.value)}
								/>
							) : type === "check" ? (
								<Checkbox
									control={control}
									name={name}
									defaultValue={false}
									refocusRef={refocusRef}
								/>
							) : type === "select" ? (
								<select
									className={"input"}
									value={sanitizeFormInput(field.value)}
									onChange={(e) => field.onChange(e.target.value)}
									data-testid={`${name}-form-control`}
								>
									{options?.map((option) => <option key={option}>{option}</option>)}
								</select>
							) : type === "textarea" ? (
								<textarea
									className={"input"}
									placeholder={placeholder}
									value={sanitizeFormInput(field.value)}
									onChange={({ target }) => handleFormAction(field, target)}
									disabled={disabled}
									ref={refocusRef}
									onFocus={onFocus}
									onBlur={onBlur}
									data-testid={`${name}-form-control`}
								/>
							) : (
								<input
									className={"input"}
									placeholder={placeholder}
									value={sanitizeFormInput(type === "file" ? field.value?.value : field.value)}
									onChange={({ target }) => handleFormAction(field, target)}
									type={type || "text"}
									disabled={disabled}
									onKeyDown={handleKeyPress}
									ref={refocusRef}
									onFocus={onFocus}
									onBlur={onBlur}
									data-testid={`${name}-form-control`}
								/>
							)}
							{fieldState.error ? (
								<div
									className={"error"}
									data-testid={`${name}-form-control-error`}
								>
									{fieldState.error?.message}
								</div>
							) : null}
							{description ? (
								<div
									className={"description"}
									data-testid={`${name}-form-control-description`}
								>
									{description}
								</div>
							) : null}
						</div>
						{actions ? (
							<div className={"form-actions"}>
								{actions.map((action, idx) => (
									<Button
										key={idx}
										{...action}
									/>
								))}
							</div>
						) : null}
					</div>
				)}
				name={name}
				control={control}
				rules={{ required: required || false }}
			/>
		);
	}
}
