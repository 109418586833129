export function Animate(start: number, end: number, cb: (e: number) => void, duration = 100) {
	const framerate = 60;
	const n_steps = (framerate / 1000) * duration;
	const step_length = duration / n_steps;

	const direction = end > start ? 1 : -1;

	const distance = Math.max(end, start) - Math.min(end, start);
	const step_size = distance / n_steps;

	let idx = 0;
	let interval;

	function next() {
		idx++;

		cb(start + direction * idx * step_size);

		if (idx >= n_steps) {
			clearInterval(interval);
		}
	}

	interval = setInterval(next, step_length);
}
