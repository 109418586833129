import { useEffect, useState } from "react";

export function useCollection<T>({ load }: { load: () => Promise<T[]> }) {
	const [items, setItems] = useState<T[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	async function loadItems() {
		setLoading(true);
		setItems(await load());
		setLoading(false);
	}

	useEffect(() => {
		loadItems();
	}, []);

	return {
		items,
		loading,
		refresh: loadItems
	};
}
