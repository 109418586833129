import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IDealRoom } from "@sage/types";
import { useAuthState } from "@sage/state";
import { DealRoomService } from "@sage/services";
import {
	Bullet,
	Button,
	ButtonBorderShape,
	ButtonIcon,
	ButtonVariant,
	Col,
	DateHeader,
	FaIcon,
	ItemsRow,
	Link,
	Pad,
	Row,
	SearchResult,
	SectionTitle,
	TimeHeader
} from "@sage/shared/core";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { useDebounce, usePageMeta } from "@sage/utils";
import "./List.scss";

export function DealList() {
	const authState = useAuthState();
	const navigate = useNavigate();
	const [deals, setDeals] = useState<IDealRoom[]>([]);
	const [allDeals, setAllDeals] = useState<IDealRoom[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const form = useForm();

	usePageMeta({
		title: "Sage AI - Active Deals",
		defaultTitle: "Sage AI Dealroom"
	});

	const updateDeals = useDebounce(setDeals);

	async function loadDeals() {
		const _deals = await DealRoomService.GetDealRooms();
		setDeals(_deals);
		setAllDeals(_deals);
	}

	async function createDeal() {
		const newDeal = await DealRoomService.CreateDealRoom();
		navigate(`/app/dealroom/${newDeal.deal_id}`);
	}

	function search(query: string) {
		setSearchTerm(query);
		updateDeals(allDeals.filter((d) => d.deal_name.toLowerCase().includes(query.toLowerCase())));
	}

	async function deleteDeal(deal_id: string) {
		await DealRoomService.DeleteDealRoom(deal_id);
		await loadDeals();
	}

	useEffect(() => {
		loadDeals();
	}, []);

	return (
		<Pad padding="1rem">
			<Col>
				<SectionTitle>
					{authState.team.teamName}'{authState.team?.teamName?.endsWith("s") ? "" : "s"} Deals
				</SectionTitle>
				<Row
					horizontalAlign="between"
					verticalAlign="center"
				>
					<FormField
						control={form.control}
						name="search"
						variant={FormFieldVariant.Vertical}
						onChange={(e) => search(e)}
						placeholder="Search..."
					/>
					<Button
						action={createDeal}
						variant={ButtonVariant.Secondary}
						borderShape={ButtonBorderShape.Sharp}
						icon={ButtonIcon.MaterialAdd}
					>
						New Deal
					</Button>
				</Row>
				<ItemsRow
					items={deals.sort((b, a) => a.lastModifiedTimestamp - b.lastModifiedTimestamp)}
					dateCol="lastModifiedTimestamp"
					render={(deal) => (
						<DealRoom
							key={deal.deal_id}
							searchTerm={searchTerm}
							deleteDeal={deleteDeal}
						>
							{deal}
						</DealRoom>
					)}
				/>
			</Col>
		</Pad>
	);
}

function DealRoom({
	children,
	searchTerm,
	deleteDeal
}: {
	children: IDealRoom;
	searchTerm?: string;
	deleteDeal?: (deal_id: string) => void;
}) {
	const authState = useAuthState();

	function handleClick(fn) {
		function inner(e, ...args) {
			e.preventDefault();
			e.stopPropagation();
			fn(...args);
		}
		return inner;
	}

	return (
		<Link to={`/app/dealroom/${children.deal_id}`}>
			<div className="__sage-deal-room-list-item">
				<Col>
					<Row
						verticalAlign={"center"}
						wrap={false}
					>
						<Col gap={"0.125rem"}>
							<SectionTitle inline>
								<SearchResult searchTerm={searchTerm}>{children.deal_name}</SearchResult>
							</SectionTitle>
						</Col>
					</Row>
					<Row verticalAlign="center">
						<DateHeader
							size="sm"
							thin
						>
							{children.lastModifiedTimestamp}
						</DateHeader>
						<Bullet size="sm" />
						<TimeHeader size="sm">{children.lastModifiedTimestamp}</TimeHeader>
					</Row>
					{authState.user.is_admin && (
						<Row horizontalAlign="right">
							<FaIcon
								icon="trash-can"
								color="#a80300"
								size="lg"
								onClick={(e) => handleClick(deleteDeal)(e, children.deal_id)}
							/>
						</Row>
					)}
				</Col>
			</div>
		</Link>
	);
}
