import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Button, Col, FaIcon, Modal, P, Pad, Row } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import "./StyleEditorModal.scss";

export function StyleEditorModal() {
	const { project_id, loadProject, showStyleEditor, setShowStyleEditor, primary_color, secondary_color, font_color, chart_colors } =
		useDealRoomProject();
	const form = useForm({ defaultValues: { primary_color, secondary_color, font_color, chart_colors } });

	const [logoUrl, setLogoUrl] = useState(null);

	async function submitForm(formValue) {
		await Promise.all([
			DealRoomService.UpdatePrimaryColor(project_id, formValue.primary_color),
			DealRoomService.UpdateSecondaryColor(project_id, formValue.secondary_color),
			DealRoomService.UpdateFontColor(project_id, formValue.font_color),
			DealRoomService.UpdateChartColors(project_id, formValue.chart_colors)
		]);
		await loadProject();
		setShowStyleEditor(false);
	}

	async function uploadLogo(file) {
		await DealRoomService.UploadLogo({ project_id, file, onProgress: () => {} });
		await downloadLogo();
	}

	async function downloadLogo() {
		setLogoUrl(await DealRoomService.DownloadLogo(project_id));
	}

	useEffect(() => {
		downloadLogo();
	}, [showStyleEditor]);

	return (
		<Modal
			visible={showStyleEditor}
			onDismiss={() => setShowStyleEditor(false)}
			title={"Theme Options"}
			minWidth={"40rem"}
		>
			<Pad>
				<Col gap={"1rem"}>
					<Form
						fields={[
							{
								control: form.control,
								name: "primary_color",
								type: "color",
								variant: FormFieldVariant.Vertical,
								label: "Primary Color"
							},
							{
								control: form.control,
								name: "secondary_color",
								type: "color",
								variant: FormFieldVariant.Vertical,
								label: "Secondary Color"
							},
							{
								control: form.control,
								name: "font_color",
								type: "color",
								variant: FormFieldVariant.Vertical,
								label: "Font Color"
							}
						]}
					/>
					<P>Default Chart Colors</P>
					<ColorPallete
						form={form}
						name="chart_colors"
					/>
					<P>Logo</P>
					<FileUpload
						uploadFn={uploadLogo}
						imageUrl={logoUrl}
					/>
				</Col>
			</Pad>
			<Row
				horizontalAlign="right"
				width={"100%"}
			>
				<Button action={form.handleSubmit(submitForm)}>Save</Button>
			</Row>
		</Modal>
	);
}

function ColorPallete({ name, form }) {
	return (
		<Controller
			name={name}
			control={form.control}
			render={({ field }) => (
				<CollorPalleteInner
					value={field.value}
					onChange={field.onChange}
				/>
			)}
		/>
	);
}

function CollorPalleteInner({ value, onChange }) {
	const defaultColors = ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056", "#8dddd0"];

	function addColor() {
		const currentColors = value || [];
		const unusedDefaultColors = defaultColors.filter((c) => !currentColors.includes(c));
		if (unusedDefaultColors.length > 0) {
			onChange([...currentColors, unusedDefaultColors[0]]);
		} else {
			onChange([...currentColors, defaultColors[0]]);
		}
	}

	function updateColor(idx: number) {
		const input = document.createElement("input");
		input.type = "color";
		input.style.display = "none";
		input.addEventListener("change", (e) => {
			onChange([...value.slice(0, idx), e.target.value, ...value.slice(idx + 1)]);
			document.body.removeChild(input);
		});
		document.body.appendChild(input);
		input.click();
	}

	function deleteColor(e, idx: number) {
		e.preventDefault();
		e.stopPropagation();
		onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
	}

	return (
		<div className="__sage-color-pallete">
			<Row
				horizontalAlign="left"
				verticalAlign="center"
				gap="0.5rem"
			>
				<Row
					horizontalAlign="left"
					verticalAlign="center"
					gap="0"
				>
					{value &&
						value.map((color, idx) => (
							<div
								className="swatch"
								key={`color-${idx}`}
								style={{ backgroundColor: color }}
								onClick={() => updateColor(idx)}
							>
								<div className="delete">
									<FaIcon
										icon={"trash-can"}
										color={"#a80300"}
										animation={"fa-shake"}
										hideBg
										size="xl"
										onClick={(e) => deleteColor(e, idx)}
									/>
								</div>
							</div>
						))}
				</Row>
				<div className="add-color">
					<FaIcon
						icon={"add"}
						size="xl"
						hideBg
						paddingInline="0"
						onClick={addColor}
					/>
				</div>
			</Row>
		</div>
	);
}

function FileUpload({ uploadFn, imageUrl }) {
	const fileInputRef = useRef(null);
	const [imageAvailable, setImageAvailable] = useState<boolean>(false);

	async function formInputHandler(file) {
		await uploadFn(file.target.files[0]);
	}

	async function checkUrl() {
		const res = await fetch(imageUrl);
		setImageAvailable(res.status === 200);
	}

	useEffect(() => {
		checkUrl();
	}, [imageUrl]);

	return (
		<div
			className="__sage-image-upload"
			onClick={() => fileInputRef.current.click()}
		>
			{imageAvailable ? <img src={imageUrl} /> : <div>Click To Upload</div>}
			<input
				className={"hidden-input"}
				type={"file"}
				ref={fileInputRef}
				onChange={formInputHandler}
				data-testid="file-form-control"
			/>
		</div>
	);
}
