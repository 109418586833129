import { useNavigate } from "react-router-dom";
import { IDealRoomProject, ProjectType } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, Col, FaIcon, ItemsCol, SectionTitle } from "@sage/shared/core";
import { InfoRequestCard, Project } from "@sage/shared/dealrooms";
import "./DealPhaseProjects.scss";

export function DealPhaseProjects() {
	const { projects, phase, deal_id, deal_name, phase_id, setDeleteProject, infoRequests, setDeleteInfoRequest } = useDealRoom();
	const navigate = useNavigate();

	async function createProject(project_type: ProjectType, project_name?: string) {
		const newProject = await DealRoomService.SaveProject({ deal_id, phase_id, project_type, project_name });
		navigate(`./project/${newProject.project_id}`);
	}

	async function deleteProject(project: IDealRoomProject) {
		setDeleteProject(project);
	}

	async function createInfoRequest() {
		const req_name = phase.phase_name === "Initial Diligence" ? "Info Request" : "Questions List";
		const request = await DealRoomService.SaveInfoRequest({ deal_id, phase_id, request_name: `${deal_name} - ${req_name}` });
		navigate(`./info/${request.request_id}`);
	}

	function openInfoRequest(request_id: string) {
		navigate(`./info/${request_id}`);
	}

	return (
		<div className="__sage-deal-room-phase-projects">
			<Col gap={"1rem"}>
				{phase?.phase_name === "Initial Diligence" && (
					<Col gap={"0.5rem"}>
						<SectionTitle>Information Requests</SectionTitle>
						<Button
							icon={ButtonIcon.MaterialAdd}
							variant={ButtonVariant.Secondary}
							borderShape={ButtonBorderShape.Sharp}
							width={"15rem"}
							action={createInfoRequest}
						>
							New Info Request
						</Button>
						<ItemsCol
							items={infoRequests}
							dateCol={"lastModifiedTimestamp"}
							gap={"1rem"}
							render={(request) => (
								<InfoRequestCard
									key={request.request_id}
									request={request}
									select={() => openInfoRequest(request.request_id)}
									deleteRequest={() => setDeleteInfoRequest(request)}
								/>
							)}
						/>
					</Col>
				)}
				{phase?.phase_name === "Buyer Questions" && (
					<Col gap={"0.5rem"}>
						<SectionTitle>Buyer Questions</SectionTitle>
						<Button
							icon={ButtonIcon.MaterialAdd}
							variant={ButtonVariant.Secondary}
							borderShape={ButtonBorderShape.Sharp}
							width={"15rem"}
							action={createInfoRequest}
						>
							New Questions List
						</Button>
						<ItemsCol
							items={infoRequests}
							dateCol={"lastModifiedTimestamp"}
							gap={"1rem"}
							render={(request) => (
								<InfoRequestCard
									key={request.request_id}
									request={request}
									select={() => openInfoRequest(request.request_id)}
									deleteRequest={() => setDeleteInfoRequest(request)}
								/>
							)}
						/>
					</Col>
				)}
				{((phase?.phase_name !== "Buyer Questions" && phase?.phase_name !== "Initial Diligence") || projects.length > 0) && (
					<Col gap={"0rem"}>
						<SectionTitle>{phase?.phase_name} Projects</SectionTitle>
						<div className="new-project">
							<Button
								icon={ButtonIcon.MaterialAdd}
								variant={ButtonVariant.Secondary}
								borderShape={ButtonBorderShape.Sharp}
								width={"15rem"}
								options={[
									{
										type: "option",
										action() {
											createProject(ProjectType.CIM, `${deal_name} - CIM`);
										},
										iconSlot: (
											<FaIcon
												icon="file-chart-column"
												color="#a80300"
												size={"2x"}
												direction={"row"}
												hideBg
											>
												CIM
											</FaIcon>
										)
									},
									{
										type: "option",
										action() {
											createProject(ProjectType.Memo, `${deal_name} - Memo`);
										},
										iconSlot: (
											<FaIcon
												icon="file-spreadsheet"
												color="#00a857"
												size={"2x"}
												direction={"row"}
												hideBg
											>
												Memo
											</FaIcon>
										)
									},
									{
										type: "option",
										action() {
											createProject(ProjectType.MP, `${deal_name} - Management Presentation`);
										},
										iconSlot: (
											<FaIcon
												icon="screen-users"
												color="#f7a501"
												size={"2x"}
												direction={"row"}
												hideBg
											>
												Management Presentation
											</FaIcon>
										)
									},
									{
										type: "option",
										action() {
											createProject(ProjectType.Teaser, `${deal_name} - Teaser`);
										},
										iconSlot: (
											<FaIcon
												icon="memo"
												color="#0051a8"
												size={"2x"}
												direction={"row"}
												hideBg
											>
												Teaser
											</FaIcon>
										)
									},
									{
										type: "option",
										action() {
											createProject(ProjectType.BakeOff, `${deal_name} - Bake Off`);
										},
										iconSlot: (
											<FaIcon
												icon="screen-users"
												color="#f7a501"
												size={"2x"}
												direction={"row"}
												hideBg
											>
												Bake Off
											</FaIcon>
										)
									},
									{
										type: "option",
										action() {
											createProject(ProjectType.Other, `${deal_name} - Untitled Project`);
										},
										iconSlot: (
											<FaIcon
												icon="file-chart-pie"
												color="#5700a8"
												size={"2x"}
												direction={"row"}
												hideBg
											>
												Other
											</FaIcon>
										)
									}
								]}
							>
								New Project
							</Button>
						</div>
						<ItemsCol
							items={projects}
							dateCol={"lastModifiedTimestamp"}
							gap={"1rem"}
							render={(project) => (
								<Project
									key={project.project_id}
									project={project}
									deleteProject={() => deleteProject(project)}
								/>
							)}
						/>
					</Col>
				)}
			</Col>
		</div>
	);
}
