export enum VaultFileType {
	DOC = "DOC",
	DECK = "DECK",
	FOLDER = "FOLDER",
	WORD = "WORD",
	EXCEL = "EXCEL",
	POWERPOINT = "POWERPOINT",
	HTML = "HTML",
	PDF = "PDF",
	IMAGE = "IMAGE",
	VIDEO = "VIDEO",
	PLAINTEXT = "PLAINTEXT",
	OTHER = "OTHER"
}

export interface IVaultFile {
	file_id: string;
	created_by: string;
	teamCode: string;
	file_type: VaultFileType;
	file_name: string;
	description: string;
	prefix: string;
	size?: number;
	parent_folder_id: string;
	sage_filetype_id: string;
	createdTimestamp: number;
	lastModifiedTimestamp: number;
	processing_status?: VaultProcessingStatus;
	path: string;
	original_filename?: string;
}

export enum VaultProcessingStatus {
	InProgress = "InProgress",
	Failed = "Failed",
	Finished = "Finished"
}

export interface IVaultDataResults {
	document_id: string;
	file_id: string;
	created_by: string;
	teamCode: string;
	file_type: VaultFileType;
	file_name: string;
	description: string;
	prefix: string;
	size?: number;
	parent_folder_id: string;
	sage_filetype_id: string;
	createdTimestamp: number;
	lastModifiedTimestamp: number;
	embedding: number[];
	page_content: string;
	page_number?: number;
	text_content: string;
}

export interface IExistVaultFileType {
	prefix: string;
	filename: string;
}
