import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DealstreamService } from "@sage/services";
import { Acquisition, CompanyCard } from "@sage/shared/company";
import { Button, ButtonVariant, Card, CardTitle, Col, Collapsible, H3, InlineLoading, Row } from "@sage/shared/core";

export function ComparableDeals() {
	const [comparableDeals, setComparableDeals] = useState(null);
	const [showMoreDeals, setShowMoreDeals] = useState<boolean>(false);

	const params = useParams();

	async function loadComps() {
		setComparableDeals(await DealstreamService.GetBuyers(params.uid));
	}

	useEffect(() => {
		if (params.uid) loadComps();
	}, [params.uid]);

	return comparableDeals ? (
		<Card>
			<CardTitle>Similar Transactions</CardTitle>
			<Col>
				{comparableDeals.slice(0, 3).map((comp) => (
					<Row key={comp.uid}>
						<Col>
							<H3>Acquisition Details</H3>
							<Acquisition acquisition_id={comp.acquisition_id} />
						</Col>
						<Col>
							<H3>Acquired Company</H3>
							<CompanyCard
								key={comp.uid}
								company_id={comp.uid}
							/>
						</Col>
						<Col>
							<H3>Buyer</H3>
							<CompanyCard
								key={comp.owner_id}
								company_id={comp.owner_id}
							/>
						</Col>
					</Row>
				))}
			</Col>
			<Col>
				<Collapsible visible={showMoreDeals}>
					{comparableDeals.slice(3).map((comp) => (
						<Row key={comp.uid}>
							<Col>
								<H3>Acquisition Details</H3>
								<Acquisition acquisition_id={comp.acquisition_id} />
							</Col>
							<Col>
								<H3>Acquired Company</H3>
								<CompanyCard
									key={comp.uid}
									company_id={comp.uid}
								/>
							</Col>
							<Col>
								<H3>Buyer</H3>
								<CompanyCard
									key={comp.owner_id}
									company_id={comp.owner_id}
								/>
							</Col>
						</Row>
					))}
				</Collapsible>
			</Col>
			<Button
				variant={ButtonVariant.Tertiary}
				action={() => setShowMoreDeals((s) => !s)}
			>
				{showMoreDeals ? "Less" : "See More Deals"}
			</Button>
		</Card>
	) : (
		<InlineLoading invert />
	);
}
