import React, { useEffect, useRef, useState } from "react";
import { Actor, DealRoomProjectElementType, IDealRoomElementTemplate, IDealRoomElementThread } from "@sage/types";
import { useAuthState, useDealRoomProject, useDealRoomProjectElement } from "@sage/state";
import { DealRoomService, LlmService } from "@sage/services";
import { Message } from "@sage/shared/chat";
import { FaIcon, LoadingBubble, Row } from "@sage/shared/core";
import { isNullOrUndefined, useMouseHandler } from "@sage/utils";
import { usePrompts } from "@sage/prompts";
import "./Element.scss";

export function Element({ element_id, page_id }) {
	const authState = useAuthState();
	const {
		deal_id,
		loadElements,
		setRefreshElement,
		moveElementDown,
		moveElementUp,
		moveElementDownWithinPage,
		moveElementUpWithinPage,
		crawls
	} = useDealRoomProject();
	const { element_text, element_template_id, element_type, remove, active, setActive } = useDealRoomProjectElement({
		element_id,
		page_id
	});
	const [inProgressLLM, setInProgressLLM] = useState<string>(null);
	const [thread, setThread] = useState<IDealRoomElementThread>(null);
	const [template, setTemplate] = useState<IDealRoomElementTemplate>(null);
	const [loadingElement, setLoadingElement] = useState<boolean>(false);
	const prompts = usePrompts();
	const generatingRef = useRef(false);
	const triesRef = useRef(0);
	const [width, setWidth] = useState(100);

	function generateText(request, cb): Promise<string> {
		if (triesRef.current < 20) {
			triesRef.current = triesRef.current + 1;
			return new Promise(async (resolve) => {
				try {
					const { generated_text } = await LlmService.Stream(request, cb);

					if (generated_text.length === 0) {
						setTimeout(async () => {
							resolve(await generateText(request, cb));
						}, 2000);
					} else {
						resolve(generated_text);
					}
				} catch (e) {
					setTimeout(async () => {
						resolve(await generateText(request, cb));
					}, 2000);
				}
			});
		} else {
			return new Promise((resolve) => resolve('<flag number="1">An Error ocoured, please try again</flag>'));
		}
	}

	async function generateElementText() {
		generatingRef.current = true;
		setLoadingElement(true);

		let text = template.prompt;

		if (template.example) {
			text += `\n\nUsing the following as a style guide, generate a response that matches its tone, structure, and writing patterns:\n\n${template.example}\n`;
		}

		await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text,
			actorType: Actor.User,
			actor: authState.user.user_id,
			idx: 0
		});

		const results = await Promise.all(
			[...template.search_terms, template.prompt].map(async (query) =>
				(
					await Promise.all([
						DealRoomService.SearchSources(deal_id, query, element_id),
						...crawls.map((crawl) => DealRoomService.SearchWebData(crawl.host, query))
					])
				).flat()
			)
		);

		const searchResultsMap = {};

		for (let result of results.flat()) {
			if ("chunk_id" in result) {
				if (result.chunk_id in searchResultsMap) {
					searchResultsMap[result.chunk_id] = {
						...searchResultsMap[result.chunk_id],
						relevance_score: Math.max(searchResultsMap[result.chunk_id].relevance_score, result.relevance_score),
						fragment_text: [...new Set([...result.fragment_text, ...searchResultsMap[result.chunk_id].fragment_text])]
					};
				} else {
					searchResultsMap[result.chunk_id] = result;
				}
			} else {
				searchResultsMap[result.page_id] = result;
			}
		}

		const searchResults = Object.values(searchResultsMap)
			.sort((a, b) => b.relevance_score - a.relevance_score)
			.slice(0, 50);

		const generated_text = await generateText(
			{
				prompt: text,
				context: JSON.stringify(searchResults),
				preprompt: prompts.dealRoom(element_type),
				thread: thread.thread_id
			},
			setInProgressLLM
		);

		await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text: generated_text,
			actorType: Actor.Assistant,
			actor: "Athena",
			idx: 1
		});

		await Promise.all(
			searchResults.map((result) => {
				if ("chunk_id" in result) {
					return DealRoomService.CreateElementSource(result);
				} else {
					return DealRoomService.CreateCrawlSource({ ...result, element_id });
				}
			})
		);

		await DealRoomService.UpdateElementText(element_id, generated_text);
		await loadElements();
		setRefreshElement(Math.random());
		generatingRef.current = false;
		setLoadingElement(false);
	}

	async function loadThread() {
		if (!isNullOrUndefined(element_id)) {
			const threads = await DealRoomService.GetThreadsByElement(element_id);
			if (threads.length === 0) {
				const _thread = await DealRoomService.SaveElementThread({ element_id });
				setThread(_thread);
			} else {
				setThread(threads[0]);
			}
		}
	}

	async function loadTemplate() {
		setTemplate(await DealRoomService.GetTemplate(element_template_id));
	}

	async function updateElementMessage(newText: string) {
		await DealRoomService.UpdateElementText(element_id, newText);
	}

	useEffect(() => {
		if (element_id) {
			loadThread();
		}
		if (!isNullOrUndefined(element_template_id)) {
			loadTemplate();
		}
	}, [element_id]);

	useEffect(() => {
		if (!isNullOrUndefined(template) && isNullOrUndefined(element_text) && !isNullOrUndefined(thread) && !generatingRef.current) {
			generateElementText();
		}
	}, [template, thread]);

	function renderEl() {
		switch (element_type) {
			case DealRoomProjectElementType.Chart:
				return element_text || inProgressLLM ? (
					<Message
						message={{ text: element_text || inProgressLLM }}
						inline
						filters={["CHART", "TABLE", "PARA", "BULLETS", "FOOTNOTE"]}
						done={!loadingElement}
						updateMessage={updateElementMessage}
					/>
				) : (
					<div className="__sage-chart-element-loading">
						<div className="title">Creating Your Chart...</div>
						<LoadingBubble />
					</div>
				);
			case DealRoomProjectElementType.Table:
				return element_text || inProgressLLM ? (
					<Message
						message={{ text: element_text || inProgressLLM }}
						inline
						filters={["CHART", "TABLE", "PARA", "BULLETS", "FOOTNOTE"]}
						updateMessage={updateElementMessage}
					/>
				) : (
					<div className="__sage-chart-element-loading">
						<div className="title">Creating Your Table...</div>
						<LoadingBubble />
					</div>
				);
			case DealRoomProjectElementType.Paragraph:
				return element_text || inProgressLLM ? (
					<Message
						message={{ text: element_text || inProgressLLM }}
						inline
						filters={["CHART", "TABLE", "PARA", "BULLETS", "FOOTNOTE"]}
						updateMessage={updateElementMessage}
					/>
				) : (
					<div className="__sage-chart-element-loading">
						<div className="title">Creating Your Paragraph...</div>
						<LoadingBubble />
					</div>
				);
			case DealRoomProjectElementType.Bullets:
				return element_text || inProgressLLM ? (
					<Message
						message={{ text: element_text || inProgressLLM }}
						inline
						filters={["CHART", "TABLE", "PARA", "BULLETS", "FOOTNOTE"]}
						updateMessage={updateElementMessage}
					/>
				) : (
					<div className="__sage-chart-element-loading">
						<div className="title">Creating Your Bullets...</div>
						<LoadingBubble />
					</div>
				);
			default:
				return <div>Blank</div>;
		}
	}

	const [resizingRight, setResizingRight] = useState(false);

	const rightMouseHandler = useMouseHandler({
		onMouseDown(e) {
			e.preventDefault();
			e.stopPropagation();
			setResizingRight(true);
			document.body.style.cursor = "col-resize";
		},
		onMouseUp(e) {
			e.preventDefault();
			e.stopPropagation();
			setResizingRight(false);
			document.body.style.cursor = "";
		},
		onMouseMove(e) {
			setWidth((w) => {
				const delta = (e.movementX / 900) * 100;
				if (w + delta < 20) {
					return 20;
				}
				if (w + delta > 100) {
					return 100;
				}
				if (w + delta > 30 && w + delta < 100) {
					return w + delta;
				} else {
					return w;
				}
			});
		}
	});

	return (
		<div
			className={`__sage-project-element ${active ? "active" : ""} ${resizingRight ? "resize-active" : ""}`}
			style={{ width: `${width}%` }}
			onClick={() => setActive()}
		>
			{renderEl()}
			<div className="delete-element">
				<FaIcon
					icon={"trash-can"}
					color={"#a80300"}
					animation={"fa-shake"}
					size={"lg"}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						remove();
					}}
				></FaIcon>
			</div>
			<div className="move-element">
				<Row>
					<FaIcon
						icon={"backward"}
						color={"#00435c"}
						size={"lg"}
						tooltip={"Move element to previous page"}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							moveElementUp(element_id, page_id);
						}}
					></FaIcon>
					<FaIcon
						icon={"up"}
						color={"#00435c"}
						size={"lg"}
						tooltip={"Move element up within this page"}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							moveElementUpWithinPage(element_id, page_id);
						}}
					></FaIcon>
					<FaIcon
						icon={"down"}
						color={"#00435c"}
						size={"lg"}
						tooltip={"Move element down within this page"}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							moveElementDownWithinPage(element_id, page_id);
						}}
					></FaIcon>
					<FaIcon
						icon={"forward"}
						color={"#00435c"}
						size={"lg"}
						tooltip={"Move element to next page"}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							moveElementDown(element_id, page_id);
						}}
					></FaIcon>
					<FaIcon
						icon={"expand"}
						color={"#00435c"}
						size={"lg"}
						tooltip={"Open Element Details"}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setActive();
						}}
					></FaIcon>
				</Row>
			</div>
			{/*<div className={`resize-handle-right ${resizingRight ? "active" : ""}`} onMouseDown={rightMouseHandler}>
                <FaIcon
                    icon={"grip-lines-vertical"}
                    size="lg"
                    paddingInline="0"
                    padding="0"
                    hideBg
                />
            </div>*/}
		</div>
	);
}
