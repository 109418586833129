import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";
import { validatePassword } from "@sage/utils";

export function ConfirmResetPasswordPage() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();

	const { control, handleSubmit, setError } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		if (validatePassword(e.password)) {
			if (e.password !== e.confirmPassword) {
				setError("confirmPassword", {
					type: "custom",
					message: "Passwords do not match"
				});
				return;
			}
			let user_id = welcomeState.welcomeForm.user_id;

			const response = await UserService.resetPassword({
				email: welcomeState.welcomeForm.email,
				user_id: welcomeState.welcomeForm.user_id,
				password: e.password,
				verificationCode: welcomeState.welcomeForm.verificationCode
			});

			if (response.statusCode !== 200) {
				setError("password", {
					type: "custom",
					message: "Error resetting password."
				});
				return;
			}

			welcomeDispatch({
				type: WelcomeAction.UpdateWelcomeForm,
				payload: {
					...e,
					user_id
				}
			});
			navigate("/app/welcome/forgot-password/confirmation");
		} else {
			setError("password", {
				type: "custom",
				message: `Passwords must:\n- Contain at least one lower case letter\n- Contain at least one upper case letter\n- Contain at least one special character\n- Contain at least one number\n- Be at least 8 characters long`
			});
		}
	}

	return (
		<Form
			title={"Set New Password"}
			fields={[
				{
					label: "Password",
					placeholder: "* * * * * * * *",
					control,
					name: "password",
					description: "Please enter a password.",
					required: true,
					type: "password"
				},
				{
					label: "Confirm Password",
					placeholder: "* * * * * * * *",
					control,
					name: "confirmPassword",
					description: "Please confirm your password.",
					required: true,
					type: "password"
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Primary,
					children: "Next",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-next"
				}
			]}
		/>
	);
}
