import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { InitialWelcomeState, WelcomeAction, WelcomeContext, WelcomeReducer } from "@sage/state";
import { OnboardingLayout } from "@sage/shared/layouts";

export function OnboardingLayoutGuard() {
	const [welcomeState, welcomeDispatch] = React.useReducer(WelcomeReducer, InitialWelcomeState);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.has("TeamCode")) {
			const teamCode = searchParams.get("TeamCode");
			welcomeDispatch({
				type: WelcomeAction.UpdateWelcomeForm,
				payload: {
					teamCode
				}
			});
		}
	}, []);

	return (
		<WelcomeContext.Provider value={welcomeState}>
			<OnboardingLayout>
				<Outlet context={{ welcomeDispatch }} />
			</OnboardingLayout>
		</WelcomeContext.Provider>
	);
}
