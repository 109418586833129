import React, { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Collapsible } from "../Collapsible";
import { DropdownMenu, IDropdownOptionProps } from "../DropdownMenu";
import { Row } from "../Row";
import "./Ribon.scss";

interface IRibonState {
	openSection: string;
	prevOpenSection: string;
	sections: { id: string; title: string }[];
}

const initialRibonState: IRibonState = {
	openSection: null,
	prevOpenSection: null,
	sections: []
};

const RibonContext = createContext<IRibonState>(initialRibonState);

function createRibonState(sections, ribonRef) {
	const [openSection, setOpenSection] = useState<string>(null);
	const [prevOpenSection, setPrevOpenSection] = useState<string>(null);

	function updateOpenSection(newSecion: string) {
		ribonRef.current.style.overflow = "hidden";
		setOpenSection((old) => {
			setPrevOpenSection(old);
			setTimeout(() => {
				if (ribonRef.current) {
					ribonRef.current.style.overflow = "visible";
				}
			}, 200);
			return newSecion;
		});
	}

	return {
		openSection,
		prevOpenSection,
		updateOpenSection,
		sections
	};
}

function useRibonState() {
	return useContext(RibonContext);
}

export function Ribon({ children, alwaysVisible }: { children: ReactNode; alwaysVisible?: ReactNode }) {
	const ribonRef = useRef(null);
	const ribonState = createRibonState(children.length ? children.map((child) => child.props) : children.props, ribonRef);

	useEffect(() => {
		if (ribonState.openSection === null && children.length > 0) {
			ribonState.updateOpenSection(children[0].props.id);
		} else if (ribonState.openSection === null && children) {
			ribonState.updateOpenSection(children.props.id);
		}
	}, [children]);

	return (
		<RibonContext.Provider value={ribonState}>
			<div className="__sage-ribon-container">
				<Row
					paddingTop={"0.25rem"}
					paddingBottom={"0.25rem"}
					gap={"1.5rem"}
					wrap={false}
				>
					{children.length ? (
						children?.map((child) => (
							<div
								className={`__sage-ribon-section-name ${child.props.id === ribonState.openSection ? "active" : ""}`}
								key={child.props.id}
								onClick={() => ribonState.updateOpenSection(child.props.id)}
							>
								{child.props.title}
							</div>
						))
					) : (
						<div
							className={`__sage-ribon-section-name ${children.props.id === ribonState.openSection ? "active" : ""}`}
							key={children.props.id}
							onClick={() => ribonState.updateOpenSection(children.props.id)}
						>
							{children.props.title}
						</div>
					)}
				</Row>
				<div
					className={`__sage-ribon-section-container`}
					ref={ribonRef}
				>
					{children}
					{alwaysVisible}
				</div>
			</div>
		</RibonContext.Provider>
	);
}

export function RibonSection({ children, title, id }: { children: ReactNode; title: string; id: string }) {
	const { openSection, prevOpenSection } = useRibonState();

	if (openSection === id) {
		return (
			<div>
				<Row
					verticalAlign="center"
					height="100%"
					gap="0"
					wrap={false}
				>
					{children}
				</Row>
			</div>
		);
	}

	if (prevOpenSection === id) {
		return (
			<div className="out">
				<Row
					verticalAlign="center"
					height="100%"
					gap="0"
				>
					{children}
				</Row>
			</div>
		);
	}

	return null;
}

export function StaticRibonSection({ children }) {
	return (
		<div className="__sage-static-ribon-container">
			<Row
				verticalAlign="center"
				height="100%"
				gap="0"
				wrap={false}
			>
				{children}
			</Row>
		</div>
	);
}

export function RibonMenu({ children, options }: { children: ReactNode; options?: ReactNode }) {
	const [open, setOpen] = useState<boolean>(false);

	return (
		<div
			className="__sage-ribon-menu"
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}
		>
			{children}
			{options && (
				<div className={`ribon-dropdown ${open ? "open" : ""}`}>
					<div className="ribon-dropdown-inner">
						<Collapsible visible={open}>{options}</Collapsible>
					</div>
				</div>
			)}
		</div>
	);
}
