import { useState } from "react";
import { IDealRoomElementTemplate } from "@sage/types";
import { dealRoomProjectElementIcon } from "@sage/state";
import { Bullet, Col, Collapsible, DateHeader, FaIcon, Link, P, Row, SearchResult, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import "./TemplateItem.scss";

export function TemplateListItem({
	template,
	select,
	searchTerm
}: {
	template: IDealRoomElementTemplate;
	select: () => void;
	searchTerm?: string;
}) {
	const { icon, color } = dealRoomProjectElementIcon(template.element_type);
	const [promptVisible, setPromptVisible] = useState<boolean>(false);

	return (
		<div
			className="__sage-template-item"
			onClick={select}
		>
			<Col>
				<Row
					gap="1rem"
					wrap={false}
					verticalAlign="center"
				>
					<FaIcon
						color={color}
						icon={icon}
						width="fit-content"
						size={"2x"}
						borderRadius={"0.5rem"}
						paddingInline="0.75rem"
						padding="0.5rem"
						border
						hideBg
						direction="row"
					/>
					<Col gap={"0.25rem"}>
						<SectionTitle inline>
							<Link>
								<SearchResult searchTerm={searchTerm}>{template.element_name}</SearchResult>
							</Link>
						</SectionTitle>
						<Row verticalAlign="center">
							<DateHeader
								size="sm"
								thin
							>
								{template.lastModifiedTimestamp}
							</DateHeader>
							<Bullet size="sm" />
							<TimeHeader size="sm">{template.lastModifiedTimestamp}</TimeHeader>
							<Bullet size="sm" />
							<Tag
								size="sm"
								caps
							>
								{template.element_type}
							</Tag>
						</Row>
					</Col>
				</Row>
				{template.element_description && (
					<P>
						<SearchResult searchTerm={searchTerm}>{template.element_description}</SearchResult>
					</P>
				)}
				{template.search_terms && (
					<Row
						verticalAlign="center"
						wrap
						gap={"0.25rem"}
					>
						{template.search_terms.map((term, idx) => (
							<>
								<Tag
									size="sm"
									caps
								>
									<SearchResult searchTerm={searchTerm}>{term}</SearchResult>
								</Tag>
								{idx !== template.search_terms?.length - 1 && <Bullet size="sm" />}
							</>
						))}
					</Row>
				)}
				<Collapsible visible={promptVisible}>{template.prompt && <P>{template.prompt}</P>}</Collapsible>
			</Col>
		</div>
	);
}
