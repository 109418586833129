import { IDealRoomElementThread } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetElementThread(thread_id: string): Promise<IDealRoomElementThread> {
	return HttpService.Get<IDealRoomElementThread>({ path: `/dealrooms/element-threads/messages/${thread_id}` }).then((res) => res.payload);
}

export function GetThreadsByElement(element_id: string): Promise<IDealRoomElementThread[]> {
	return HttpService.Get<IDealRoomElementThread[]>({ path: `/dealrooms/element-threads/element/${element_id}` }).then(
		(res) => res.payload
	);
}

export function SaveElementThread(thread: Partial<IDealRoomElementThread>): Promise<IDealRoomElementThread> {
	return HttpService.Post<IDealRoomElementThread>({ path: "/dealrooms/element-threads", body: thread }).then((res) => res.payload);
}
