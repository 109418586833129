import React, { useEffect, useRef } from "react";
import { ISpan } from "@sage/types";
import { useDecksState } from "../../DecksState";

export function Span({ span }: { span: ISpan }) {
	const { scale, selection, activeSpans, addActiveSpan, removeActiveSpan, spanGroups, headers } = useDecksState();
	const spanRef = useRef(null);

	function updateSpanActive() {
		const rect = spanRef.current?.getBoundingClientRect();

		if (
			selection.x1 <= rect.x + rect.width &&
			selection.x2 >= rect.x &&
			selection.y1 <= rect.y + rect.height &&
			selection.y2 >= rect.y
		) {
			addActiveSpan(span.span_id);
		} else {
			removeActiveSpan(span.span_id);
		}
	}

	useEffect(() => {
		updateSpanActive();
	}, [selection, spanRef]);

	return (
		<div
			ref={spanRef}
			className={`span ${activeSpans.includes(span.span_id) ? "span-active" : ""} ${spanGroups.some((grp) => grp.includes(span.span_id)) || headers.some((grp) => grp.includes(span.span_id)) ? "span-visible" : ""}`}
			style={{
				fontSize: `${span.font_size * scale * 0.95}pt`,
				color: "black",
				top: `${span.bbox.y1 * scale}pt`,
				left: `${span.bbox.x1 * scale}pt`,
				width: `${span.bbox.w * scale}pt`,
				height: `${span.bbox.h * scale}pt`,
				fontFamily: span.font_family,
				fontWeight: span.flags.includes("bold") ? 700 : undefined
			}}
		>
			{span.text}
		</div>
	);
}
