import React, { useEffect, useState } from "react";
import "./DateBar.scss";

export function DateBar({ startTime, endTime, slices }: { startTime: number; endTime: number; slices?: number }) {
	const [chunks, setChunks] = useState(null);

	function calculateChunks() {
		if (startTime && endTime) {
			const slice_count = slices || 5;
			const duration = endTime - startTime;
			const slice_size = duration / slice_count;
			console.log(slice_size);

			const chunks_ = new Array(slice_count).fill(0);
			let last_chunk = startTime - slice_size;
			setChunks(
				chunks_.map(() => {
					last_chunk += slice_size;
					return last_chunk;
				})
			);
		}
	}

	useEffect(() => {
		calculateChunks();
	}, [startTime, endTime, slices]);

	return (
		<div className={"__date-bar-container"}>
			{chunks?.map((ch) => (
				<div
					key={ch}
					className={"date-bar-chunk"}
				>
					{new Date(ch).toLocaleDateString()}
				</div>
			))}
		</div>
	);
}
