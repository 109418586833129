import CryptoJS from "crypto-js";

export class Credentials {
	static StoreToken(token: any) {
		const key = Credentials.RetrieveEncryptionKey();
		const encrypted = CryptoJS.AES.encrypt(JSON.stringify(token), key);
		localStorage.setItem("b91e3bf1-63a5-4fdf-a157-b07b456d05cd", encrypted.toString());
	}

	static RetrieveToken() {
		const key = Credentials.RetrieveEncryptionKey();
		const encryptedToken = localStorage.getItem("b91e3bf1-63a5-4fdf-a157-b07b456d05cd");
		if (encryptedToken) {
			const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, key).toString(CryptoJS.enc.Utf8);
			return JSON.parse(decryptedToken);
		} else {
			return null;
		}
	}

	static PurgeToken(): void {
		localStorage.removeItem("b91e3bf1-63a5-4fdf-a157-b07b456d05cd");
	}

	static RetrieveEncryptionKey(): string {
		return localStorage.getItem("0a9e9aaa-0155-4943-8ae0-2ae9aa707e33");
	}

	static StoreEncryptionKey(key: string) {
		localStorage.setItem("0a9e9aaa-0155-4943-8ae0-2ae9aa707e33", key);
	}

	static PurgeEncryptionKey() {
		localStorage.removeItem("0a9e9aaa-0155-4943-8ae0-2ae9aa707e33");
	}
}
