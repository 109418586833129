import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $createHeadingNode } from "@lexical/rich-text";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { useOutletContext } from "react-router-dom";
import { AuthContext, WorkspaceAction, WorkspaceContext } from "@sage/state";
import { ProjectService } from "@sage/services";

export const HandleInsert = () => {
	const authState = React.useContext(AuthContext);
	const workspaceState = React.useContext(WorkspaceContext);
	const { workspaceDispatch } = useOutletContext<{ workspaceDispatch }>();
	const [editor] = useLexicalComposerContext();

	const [saveQueue, setSaveQueue] = React.useState([]);
	const saveQueueRef = React.useRef(saveQueue);
	saveQueueRef.current = saveQueue;

	const remoteChangesRef = React.useRef("");

	const activeDocumentRef = React.useRef("");
	activeDocumentRef.current = workspaceState.activeDocument;

	const acquireDocumentLock = (document_id: string) => {
		if (document_id) return ProjectService.AcquireDocumentLock(document_id, authState.user.user_id);
	};

	React.useEffect(() => {
		if (workspaceState.documentInsert.text) {
			editor.update(() => {
				const root = $getRoot();
				if (workspaceState.documentInsert.actor === "USER") {
					const headingNode = $createHeadingNode("h2");
					const textNode = $createTextNode(workspaceState.documentInsert.text);
					headingNode.append(textNode);

					const linebreakNode = $createParagraphNode().append($createTextNode());

					root.append(headingNode);
					root.append(linebreakNode);
					linebreakNode.selectEnd();
				} else if (workspaceState.documentInsert.actor === "ASSISTANT") {
					const paragraphNode = $createParagraphNode();
					const textNode = $createTextNode(workspaceState.documentInsert.text);
					paragraphNode.append(textNode);
					const linebreakNode = $createParagraphNode().append($createTextNode());

					root.append(paragraphNode);
					root.append(linebreakNode);

					linebreakNode.selectEnd();
				}
			});
		}
	}, [workspaceState.documentInsert]);

	const [editorStateCache, setEditorStateCache] = React.useState({});
	const editorStateCacheRef = React.useRef(editorStateCache);
	editorStateCacheRef.current = editorStateCache;

	const updateLock = () => {
		editor.update(async () => {
			const lock = await ProjectService.GetDocumentLock(activeDocumentRef.current);
			workspaceDispatch({
				type: WorkspaceAction.SetCurrentEditor,
				payload: lock?.editor
			});
			if (lock && lock.editor !== authState.user.user_id) {
				editor.setEditable(false);
				try {
					const eState = await ProjectService.GetEditorState(activeDocumentRef.current);
					console.log(eState);
					if (JSON.stringify(eState) !== editorStateCacheRef.current[activeDocumentRef.current]) {
						remoteChangesRef.current = JSON.stringify(eState);
						const state = editor.parseEditorState(eState);
						editor.setEditorState(state);
					}
				} catch (e) {
					console.log(e);
				}
			} else {
				editor.setEditable(true);
			}
		});
	};

	const checkSaveQueue = async () => {
		console.log("checkSaveQueue");
		updateLock();
		const saveActions = {};
		const saveQueueCopy = JSON.parse(JSON.stringify(saveQueueRef.current));
		setSaveQueue([]);

		saveQueueCopy.forEach((saveAction) => {
			if (saveAction.document_id in saveActions) {
				if (saveAction.timestamp > saveActions[saveAction.document_id]) {
					saveActions[saveAction.document_id] = saveAction;
				}
			} else {
				saveActions[saveAction.document_id] = saveAction;
			}
		});

		for (let document_id of Object.keys(saveActions)) {
			if (await acquireDocumentLock(document_id)) {
				await ProjectService.SaveEditorState(document_id, editorStateCacheRef.current[document_id]);
			}
		}
		if (Object.keys(saveActions).length) await acquireDocumentLock(activeDocumentRef.current);
	};

	React.useEffect(() => {
		const checkSaveQueueTimer = setInterval(checkSaveQueue, 1000);

		return () => clearInterval(checkSaveQueueTimer);
	}, []);

	React.useEffect(() => {
		activeDocumentRef.current = workspaceState.activeDocument;
		editor.update(async () => {
			if (activeDocumentRef.current) {
				const lock = await acquireDocumentLock(activeDocumentRef.current);
				if (lock) {
					editor.setEditable(true);
				} else {
					editor.setEditable(false);
				}
				if (activeDocumentRef.current in editorStateCacheRef.current) {
					const state = editor.parseEditorState(JSON.parse(editorStateCacheRef.current[activeDocumentRef.current]));
					editor.setEditorState(state);
				} else {
					try {
						const eState = await ProjectService.GetEditorState(activeDocumentRef.current);
						const state = editor.parseEditorState(eState);
						editor.setEditorState(state);
					} catch (e) {
						const eState = JSON.parse(
							'{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
						);
						const state = editor.parseEditorState(eState);
						editor.setEditorState(state);
					}
				}
			}
		});
	}, [workspaceState.activeDocument]);

	const saveEditorState = async (editorState) => {
		const eState = JSON.stringify(editorState);
		if (
			eState !==
			'{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
		) {
			if (eState !== remoteChangesRef.current) {
				setEditorStateCache((c) => ({
					...c,
					[activeDocumentRef.current]: eState
				}));
				setSaveQueue((q) => [
					...q,
					{
						timestamp: new Date().getTime(),
						document_id: activeDocumentRef.current
					}
				]);
			} else {
				remoteChangesRef.current = "";
			}
		}
	};

	return (
		<OnChangePlugin
			onChange={saveEditorState}
			ignoreSelectionChange={true}
		/>
	);
};
