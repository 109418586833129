import React, { useEffect, useRef, useState } from "react";
import { MouseHandler } from "@sage/utils";
import "./Toggle.scss";

export function Toggle({
	active,
	update,
	toggle,
	testid
}: {
	active: boolean;
	update: (active: boolean) => void;
	toggle: () => void;
	testid?: string;
}) {
	const toggleWrapperRef = useRef(null);

	const [isDragging, setIsDragging] = useState<boolean>(false);
	const isDraggingRef = useRef(null);
	isDraggingRef.current = isDragging;

	const [position, setPosition] = useState<number>(null);
	const positionRef = useRef(null);
	positionRef.current = position;

	const animationLockRef = useRef(false);
	animationLockRef.current = false;

	function onMouseMove(e) {
		setIsDragging(true);
		isDraggingRef.current = true;

		const new_p = positionRef.current + e.movementX;

		if (new_p > 0 && new_p < 16) {
			setPosition(new_p);
			positionRef.current = new_p;
		} else if (new_p >= 8) {
			setPosition(16);
			positionRef.current = 16;
		} else {
			setPosition(0);
			positionRef.current = 0;
		}
	}

	function onMouseUp() {
		if (isDraggingRef.current) {
			setIsDragging(false);
			isDraggingRef.current = false;

			if (positionRef.current >= 8) {
				update(true);
				animate(16);
			} else {
				update(false);
				animate(0);
			}
		} else {
			toggle();
			animate(active ? 0 : 16);
		}
	}

	function animate(to: number) {
		if (!animationLockRef.current) {
			animationLockRef.current = true;
			const step_size = 1;
			const step_duration = 100 / 32;
			const direction = to < 8 ? -1 : 1;

			let interval;

			function next() {
				const new_p = positionRef.current + step_size * direction;

				if (new_p * direction >= to) {
					clearInterval(interval);
					animationLockRef.current = false;
					setPosition(to);
					positionRef.current = to;
				} else {
					setPosition(new_p);
					positionRef.current = new_p;
				}
			}

			interval = setInterval(next, step_duration);
		}
	}

	function onMouseDown(e) {
		e.stopPropagation();
	}

	const toggleMouseHandler = MouseHandler({ onMouseMove, onMouseUp, onMouseDown });

	useEffect(() => {
		if (active && position === 0) {
			animate(16);
		} else if (!active && position === 16) {
			animate(0);
		} else if (position === null) {
			setPosition(active ? 16 : 0);
		}
	}, [active]);

	return (
		<div
			ref={toggleWrapperRef}
			className={`__toggle-container  ${active ? "active" : ""} ${isDragging ? "dragging" : ""}`}
			onMouseDown={toggleMouseHandler}
			data-testid={testid}
		>
			<div
				className="toggle-inner"
				style={{ transform: `translateX(${position}px)` }}
			/>
		</div>
	);
}
