import { useEffect, useState } from "react";
import { ITeam } from "@sage/types";
import { AdminService } from "@sage/services";
import { ButtonBorderShape, Col, PageHeader, Table, TableColumn, TableColumnSpacing, TableRow } from "@sage/shared/core";

export function Accounts() {
	const [teams, setTeams] = useState<ITeam[]>([]);

	async function loadTeams() {
		setTeams(await AdminService.GetAllTeams());
	}

	useEffect(() => {
		loadTeams();
	}, []);

	return (
		<Col>
			<PageHeader title={"Accounts"} />
			<Table
				items={teams}
				actions={[
					{
						children: "New Account",
						borderShape: ButtonBorderShape.Round,
						to: "new"
					}
				]}
				headers={
					<TableRow>
						<TableColumn>Team Name</TableColumn>
						<TableColumn>Team Code</TableColumn>
						<TableColumn
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							Created
						</TableColumn>
					</TableRow>
				}
				row={(account: ITeam) => (
					<TableRow>
						<TableColumn>{account.teamName}</TableColumn>
						<TableColumn>{account.teamCode}</TableColumn>
						<TableColumn
							relativeDate
							spacing={TableColumnSpacing.Fixed}
							width={"10rem"}
						>
							{account.createdTimestamp}
						</TableColumn>
					</TableRow>
				)}
			/>
		</Col>
	);
}
