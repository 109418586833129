export interface IDeck {
	deck_id: string;
	user_id: string;
	teamCode: string;
	text: string;
	slides: ISlide[];
	deck_name: string;
	processing_status: DeckProcessingStatus;
	lastModifiedTimestamp: number;
	createdTimestamp: number;
}

export enum DeckProcessingStatus {
	NotStarted = "NotStarted",
	InProgress = "InProgress",
	Finished = "Finished"
}

export interface IBBox {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
	h: number;
	w: number;
}

export interface ISlide {
	slide_id: string;
	deck_id: string;
	idx: number;
	text: string;
	image: string;
	bbox: IBBox;
	blocks: IBlock[];
}

export interface IBlock {
	block_id: string;
	slide_id: string;
	deck_id: string;
	text?: string;
	image?: string;
	bbox: IBBox;
	lines: ILine[];
}

export interface ILine {
	line_id: string;
	block_id: string;
	slide_id: string;
	deck_id: string;
	text: string;
	bbox: IBBox;
	spans: ISpan[];
}

export interface ISpan {
	span_id: string;
	line_id: string;
	block_id: string;
	slide_id: string;
	deck_id: string;
	text: string;
	bbox: IBBox;
	font_size: number;
	font_family: string;
	flags: string[];
	color: string;
}

export interface ISlideData {
	groups: string[];
	headers: string[];
	headerMapping: IHeaderMapping;
	rewrittenSections: IRewrittenSections;
}

export interface IRewrittenSections {
	[key: string]: string;
}

export interface IHeaderMapping {
	[key: string]: string[];
}
