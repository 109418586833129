import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { ICompany } from "@sage/types";
import { DealstreamService } from "@sage/services";
import { ButtonBorderShape, Link, Table, TableColumn, TableRow, TeamDetails } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import "./Search.scss";

export function SearchPage() {
	const [searchParams, setSearchParams] = useSearchParams();
	const { control, handleSubmit } = useForm();
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState([]);

	async function search() {
		const keyword = searchParams.get("search");
		if (keyword) {
			setLoading(true);
			const companies = await DealstreamService.SearchCompany(keyword);

			setResults(companies);
			setLoading(false);
		}
	}

	function setSearch(formValue) {
		if (formValue.keyword.length > 0) {
			setSearchParams({
				search: formValue.keyword
			});
		}
	}

	useEffect(() => {
		search();
	}, [searchParams]);

	return (
		<div className={"__search-page-container"}>
			<TeamDetails />
			<div className={"search-bar"}>
				<Form
					handleSubmit={handleSubmit(setSearch)}
					fields={[
						{
							control,
							name: "keyword",
							variant: FormFieldVariant.Vertical,
							placeholder: "Search for a company",
							actions: [
								{
									loading,
									children: "Search",
									action: handleSubmit(setSearch),
									borderShape: ButtonBorderShape.Round
								}
							]
						}
					]}
				/>
			</div>
			{results.length ? (
				<Table
					items={results}
					headers={
						<TableRow>
							<TableColumn width={"1"}>Name</TableColumn>
							<TableColumn width={"3"}>Description</TableColumn>
						</TableRow>
					}
					row={(company: ICompany) => (
						<TableRow>
							<TableColumn width={"1"}>
								<Link to={`/app/fs/private-company/${company.uid}`}>{company.legal_name}</Link>
							</TableColumn>
							<TableColumn width={"3"}>{company.description}</TableColumn>
						</TableRow>
					)}
				/>
			) : null}
		</div>
	);
}
