import { IMessage, IThread } from "@sage/types";
import { HttpService } from "../HttpService";

export class AthenaService {
	static getThreads() {
		return HttpService.Get<IThread[]>({ path: "/threads" }).then(({ payload }) => payload);
	}

	static loadThread(thread_id: string) {
		return HttpService.Get<IThread>({ path: `/threads/${thread_id}` });
	}

	static createThread(body) {
		return HttpService.Post({ path: "/threads", body });
	}

	static saveMessage(message: IMessage) {
		return HttpService.Post({ path: "/threads/message", body: message });
	}

	static updateThread(body: { thread_id: string; sourceFiles?: string[]; sourceCompanies?: string[] }) {
		return HttpService.Post({ path: `/threads/update/${body.thread_id}`, body });
	}
}
