import { useNavigate } from "react-router-dom";
import { IVaultFile, VaultFileType } from "@sage/types";
import { VaultService } from "@sage/services";

export interface IVaultHandlerProps {
	navigate?: (file_id: string) => void;
	newTab?: boolean;
}

export function useVaultHandler(props?: IVaultHandlerProps) {
	const navigate = useNavigate();

	async function open(file: IVaultFile) {
		if (file.file_type === VaultFileType.FOLDER) {
			if (props?.navigate) {
				props?.navigate(file.file_id);
			} else {
				if (props?.newTab !== false) {
					window.open(`/app/fs/vault/files/${file.file_id}`, "_blank");
				} else {
					navigate(`/app/fs/vault/files/${file.file_id}`);
				}
			}
		} else {
			const url = await VaultService.GetFileUrl(file);
			window.open(url, props?.newTab !== false ? "_blank" : null);
		}
	}

	async function loadAndOpen(file_id: string) {
		await open(await VaultService.LoadFile(file_id));
	}

	return { open, loadAndOpen };
}
