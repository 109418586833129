import { IDealRoomProjectPage } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetPages(project_id: string): Promise<IDealRoomProjectPage[]> {
	return HttpService.Get<IDealRoomProjectPage[]>({ path: `/dealrooms/pages/project/${project_id}` }).then((res) => res.payload);
}

export function DeletePage(page_id: string) {
	return HttpService.Delete({ path: `/dealrooms/pages/${page_id}` }).then((res) => res.payload);
}

export function SavePage(page: Partial<IDealRoomProjectPage>): Promise<IDealRoomProjectPage> {
	return HttpService.Post<IDealRoomProjectPage>({ path: "/dealrooms/pages", body: page }).then((res) => res.payload);
}

export function UpdatePageIndex(page_id: string, page_index: number) {
	return HttpService.Post({ path: `/dealrooms/pages/${page_id}/index/${page_index}` }).then((res) => res.payload);
}

export function UpdatePageName(page_id: string, page_name: string) {
	return HttpService.Post({ path: `/dealrooms/pages/${page_id}/name`, body: { page_name } }).then((res) => res.payload);
}

export function UpdatePageLayout(page_id: string, layout: string) {
	return HttpService.Post({ path: `/dealrooms/pages/${page_id}/layout/${layout}` }).then((res) => res.payload);
}
