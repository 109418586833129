import { useEffect, useState } from "react";
import { useDealRoomProject } from "@sage/state";
import { DealRoomService, LlmService } from "@sage/services";
import { Col, FaIcon, LoadingBubble, Modal, P, Row, SectionTitle } from "@sage/shared/core";
import { usePrompts } from "@sage/prompts";
import "./ScreenshotModal.scss";

export function ScreenshotModal() {
	const { showScreenshotModal, setShowScreenshotModal, createPageFromTemplate } = useDealRoomProject();
	const [isFileOver, setIsFileOver] = useState<boolean>(false);
	const [files, setFiles] = useState<string[]>([]);
	const [loadingElements, setLoadingElements] = useState<boolean>(false);
	const prompts = usePrompts();

	function fileToBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				resolve(reader.result);
			};

			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file);
		});
	}

	function parseResponse(generated_text: string) {
		try {
			return JSON.parse(generated_text);
		} catch (_) {
			return JSON.parse(generated_text.split("```json")[1].split("```")[0]);
		}
	}

	async function generateStuff(file) {
		setLoadingElements(true);
		const { generated_text } = await LlmService.Stream(
			{
				prompt: "Here is my image",
				image: file,
				preprompt: prompts.screenshot()
			},
			() => {}
		);

		const pageObj = parseResponse(generated_text);

		const elements = await Promise.all(pageObj.elements.map((el) => DealRoomService.SaveTemplate(el)));

		const pageTemplate = await DealRoomService.SavePageTemplate({
			page_name: pageObj.page_name,
			page_title: pageObj.page_title,
			elements: elements.map((el) => el.element_id)
		});

		createPageFromTemplate(pageTemplate);
		dismissModal();
	}

	async function dropHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		setIsFileOver(false);

		const _files = [];

		for (let item of e.dataTransfer.items) {
			if (item.kind === "file") {
				const file = item.getAsFile();
				_files.push(await fileToBase64(file));
			}
		}

		setFiles((f) => [...f, ..._files]);

		if (_files.length) {
			await generateStuff(_files[0]);
		}
	}

	function manualUpload() {
		const input = document.createElement("input");
		input.type = "file";
		input.style.display = "none";
		input.multiple = false;

		input.addEventListener("change", async (e) => {
			const _file = await fileToBase64(e.target.files[0]);
			setFiles((f) => [...f, _file]);
			await generateStuff(_file);
			document.body.removeChild(input);
		});

		document.body.appendChild(input);
		input.click();
	}

	function dragOverHandler(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	function dragEnterHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		for (let item of e.dataTransfer.items) {
			if (item.kind === "file") {
				setIsFileOver(true);
			}
		}
	}

	function dragLeaveHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		setIsFileOver(false);
	}

	function dismissModal() {
		setFiles([]);
		setLoadingElements(false);
		setShowScreenshotModal(false);
	}

	async function handlePaste(e) {
		for (let item of e.clipboardData.items) {
			if (item.kind === "file") {
				const _file = await fileToBase64(item.getAsFile());
				setFiles((f) => [...f, _file]);
				await generateStuff(_file);
			}
		}
	}

	useEffect(() => {
		document.addEventListener("paste", handlePaste);
		return () => {
			document.removeEventListener("paste", handlePaste);
		};
	}, []);

	return (
		<Modal
			visible={showScreenshotModal}
			onDismiss={dismissModal}
			minWidth={"50rem"}
		>
			<div
				className="__sage-deal-room-screenshot-modal-container"
				onDrop={dropHandler}
				onDragLeave={dragLeaveHandler}
				onDragEnter={dragEnterHandler}
				onDragOver={dragOverHandler}
			>
				<SectionTitle>Create Slide From Screenshot</SectionTitle>
				{files.length === 0 && (
					<div className={`drop-zone ${isFileOver ? "active" : ""}`}>
						<div
							className="drop-zone-text"
							onClick={manualUpload}
						>
							<FaIcon
								icon="cloud-arrow-up"
								size="10x"
								color="#0094f3"
								hideBg
							/>
							<P>Drag and drop your screenshot in.</P>
						</div>
					</div>
				)}
				<Col>
					{files.length > 0 &&
						files.map((f, idx) => (
							<UploadingFile
								key={`${idx}`}
								file={f}
							/>
						))}
					{loadingElements && (
						<Row>
							<div className="screenshot-loading">
								<div className="title">Generating Slide Elements</div>
								<LoadingBubble />
							</div>
						</Row>
					)}
				</Col>
			</div>
		</Modal>
	);
}

function UploadingFile({ file }: { file: string }) {
	return (
		<div className="file">
			<img src={file} />
		</div>
	);
}
