import { ReactNode } from "react";
import { DealRoomProjectElementType, DealRoomProjectPageType } from "@sage/types";
import { dealRoomProjectElementIcon, dealRoomProjectPageIcon } from "@sage/state";
import { FaIcon } from "@sage/shared/core";
import { notNullOrUndefined } from "@sage/utils";

export function ElementIcon({
	elementType,
	children,
	tooltip,
	onClick,
	size
}: {
	elementType: DealRoomProjectElementType;
	tooltip?: string;
	children?: ReactNode;
	onClick?: () => void;
	size?: string;
}) {
	const { icon, color } = dealRoomProjectElementIcon(elementType);
	return (
		<FaIcon
			icon={icon}
			color={color}
			size={size || "2x"}
			onClick={onClick}
			tooltip={tooltip}
		>
			{children || elementType.toString()}
		</FaIcon>
	);
}

export function PageIcon({
	pageType,
	children,
	onClick,
	tooltip,
	size
}: {
	pageType: DealRoomProjectPageType;
	tooltip?: string;
	children?: ReactNode;
	onClick?: () => void;
	size?: string;
}) {
	const { icon, color } = dealRoomProjectPageIcon(pageType);
	return (
		<FaIcon
			icon={icon}
			color={color}
			size={size || "2x"}
			onClick={onClick}
			tooltip={tooltip}
		>
			{notNullOrUndefined(children) ? children : pageType.toString()}
		</FaIcon>
	);
}
