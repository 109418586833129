import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { IProject } from "@sage/types";
import { AuthContext, FsContext } from "@sage/state";
import { NewsService, ProjectService } from "@sage/services";
import { InlineLoading } from "@sage/shared/core";
import { ArticleWithImage } from "@sage/shared/other";
import { LookupUser } from "@sage/utils";
import "./Dashboard.scss";

dayjs.extend(relativeTime);

export function Dashboard() {
	const authState = useContext(AuthContext);
	const fsState = useContext(FsContext);

	const [articles, setArticles] = useState([]);
	const [greeting, setGreeting] = useState("");

	async function loadArticles() {
		const response = await NewsService.GetNews();

		setArticles(response.filter((article) => article.description !== null));
	}

	function updateGreeting() {
		const hour = new Date().getHours();
		let prefix;

		if (hour > 3 && hour < 12) {
			prefix = "Good Morning";
		} else if (hour >= 12 && hour <= 5) {
			prefix = "Good Afternoon";
		} else {
			prefix = "Good Evening";
		}

		setGreeting(`${prefix}, ${authState.user.firstName}`);
	}

	useEffect(() => {
		if (authState.user) updateGreeting();
	}, [authState.user]);

	useEffect(() => {
		loadArticles();
	}, []);

	return (
		<div className="__dashboard-container">
			<div className="dashboard-title">{greeting}</div>
			<div className="section">
				<div className="flex-1" />
				<div className="recent-docs">
					{fsState.sharedProjects.projects.slice(0, 4).map((proj) => (
						<Doc
							key={proj.project_id}
							doc={proj}
						/>
					))}
				</div>
			</div>
			<div className="news-section">
				{articles.map((article) => (
					<ArticleWithImage
						key={article.uid}
						article={article}
					/>
				))}
			</div>
		</div>
	);
}

function Doc({ doc }: { doc: IProject }) {
	const [previewText, setPreviewText] = useState("");

	const loadPreviewText = async () => {
		const documents = await ProjectService.LoadDocumentsByProject(doc.project_id);

		for (let document of documents) {
			try {
				const eState = await ProjectService.GetEditorState(document.document_id);
				setPreviewText(eStateToPreview(eState.root).substring(0, 125));
			} catch (e) {}
		}
	};

	useEffect(() => {
		loadPreviewText();
	}, [doc.documents]);

	return (
		<div className="doc-container">
			<div className="col">
				<img
					className="doc-icon"
					src={"/cdn/sage/icons/document-new.svg"}
				/>
			</div>
			<div className="col flex-1">
				<div className="doc-name">{doc.projectName}</div>
				<div className="dt">
					<UserTagV2 user_id={doc.user_id} />
					<div>•</div>Updated {dayjs(doc.lastModifiedTimestamp).fromNow()}
				</div>
				<div className="doc-description">{previewText}...</div>
			</div>
		</div>
	);
}

function UserTagV2({ user_id }) {
	const [user, setUser] = useState(localStorage.getItem(user_id));

	const lookupUser = async () => {
		if (!user) {
			setUser(await LookupUser(user_id));
		}
	};

	useEffect(() => {
		lookupUser();
	}, [user_id]);

	return <span>{user}</span> || <InlineLoading />;
}

function eStateToPreview(state) {
	return state.children
		.map((child) => {
			if (child.text) {
				return child.text;
			} else if (child.children) {
				return eStateToPreview(child);
			} else {
				return "";
			}
		})
		.join(" ");
}
