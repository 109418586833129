import React, { useContext, useEffect, useRef, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import { AuthContext } from "@sage/state";
import { Autosuggest } from "@sage/shared/forms";
import { UserIcon } from "../UserIcon/UserIcon";

export interface IUserAutosuggestProps {
	control: Control;
	name: string;
	label?: string;
	description?: string;
	setValue: (name: string, value: any) => any | void;
	onChange?: (user) => void;
}

export function UserAutosuggest({ control, name, label, description, setValue, onChange }: IUserAutosuggestProps) {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const authState = useContext(AuthContext);
	const fieldRef = useRef(null);

	const userName = useWatch({ control, name });

	function handleFieldChange(e) {
		fieldRef.current = e;
		setLoading(true);
		setOptions([]);
		setTimeout(() => {
			if (fieldRef.current == e) {
				const users = authState.team.members.filter((member) =>
					`${member.firstName} ${member.lastName}`.toLowerCase().includes(e.toLowerCase())
				);
				setOptions(
					users.map((user) => {
						return {
							icon: (
								<UserIcon
									user_id={user.user_id}
									size="sm"
								/>
							),
							user_id: user.user_id,
							value: `${user.firstName} ${user.lastName}`,
							label: `${user.firstName} ${user.lastName}`
						};
					})
				);
				setLoading(false);
			}
		}, 1500);
	}

	function handleClick(option) {
		setValue("user_id", option._id);

		if (onChange) onChange(option);
	}

	useEffect(() => {
		setOptions(
			authState.team.members.map((user) => {
				return {
					icon: (
						<UserIcon
							user_id={user.user_id}
							size="sm"
						/>
					),
					user_id: user.user_id,
					value: `${user.firstName} ${user.lastName}`,
					label: `${user.firstName} ${user.lastName}`
				};
			})
		);
	}, []);

	useEffect(() => {
		if (options.length === 0 && userName?.length) {
			handleFieldChange(userName);
		}
	}, [userName]);

	return (
		<Autosuggest
			control={control}
			name={name}
			options={options}
			setValue={setValue}
			onChange={handleFieldChange}
			label={label}
			description={description}
			onClick={handleClick}
			loading={loading}
		/>
	);
}
