import { useEffect, useState } from "react";
import { CrawlStatus, IDealRoomCrawl } from "@sage/types";
import { DealRoomService } from "@sage/services";
import { Bullet, Col, DateHeader, FaIcon, P, Row, SearchResult, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import "./Crawl.scss";

export function Crawl({
	crawl,
	searchTerm,
	startCrawl,
	deleteCrawl
}: {
	crawl: IDealRoomCrawl;
	searchTerm?: string;
	startCrawl?: () => void;
	deleteCrawl?: () => void;
}) {
	const [nPages, setNPages] = useState<number>(null);

	async function loadPageCount() {
		const res = await DealRoomService.CountCrawledPages(crawl.host);
		setNPages(res.count);
	}

	useEffect(() => {
		const interval = setInterval(async () => {
			if (crawl.crawl_status === CrawlStatus.InProgress) {
				await loadPageCount();
			}
		}, 5000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		loadPageCount();
	}, [crawl?.crawl_id, crawl?.crawl_status]);

	return (
		<div className="__sage-deal-room-crawl">
			<Col>
				<Row
					verticalAlign={"center"}
					wrap={false}
					gap={"1rem"}
				>
					<FaIcon
						color={"#5700a8"}
						icon={"globe-pointer"}
						width="fit-content"
						size={"2x"}
						borderRadius={"0.5rem"}
						paddingInline="0.75rem"
						padding="0.5rem"
						border
						hideBg
						direction="row"
					/>
					<Col gap={"0.125rem"}>
						<SectionTitle inline>
							<SearchResult searchTerm={searchTerm}>{crawl.host}</SearchResult>
						</SectionTitle>
					</Col>
				</Row>
				<Row verticalAlign="center">
					<Tag size="sm">Last Refreshed</Tag>
					<Bullet size="sm" />
					<DateHeader
						size="sm"
						thin
					>
						{crawl.lastModifiedTimestamp}
					</DateHeader>
					<TimeHeader size="sm">{crawl.lastModifiedTimestamp}</TimeHeader>
					<Bullet size="sm" />
					<Tag size="sm">Pages</Tag>
					<Bullet size="sm" />
					<Tag size="sm">{nPages}</Tag>
				</Row>
				<Row horizontalAlign="right">
					{startCrawl &&
						(crawl.crawl_status !== CrawlStatus.InProgress ? (
							<FaIcon
								icon="arrows-rotate"
								color="#00a857"
								size="lg"
								onClick={startCrawl}
							/>
						) : (
							<FaIcon
								icon="arrows-rotate"
								color="#5700a8"
								size="lg"
								animation="fa-spin"
								loop
							/>
						))}
					{deleteCrawl && (
						<FaIcon
							icon="trash-can"
							color="#a80300"
							size="lg"
							onClick={deleteCrawl}
						/>
					)}
				</Row>
			</Col>
		</div>
	);
}
