import React, { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

export interface IToolsPaneState {
	activeTab: ActiveTab;
	setActiveTab: Dispatch<SetStateAction<ActiveTab>>;
}

const InitialToolsPaneState: IToolsPaneState = {
	activeTab: null,
	setActiveTab: null
};

export const ToolsPaneContext = createContext(InitialToolsPaneState);

export function createToolsPaneState(): IToolsPaneState {
	const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Template);
	return { activeTab, setActiveTab };
}

export function useToolsPaneState() {
	const toolsPaneContext = useContext<IToolsPaneState>(ToolsPaneContext);

	return {
		...toolsPaneContext
	};
}

export enum ActiveTab {
	Template = "template",
	Vault = "vault",
	ReferenceSlides = "reference-slides",
	ProjectFiles = "project-files",
	Athena = "athena"
}
