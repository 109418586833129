import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IMeeting } from "@sage/types";
import { DealRoomProvider } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Col, DeleteConfirmation, FaIcon, HidePrint, Row } from "@sage/shared/core";
import { isNullOrUndefined } from "../../../../../Utils/Helpers";
import { Header } from "../Header";
import { Meeting } from "../Meeting";
import { UploadMeetingModal } from "../UploadMeetingModal";
import "./Meetings.scss";

export function Meetings() {
	return (
		<DealRoomProvider>
			<MeetingsPage />
		</DealRoomProvider>
	);
}

export function MeetingsPage() {
	const params = useParams();
	const [meetings, setMeetings] = useState<IMeeting[]>([]);
	const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
	const [meetingToDelete, setMeetingToDelete] = useState<string>(null);

	async function loadMeetings() {
		if (params.deal_id) {
			setMeetings((await DealRoomService.GetMeetings(params.deal_id)).sort((b, a) => a.createdTimestamp - b.createdTimestamp));
		}
	}

	async function deleteMeeting() {
		await DealRoomService.DeleteMeeting(meetingToDelete);
		setMeetingToDelete(null);
		await loadMeetings();
	}

	useEffect(() => {
		loadMeetings();
	}, [params.deal_id]);

	const _meetings = [
		{
			meeting_id: "1",
			meeting_name: "Sage AI Platform Demo for Sunbelt Business Advisors",
			meeting_overview:
				"Demo presentation of Sage's AI-powered M&A document automation platform to Sunbelt Business Advisors team, discussing capabilities, security, pricing and potential implementation.",
			meeting_notes:
				"Brandon from Sage demonstrated their AI platform that helps automate various aspects of M&A deal processes, from information gathering to document creation. The platform can analyze data rooms, create charts/graphs, draft SIMs and other documents, and allows for customization.\n\nKey discussion points included security measures (end-to-end encryption, AWS hosting), pricing ($240/user/month), and integration capabilities. Sunbelt team expressed interest but noted they already have robust internal systems, particularly for their Main Street business broker deals. The team requested additional marketing materials and examples to evaluate further.",
			tasks: [
				{
					task_name: "Share Marketing Materials",
					description:
						"Send marketing collateral, pricing guidelines, and platform capabilities overview to Sunbelt team and Michael",
					due_date: "2025-02-08",
					task_owners: [
						{
							name: "Brandon",
							responsibility: "Compile and send marketing materials and examples to team"
						}
					]
				},
				{
					task_name: "Internal Evaluation",
					description: "Review Sage platform capabilities and conduct cost-benefit analysis",
					due_date: null,
					task_owners: [
						{
							name: "Sunbelt Team",
							responsibility: "Meet internally to discuss potential implementation and next steps"
						}
					]
				}
			],
			attendees: [
				{
					name: "Brandon",
					role: "Sage Representative",
					summary: "Led the demo presentation and explained platform capabilities, security features, and pricing"
				},
				{
					name: "Manny",
					role: "Sunbelt Business Advisor",
					summary: "Raised security concerns and discussed team structure/size (70 people in office)"
				},
				{
					name: "Nate",
					role: "M&A Analyst",
					summary: "Provided perspective on True North M&A deals and expressed interest in data room analysis capabilities"
				},
				{
					name: "Analyst 1",
					role: "Main Street SIM Writer",
					summary:
						"Asked about platform's graphic capabilities and provided context about Main Street business documentation needs"
				}
			],
			createdTimestamp: new Date().getTime()
		}
	];

	return (
		<div className="__sage-deal-room-meetings">
			<Header />
			<div className="meetings">
				<Col horizontalAlign="center">
					<HidePrint>
						<Row horizontalAlign="center">
							<FaIcon
								icon="layer-plus"
								size="2x"
								color="#5700a8"
								onClick={() => setUploadModalVisible(true)}
							></FaIcon>
						</Row>
					</HidePrint>
					{meetings.map((meeting) => (
						<Meeting
							key={meeting.meeting_id}
							meeting={meeting}
							loadMeetings={loadMeetings}
							deleteMeeting={() => setMeetingToDelete(meeting.meeting_id)}
						/>
					))}
				</Col>
			</div>
			<UploadMeetingModal
				uploadModalVisible={uploadModalVisible}
				setUploadModalVisible={setUploadModalVisible}
				loadMeetings={loadMeetings}
			/>
			<DeleteConfirmation
				visible={!isNullOrUndefined(meetingToDelete)}
				onDismiss={() => setMeetingToDelete(null)}
				onConfirm={deleteMeeting}
			></DeleteConfirmation>
		</div>
	);
}
