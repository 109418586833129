import React, { useState } from "react";
import { Button, IButtonProps } from "@sage/shared/core";
import "./FloatingPane.scss";

interface IFloatingPaneProps {
	children?: any;
	actions?: IButtonProps[];
	title?: string;
	footer?: any;
	resizable?: boolean;
	resizeDirection?: "right" | "left";
}

const MIN_WIDTH = 25 * 16;

export function FloatingPane({ children, actions, title, footer, resizable, resizeDirection }: IFloatingPaneProps) {
	const [rightActive, setRightActive] = useState<boolean>(false);
	const [leftActive, setLeftActive] = useState<boolean>(false);
	const [bottomActive, setBottomActive] = useState<boolean>(false);
	const [height, setHeight] = useState<number>(30 * 16);
	const [width, setWidth] = useState<number>(MIN_WIDTH);

	function widthRightMouseDown() {
		document.body.style.userSelect = "none";
		document.addEventListener("mousemove", widthRightMouseMove);
		document.addEventListener("mouseup", widthRightMouseUp);
		setRightActive(true);
	}

	function widthRightMouseUp() {
		document.body.style.userSelect = "";
		document.removeEventListener("mouseup", widthRightMouseUp);
		document.removeEventListener("mousemove", widthRightMouseMove);
		setRightActive(false);
	}

	function widthRightMouseMove(e) {
		setWidth((w) => {
			const newWidth = w + e.movementX;
			if (newWidth > MIN_WIDTH) {
				return newWidth;
			} else {
				return w;
			}
		});
	}

	function widthLeftMouseDown() {
		document.body.style.userSelect = "none";
		document.addEventListener("mousemove", widthLeftMouseMove);
		document.addEventListener("mouseup", widthLeftMouseUp);
		setLeftActive(true);
	}

	function widthLeftMouseUp() {
		document.body.style.userSelect = "";
		document.removeEventListener("mouseup", widthLeftMouseUp);
		document.removeEventListener("mousemove", widthLeftMouseMove);
		setLeftActive(false);
	}

	function widthLeftMouseMove(e) {
		setWidth((w) => {
			const newWidth = w - e.movementX;
			if (newWidth > MIN_WIDTH) {
				return newWidth;
			} else {
				return w;
			}
		});
	}

	function heightBottomMouseDown() {
		document.body.style.userSelect = "none";
		document.addEventListener("mousemove", heightBottomMouseMove);
		document.addEventListener("mouseup", heightBottomMouseUp);
		setBottomActive(true);
	}

	function heightBottomMouseUp() {
		document.body.style.userSelect = "";
		document.removeEventListener("mouseup", heightBottomMouseUp);
		document.removeEventListener("mousemove", heightBottomMouseMove);
		setBottomActive(false);
	}

	function heightBottomMouseMove(e) {
		setHeight((h) => {
			const newHeight = h + e.movementY;
			if (newHeight > 30 * 16) {
				return newHeight;
			} else {
				return h;
			}
		});
	}

	function bottomRightMouseDown() {
		document.body.style.userSelect = "none";
		document.addEventListener("mousemove", bottomRightMouseMove);
		document.addEventListener("mouseup", bottomRightMouseUp);
		setRightActive(true);
		setBottomActive(true);
	}

	function bottomRightMouseUp() {
		document.body.style.userSelect = "";
		document.removeEventListener("mouseup", bottomRightMouseUp);
		document.removeEventListener("mousemove", bottomRightMouseMove);
		setRightActive(false);
		setBottomActive(false);
	}

	function bottomRightMouseMove(e) {
		setHeight((h) => {
			const newHeight = h + e.movementY;
			if (newHeight > 30 * 16) {
				return newHeight;
			} else {
				return h;
			}
		});
		setWidth((w) => {
			const newWidth = w + e.movementX;
			if (newWidth > MIN_WIDTH) {
				return newWidth;
			} else {
				return w;
			}
		});
	}

	function bottomLeftMouseDown() {
		document.body.style.userSelect = "none";
		document.addEventListener("mousemove", bottomLeftMouseMove);
		document.addEventListener("mouseup", bottomLeftMouseUp);
		setLeftActive(true);
		setBottomActive(true);
	}

	function bottomLeftMouseUp() {
		document.body.style.userSelect = "";
		document.removeEventListener("mouseup", bottomLeftMouseUp);
		document.removeEventListener("mousemove", bottomLeftMouseMove);
		setLeftActive(false);
		setBottomActive(false);
	}

	function bottomLeftMouseMove(e) {
		setHeight((h) => {
			const newHeight = h + e.movementY;
			if (newHeight > 30 * 16) {
				return newHeight;
			} else {
				return h;
			}
		});
		setWidth((w) => {
			const newWidth = w - e.movementX;
			if (newWidth > MIN_WIDTH) {
				return newWidth;
			} else {
				return w;
			}
		});
	}

	return (
		<div
			className={`__floating-pane-container ${leftActive ? "left-active" : ""} ${rightActive ? "right-active" : ""} ${bottomActive ? "bottom-active" : ""} ${resizable || resizeDirection === "right" ? "resize-right" : ""} ${resizeDirection === "left" ? "resize-left" : ""}`}
			style={{ width: `${width}px`, height: `${height}px` }}
		>
			{title ? <div className={"pane-title"}>{title}</div> : null}
			{children ? <div className={"pane-content"}>{children}</div> : null}
			{footer}
			{actions ? (
				<div className={"pane-actions"}>
					{actions.map((action, idx) => (
						<Button
							key={idx}
							{...action}
						/>
					))}
				</div>
			) : null}
			{resizeDirection === "left" ? (
				<>
					<div
						className={"left-handle"}
						onMouseDown={widthLeftMouseDown}
					/>
					<div
						className={"left-corner-handle"}
						onMouseDown={bottomLeftMouseDown}
					/>
					<div
						className={"bottom-handle"}
						onMouseDown={heightBottomMouseDown}
					/>
				</>
			) : null}
			{(resizable || resizeDirection === "right") && resizeDirection !== "left" ? (
				<>
					<div
						className={"right-handle"}
						onMouseDown={widthRightMouseDown}
					/>
					<div
						className={"corner-handle"}
						onMouseDown={bottomRightMouseDown}
					/>
					<div
						className={"bottom-handle"}
						onMouseDown={heightBottomMouseDown}
					/>
				</>
			) : null}
		</div>
	);
}
