import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { useChartColors } from "@sage/state";
import { Chart, format_value, useDims } from "../ChartProvider";

export function PieChart({
	title,
	data,
	colors,
	updateColor
}: {
	title?: string;
	data: { category: string; value: number; date: Date; value_label?: string }[];
	colors?: { [key: string]: string };
	updateColor?: (category: string, color: string) => void;
}) {
	const { chart_colors } = useChartColors();
	const c = useMemo(
		() =>
			d3
				.scaleOrdinal()
				.domain([
					...Object.keys(colors || {}),
					...data.map((d) => d.category).filter((c) => !Object.keys(colors || {}).includes(c))
				])
				.range([
					// "#003466",
					// "#01509d",
					// "#007acd",
					// "#66a3fe",
					// "#cce1ff",
					...Object.keys(colors || {}).map((c) => colors[c]),
					...(chart_colors?.length > 0
						? chart_colors
						: ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056", "#8dddd0"]),
					...d3.schemeTableau10
				]),
		[data, colors]
	);

	const legend = useMemo(
		() => [...new Set(data.map((d) => d.category))].sort().map((category) => ({ category, color: c(category) })),
		[data, c]
	);

	return (
		<Chart
			legend={legend}
			title={title}
			updateColor={updateColor ? updateColor : undefined}
			data={data}
		>
			<Series
				data={data}
				c={c}
			/>
		</Chart>
	);
}

function Series({ data, c }) {
	const dims = useDims();
	const containerRef = useRef(null);
	const seriesRef = useRef(null);
	const linesRef = useRef(null);
	const labelsRef = useRef(null);

	const pie = useMemo(() => d3.pie().value((d) => d.value), []);

	useEffect(() => {
		if ((seriesRef.current, containerRef.current && c && data)) {
			const radius = Math.min(dims.height, dims.width) / 2;

			const arc = d3
				.arc()
				.outerRadius(radius)
				.innerRadius(radius * 0.7);
			const outerArc = d3
				.arc()
				.innerRadius(radius * 0.9)
				.outerRadius(radius * 0.9);

			d3.select(seriesRef.current)
				.attr("transform", `translate(${dims.width / 2}, ${dims.height / 2})`)
				.selectAll("path")
				.data(pie(data))
				.join("path")
				.attr("fill", (d) => c(d.data.category))
				.attr("d", arc)
				.attr("stroke", "white")
				.attr("stroke-width", 4);

			d3.select(labelsRef.current)
				.attr("transform", `translate(${dims.width / 2}, ${dims.height / 2})`)
				.selectAll("text")
				.data(pie(data))
				.join("text")
				.attr("transform", function (d) {
					const pos = outerArc.centroid(d);
					const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
					pos[0] = radius * 1.25 * (midAngle < Math.PI ? 1 : -1);
					return `translate(${pos})`;
				})
				.style("text-anchor", function (d) {
					const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
					return midAngle < Math.PI ? "start" : "end";
				})
				.text((d) => d.data.value_label || format_value(d.data.value))
				.attr("font-size", "0.85rem")
				.attr("font-weight", "500")
				.attr("fill", "black")
				.attr("dy", ".35em");

			d3.select(linesRef.current)
				.attr("transform", `translate(${dims.width / 2}, ${dims.height / 2})`)
				.selectAll("polyline")
				.data(pie(data))
				.join("polyline")
				.attr("points", function (d) {
					const pos = outerArc.centroid(d);
					const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
					pos[0] = radius * 1.2 * (midAngle < Math.PI ? 1 : -1);
					return [arc.centroid(d), outerArc.centroid(d), pos];
				})
				.style("fill", "none")
				.style("stroke", "black")
				.style("stroke-width", "1px");
		}
	}, [seriesRef.current, containerRef.current, c, dims, data]);

	return (
		<svg
			ref={containerRef}
			width={"100%"}
			height={dims.height}
		>
			<g ref={seriesRef} />
			<g ref={linesRef} />
			<g ref={labelsRef} />
		</svg>
	);
}
