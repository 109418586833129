import { IDealRoomCrawlSource } from "@sage/types";
import { Bullet, Col, DateHeader, FaIcon, Row, SectionTitle, Tag, TimeHeader } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import "./SourceCrawl.scss";

export function SourceCrawl({ crawl }: { crawl: IDealRoomCrawlSource }) {
	return (
		<div className="__sage-deal-room-source-crawl">
			<a
				href={crawl.url}
				target="_blank"
			>
				<Col>
					<Row
						verticalAlign={"center"}
						wrap={false}
						gap={"1rem"}
					>
						<FaIcon
							color={"#5700a8"}
							icon={"globe-pointer"}
							width="fit-content"
							size={"2x"}
							borderRadius={"0.5rem"}
							paddingInline="0.75rem"
							padding="0.5rem"
							border
							hideBg
							direction="row"
						/>
						<Col gap={"0.125rem"}>
							<SectionTitle
								inline
								size="sm"
							>
								{crawl.page_title}
							</SectionTitle>
						</Col>
					</Row>
					<Row verticalAlign="center">
						<Tag size="sm">Last Refreshed</Tag>
						<Bullet size="sm" />
						<DateHeader
							size="sm"
							thin
						>
							{crawl.enriched_at}
						</DateHeader>
						<TimeHeader size="sm">{crawl.enriched_at}</TimeHeader>
						{!isNullOrUndefined(crawl.relevance_score) && (
							<>
								<Bullet size="sm" />
								<Tag size="sm">{Math.round(crawl.relevance_score * 10000) / 100}% Match</Tag>
							</>
						)}
					</Row>
				</Col>
			</a>
		</div>
	);
}
