import React, { useEffect, useState } from "react";
import { IAcquisition } from "@sage/types";
import { DealstreamService } from "@sage/services";
import { InlineLoading } from "@sage/shared/core";
import "./Acquisition.scss";

export function Acquisition({ acquisition_id }) {
	const [acquisition, setAcquisition] = useState<IAcquisition>(null);

	async function loadAcquisition() {
		if (acquisition_id && acquisition_id !== acquisition?.acquisition_id) {
			setAcquisition(await DealstreamService.GetAcquisition(acquisition_id));
		}
	}

	useEffect(() => {
		loadAcquisition();
	}, [acquisition_id]);

	return (
		<div className={"__acquisition-card"}>
			{acquisition ? (
				<>
					{acquisition?.acquisition_date ? (
						<div className={"item"}>
							<div className={"key"}>Acquisition Date</div>
							<div
								className={"value"}
								data-testid="acquisition-date"
							>
								{new Date(acquisition.acquisition_date).toLocaleDateString()}
							</div>
						</div>
					) : null}
					{acquisition?.acquisition_type ? (
						<div className={"item"}>
							<div className={"key"}>Acquisition Type</div>
							<div
								className={"value"}
								data-testid="acquisition-type"
							>
								{acquisition.acquisition_type}
							</div>
						</div>
					) : null}
					{acquisition?.price ? (
						<div className={"item"}>
							<div className={"key"}>Price</div>
							<div
								className={"value"}
								data-testid="acquisition-price"
							>
								{acquisition.price}
							</div>
						</div>
					) : null}
				</>
			) : (
				<InlineLoading invert />
			)}
		</div>
	);
}
