import { IVaultFile } from "@sage/types";
import { ILlmSource } from "@sage/services";

/**
 * @typedef {Object} IGetRagSourceProps
 * @property {IVaultFile[]} sourceFiles - a list of source files attached to this project
 * @property {string[]} sourceCompanies - a list of source companies attached to this project
 * @property {string[]} activeSourceFiles - a list of "active" or selected source files
 * @property {string[]} activeSourceCompanies - a list of "active" or selected source companies
 * @property {string} teamCode - user's team code from auth state
 */
export interface IGetRagSourcesProps {
	sourceFiles: IVaultFile[];
	sourceCompanies: string[];
	activeSourceFiles: string[];
	activeSourceCompanies: string[];
	teamCode: string;
}

/**
 *   buildRagSources()
 *
 *   This function returns a list of sources that can be used with the
 *   sage llm api, based on the state of the user's selections.
 *
 *   1. If no companies are selected AND no source files are selected:
 *       1.a. If source files exist:
 *           - add all source files
 *       1.b. If source companies exist:
 *           - add all source companies
 *       1.c. Else:
 *           - Add entire vault
 *
 *   2. Else:
 *       2.a. If active companies:
 *           - add active companies
 *       2.b. If active files:
 *           - add active files
 *
 *   3. Finally:
 *       - Turn the arrays of sourceFile prefixes and sourceCompany ids
 *           into an array of ILlmSource objects that can be passed into
 *           the llm api.
 *
 *
 *  @param {IGetRagSourcesProps} props
 *  @returns {ILlmSource}
 */
export function buildRagSources({ sourceFiles, sourceCompanies, activeSourceFiles, activeSourceCompanies, teamCode }): ILlmSource[] {
	let sources: ILlmSource[] = [];
	let neededSourceFiles: string[] = [];
	let neededSourceCompanies: string[] = [];

	function getFileK() {
		if (neededSourceCompanies.length) {
			return 5;
		}
		return 10;
	}

	function getCompanyK() {
		if (neededSourceFiles.length) {
			return 5;
		}
		return 10;
	}

	if (activeSourceCompanies.length) {
		neededSourceCompanies = [...neededSourceCompanies, ...activeSourceCompanies];
	}

	if (activeSourceFiles.length) {
		neededSourceFiles = [
			...neededSourceFiles,
			...activeSourceFiles
				.map((file_id: string) => sourceFiles.find((file: IVaultFile) => file.file_id === file_id))
				.filter((file: IVaultFile) => !!file)
				.map((file: IVaultFile) => file.prefix)
		];
	}

	if (neededSourceCompanies.length) {
		sources.push({
			knn: false,
			index: "companies",
			pretty: true,
			label: "Company Data",
			target_fields: ["legal_name"],
			uids: neededSourceCompanies
		});

		const companyK = getCompanyK();

		for (let company_id of neededSourceCompanies) {
			sources.push({
				knn: true,
				index: "company_pages",
				company_id,
				label: "Web Pages:",
				target_fields: ["url", "l1_content"],
				k: companyK,
				size: companyK
			});
		}
	}

	if (neededSourceFiles.length) {
		const fileK = getFileK();

		sources.push({
			index: "vault_data_v2",
			knn: true,
			pretty: true,
			teamCode: teamCode,
			prefix: neededSourceFiles,
			target_fields: ["file_name", "file_id", "page_content"],
			label: "Document Data:",
			k: fileK,
			size: fileK
		});
	}

	return sources;
}
