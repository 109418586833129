import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "@sage/state";
import { Button, ButtonBorderShape, ButtonIcon } from "@sage/shared/core";
import { UserIcon } from "@sage/shared/other";
import { useDecksState } from "../DecksState";
import "./Toolbar.scss";

export function Toolbar() {
	const authState = useContext(AuthContext);
	const { deck } = useDecksState();

	return (
		<div className="__decks-toolbar-container">
			<Link to={"/app"}>
				<img
					alt={"logo"}
					className={"header-img"}
					src={"https://cdn.ccm.vc/sage/logo-lg.svg"}
				/>
			</Link>
			<div className="toolbar-rows">
				<div className="toolbar-row">
					<div
						className="deck-name"
						contentEditable
						suppressContentEditableWarning
					>
						{deck?.deck_name}
					</div>
				</div>
				<div className="toolbar-row">
					<div className="toolbar-actions">
						<div className="action">File</div>
						<div className="action">Tools</div>
						<div className="action">References</div>
						<div className="action">Help</div>
					</div>
				</div>
			</div>
			<div className="share">
				<Button
					borderShape={ButtonBorderShape.Round}
					icon={ButtonIcon.MaterialShareWhite}
				>
					Share
				</Button>
			</div>
			<div className="share">
				<UserIcon user_id={authState.user.user_id} />
			</div>
		</div>
	);
}
