import React, { useEffect, useState } from "react";
import { ILlmRequest, LlmService } from "@sage/services";
import { InlineLoading } from "@sage/shared/core";
import { InlineSource } from "@sage/shared/other";
import "./TemplateSection.scss";

export interface ITemplateSection {
	item_type: "h1" | "h2" | "h3" | "p" | "prompt";
	text: string;
	id: string;
}

export interface ITemplateSectionProps {
	request?: ILlmRequest;
	templateSection: ITemplateSection;
	finish: (e: { [key: string]: any }) => void;
	idx: number;
}

export function TemplateSection({ request, templateSection, finish, idx }: ITemplateSectionProps) {
	switch (templateSection.item_type) {
		case "h1":
			return (
				<H1Section
					templateSection={templateSection}
					finish={finish}
					idx={idx}
				/>
			);
		case "h2":
			return (
				<H2Section
					templateSection={templateSection}
					finish={finish}
					idx={idx}
				/>
			);
		case "h3":
			return (
				<H3Section
					templateSection={templateSection}
					finish={finish}
					idx={idx}
				/>
			);
		case "p":
			return (
				<PSection
					templateSection={templateSection}
					finish={finish}
					idx={idx}
				/>
			);
		case "prompt":
			return (
				<PromptSection
					templateSection={templateSection}
					request={request}
					finish={finish}
					idx={idx}
				/>
			);
	}
}

function PromptSection({ request, finish, idx }: ITemplateSectionProps) {
	const [inProgress, setInProgress] = useState<string>(null);
	const [sources, setSources] = useState([]);

	async function generate() {
		const resp = await LlmService.Stream(request, setInProgress);
		setSources(resp.sources);
		const node = ParagraphNode({
			text: resp.generated_text,
			type: "paragraph",
			tag: "p"
		});
		finish({ [`${idx}`]: node });
	}

	useEffect(() => {
		if (request) generate();
	}, [request]);

	return (
		<div className="__template-section-container">
			{inProgress || <InlineLoading invert />}
			{sources?.length ? (
				<div className={"sources"}>
					{sources.map((source, idx) => (
						<InlineSource
							key={idx}
							citation={source}
							capped
						/>
					))}
				</div>
			) : null}
		</div>
	);
}

function H1Section({ templateSection, finish, idx }: ITemplateSectionProps) {
	useEffect(() => {
		if (templateSection) {
			const node = ParagraphNode({
				text: templateSection.text,
				type: "heading",
				tag: "h1"
			});
			finish({ [`${idx}`]: node });
		}
	}, [templateSection]);

	return <h1>{templateSection.text}</h1>;
}

function H2Section({ templateSection, finish, idx }: ITemplateSectionProps) {
	useEffect(() => {
		if (templateSection) {
			const node = ParagraphNode({
				text: templateSection.text,
				type: "heading",
				tag: "h2"
			});
			finish({ [`${idx}`]: node });
		}
	}, [templateSection]);

	return <h2>{templateSection.text}</h2>;
}

function H3Section({ templateSection, finish, idx }: ITemplateSectionProps) {
	useEffect(() => {
		if (templateSection) {
			const node = ParagraphNode({
				text: templateSection.text,
				type: "heading",
				tag: "h3"
			});
			finish({ [`${idx}`]: node });
		}
	}, [templateSection]);

	return <h3>{templateSection.text}</h3>;
}

function PSection({ templateSection, finish, idx }: ITemplateSectionProps) {
	useEffect(() => {
		if (templateSection) {
			const node = ParagraphNode({
				text: templateSection.text,
				type: "paragraph",
				tag: "p"
			});
			finish({ [`${idx}`]: node });
		}
	}, [templateSection]);

	return <p>{templateSection.text}</p>;
}

const ParagraphNode = ({ text, tag, type }) => [
	{
		children: [TextNode({ text })],
		direction: "ltr",
		format: "",
		indent: 0,
		type,
		tag,
		version: 1
	},
	{
		children: [TextNode({ text: "\n" })],
		direction: "ltr",
		format: "",
		indent: 0,
		type,
		tag,
		version: 1
	}
];

const TextNode = ({ text }) => ({
	detail: 0,
	format: 0,
	mode: "normal",
	style: "",
	text,
	version: 1,
	type: "text"
});
