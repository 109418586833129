import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useWorkflowState } from "@sage/state";
import { Button, ButtonBorderShape } from "@sage/shared/core";
import { Tiles } from "@sage/shared/forms";
import "./Templates.scss";

export function Templates() {
	const { form, updatePageStatus } = useWorkflowState();
	const navigate = useNavigate();
	const template_method = useWatch({ control: form.control, name: "template_method" });

	function handleNavigate() {
		updatePageStatus("complete", "Templates");

		switch (template_method) {
			case "generate-template":
				updatePageStatus("skip", "Templates", "Use a Sage Template");
				updatePageStatus("skip", "Templates", "Use an Existing Template");
				navigate("../generate-template");
				break;
			case "existing-template":
				updatePageStatus("skip", "Templates", "Use a Sage Template");
				updatePageStatus("skip", "Templates", "Generate a Template");
				navigate("../select-template");
				break;
			case "sage-template":
				updatePageStatus("skip", "Templates", "Generate a Template");
				updatePageStatus("skip", "Templates", "Use an Existing Template");
				navigate("../sage-template");
				break;
		}
	}

	useEffect(() => {
		updatePageStatus("in-progress", "Templates");
		return () => {
			updatePageStatus("complete", "Templates");
		};
	}, []);

	return (
		<section>
			<h2>Templates</h2>
			<p>How would you like to generate your CIM section?</p>
			<p>
				<strong>Use an Existing Template:</strong> Use a template that you've already created.
			</p>
			<p>
				<strong>Use a Sage Template:</strong> Use a Template provided by Sage
			</p>
			<Tiles
				control={form.control}
				setValue={form.setValue}
				name={"template_method"}
				tiles={[
					// {
					// 	id: "generate-template",
					// 	label: "Generate a Template",
					// 	description: "Generate a template from a previous CIM."
					// },
					{
						id: "existing-template",
						label: "Use an Existing Template",
						description: "Use a template that you've already created."
					},
					{
						id: "sage-template",
						label: "Use a Sage Template",
						description: "Use a template provided by Sage."
					}
				]}
			/>
			<Button
				borderShape={ButtonBorderShape.Round}
				action={handleNavigate}
			>
				Next
			</Button>
		</section>
	);
}
