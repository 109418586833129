import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { useChartColors } from "@sage/state";
import { Chart, format_tick, format_value, useDims } from "../ChartProvider";

export function AreaChart({
	title,
	data,
	colors,
	updateColor
}: {
	title?: string;
	data: { category: string; value: number; date: Date }[];
	colors?: { [key: string]: string };
	updateColor?: (category: string, color: string) => void;
}) {
	const { chart_colors } = useChartColors();
	const c = useMemo(
		() =>
			d3
				.scaleOrdinal()
				.domain([
					...Object.keys(colors || {}),
					...data.map((d) => d.category).filter((c) => !Object.keys(colors || {}).includes(c))
				])
				.range([
					// "#007932",
					// "#a64dff",
					// "#007acd",
					// "#66a3fe",
					...Object.keys(colors || {}).map((c) => colors[c]),
					...(chart_colors?.length > 0
						? chart_colors
						: ["#0b84a5", "#f6c85f", "#6f4e7c", "#9dd866", "#ca472f", "#ffa056", "#8dddd0"]),
					...d3.schemeTableau10
				]),
		[data, colors]
	);

	const legend = useMemo(
		() => [...new Set(data.map((d) => d.category))].sort().map((category) => ({ category, color: c(category) })),
		[data, c]
	);

	return (
		<Chart
			legend={legend}
			title={title}
			updateColor={updateColor ? updateColor : undefined}
			data={data}
		>
			<Series
				data={data}
				c={c}
			/>
		</Chart>
	);
}

function Series({ data, c }) {
	const dims = useDims();
	const containerRef = useRef(null);
	const seriesRef = useRef(null);
	const fillRef = useRef(null);
	const marksRef = useRef(null);
	const labelsRef = useRef(null);
	const xAxisRef = useRef(null);
	const xGridRef = useRef(null);
	const yAxisRef = useRef(null);
	const yGridRef = useRef(null);

	const groupedData = useMemo(() => {
		const stack = d3.stack();
		const keys = stack.keys(d3.union(data.map((d) => d.category)));
		const values = keys.value(([, map], key) => map.get(key)?.value || 0)(
			d3.index(
				data,
				(d) => d.date,
				(d) => d.category
			)
		);
		return values;
	}, [data]);

	const x = useMemo(() => {
		const minDate = d3.min(data, (d) => new Date(d.date).getTime());
		const maxDate = d3.max(data, (d) => new Date(d.date).getTime());

		const duration = maxDate - minDate;

		const pad = duration * 0.05;

		return d3
			.scaleUtc()
			.domain([minDate - pad, maxDate + pad])
			.range([dims.marginLeft, dims.width - dims.marginRight]);
	}, [data, dims]);

	const y = useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([0, d3.max(groupedData, (d) => d3.max(d, (d) => d[1])) * 1.05])
				.range([dims.height - dims.marginBottom, dims.marginTop]),
		[groupedData, dims]
	);

	const area = useMemo(
		() =>
			d3
				.area()
				.x((d) => x(d.data[0]))
				.y0((d) => y(d[0]))
				.y1((d) => y(d[1])),
		[x, y]
	);

	const line = useMemo(
		() =>
			d3
				.line()
				.x((d) => x(d.data[0]))
				.y((d) => y(d[1]))
				.curve(d3.curveCatmullRom.alpha(0.5)),
		[x, y]
	);

	useEffect(() => {
		if ((seriesRef.current, containerRef.current && x && y && c && groupedData)) {
			d3.select(seriesRef.current)
				.selectAll("path")
				.data(groupedData)
				.join(
					(enter) => enter.append("path").attr("stroke-width", 2).attr("fill", "none").attr("d", line),
					(update) => update.attr("d", line).attr("stroke", (d) => c(d.key)),
					(exit) => exit.remove()
				);

			d3.select(fillRef.current)
				.selectAll("path")
				.data(groupedData)
				.join(
					(enter) =>
						enter
							.append("path")
							.attr("fill", (d) => `${c(d.key)}1A`)
							.attr("d", area),
					(update) => update.attr("fill", (d) => `${c(d.key)}1A`).attr("d", area),
					(exit) => exit.remove()
				);

			d3.select(labelsRef.current)
				.selectAll("g")
				.data(groupedData)
				.join("g")
				.selectAll("text")
				.data((d) => d)
				.join("text")
				.attr("text-anchor", "middle")
				.attr("font-size", "0.85rem")
				.attr("font-weight", "500")
				.attr("x", (d) => {
					const xPos = x(d.data[0]);
					return xPos;
				})
				.attr("y", (d) => y(d[1]) - 9)
				.text((d) => format_value(d[1] - d[0]));

			d3.select(marksRef.current)
				.selectAll("g")
				.data(groupedData)
				.attr("fill", (d) => c(d.key))
				.attr("stroke", "white")
				.attr("stroke-width", 2)
				.join("g")
				.selectAll("circle")
				.data((d) => d)
				.join("circle")
				.attr("cx", (d) => x(d.data[0]))
				.attr("cy", (d) => y(d[1]) + 1)
				.attr("r", 4);

			d3.select(xAxisRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3
						.axisBottom(x)
						.ticks(5)
						.tickFormat((d) => {
							try {
								const dateString = new Date(d).toLocaleDateString("en-US", {
									month: "long",
									day: "numeric",
									year: "numeric",
									timeZone: "UTC"
								});
								if (dateString.includes("January 1") || dateString.includes("December 31")) {
									return new Date(d).toLocaleDateString("en-US", { year: "numeric", timeZone: "UTC" });
								}
								return dateString;
							} catch (e) {
								return d.toLocaleString("en-US", { timeZone: "UTC" });
							}
						})
				)
				.style("stroke-opacity", 0.6);

			d3.select(yAxisRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(d3.axisLeft(y).ticks(6).tickFormat(format_tick))
				.style("stroke-opacity", 0.6);
			d3.select(yGridRef.current)
				.attr("transform", `translate(${dims.marginLeft}, 0)`)
				.call(
					d3
						.axisLeft(y)
						.tickSize(-(dims.width - dims.marginRight - dims.marginLeft))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);

			d3.select(xGridRef.current)
				.attr("transform", `translate(0, ${dims.height - dims.marginBottom})`)
				.call(
					d3
						.axisBottom(x)
						.tickSize(-(dims.height - dims.marginTop - dims.marginBottom))
						.tickFormat("")
				)
				.style("stroke-dasharray", "2,2")
				.style("stroke-opacity", 0.24);
		}
	}, [seriesRef.current, containerRef.current, x, y, c, dims, groupedData, data]);

	return (
		<svg
			ref={containerRef}
			width={"100%"}
			height={dims.height}
		>
			<g ref={xAxisRef} />
			<g ref={xGridRef} />
			<g ref={yAxisRef} />
			<g ref={yGridRef} />
			<g ref={seriesRef} />
			<g ref={fillRef} />
			<g ref={marksRef} />
			<g ref={labelsRef} />
		</svg>
	);
}
